import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Button, Form, Header, Icon, Loader, Menu, Segment} from "semantic-ui-react";
import axios from "axios";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import {toast, ToastContainer} from 'react-toastify';
import {UserRoleContext} from "../../Main";
import moment from "moment";
import DocumentTypeForm from "./DocumentTypeForm";
import SearchTableData from "../Utilities/SearchTableData";
import PaginationFront from "../Utilities/PaginationFront";
import ReactExport from 'react-data-export';


const reducer = (state, action)=> {
    switch (action.type) {
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'documentTypes':
            return {...state, documentTypes: action.payload};
        case 'documentTypesData':
            return {...state, documentTypesData: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'openTable':
            return {...state, openTable: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;

    }
};

const DocumentTypes = () => {
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const {activeRole} = useContext(UserRoleContext);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [state, dispatch] = useReducer(reducer,{
        documentTypes: [],
        documentTypesData: [],
        currentId: null,
        organizer: '',
        organizers: [],
        openTable: false,
        data:[],
        results:[],
        postsPerPage: 20,
        currentPosts: [],

    });

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    const columns = [
        { title: 'Vrsta obrasca', width: { wpx: 200 } },
        { title: 'Opseg zaduženih serijskih brojeva', width: { wpx: 250 } },
        { title: 'Datum zaduženja', width: { wpx: 150 } },
        { title: 'Napomena', width: { wpx: 150 } },
    ];

    if (activeRole !== "ROLE_ORGANIZER") {
        columns.unshift({ title: 'Organizator', width: { wpx: 350 } });
    }

    const multiDataSet = [
        {
            columns: columns,
            data: state.documentTypesData,
        }
    ];

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            dispatch({type: 'openTable', payload: true});
            getDocumentTypesForAdmin(value)
        }
        if(name === 'organizer' && !value){
            dispatch({type: 'openTable', payload: true});
            getDocumentTypesAll()
        }
    }

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    // let toggleEditForm = () =>{
    //     setShowEditForm(!showEditForm)
    // };

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function deleteDocumentType(id){
        setCurrentId(id);
        dispatch({type: 'loader', payload: true});
        axios.delete(`/form/delete/${id}`,).then(
            (res) => {
                if (activeRole === "ROLE_ORGANIZER") {getDocumentTypes();}
                else {getDocumentTypesForAdmin(state.organizer)}
                toast('Uspješno ste izbrisali zaduženje obrasca!');
                setCurrentId(null);
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            if (err.response.data.message === 'This form cannot be deleted because there are documents with serial number from its range!') {
                toast('Zaduženje obrasca ne može biti obrisano jer postoje dokumenti sa serijskim brojem iz opsega odabranog zaduženja!');
            }
            console.log('err', err)
        });
    }

    useEffect(() => {
        if (activeRole === "ROLE_ORGANIZER") {
            getDocumentTypes()
        }
        else if(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") {
            getOrganizers();
            getDocumentTypesAll();
        }
        // if (activeRole === "ROLE_ORGANIZER") {getDocumentTypes()}
        // else {getOrganizers();}
    },[activeRole]);


    function getDocumentTypes(){
        dispatch({type: 'loader', payload: true});
        axios.get('/form/by-organizer',).then(
            (res) => {
                // let documentTypes = res.data;
                let documentTypes = res.data.map(el => ({
                    ...el,
                    formTypeFilter: el.formType.name,
                    dateFilter: moment(el.createdDate).format('D. M. YYYY.')
                }))
                dispatch({type: 'documentTypes', payload: documentTypes});
                dispatch({type: 'data', payload: documentTypes});
                let documentTypesData = res.data.map(el => ([
                    { value: el.formType.name},
                    { value: el.rangeFrom === el.rangeTo ? el.rangeFrom : el.rangeFrom +' - ' + el.rangeTo },
                    { value: moment(el.createdDate).format('D. M. YYYY.') },
                    { value: el.comment ? el.comment : ''},
                ]));
                dispatch({type: 'documentTypesData', payload: documentTypesData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getDocumentTypesAll(){
        dispatch({type: 'loader', payload: true});
        axios.get('/form/all',).then(
            (res) => {
                // let documentTypes = res.data;
                let documentTypes = res.data.map(el => ({
                    ...el,
                    formTypeFilter: el.formType.name,
                    dateFilter: moment(el.createdDate).format('D. M. YYYY.')
                }))
                dispatch({type: 'documentTypes', payload: documentTypes});
                dispatch({type: 'data', payload: documentTypes});
                let documentTypesData = res.data.map(el => ([
                    { value: el.organizer.name},
                    { value: el.formType.name},
                    { value: el.rangeFrom === el.rangeTo ? el.rangeFrom : el.rangeFrom +' - ' + el.rangeTo },
                    { value: moment(el.createdDate).format('D. M. YYYY.') },
                    { value: el.comment ? el.comment : ''},
                ]));
                dispatch({type: 'documentTypesData', payload: documentTypesData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getDocumentTypesForAdmin(id){
        setCurrentId(id);
        setCurrentIdOrganizer(id);
        dispatch({type: 'loader', payload: true});
        dispatch({type: 'openTable', payload: true});
        axios.get(`/form/by-organizer-id/${id}`,).then(
            (res) => {
                let documentTypes = res.data.map(el => ({
                    ...el,
                    formTypeFilter: el.formType.name,
                    dateFilter: moment(el.createdDate).format('D. M. YYYY.')
                }))
                // let documentTypes = res.data;
                dispatch({type: 'documentTypes', payload: documentTypes});
                dispatch({type: 'data', payload: documentTypes});
                let documentTypesData = res.data.map(el => ([
                    { value: el.organizer.name},
                    { value: el.formType.name},
                    { value: el.rangeFrom === el.rangeTo ? el.rangeFrom : el.rangeFrom +' - ' + el.rangeTo },
                    { value: moment(el.createdDate).format('D. M. YYYY.') },
                    { value: el.comment ? el.comment : ''},
                ]));
                dispatch({type: 'documentTypesData', payload: documentTypesData});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }


    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Zaduženja obrazaca </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            {(!showEditForm) &&<Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Unesi zaduženje
                                </Button>
                            </Menu.Item>}
                            <Menu.Item>
                                <ExcelFile filename="Spisak zaduženih obrazaca" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={multiDataSet} name="Zaduženi obrasci" />
                                </ExcelFile>
                            </Menu.Item>
                            <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Segment>

                {showAddForm &&
                <Segment>
                    <DocumentTypeForm add toggleEditForm={toggleAddForm} getDocumentTypes={getDocumentTypes} getDocumentTypesForAdmin={getDocumentTypesForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer}/>
                </Segment>}

                {/*{showEditForm &&*/}
                {/*<Segment>*/}
                {/*    <DocumentTypeForm edit toggleEditForm={toggleEditForm}/>*/}
                {/*</Segment>}*/}

                {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Segment><Form size={'small'}>
                    <Form.Group widths="3">
                        <Form.Field>
                            <label>Organizator</label>
                            <Form.Dropdown
                                placeholder="Odaberite organizatora"
                                value={state.organizer}
                                onChange={handleInputChange}
                                name="organizer"
                                options={state.organizers}
                                search
                                fluid
                                selection
                                clearable
                            />
                        </Form.Field>
                    </Form.Group>
                </Form></Segment>}

                {/*{((activeRole === "ROLE_ADMIN" && state.openTable) || (activeRole === "ROLE_MINISTRY" && state.openTable) || activeRole === "ROLE_ORGANIZER") &&*/}
                <Segment>
                    {state.loader ?
                        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                        :
                        // state.documentTypes &&
                        currentPosts.length ?
                            <Table celled size={'small'}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={activeRole !== "ROLE_ORGANIZER" ? 11 : 9} >
                                            <div style={{ paddingLeft: '0px' }}>
                                                <SearchTableData data={state.data} getResults={getResults} filterItem={['comment','formTypeFilter', 'dateFilter']} />
                                            </div>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell width={1} >R. br.</Table.HeaderCell>
                                        {activeRole !== "ROLE_ORGANIZER"  &&<Table.HeaderCell colSpan={2} >Organizator</Table.HeaderCell>}
                                        <Table.HeaderCell colSpan={2} >Vrsta obrasca</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            <Header size={"small"}>
                                                Opseg zaduženih serijskih brojeva
                                                <Header.Subheader>
                                                    <i>od - do</i>
                                                </Header.Subheader>
                                            </Header>

                                        </Table.HeaderCell>
                                        {/*<Table.HeaderCell width={3}>Opseg zaduženih serijskih brojeva</Table.HeaderCell>*/}
                                        <Table.HeaderCell width={2}>Datum zaduženja</Table.HeaderCell>
                                        <Table.HeaderCell >Napomena</Table.HeaderCell>
                                        <Table.HeaderCell width={1}/>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        currentPosts.map((el, index) =>
                                        // state.documentTypes.map((el, index)=>
                                            (<Table.Row key={el.id}>
                                                <Table.Cell width={1}>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                                {activeRole !== "ROLE_ORGANIZER"  &&<Table.Cell colSpan={2}>{el.organizer.name}</Table.Cell>}
                                                <Table.Cell colSpan={2}>{el.formType.name}</Table.Cell>
                                                <Table.Cell width={3}>{el.rangeFrom === el.rangeTo ? el.rangeFrom : el.rangeFrom +' - ' + el.rangeTo}</Table.Cell>
                                                <Table.Cell width={2}>{moment(el.createdDate).format('D. M. YYYY.')}</Table.Cell>
                                                <Table.Cell>{el.comment}</Table.Cell>
                                                <Table.Cell verticalAlign="middle" textAlign="center" width={1}>
                                                    {/*<Popup size={"small"} content='Izmijeni podatke' trigger={*/}
                                                    {/*    <Button icon size={"mini"} basic color={"grey"} onClick={() => {setShowEditForm(true);}}>*/}
                                                    {/*        <Icon name='pencil' color={"teal"} />*/}
                                                    {/*    </Button>} />*/}
                                                    <Popup size={"small"} content='Obriši' trigger={
                                                        <Button icon size={"mini"} basic color={"grey"} onClick={() => {deleteDocumentType(el.id)}}>
                                                            <Icon name='trash alternate outline' color={"red"} />
                                                        </Button>} />
                                                </Table.Cell>
                                            </Table.Row>)
                                        )
                                    }
                                </Table.Body>
                                <Table.Footer>
                                </Table.Footer>
                            </Table> : <div>Nema zaduženih obrazaca.</div>}
                    {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                </Segment>
            </Segment.Group>
            <ToastContainer  />
        </div>
    );
};

export default DocumentTypes;
