import React, {useContext, useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Button, Form, Header, Icon, Loader, Menu} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {Link} from "react-router-dom";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import StudyProgramForm from "./StudyProgramForm";
import axios from "axios";
import moment from "moment";
import {toast, ToastContainer} from "react-toastify";
import {UserRoleContext} from "../../Main";
import SearchTableData from "../Utilities/SearchTableData";
import ReactExport from 'react-data-export';
import PaginationFront from "../Utilities/PaginationFront";

const reducer = (state, action)=>{
    switch (action.type) {
        case 'studyPrograms':
            return {...state, studyPrograms: action.payload, loader:  false };
        case 'studyProgram':
            return {...state, studyProgram: action.payload};
        case 'studyProgramsData':
            return {...state, studyProgramsData: action.payload, loader: false };
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'openTable':
            return {...state, openTable: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'currentIdOrganizer':
            return {...state, currentIdOrganizer: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;
    }
};

const StudyPrograms = () => {
    const [activePage, setActivePage] = useState(1);
    const [state, dispatch] = useReducer(reducer,{
        studyPrograms:[],
        studyProgramsData: [],
        currentId: null,
        studyProgram:'',
        openTable: false,
        loader: false,
        currentIdOrganizer: null,
        organizer: '',
        organizers: [],
        data:[],
        results:[],
        postsPerPage: 20,
        currentPosts: [],
    } );
    const {activeRole} = useContext(UserRoleContext);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    // const multiDataSet = [
    //     {
    //         columns: [
    //             { title: 'Naziv nastavnog programa', width: { wpx: 250 } },
    //             { title: 'Status', width: { wpx: 100 } },
    //             { title: 'Zanimanje', width: { wpx: 200 } },
    //             { title: 'Broj rješenja', width: { wpx: 200 } },
    //             { title: 'Datum rješenja', width: { wpx: 200 } },
    //             { title: 'Stečeni stepen stručne spreme', width: { wpx: 200 } },
    //             { title: 'Vrsta obrazovanja', width: { wpx: 200 } },
    //             { title: 'Period trajanja (broj nastavnih sati)', width: { wpx: 200 } },
    //             { title: 'Napomena', width: { wpx: 200 } },
    //         ],
    //         data: state.studyProgramsData,
    //     }
    // ];

    const columns = [
        { title: 'Naziv nastavnog programa', width: { wpx: 300 } },
        { title: 'Status', width: { wpx: 100 } },
        { title: 'Zanimanje', width: { wpx: 250 } },
        { title: 'Broj rješenja', width: { wpx: 200 } },
        { title: 'Datum rješenja', width: { wpx: 200 } },
        { title: 'Stečeni stepen stručne spreme', width: { wpx: 200 } },
        { title: 'Vrsta obrazovanja', width: { wpx: 200 } },
        { title: 'Period trajanja (broj nastavnih sati)', width: { wpx: 200 } },
        { title: 'Napomena', width: { wpx: 200 } },
    ];

    if (activeRole !== "ROLE_ORGANIZER") {
        columns.unshift({ title: 'Organizator', width: { wpx: 350 } });
    }

    const multiDataSet = [
        {
            columns: columns,
            data: state.studyProgramsData,
        }
    ];

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    useEffect(() => {
       if (activeRole === "ROLE_ORGANIZER") getStudyPrograms();
       if (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") getOrganizers();

       // if (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") getStudyProgramsForAdmin(parseInt(localStorage.getItem('organizerOption')));

    },[activeRole]);

    useEffect(() => {
        dispatch({type: 'organizer', payload: parseInt(localStorage.getItem('organizerOption')) ? parseInt(localStorage.getItem('organizerOption')) : ''});
        if ((activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && state.organizer) getStudyProgramsForAdmin(parseInt(localStorage.getItem('organizerOption')));

    },[state.organizer]);


    function getStudyPrograms(){
        axios.get('/study-program/by-organizer',).then(
            (res) => {
                // let studyPrograms = res.data;
                let studyPrograms = res.data.map(el => ({
                    ...el,
                    occupationNameBosnian:el.organizerOccupation.occupation.nameBosnian,
                    dateFilterDocument: moment(el.documentDate).format('D. M. YYYY.'),
                    educationLevelFilter:el.educationLevel?.name,
                    supplementaryEducationTypeFilter:el.supplementaryEducationType.name
                }))
                dispatch({type: 'studyPrograms', payload: studyPrograms});
                dispatch({type: 'data', payload: studyPrograms});
                let studyProgramsData = res.data.map(el => ([
                    { value: el.name },
                    { value: el.verified ? 'verifikovan' : 'nije verifikovan' },
                    { value: el.organizerOccupation.occupation.nameBosnian },
                    { value: el.documentNumber },
                    { value: moment(el.documentDate).format('D. M. YYYY.') },
                    { value: el.educationLevel?.name },
                    { value: el.supplementaryEducationType.name },
                    { value: moment(el.startDate).format('D. M. YYYY.') + ' ' +(el.endDate!==null ? (el.endDate && ' - ' + moment(el.endDate).format('D. M. YYYY.')): '') + '' + (el.durationHours!==null && '(' + el.durationHours + ')') },
                    { value: el.comment },
                ]));
                dispatch({type: 'studyProgramsData', payload: studyProgramsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            localStorage.setItem('organizerOption', value);
            dispatch({type: 'openTable', payload: true});
            getStudyProgramsForAdmin(value)
        }
    }

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getStudyProgramsForAdmin(id){
        setCurrentId(id);
        setCurrentIdOrganizer(id);
        dispatch({type: 'loader', payload: true});
        dispatch({type: 'openTable', payload: true});
        axios.get(`/study-program/by-organizer-id/${id}`,).then(
            (res) => {
                // let studyPrograms = res.data;
                let studyPrograms = res.data.map(el => ({
                    ...el,
                    occupationNameBosnian:el.organizerOccupation.occupation.nameBosnian,
                    dateFilterDocument: moment(el.documentDate).format('D. M. YYYY.'),
                    educationLevelFilter:el.educationLevel?.name,
                    supplementaryEducationTypeFilter:el.supplementaryEducationType.name
                }))
                dispatch({type: 'studyPrograms', payload: studyPrograms});
                dispatch({type: 'data', payload: studyPrograms});
                let studyProgramsData = res.data.map(el => ([
                    { value: el.organizerOccupation.organizer.name },
                    { value: el.name },
                    { value: el.verified ? 'verifikovan' : 'nije verifikovan' },
                    { value: el.organizerOccupation.occupation.nameBosnian },
                    { value: el.documentNumber },
                    { value: moment(el.documentDate).format('D. M. YYYY.') },
                    { value: el.educationLevel?.name },
                    { value: el.supplementaryEducationType.name },
                    { value: moment(el.startDate).format('D. M. YYYY.') + ' ' +(el.endDate!==null ? (el.endDate && ' - ' + moment(el.endDate).format('D. M. YYYY.')): '') + '' + (el.durationHours!==null && '(' + el.durationHours + ')') },
                    { value: el.comment },
                ]));
                dispatch({type: 'studyProgramsData', payload: studyProgramsData});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function verifyProgram(id, optlock) {
        dispatch({type: 'loader', payload: true});
        axios.put(`study-program/ready-for-verification/${id}/${optlock}`,).then(
            (res) => {
                toast('Uspješno ste poslali zahtjev za verifikaciju programa.');
                getStudyPrograms()
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }
    function deleteStudyProgram(id){
        setCurrentId(id);
        dispatch({type: 'loader', payload: true});
        axios.delete(`/study-program/delete/${id}`,).then(
            (res) => {
                activeRole === "ROLE_ORGANIZER" ? getStudyPrograms() : getStudyProgramsForAdmin(state.organizer);
                toast('Uspješno ste izbrisali nastavni program!');
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            if (err.response.data.message === 'This study program has courses and thus cannot be deleted!') {
                toast('Prije brisanja potrebno je obrisati sve predmete sa nastavnog programa!');

            } else {
                toast('Došlo je do greške! Brisanje predmeta nije uspjelo.');

            }
            dispatch({type: 'loader', payload: false});
            setCurrentId(null);
        });
    }

    return (
        <div>
            <div>
                <Segment.Group >

                    <Segment className={'customPadding'} clearing color={"teal"}>

                        <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                            <Menu.Item >
                                <Header as={'h3'} floated={"left"}> Spisak nastavnih programa </Header>
                            </Menu.Item>
                            <Menu.Menu position={'right'}>
                                {(!showEditForm) && activeRole !== "ROLE_MINISTRY" && <Menu.Item>
                                    <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                        <Icon name='add' color={"teal"}/>
                                        Kreiraj program
                                    </Button>
                                </Menu.Item>}
                                {((activeRole === "ROLE_ORGANIZER") || (state.organizer && (activeRole === "ROLE_MINISTRY" || activeRole === "ROLE_ADMIN"))) && <Menu.Item>
                                    <ExcelFile filename="Spisak nastavnih programa" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                        <Icon name='file excel outline' color={"green"}/>
                                        Preuzmi spisak
                                    </Button>}>
                                        <ExcelSheet dataSet={multiDataSet} name="Nastavni programi" />
                                    </ExcelFile>
                                </Menu.Item>}
                                <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                                </Menu.Item>
                                {/*<Menu.Item>*/}
                                {/*    <Button icon labelPosition='left' size="mini" basic color="black">*/}
                                {/*        <Icon name='file pdf outline' color={"red"} />*/}
                                {/*        Preuzmi spisak*/}
                                {/*    </Button>*/}
                                {/*</Menu.Item>*/}
                                {/*<Menu.Item>*/}
                                {/*    <Button icon labelPosition='left' size="mini" basic color="black">*/}
                                {/*        <Icon name='file excel outline' color={"green"}/>*/}
                                {/*        Preuzmi spisak*/}
                                {/*    </Button>*/}
                                {/*</Menu.Item>*/}
                            </Menu.Menu>

                        </Menu>

                    </Segment>

                    {(showAddForm) &&
                        <Segment>
                            <StudyProgramForm add toggleEditForm={toggleAddForm} getStudyPrograms={getStudyPrograms} getStudyProgramsForAdmin={getStudyProgramsForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer}/>
                        </Segment>}

                    {showEditForm &&
                    <Segment>
                        <StudyProgramForm edit toggleEditForm={toggleEditForm} getStudyPrograms={getStudyPrograms} studyProgram={state.studyProgram} getStudyProgramsForAdmin={getStudyProgramsForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer}/>
                    </Segment>}

                    {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Segment><Form size={'small'}>
                        <Form.Group widths="3">
                            <Form.Field>
                                <label>Organizator</label>
                                <Form.Dropdown
                                    placeholder="Odaberite organizatora"
                                    value={state.organizer}
                                    onChange={handleInputChange}
                                    name="organizer"
                                    options={state.organizers}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form></Segment>}

                    {((activeRole === "ROLE_ADMIN" && state.openTable) || (activeRole === "ROLE_MINISTRY" && state.openTable) || activeRole === "ROLE_ORGANIZER") &&
                        <Segment>
                            {state.loader ?
                                <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                                :
                                currentPosts.length ?
                            <Table celled size={'small'}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={9} >
                                            <div style={{ paddingLeft: '0px' }}>
                                                <SearchTableData data={state.data} getResults={getResults} filterItem={['name', 'documentNumber', 'occupationNameBosnian', 'dateFilterDocument', 'educationLevelFilter', 'supplementaryEducationTypeFilter']} />
                                            </div>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>

                                        <Table.HeaderCell >R. br.</Table.HeaderCell>
                                        <Table.HeaderCell>Naziv nastavnog programa / zanimanje</Table.HeaderCell>
                                        <Table.HeaderCell>Broj rješenja</Table.HeaderCell>
                                        <Table.HeaderCell>Datum rješenja</Table.HeaderCell>
                                        <Table.HeaderCell>Stečeni stepen stručne spreme / vrsta obrazovanja</Table.HeaderCell>
                                        <Table.HeaderCell>Period trajanja (broj nastavnih sati)</Table.HeaderCell>
                                        <Table.HeaderCell>Napomena</Table.HeaderCell>
                                        <Table.HeaderCell />
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        currentPosts.map((el, index) =>
                                        // state.studyPrograms.map((el, index)=>
                                            (<Table.Row negative={!el.verified} warning={!el.verified && el.readyForVerification}>
                                                <Table.Cell>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                                <Table.Cell>
                                                    <Popup size={"small"} content={el.verified? 'Program verifikovan':'Program nije verifikovan'} trigger={ el.verified ? <Icon name='check circle' color={"blue"} /> :el.readyForVerification?<Icon name='hourglass half' color={"brown"} />:<Icon name='attention' color={"red"} />} />
                                                    <strong> {el.name}</strong> / {el.organizerOccupation.occupation.nameBosnian}</Table.Cell>
                                                <Table.Cell>{el.documentNumber}</Table.Cell>
                                                <Table.Cell>{moment(el.documentDate).format('D. M. YYYY.')}</Table.Cell>
                                                <Table.Cell><strong>{el.educationLevel?.name}</strong> / {el.supplementaryEducationType.name}</Table.Cell>
                                                <Table.Cell><strong>{moment(el.startDate).format('D. M. YYYY.')} {el.endDate && (el.endDate && ' - ' + moment(el.endDate).format('D. M. YYYY.'))}</strong> {el.durationHours && '(' + el.durationHours + ')'}</Table.Cell>
                                                <Table.Cell>{el.comment}</Table.Cell>
                                                <Table.Cell width={2} verticalAlign="middle" textAlign="center">
                                                    {/*<Popup size={"small"} content='Izmijeni nastavni plan i program' trigger={*/}
                                                    {/*    <Button disabled={el.verified} icon size={"mini"} basic color={"grey"} onClick={() => {setShowEditForm(true); dispatch({type: 'studyProgram', payload: el})}}>*/}
                                                    {/*        <Icon name='pencil' color={"teal"} />*/}
                                                    {/*    </Button>} />*/}
                                                    <Link to='/npp/predmeti' state={{ name: el.name, studyProgramId: el.id, organizerId: state.organizer, duration: el.duration, verified: el.verified, optLock: el.optLock, formal: el.supplementaryEducationType.formal}}>
                                                        <Popup size={"small"} content='Predmeti' trigger={ <Button icon size={"mini"} basic color={"grey"}>
                                                            <Icon name='file alternate' color={"blue"} />
                                                        </Button>} />
                                                    </Link>
                                                    <Popup size={"small"} content='Obriši program' trigger={ <Button icon size={"mini"} basic color={"grey"} disabled={ el.verified || state.currentId && el.id !== state.currentId || activeRole === "ROLE_MINISTRY" } onClick={() => {deleteStudyProgram(el.id)}}>
                                                        <Icon name='trash alternate outline' color={"red"} />
                                                    </Button>} />
                                                    {!el.verified && activeRole === "ROLE_ORGANIZER" && <Popup
                                                        size={"small"}
                                                        content={el.readyForVerification ? 'Mail je poslan.' :<div><strong>Označi program kao <i>'spreman za verifikaciju'. </i></strong> <br/>Klikom na ovu opciju ministarstvu se šalje mail sa naznakom da je navedeni program spreman za verifikaciju.</div>}
                                                        trigger={
                                                            <Button icon size={"mini"} basic color={"grey"} disabled={ el.verified || el.readyForVerification} onClick={() => {verifyProgram(el.id, el.optLock )}}>
                                                                <Icon name={el.readyForVerification ? 'check' : 'mail outline'} color={"green"} />
                                                            </Button>
                                                        }
                                                    />}
                                                </Table.Cell>
                                            </Table.Row>)
                                        )
                                    }


                                </Table.Body>

                                <Table.Footer>
                                    {/*<Table.Row>*/}
                                    {/*    <Table.HeaderCell colSpan='14' textAlign='center'>*/}
                                    {/*        <Menu  pagination>*/}
                                    {/*            <Menu.Item as='a' icon>*/}
                                    {/*                <Icon name='chevron left' />*/}
                                    {/*            </Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>1</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>2</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>3</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>4</Menu.Item>*/}
                                    {/*            <Menu.Item as='a' icon>*/}
                                    {/*                <Icon name='chevron right' />*/}
                                    {/*            </Menu.Item>*/}
                                    {/*        </Menu>*/}
                                    {/*    </Table.HeaderCell>*/}
                                    {/*</Table.Row>*/}
                                </Table.Footer>
                            </Table>: <div>Nema unesenih podataka o nastavnim programima.</div>}
                            {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                        </Segment>}
                </Segment.Group>
                <ToastContainer  />
            </div>
        </div>
    );
};

export default StudyPrograms;
