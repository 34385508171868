import React, {useContext, useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Button, Header, Icon, Label, Menu} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {Link} from "react-router-dom";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import axios from "axios";
import {UserRoleContext} from "../../Main";
import Requests from "../Utilities/Requests";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import download from 'downloadjs';
import {extractFileNameFromHeaders} from "../Utilities/Util";
import {toast, ToastContainer} from "react-toastify";
import moment from "moment";
import SearchTableData from "../Utilities/SearchTableData";
import PaginationFront from "../Utilities/PaginationFront";
import ReactExport from 'react-data-export';

const reducer = (state, action)=>{
    switch (action.type) {
         case 'organizers':
            return {...state, organizers: action.payload };
         case 'students':
            return {...state, students: action.payload, loader:  false };
        case 'studentsData':
            return {...state, studentsData: action.payload, loader:  false };
         case 'currentId':
            return {...state, currentId: action.payload};
         case 'organizer':
            return {...state, organizer: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
         default:
            return state;
    }
};

const Students = () => {
    const {activeRole} = useContext(UserRoleContext);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [activePage, setActivePage] = useState(1);
    const [state, dispatch] = useReducer(reducer,{
        organizers:[],
        students:[],
        studentsData: [],
        organizer: null,
        currentId: null,
        loader: true,
        data:[],
        results:[],

        postsPerPage: 20,
        currentPosts: [],
    } );
    const [showAddForm, setShowAddForm] = useState(false);
    const multiDataSet = [
        {
            columns: [
                { title: 'JMBG', width: { wpx: 100 } },
                { title: 'Ime', width: { wpx: 150 } },
                { title: 'Prezime', width: { wpx: 150 } },
                { title: 'Vrsta obrazovanja', width: { wpx: 150 } },
                { title: 'Struka', width: { wpx: 250 } },
                { title: 'Zanimanje', width: { wpx: 250 } },
                { title: 'Početak obrazovnog procesa', width: { wpx: 200 } },
                { title: 'Naziv programa', width: { wpx: 300 } },
            ],
            data: state.studentsData,
        }
    ];

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    useEffect(() => {
        console.log(activeRole);
        localStorage.removeItem('formType');
        activeRole === "ROLE_ORGANIZER" && getStudents();
        activeRole === "ROLE_ADMIN" && getOrganizers();
        activeRole === "ROLE_MINISTRY" && getOrganizers();
    },[activeRole]);

    useEffect(() => {
        dispatch({type: 'organizer', payload: parseInt(localStorage.getItem('organizerOption')) ? parseInt(localStorage.getItem('organizerOption')) : ''});
        console.log('u use', parseInt(localStorage.getItem('organizerOption') ))
        if ((activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && state.organizer) getStudentsForAdmin(parseInt(localStorage.getItem('organizerOption')));

    },[state.organizer]);

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    // function getOrganizers(){
    //     Requests.getAllOrganizers().then(res => {
    //         dispatch({type: 'organizers', payload: res});
    //     });
    // }

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getStudents(){
        axios.get('/student-study-program/by-organizer',).then(
            (res) => {
                let students = res.data.map(el => ({
                        ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                    }))
                // let students = res.data;
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = res.data.map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function getStudentsForAdmin(organizerId){
        setCurrentId(organizerId);
        setCurrentIdOrganizer(organizerId);
        console.log('u funkciji', organizerId)
        axios.get(`/student-study-program/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let students = res.data.map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    studyProgramFilter: el.studyProgram.name,
                    startDateFilter: moment(el.startDate).format('D. M. YYYY.')
                }))
                // let students = res.data;
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = res.data.map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer'){
            localStorage.setItem('organizerOption', value);
            localStorage.setItem('organizerId', value);
            console.log('u handle', value.id)
            getStudentsForAdmin(value)
        }
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function deleteStudentById(id){
        setCurrentId(id);
        axios.delete(`/student-study-program/delete/${id}`,).then(
            (res) => {
                getStudents();
                setCurrentId(null);
            }).catch((err) => {
                if (err.response.data.message === 'This student for passed study program has informal education document information and thus cannot be deleted!' ||
                    err.response.data.message === 'This student for passed study program has recognition information and thus cannot be deleted!' ||
                    err.response.data.message === 'This student for passed study program has grade enrollment information and thus cannot be deleted!' ||
                    err.response.data.message === 'This student for passed study program has recognition and its marks information and thus cannot be deleted!'
                ) {
                    toast('Polaznik je već u obrazovnom procesu.');
                } else {
                    toast('Došlo je do greške! Brisanje polaznika nije uspjelo.');
                }
                console.log('err', err);
                setCurrentId(null);
        });
    }

    function downloadBlankEnrollmentForm(){
            axios({
                method: 'get',
                url: '/document/enrollment-form/blank',
                responseType: 'blob',
                // data: { ...obj }
            })
                .then(res => {
                    if (res.data.size === 0) {
                        return;
                    }
                    const fileName = extractFileNameFromHeaders(res.headers);
                    download(res.data, fileName);
                    return res;
                })
                .catch(err => {
                    toast('Došlo je do greške!');
                });

    }

    function downloadStudentRegister(){
            axios({
                method: 'post',
                url: `document/register-of-students-form/${localStorage.getItem('organizerOption')}`,
                responseType: 'blob',
                // data: { ...obj }
            })
                .then(res => {
                    if (res.data.size === 0) {
                        return;
                    }
                    const fileName = extractFileNameFromHeaders(res.headers);
                    download(res.data, fileName);
                    return res;
                })
                .catch(err => {
                    toast('Došlo je do greške!');
                });

    }

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }


    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Spisak polaznika </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            <Menu.Item>
                               {(activeRole === "ROLE_ORGANIZER" ) && <Link to='/polaznici/kreiranje' state={{ add: true}}>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Dodaj polaznika
                                </Button>
                                </Link>}
                            </Menu.Item>
                            <Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={downloadBlankEnrollmentForm}>
                                    <Icon name='file pdf outline' color={"red"} />
                                    Preuzmi praznu prijavu
                                </Button>
                            </Menu.Item>
                           {state.organizer && (activeRole === "ROLE_MINISTRY" || activeRole === "ROLE_ADMIN") && <Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={downloadStudentRegister}>
                                    <Icon name='download' color={"green"} />
                                    Registar polaznika
                                </Button>
                            </Menu.Item>}
                            {((activeRole === "ROLE_ORGANIZER") || (state.organizer && (activeRole === "ROLE_MINISTRY" || activeRole === "ROLE_ADMIN"))) && <Menu.Item>
                                <ExcelFile filename="Spisak studenata" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={multiDataSet} name="Studenti" />
                                </ExcelFile>
                            </Menu.Item>}
                            <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                        </Menu.Menu>

                    </Menu>

                </Segment>

                {/*{showAddForm ?*/}
                {/*<Segment>*/}
                {/*    /!*send add or edit as props*!/*/}
                {/*    <StudentForm add toggleEditForm={toggleAddForm}/>*/}
                {/*</Segment>:*/}

                    {(activeRole === 'ROLE_ADMIN' || activeRole === 'ROLE_MINISTRY') && <Segment><Form size={'small'}>
                            <Form.Group widths="3">
                                <Form.Field>
                                    <label>Organizator</label>
                                    <Form.Dropdown
                                        placeholder="Odaberite organizatora"
                                        value={state.organizer}
                                        onChange={handleInputChange}
                                        name="organizer"
                                        options={state.organizers}
                                        search
                                        fluid
                                        selection
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form></Segment>
                    }
                {<Segment>
                    {state.students.length === 0 ? 'Odabrani organizator nema polaznika.' : <div> {currentPosts.length ?<Table celled size={'small'}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={8} >
                                    <div style={{ paddingLeft: '0px' }}>
                                        <SearchTableData data={state.data} getResults={getResults} filterItem={['startDateFilter', 'jmbg', 'firstName', 'lastName', 'supplementaryEducationType', 'occupation', 'nameBosnian', 'studyProgramFilter']} />
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>

                                <Table.HeaderCell >R. br.</Table.HeaderCell>
                                <Table.HeaderCell>JMBG</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Ime i prezime</Table.HeaderCell>
                                <Table.HeaderCell>Vrsta obrazovanja</Table.HeaderCell>
                                <Table.HeaderCell>Struka / zanimanje</Table.HeaderCell>
                                <Table.HeaderCell>Program</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Početak obrazovnog procesa</Table.HeaderCell>
                                {/*<Table.HeaderCell>Status</Table.HeaderCell>*/}
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                currentPosts.map((el, index) =>
                                // state.students.map((el, index)=> negative={!!el.droppedOutDate} positive={!!el.graduationDate}
                                    (<Table.Row key={el.id} >
                                        <Table.Cell >{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                        <Table.Cell>{el.student.person.jmbg}</Table.Cell>
                                        <Table.Cell width={2}>
                                            {el.student.person.firstName} {el.student.person.lastName}
                                            {el.graduationDate && <Popup content="Polaznik je završio." hideOnScroll position="top center" trigger={<span size="tiny" as="a" basic style={{ float: 'right' }}><span><Icon name="lock" color="blue" /></span></span>} />}
                                            {el.droppedOutDate && <Popup content="Polaznik je ispisan." hideOnScroll position="top center" trigger={<span size="tiny" as="a" basic style={{ float: 'right' }}><span><Icon name="sign-out" color="red" /></span></span>} />}
                                        </Table.Cell>
                                        <Table.Cell>{el.studyProgram.supplementaryEducationType.name}</Table.Cell>
                                        <Table.Cell>{el.studyProgram.organizerOccupation.occupation.profession.name} / <strong>{el.studyProgram.organizerOccupation.occupation.nameBosnian}</strong></Table.Cell>
                                        <Table.Cell>{el.studyProgram.name}</Table.Cell>
                                        <Table.Cell width={2}>{moment(el.startDate).format('D. M. YYYY.')}</Table.Cell>
                                        <Table.Cell width={2} verticalAlign="middle" textAlign="center">
                                            <Link to='/polaznici/detalji' state={{ name: (el.student.person.firstName + ' ' + el.student.person.lastName), id: el.id, personId: el.student.person.id}}>
                                                <Popup size={"small"} content='O polazniku'
                                                       trigger={ <Button
                                                           onClick={
                                                               ()=>{
                                                                   localStorage.setItem('name', el.student.person.firstName + ' ' + el.student.person.lastName);
                                                                   localStorage.setItem('sspId', el.id);
                                                                   localStorage.setItem('personId', el.student.person.id);
                                                                   localStorage.setItem('lastCompletedGrade', JSON.stringify(el.lastCompletedGrade))
                                                                   localStorage.setItem('gradDate', el.graduationDate);
                                                                   localStorage.setItem('droppedDate', el.droppedOutDate);
                                                               }}
                                                           icon
                                                           size={"mini"}
                                                           basic
                                                           color={"grey"}>
                                                    <Icon name='user' />
                                                </Button>} />
                                            </Link>

                                            {!el.studyProgram.supplementaryEducationType.formal && <Link to='/polaznici/neformalno-obrazovanje' state={{ name: (el.student.person.firstName + ' ' + el.student.person.lastName), id: el.id, personId: el.student.person.id}}>
                                                <Popup size={"small"} content='Obrazovni proces'
                                                       trigger={ <Button
                                                           onClick={
                                                               ()=>{
                                                                   localStorage.setItem('name', el.student.person.firstName + ' ' + el.student.person.lastName);
                                                                   localStorage.setItem('sspId', el.id);
                                                                   localStorage.setItem('personId', el.student.person.id);
                                                                   localStorage.setItem('studyProgramId', el.studyProgram.id);
                                                                   localStorage.setItem('formType', el.studyProgram.supplementaryEducationType.key);
                                                                   localStorage.setItem('lastCompletedGrade', JSON.stringify(el.lastCompletedGrade));
                                                                   localStorage.setItem('supplementaryEducationTypeName', el.studyProgram.supplementaryEducationType.name);
                                                                   localStorage.setItem('supplementaryEducationTypeKey', el.studyProgram.supplementaryEducationType.key);
                                                                   localStorage.setItem('startEducationDate', el.startDate); // pocetak obrazovnog procesa
                                                                   localStorage.setItem('gradDate', el.graduationDate);
                                                                   localStorage.setItem('droppedDate', el.droppedOutDate);
                                                               }}
                                                           icon
                                                           size={"mini"}
                                                           basic
                                                           color={"grey"}>
                                                           <Icon name='graduation cap' color={"teal"}  />
                                                       </Button>} />
                                            </Link>}

                                            {el.studyProgram.supplementaryEducationType.formal && <Link to='/polaznici/formalno-obrazovanje' state={{ name: (el.student.person.firstName + ' ' + el.student.person.lastName), id: el.id, personId: el.student.person.id}}>
                                                <Popup size={"small"} content='Obrazovni proces'
                                                       trigger={ <Button
                                                           onClick={
                                                               ()=>{
                                                                   localStorage.setItem('name', el.student.person.firstName + ' ' + el.student.person.lastName);
                                                                   localStorage.setItem('sspId', el.id);
                                                                   localStorage.setItem('personId', el.student.person.id);
                                                                   localStorage.setItem('studyProgramId', el.studyProgram.id);
                                                                   localStorage.setItem('formType', el.studyProgram.supplementaryEducationType.key);
                                                                   localStorage.setItem('duration', el.studyProgram.duration);
                                                                   localStorage.setItem('gradDate', el.graduationDate);
                                                                   localStorage.setItem('droppedDate', el.droppedOutDate);
                                                                   localStorage.setItem('lastCompletedGrade', JSON.stringify(el.lastCompletedGrade));
                                                                   localStorage.setItem('supplementaryEducationTypeName', el.studyProgram.supplementaryEducationType.name);
                                                                   localStorage.setItem('supplementaryEducationTypeKey', el.studyProgram.supplementaryEducationType.key);
                                                                   localStorage.setItem('startEducationDate', el.startDate); // pocetak obrazovnog procesa
                                                                   localStorage.setItem('gradeDurationMonths', el.studyProgram.gradeDurationMonths); // trajanje jednog razreda u mjesecima
                                                               }}
                                                           icon
                                                           size={"mini"}
                                                           basic
                                                           color={"grey"}>
                                                           <Icon name='graduation cap' color={"teal"}  />
                                                       </Button>} />
                                            </Link>}

                                           {el.studyProgram.supplementaryEducationType.formal && <Link to='/polaznici/rjesenje-o-razlici-predmeta' state={{ name: (el.student.person.firstName + ' ' + el.student.person.lastName), sspId: el.id, ssp:el, personId: el.student.person.id}}>
                                                <Popup size={"small"} content='Podaci o razlici predmeta' trigger={
                                                    <Button
                                                        onClick={
                                                            ()=>{
                                                                localStorage.setItem('name', el.student.person.firstName + ' ' + el.student.person.lastName);
                                                                localStorage.setItem('sspId', el.id);
                                                                localStorage.setItem('gradDate', el.graduationDate);
                                                                localStorage.setItem('droppedDate', el.droppedOutDate);
                                                                localStorage.setItem('studyProgramId', el.studyProgram.id);
                                                                localStorage.setItem('duration', el.studyProgram.duration);
                                                                localStorage.setItem('personId', el.student.person.id);
                                                                localStorage.setItem('lastCompletedGrade', JSON.stringify(el.lastCompletedGrade))
                                                                localStorage.setItem('startEducationDate', el.startDate); // pocetak obrazovnog procesa
                                                        }}
                                                        icon
                                                        size={"mini"}
                                                        basic
                                                        color={"grey"}
                                                    >
                                                        <Icon name='file alternate' color={"blue"} />
                                                </Button>} />
                                            </Link>}

                                           {activeRole === "ROLE_ORGANIZER" && <Popup size={"small"}
                                                   content='Obriši polaznika'
                                                   trigger={
                                                       <Button disabled={state.currentId && el.id !== state.currentId }
                                                               icon
                                                               size={"mini"}
                                                               basic
                                                               color={"grey"}
                                                               onClick={() => {deleteStudentById(el.id)}}
                                                       >
                                                           <Icon name='trash alternate outline' color={"red"} />
                                                       </Button>
                                                   }
                                            />}

                                        </Table.Cell>
                                    </Table.Row>)
                                )
                            }


                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='14' textAlign='center'>
                                    {/*<Menu  pagination>*/}
                                    {/*    <Menu.Item as='a' icon>*/}
                                    {/*        <Icon name='chevron left' />*/}
                                    {/*    </Menu.Item>*/}
                                    {/*    <Menu.Item as='a'>1</Menu.Item>*/}
                                    {/*    <Menu.Item as='a'>2</Menu.Item>*/}
                                    {/*    <Menu.Item as='a'>3</Menu.Item>*/}
                                    {/*    <Menu.Item as='a'>4</Menu.Item>*/}
                                    {/*    <Menu.Item as='a' icon>*/}
                                    {/*        <Icon name='chevron right' />*/}
                                    {/*    </Menu.Item>*/}
                                    {/*</Menu>*/}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table> : ''}</div>}
                    {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                </Segment>}
            </Segment.Group>
<ToastContainer/>
        </div>
    );
};

export default Students;
