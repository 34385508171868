import React from 'react';
import {Segment, Table, Button, Header, Icon, Loader, Menu, Form, Dropdown, Input, TextArea} from "semantic-ui-react";
import {DateTimePicker} from "react-widgets";
import Se from "react-datepicker";
import {Link} from "react-router-dom";

const loader = false
const date = new Date()
const courses = [
    {
        id: 1,
        name: 'Interna medicina'
    },
    {
        id: 2,
        name: 'Hirurgija'
    },
    {
        id: 3,
        name: 'Pedijatrija'
    }
]



function handleDateChange(name, date) {
}

const Zapisnik = () => {
    return (
        <div>
            <div>
                <Segment.Group >
                    <Segment className={'customPadding'} clearing color={"teal"}>

                        <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                            <Menu.Item >
                                <Header as={'h3'} floated={"left"}> Memija Azra - Medicinska sestra-tehničar - 4. razred
                                    <span><Icon/></span>
                                    <Header.Subheader>
                                        <i>Zapisnik o polaganju ispita</i>
                                    </Header.Subheader>
                                </Header>
                            </Menu.Item>
                            <Menu.Menu position={'right'}>
                                <Menu.Item>
                                    <span>
                                        <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                            <Icon name='arrow left'/>
                                        </Button>
                                            <Icon/>
                                    </span>
                                </Menu.Item>
                            </Menu.Menu>

                        </Menu>

                    </Segment>
                    <Segment clearing>

                        <Button icon labelPosition='left' size="mini" basic color="black" floated='right'>
                            <Icon name='file excel outline' color={"green"}/>
                            Preuzmi spisak
                        </Button>

                    </Segment>

                    <Segment clearing>
                        {loader ?
                            <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                            :
                            courses.length ?
                                <Table celled size={'small'}>
                                    <Table.Header>
                                        <Table.Row >

                                            <Table.HeaderCell>R. br.</Table.HeaderCell>
                                            <Table.HeaderCell width={2}>Naziv predmeta</Table.HeaderCell>
                                            <Table.HeaderCell width={2}>Datum polaganja</Table.HeaderCell>
                                            <Table.HeaderCell>Teme na pismenom ispitu</Table.HeaderCell>
                                            <Table.HeaderCell>Pitanja na usmenom ispitu</Table.HeaderCell>
                                            <Table.HeaderCell width={3}>Ocjene</Table.HeaderCell>
                                            <Table.HeaderCell width={3}/>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            courses.map((el, index)=>
                                                (<Table.Row key={el.id}>
                                                    <Table.Cell >{index+1}.</Table.Cell>
                                                    <Table.Cell>{el.name}</Table.Cell>
                                                    <Table.Cell>
                                                        <Form.Field
                                                            required
                                                            control={DateTimePicker}
                                                            format="D. M. YYYY."
                                                            placeholder="Unesite datum"
                                                            name="date"
                                                            value={date}
                                                            time={false}
                                                            onChange={(e) => handleDateChange('date', e)}
                                                            style={{ borderWidth: '0px' }}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell >
                                                        <Form size={'mini'}>
                                                            <TextArea rows={7} placeholder="Unesite teme"/>
                                                        </Form>
                                                    </Table.Cell >
                                                    <Table.Cell >
                                                        <Form size={'mini'}>
                                                            <TextArea rows={7} placeholder="Unesite pitanja"/>
                                                        </Form>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Form size={'mini'} >
                                                                <Form.Field inline>
                                                                    <label >Pismeni dio:</label>
                                                                    <Dropdown
                                                                        selection
                                                                        placeholder="Odaberite ocjenu"
                                                                        style={{float: 'right'}}
                                                                        size
                                                                    />
                                                                </Form.Field>
                                                            <br/>
                                                                <Form.Field inline>
                                                                    <label>Usmeni dio:</label>
                                                                    <Dropdown
                                                                        selection
                                                                        placeholder="Odaberite ocjenu"
                                                                        style={{float: 'right'}}
                                                                    />
                                                                </Form.Field>
                                                            <br/>
                                                                <Form.Field inline>
                                                                    <label>Zaključna ocjena:</label>
                                                                    <Dropdown
                                                                        selection
                                                                        placeholder="Odaberite ocjenu"
                                                                        style={{float: 'right'}}
                                                                    />
                                                                </Form.Field>
                                                        </Form>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Form size={'mini'} >
                                                            <Form.Field inline>
                                                                <label>Predsjednik:</label>
                                                                <Input style={{float: 'right'}} placeholder="Ime i prezime"/>
                                                            </Form.Field>
                                                            <br/>
                                                            <Form.Field inline>
                                                                <label>Ispitivač:</label>
                                                                <Input style={{float: 'right'}} placeholder="Ime i prezime"/>
                                                            </Form.Field>
                                                            <br/>
                                                            <Form.Field inline>
                                                                <label>Član: </label>
                                                                <Input style={{float: 'right'}}   placeholder="Ime i prezime"/>
                                                            </Form.Field>
                                                        </Form>
                                                    </Table.Cell>
                                                </Table.Row>)
                                            )
                                        }
                                    </Table.Body>

                                </Table> : <div>Nema unesenih podataka o predmetima.</div>}



                    <Button floated={"right"} basic size={"mini"} color={"black"} >{'Odustani'}</Button>
                    <Button floated={"right"} basic size={"mini"} color={"teal"} >{'Sačuvaj podatke'}</Button>
                </Segment>

                </Segment.Group>


            </div>
        </div>
    );
};

export default Zapisnik;
