import React, {useReducer} from 'react';
import {Form, Header, Icon, Input, SegmentGroup, Select} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";


const reducer = (state, action)=> {
    switch (action.type) {
        case 'name':
            return {...state, name: action.payload};
        case 'key':
            return {...state, key: action.payload};
        case 'officialName':
            return {...state, officialName: action.payload};
        case 'graduationDocument':
            return {...state, graduationDocument: action.payload};
        case 'number':
            return {...state, number: action.payload};
        case 'nameCroatian':
            return {...state, nameCroatian: action.payload};
        case 'nameSerbian':
            return {...state, nameSerbian: action.payload};
        case 'formal':
            return {...state, formal: action.payload};
        default:
            return state;

    }
}


const DefaultForm = ({toggleEditForm, addNewEntry, add, additionalInformation}) => {
    const [state, dispatch] = useReducer(reducer,{
        name: '',
    });

    const graduationDocumentOptions = [
        {
            key: 1,
            value: true,
            text: 'Da'
        },
        {
            key: 2,
            value: false,
            text: 'Ne'
        }
    ]

    function addEntry(){
        //ovdje definises kakv objekat saljes
        let obj = {
            //created: new Date(),
            name: state.name,
            key: state.key,
            id: 123

        }
        if (additionalInformation) {
            additionalInformation.forEach(key => {
                // Check if the key exists in the state

                    // Add the key and its corresponding value from the state to the obj

                    if(key === 'number'){
                        obj[key] = parseInt(state[key]);
                    }
                    else{
                        obj[key] = state[key];
                    }

            });
        }
        addNewEntry(obj)
        toggleEditForm()
    }

    function handleInputChange(event, { name, value }) {
    dispatch({type: name, payload: value});
}
    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {'Dodavanje'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths={'equal'}>

                            <Form.Field
                                required
                                label="Naziv"
                                control={Input}
                                name="name"
                                value={state.name}
                                onChange={handleInputChange}
                                placeholder="Unesite naziv"
                                //error={nameBosnianErr}
                            />

                            <Form.Field
                                required
                                label="Ključ"
                                control={Input}
                                name="key"
                                value={state.key}
                                onChange={handleInputChange}
                                placeholder="Unesite ključ"
                                //error={nameBosnianErr}
                            />
                            {additionalInformation?.map(key => (
                                key === 'number'?
                                    <Form.Field
                                        required
                                        type={'number'}
                                        label="Broj"
                                        control={Input}
                                        name="number"
                                        value={state[key]}
                                        onChange={handleInputChange}
                                        placeholder="Unesite broj"
                                    />:
                                    key === 'graduationDocument'?
                                        <Form.Field required>
                                            <label>Završni dokument</label>
                                            <Form.Dropdown
                                                clearable
                                                placeholder="Odaberite opciju"
                                                value={state[key]}
                                                onChange={handleInputChange}
                                                name="graduationDocument"
                                                options={graduationDocumentOptions}
                                                search
                                                fluid
                                                selection
                                            />
                                        </Form.Field>
                                    :
                                    <Form.Field
                                        key={key}
                                        required
                                        label={key}
                                        control={Input}
                                        name={key}
                                        value={state[key]}
                                        onChange={handleInputChange}
                                        placeholder={`Unesite ${key}`}
                                    />
                            ))}

                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={addEntry}>{'Dodaj'}</Button>
                    </Form>
                </Segment>
            </SegmentGroup>
        </div>
    );
};

export default DefaultForm;
