import React, {useContext, useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Form, Header, Icon, Input, SegmentGroup, Select} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import axios from "axios";
import {UserRoleContext} from "../../../Main";
import {toast} from "react-toastify";

const teacherDropdown = [
    {
        key: 1,
        value: 1,
        text: 'DA',
    },
    {
        key: 2,
        value: 2,
        text: 'NE'
    }
];

const reducer = (state, action)=> {
    switch (action.type) {
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'teachers':
            return {...state, teachers: action.payload};
        case 'teacher':
            return {...state, teacher: action.payload};
        case 'teacherInList':
            return {...state, teacherInList: action.payload};
        case 'teacherInListDisabled':
            return {...state, teacherInListDisabled: action.payload};
        case 'firstName':
            return {...state, firstName: action.payload};
        case 'lastName':
            return {...state, lastName: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        default:
            return state;

    }
}

const EducationalGroupHeadForm = ({add, edit, toggleEditForm, setCurrentIdOrganizer, educationalGroupHead, getEducationalGroupHeads, getEducationalGroupHeadsForAdmin}) => {
    const {activeRole} = useContext(UserRoleContext);
    const [firstNameErr, setFirstNameErr] = useState(false);
    const [lastNameErr, setLastNameErr] = useState(false);
    const [teacherInListErr, setTeacherInListErr] = useState(false);
    const [teacherErr, setTeacherErr] = useState(false);
    const [state, dispatch] = useReducer(reducer,{
        organizers: [],
        organizer: educationalGroupHead && educationalGroupHead.organizer.id ? educationalGroupHead.organizer.id : null,
        currentId: null,
        teachers: [],
        teacher: educationalGroupHead && educationalGroupHead.teacher ? educationalGroupHead.teacher.id : '',
        teacherInList: (educationalGroupHead && educationalGroupHead.teacher === null) ? 2 : null,
        firstName: educationalGroupHead && educationalGroupHead.firstName ? educationalGroupHead.firstName : null,
        lastName: educationalGroupHead && educationalGroupHead.lastName ? educationalGroupHead.lastName : null,
        teacherInListDisabled: false,
        edu: educationalGroupHead
    });

    useEffect(() => {
        if (!add && activeRole === "ROLE_ADMIN") { getOrganizers(); getTeacherOrganizerById(state.organizer); console.log('edu', state.edu)}
        if(activeRole === "ROLE_ORGANIZER") {getTeacherOrganizer()}
        if(activeRole !== "ROLE_ORGANIZER") getOrganizers()
    },[activeRole]);

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'teacher'){
            if(!value){
                dispatch({type: 'firstName', payload: ''});
                dispatch({type: 'lastName', payload: ''});
                dispatch({type: 'teacherInList', payload: null});
            }else{
                dispatch({type: 'firstName', payload: value.teacher.person.firstName});
                dispatch({type: 'lastName', payload: value.teacher.person.lastName});
                dispatch({type: 'teacherInList', payload: 1});
            }

        }
        if(name === 'organizer' && value){
            getTeacherOrganizerById(value);
            dispatch({type: 'organizer', payload: value});
            console.log('edu', state.edu)
        }
        if(name === 'teacherInList' && value === 2){
                dispatch({type: 'firstName', payload: ''});
                dispatch({type: 'lastName', payload: ''});
                dispatch({type: 'teacher', payload: null});
        }
        if(name === 'teacherInList' && value === 1){
            dispatch({type: 'firstName', payload: state.teacher ? state.teacher.teacher.person.firstName : ''});
            dispatch({type: 'lastName', payload: state.teacher ? state.teacher?.teacher.person.lastName : ''});
        }
        if(name === 'teacherInList' && !value){
            dispatch({type: 'firstName', payload: ''});
            dispatch({type: 'lastName', payload: ''});
            dispatch({type: 'teacher', payload: null});
        }
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function getTeacherOrganizerById(id){

        setCurrentId(id);
        axios.get(`/teacher-organizer/by-organizer-id/${id}`,).then(
            (res) => {
                let teachers = res.data.map((el)=>({...el, key: el.id, text: el.teacher.person.firstName + '  ' + el.teacher.person.lastName, value: el}));
                dispatch({type: 'teachers', payload: teachers});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getTeacherOrganizer(){
        axios.get('/teacher-organizer/by-organizer',).then(
            (res) => {
                let teachers = res.data.map((el)=>({...el, key: el.id, text: el.teacher.person.firstName + '  ' + el.teacher.person.lastName, value: el}));
                dispatch({type: 'teachers', payload: teachers});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOrganizers(){
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function addEducationalGroupHead(){
        if (!state.firstName || !state.lastName){
            toast('Niste unijeli sve podatke.');
            if (!state.firstName) { setFirstNameErr(true); } else { setFirstNameErr(false); }
            if (!state.lastName) { setLastNameErr(true);} else { setLastNameErr(false); }

        } else {
            if (activeRole === "ROLE_ORGANIZER") {
                let obj = {
                    firstName:  state.firstName,
                    lastName:  state.lastName,
                    organizer: null,
                    teacher: state.teacherInList === 1 ? state.teacher.teacher : null
                };
                axios.post('/educational-group-head/save', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste dodali voditelja obrazovne grupe.');
                        getEducationalGroupHeads();
                    }).catch((err) => {
                    if (err.response.data.message === 'This teacher is already added as educational group head!') {
                        toast('Odabrani voditelj obrazovne grupe već postoji!');
                    } else {
                        toast('Došlo je do greške! Dodavanje voditelja obrazovne grupe nije uspjelo.');
                    }
                    console.log('err', err)
                });
            }
            else{
                let obj = {
                    firstName:  state.firstName,
                    lastName:  state.lastName,
                    organizer: {id: state.organizer},
                    teacher: state.teacherInList === 1 ? state.teacher.teacher : null
                };
                axios.post('/educational-group-head/save/for-admin', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste dodali voditelja obrazovne grupe.');
                        getEducationalGroupHeadsForAdmin(state.organizer);
                        setCurrentIdOrganizer(state.organizer);
                    }).catch((err) => {
                    if (err.response.data.message === 'This teacher is already added as educational group head!') {
                        toast('Odabrani voditelj obrazovne grupe već postoji!');
                    } else {
                        toast('Došlo je do greške! Dodavanje voditelja obrazovne grupe nije uspjelo.');
                    }
                    console.log('err', err)
                });
            }
        }
    }

    function editEducationalGroupHead(){
        if (!state.firstName || !state.lastName){
            toast('Niste unijeli sve podatke.');
            if (!state.firstName) { setFirstNameErr(true); } else { setFirstNameErr(false); }
            if (!state.lastName) { setLastNameErr(true);} else { setLastNameErr(false); }
        } else {
            let obj = {...educationalGroupHead,
                firstName: state.firstName,
                lastName: state.lastName
            }

            axios.put('/educational-group-head/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste izmijenili voditelja obrazovne grupe.');
                    activeRole === "ROLE_ADMIN" ? getEducationalGroupHeadsForAdmin(state.organizer) : getEducationalGroupHeads();
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {add?'Dodavanje novog voditelja obrazovne grupe':'Izmjena voditelja obrazovne grupe'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group>
                            {activeRole === "ROLE_ADMIN" &&<Form.Field required width={8}>
                                <label>Organizator</label>
                                <Form.Dropdown
                                    clearable
                                    disabled={activeRole === "ROLE_ADMIN" && !add}
                                    placeholder="Odaberite organizatora"
                                    value={state.organizer}
                                    onChange={handleInputChange}
                                    name="organizer"
                                    options={state.organizers}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>}
                            </Form.Group>
                        <Form.Group widths={'equal'}>
                            {state.teacherInList !== 2 && <Form.Field
                                required={state.teacherInList !== 2}
                                control={Select}
                                clearable
                                disabled={(activeRole === "ROLE_ADMIN" && state.organizer === null) || !add}
                                options={state.teachers}
                                placeholder="Odaberite nastavnika/icu"
                                label="Nastavnik/ica"
                                onChange={handleInputChange}
                                name="teacher"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={state.teacher}
                                search
                                error={teacherErr}

                            />}
                            <Form.Field
                                required
                                control={Select}
                                clearable
                                disabled={(activeRole === "ROLE_ADMIN" && state.organizer === null) || edit}
                                options={teacherDropdown}
                                placeholder="Odaberite opciju"
                                label="Da li se voditelj grupe nalazi u ponuđenoj listi?"
                                onChange={handleInputChange}
                                name="teacherInList"
                                value={state.teacherInList}
                                search
                                fluid
                                // error={teacherInListErr}

                            />

                           {state.teacherInList === 2 && <> <Form.Field
                                required
                                label="Ime voditelja grupe"
                                control={Input}
                                disabled={state.teacherInList !== 2}
                                name="firstName"
                                value={state.teacherInList === 1 && state.teacher ? state.teacher.teacher.person.firstName : state.firstName}
                                onChange={handleInputChange}
                                placeholder="Unesite ime"
                                error={firstNameErr}
                            />

                            <Form.Field
                                required
                                label="Prezime voditelja grupe"
                                control={Input}
                                disabled={state.teacherInList !== 2}
                                name="lastName"
                                value={state.teacherInList === 1 && state.teacher ? state.teacher.teacher.person.lastName : state.lastName}
                                onChange={handleInputChange}
                                placeholder="Unesite prezime"
                                error={lastNameErr}
                            /></>}
                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addEducationalGroupHead : editEducationalGroupHead}>{add ? 'Dodaj' : 'Sačuvaj'}</Button>
                    </Form>
                </Segment>
            </SegmentGroup>
        </div>
    );
};

export default EducationalGroupHeadForm;
