import React, {useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Button, Header, Icon, Loader, Menu} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {Link} from "react-router-dom";
import OrganizerForm from "./OrganizerForm";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import axios from "axios";
import {ToastContainer} from "react-toastify";
import SearchTableData from "../Utilities/SearchTableData";
import ReactExport from 'react-data-export';
import PaginationFront from "../Utilities/PaginationFront";

const reducer = (state, action)=>{
    switch (action.type) {
        case 'organizers':
            return {...state, organizers: action.payload, loader:  false };
        case 'organizersData':
            return {...state, organizersData: action.payload, loader: false};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;
    }
};

const Organizers = () => {
    const [state, dispatch] = useReducer(reducer,{
        organizers:[],
        organizersData: [],
        currentId: null,
        loader: true,
        data:[],
        results:[],
        postsPerPage: 10,
        currentPosts: [],
    }
    );
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [showAddForm, setShowAddForm] = useState(false);
    const [activePage, setActivePage] = useState(1);

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    const multiDataSet = [
        {
            columns: [
                { title: 'Naziv', width: { wpx: 500 } },
                { title: 'JIB', width: { wpx: 200 } },
                { title: 'PIB', width: { wpx: 200 } },
                { title: 'Adresa', width: { wpx: 200 } },
                { title: 'Telefon', width: { wpx: 150 } },
                { title: 'E-mail', width: { wpx: 200 } },
            ],
            data: state.organizersData
        }
    ];

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    useEffect(() => {
        getOrganizers();
    },[]);

    function getOrganizers(){
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data;
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'data', payload: organizers});
                let organizersData = res.data.map(el => ([
                    { value: el.name },
                    { value: String(el.jib) },
                    { value: String(el.pib) },
                    { value: el.residence.address },
                    { value: el.phone},
                    { value: el.email}
                ]));
                dispatch({type: 'organizersData', payload: organizersData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    function validation(){

    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function deleteOrganizerById(id){
        setCurrentId(id);
        axios.delete(`/organizer/delete/${id}`,).then(
            (res) => {
                 getOrganizers();
                 setCurrentId(null);
            }).catch((err) => {
                console.log('err', err)
        });
    }

    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Spisak organizatora </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            <Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Kreiraj organizatora
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                <ExcelFile filename="Spisak organizatora" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={multiDataSet} name="Organizatori" />
                                </ExcelFile>
                            </Menu.Item>
                            <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                            {/*<Menu.Item>*/}
                            {/*    <Button icon labelPosition='left' size="mini" basic color="black">*/}
                            {/*        <Icon name='file pdf outline' color={"red"} />*/}
                            {/*        Preuzmi spisak*/}
                            {/*    </Button>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                            {/*    <Button icon labelPosition='left' size="mini" basic color="black">*/}
                            {/*        <Icon name='file excel outline' color={"green"}/>*/}
                            {/*        Preuzmi spisak*/}
                            {/*    </Button>*/}
                            {/*</Menu.Item>*/}
                        </Menu.Menu>

                    </Menu>

                </Segment>

                {showAddForm &&
                <Segment>
                    {/*send add or edit as props*/}
                    <OrganizerForm add toggleEditForm={toggleAddForm} getOrganizer={getOrganizers} organizer={state.organizer}/>
                </Segment>}


                <Segment>
                   {state.loader ?
                       <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                       : <div> {currentPosts.length  ? <Table celled size={'small'}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={8} >
                                    <div style={{ paddingLeft: '0px' }}>
                                        <SearchTableData data={state.data} getResults={getResults} filterItem={['name']} />
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>

                                <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Naziv</Table.HeaderCell>
                                <Table.HeaderCell width={2}>JIB</Table.HeaderCell>
                                <Table.HeaderCell width={2}>PIB</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Adresa</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Telefon</Table.HeaderCell>
                                {/*<Table.HeaderCell width={2}>WEB</Table.HeaderCell>*/}
                                <Table.HeaderCell width={2}>E-mail</Table.HeaderCell>
                                <Table.HeaderCell width={3} />
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                currentPosts.map((el, index) =>
                                // state.organizers.map((el, index)=>
                                    (<Table.Row key={el.id}>
                                        <Table.Cell>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                        <Table.Cell>{el.name}</Table.Cell>
                                        <Table.Cell>{el.jib}</Table.Cell>
                                        <Table.Cell>{el.pib}</Table.Cell>
                                        {/*<Table.Cell>{el.residence.place.municipality?el.residence.place.municipality.canton.name + " | " + el.residence.place.municipality.name + " | " +el.residence.place.name:el.residence.place.name}</Table.Cell>*/}
                                        <Table.Cell>{el.residence.address}</Table.Cell>
                                        <Table.Cell>{el.phone}</Table.Cell>
                                        {/*<Table.Cell>{el.web}</Table.Cell>*/}
                                        <Table.Cell>{el.email}</Table.Cell>
                                        <Table.Cell width={3} verticalAlign="middle" textAlign="center">
                                            <Link to='/organizatori/detalji' state={{ name: el.name, id: el.id}}>
                                                <Popup size={"small"} content='O organizatoru' trigger={ <Button icon size={"mini"} basic color={"grey"}>
                                                    <Icon name='building outline' color={"teal"}/>
                                                </Button>} />
                                            </Link>
                                            <Link to='/organizatori/zanimanja' state={{ name: el.name, id: el.id}}>
                                            <Popup size={"small"} content='Zanimanja' trigger={  <Button icon size={"mini"} basic color={"grey"}>
                                                <Icon name='briefcase' color={"black"} />
                                            </Button>} />
                                            </Link>
                                            <Popup size={"small"} content='Obriši organizatora' trigger={
                                                <Button icon size={"mini"} basic color={"grey"} disabled={state.currentId && el.id !== state.currentId } onClick={() => {deleteOrganizerById(el.id)}} >
                                                <Icon name='trash alternate outline' color={"red"} />
                                            </Button>} />

                                        </Table.Cell>
                                    </Table.Row>)
                                )
                            }


                        </Table.Body>

                        <Table.Footer>
                        </Table.Footer>
                    </Table>: 'Nema podataka o organizatorima'}</div>}
                    {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                </Segment>
            </Segment.Group>
            <ToastContainer  />
        </div>
    );
};

export default Organizers;
