import React, {useEffect, useReducer} from 'react';
import {Header, Menu, Segment, SegmentGroup, Tab} from "semantic-ui-react";
import CourseTableByGrade from "../../StudyPrograms/CourseTableByGrade";
import StudentGradeList from "./StudentGradeList";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import GraduationExamRegistration from "./GraduationExamRegistration";
import GraduationExam from "./GraduationExam";
import Bhs from "./BHS";
import OptionalCourse from "./OptionalCourse";
import axios from "axios";
import VerbalOptionalCourse from "./VerbalOptionalCourse";
import moment from "moment";

const reducer = (state, action)=>{
    switch (action.type) {
        case 'isLastGradeCompleted':
            return {...state, isLastGradeCompleted: action.payload};
        case 'isCompleted':
            return {...state, isCompleted: action.payload};
        case 'isRecognized':
            return {...state, isRecognized: action.payload};
        case 'sspGrades':
            return {...state, sspGrades: action.payload};
        case 'graduationExam':
            return {...state, graduationExam: action.payload};
        case 'activeIndex':
            return {...state, activeIndex: action.payload};
        default:
            return state;
    }
};

const FormalEducation = () => {

    const sspId = localStorage.getItem('sspId');
    const duration = parseInt(localStorage.getItem('duration'));
    const startEducationDate = localStorage.getItem('startEducationDate')
    const [state, dispatch] = useReducer(reducer,{
        activeIndex:0,
        sspGrades:[],
        isLastGradeCompleted: false,
        isCompleted: false,
        isRecognized: false
    } );
    const panes = [
        {
            menuItem: 'Upisani razredi',
            render: () => (<Tab.Pane attached={false} as={'div'}>
                <StudentGradeList graduationExam={state.graduationExam} getSspGrades={getSspGrades} sspGrades={state.sspGrades}/>

            </Tab.Pane>)
        },
        {
            menuItem: duration === '3'? 'Prijava završnog ispita': 'Prijava maturskog ispita',
            render: () => (<Tab.Pane attached={false} as={'div'}>

                   <GraduationExamRegistration graduationExam={state.graduationExam} getGraduationInfo={getGraduationInfo}/>
            </Tab.Pane>)
        },
        state.graduationExam?.id && {
            menuItem: 'B/ H/ S jezik',
            render: () => (<Tab.Pane attached={false} as={'div'}>

                <Bhs graduationExam={state.graduationExam} getGraduationInfo={getGraduationInfo}/>
            </Tab.Pane>)
        },
       state.graduationExam?.id && {
            menuItem: 'Odabrani predmet',
            render: () => (<Tab.Pane attached={false} as={'div'}>

                <OptionalCourse graduationExam={state.graduationExam} getGraduationInfo={getGraduationInfo}/>
            </Tab.Pane>)
        },
        state.graduationExam?.id && {
            menuItem: 'Usmeni ispit iz odabranog predmeta',
            render: () => (<Tab.Pane attached={false} as={'div'}>

                <VerbalOptionalCourse graduationExam={state.graduationExam} getGraduationInfo={getGraduationInfo}/>
            </Tab.Pane>)
        },
        state.graduationExam?.id && {
            menuItem: 'Ostali podaci',
            render: () => (<Tab.Pane attached={false}  as={'div'}>
                 <GraduationExam graduationExam={state.graduationExam} getGraduationInfo={getGraduationInfo}/>
            </Tab.Pane>)
        },
    ];

    useEffect(() => {

        getSspGrades();

        getGraduationInfo();
    },[]);
    function isLastGradeCompleted(sspGrades, gradeNumber = duration){
        let isLastGradeCompleted = sspGrades.find(el=>(el.studentStudyProgramGrade.grade.number === duration))?.studentStudyProgramGrade.gradeDiplomaDate
        dispatch({type: 'isLastGradeCompleted', payload: isLastGradeCompleted});

    }

    // da li su svi upisani razredi koji nisu priznati zavrseni
    function isCompleted(sspGrades) {
        let isCompleted = sspGrades.find(el => ((el.studentStudyProgramGrade.completed === false) && (el.studentStudyProgramGrade.recognized === null)))?.studentStudyProgramGrade.completed
        if (isCompleted === undefined) dispatch({type: 'isCompleted', payload: true});
        else {dispatch({type: 'isCompleted', payload: false});}
    }

    function isRecognized(sspGrades) {
        let isRecognized = sspGrades.filter(el => ((el.studentStudyProgramGrade.recognized === true))).length === sspGrades.length
        if (isRecognized) dispatch({type: 'isRecognized', payload: true});
    }

    function getSspGrades(studentStudyProgramId = sspId ){

        dispatch({type: 'loader', payload: true});
        axios.get(`student-study-program-grade/and-not-passed-courses/by-student-study-program-id/${studentStudyProgramId}`,)
            .then((res) => {
                let sspGrades = res.data;
                dispatch({type: 'sspGrades', payload: sspGrades});
                dispatch({type: 'loader', payload: false});
                isLastGradeCompleted(sspGrades);
                isCompleted(sspGrades);
                isRecognized(sspGrades);
            }).catch((err) => {
            dispatch({type: 'loader', payload: false});
        });
    }
    function getGraduationInfo(){
        axios.get(`/graduation-exam/by-student-study-program/${localStorage.getItem('sspId')}`).then(
            (res) => {
                let graduationExam = res.data;
                dispatch({type: 'graduationExam', payload: graduationExam});
            }).catch((err) => {
            console.log('err', err)
        });

    }
    function handleTabChange(e, { activeIndex }) {
        dispatch({type: 'activeIndex', payload: activeIndex});
    }

    return (
        <SegmentGroup>
            <Segment className={'customPadding'} clearing color={"teal"}>
                <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>
                    <Menu.Item ><Header as={'h3'} floated={"left"}>
                        {localStorage.getItem('name')} - Obrazovni proces
                        <span>
                            <Icon/></span>
                        <Header.Subheader>
                            <i>Ime i prezime polaznika/ice</i> <br/>
                            <i>Početak obrazovnog procesa polaznika/ice: {moment(startEducationDate).format('D. M. YYYY.')}</i>
                        </Header.Subheader>
                    </Header>
                    </Menu.Item>
                    <Menu.Menu position={'right'}>
                        <Menu.Item>
                            <span>
                                <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                    <Icon name='arrow left'/>
                                </Button>
                                <Icon/>
                            </span>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </Segment>
            <Segment>
                {state.isLastGradeCompleted ? <Tab
                        // menu={{secondary: true, pointing: true, color: 'teal'}}
                        menu={{
                            secondary: true,
                            pointing: true,
                            color: 'teal',
                            vertical: true,
                            fluid: true,
                            attached: true,
                            tabular: true
                        }}
                        grid={{paneWidth: 13, tabWidth: 3}}
                        panes={panes}
                        activeIndex={state.activeIndex} onTabChange={handleTabChange}/>
                    :
                    <StudentGradeList graduationExam={state.graduationExam} getSspGrades={getSspGrades}
                                      sspGrades={state.sspGrades} isCompleted={state.isCompleted} isRecognized={state.isRecognized} isLastGradeCompleted={state.isLastGradeCompleted}/>}
            </Segment>

        </SegmentGroup>
    );
};

export default FormalEducation;
