import React, {useEffect, useReducer, useState} from 'react';
import PlaceForm from "../Utilities/PlaceForm";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {Header, Icon, SegmentGroup} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import axios from "axios";
import {toast} from "react-toastify";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'countries':
            return {...state, countries: action.payload};
        case 'entities':
            return {...state, entities: action.payload };
        case 'cantons':
            return {...state, cantons: action.payload };
        case 'municipalities':
            return {...state, municipalities: action.payload };
        case 'placeOfBirth':
            return {...state, placeOfBirth: action.payload};
        default:
            return state;
    }
};
const StudentPlaceOfBirthForm = ({toggleEditForm, add, edit, getStudent, student, getTeacher, teacher, municipalities}) => {
    const [countryErr, setCountryErr] = useState(false);
    const [placeErr, setPlaceErr] = useState(false);
    const [addressErr, setAddressErr] = useState(false);
    const [zipErr, setZipErr] = useState(false);

    const [state, dispatch] = useReducer(reducer,{

        firstName: (edit && student) ? student.student.person.firstName : (edit && teacher) ? teacher.person.firstName : '',
        lastName: (edit && student) ? student.student.person.lastName : (edit && teacher) ? teacher.person.lastName : '',
        maidenName: (edit && student) ? student.student.person.maidenName : (edit && teacher) ? teacher.person.maidenName : '',
        gender: (edit && student) ? student.student.person.gender.id : (edit && teacher) ? teacher.person.gender.id : null,
        fatherFirstName: (edit && student) ? student.student.fatherFirstName :'',
        fatherLastName: (edit && student) ? student.student.fatherLastName : '',
        motherFirstName: (edit && student) ? student.student.motherFirstName : '',
        motherLastName: (edit && student) ? student.student.motherLastName : '',
        jmbg: (edit && student) ? student.student.person.jmbg : (edit && teacher) ? teacher.person.jmbg : '',
        oid: (edit && student) ? student.student.person.oid : (edit && teacher) ? teacher.person.oid : '',
        dateOfBirth: (edit && student) ? student.student.person.dateOfBirth : (edit && teacher) ? teacher.person.dateOfBirth: new Date(),
        placeOfResidence: (edit && student) ? student.student.person.residence : (edit && teacher) ? teacher.person.residence: '',

        //teacher
        numberOfHoursInOriginPlaceOfEmployment: (edit && teacher) ? teacher.numberOfHoursInOriginPlaceOfEmployment : '',
        originPlaceOfEmployment : (edit && teacher) ? teacher.numberOfHoursInOriginPlaceOfEmployment : '',

        // student
        fileNumber: (edit && student) ? student.fileNumber : '',
        studyProgram: (edit && student) ? student.studyProgram : '',
        language: (edit && student) ? student.language : [],
        occupationType: (edit && student) ? student.student.occupationType : null,
        fileDate: (edit && student) ? student.fileDate : new Date(),
        startDate: (edit && student) ? student.startDate : new Date(),

        countries:[],
        entities: [],
        cantons: [],
        municipalities: [],

        country: (edit && student) ? student.student.person.placeOfBirth.country : (edit && teacher) ? teacher.person.placeOfBirth.country : '',
        entity: edit && student &&  !!student.student.person.placeOfBirth.municipality && !!student.student.person.placeOfBirth.municipality.entity ? student.student.person.placeOfBirth.municipality.entity : edit && teacher &&  !!teacher.person.placeOfBirth.municipality && !!teacher.person.placeOfBirth.municipality.entity ? teacher.person.placeOfBirth.municipality.entity : '',
        canton: (edit && student) ?  !!student.student.person.placeOfBirth.municipality && !!student.student.person.placeOfBirth.municipality.canton && student.student.person.placeOfBirth.municipality.canton : (edit && teacher) ?  !!teacher.person.placeOfBirth.municipality && !!teacher.person.placeOfBirth.municipality.canton && teacher.person.placeOfBirth.municipality.canton : '',
        municipality: edit && student && !!student.student.person.placeOfBirth.municipality ?  student.student.person.placeOfBirth.municipality : edit && teacher && !!teacher.person.placeOfBirth.municipality ?  teacher.person.placeOfBirth.municipality : '',
        place: (edit && student) ?  !!student.student.person.placeOfBirth && student.student.person.placeOfBirth.name : (edit && teacher) ?  !!teacher.person.placeOfBirth && teacher.person.placeOfBirth.name : '',
        // address: edit ?  !!student.student.person.residence && student.student.person.residence.address : '',
        // zip: edit ?  !!student.student.person.residence && student.student.person.residence.zipCode : '',
        // disable properties
        enableEntity: (edit && student) ? !!student.student.person.placeOfBirth.municipality && !!student.student.person.placeOfBirth.municipality.entity: (edit && teacher) ? !!teacher.person.placeOfBirth.municipality && !!teacher.person.placeOfBirth.municipality.entity : false,
        enableCanton: (edit && student) ? !!student.student.person.placeOfBirth.municipality && !!student.student.person.placeOfBirth.municipality.canton : (edit && teacher) ? !!teacher.person.placeOfBirth.municipality && !!teacher.person.placeOfBirth.municipality.canton : false,
        enableMunicipality: (edit && student) ? student.student.person.placeOfBirth.municipality !== null : (edit && teacher) ? teacher.person.placeOfBirth.municipality !== null : false
    });

    function handlePlaceChange(placeName, obj) {
        dispatch({type: placeName, payload: obj});
    }

    // function formValidation(){
    //     if (!state.placeOfBirth.country || !state.placeOfBirth.place) {
    //         toast("Niste unijeli sve podatke!");
    //         if (!state.placeOfResidence.country) { setCountryErr(true); } else { setCountryErr(false); }
    //         if (!state.placeOfResidence.place) { setPlaceErr(true); } else { setPlaceErr(false); }
    //         if (!state.placeOfResidence.address) { setAddressErr(true); } else { setAddressErr(false); }
    //         if (!state.placeOfResidence.zip) { setZipErr(true); } else { setZipErr(false); }
    //         return false;
    //     }
    //     return true;
    // }

    function editStudent(){
        let obj = {
            id: student.id,
            optLock: student.optLock,
            chosenLanguage: student.language,
            comment: student.comment,
            registryBook: student.registryBook,
            numberInRegistryBook: student.numberInRegistryBook,
            // created: new Date(),
            fileDate: student.fileDate,
            fileNumber: student.fileNumber,
            startDate: student.startDate,
            student: {
                id: student.student.id,
                // created: student.student.created,
                fatherFirstName: student.student.fatherFirstName,
                fatherLastName: student.student.fatherLastName,
                motherFirstName: student.student.motherFirstName,
                motherLastName: student.student.motherLastName,
                occupationType: student.student.occupationType,
                person: {
                    id: student.student.person.id,
                    // created: student.student.person.created,
                    dateOfBirth: student.student.person.dateOfBirth,
                    firstName: student.student.person.firstName,
                    lastName: student.student.person.lastName,
                    jmbg: student.student.person.jmbg,
                    oid: student.student.person.oid,
                    gender: {id: student.student.person.gender.id},

                    residence: state.placeOfResidence,
                    placeOfBirth: {
                            country: state.placeOfBirth.country,
                            name: state.placeOfBirth.place,
                            municipality: state.placeOfBirth.country.key === 'bih'?{
                                id: state.placeOfBirth.municipality.id,
                                canton: { id: state.placeOfBirth.canton.id },
                                entity: { id: state.placeOfBirth.entity.id }
                            }: null
                    },

                }
            },
            studyProgram: {
                id: student.studyProgram.id
            }

        };
        axios.put('/student-study-program/edit', {...obj}).then(
            (res) => {
                toggleEditForm();
                toast('Uspješno ste izmijenili polaznika.');
                getStudent();
            }).catch((err) => {
            console.log('err', err)
        });


    };

    function editTeacher(){
        let obj = {
            id: teacher.id,
            optLock: teacher.optLock,
            person: {
                // created: new Date(),
                id: teacher.person.id,
                optLock: teacher.person.optLock,
                dateOfBirth: teacher.person.dateOfBirth,
                firstName: teacher.person.firstName,
                lastName: teacher.person.lastName,
                maidenName: teacher.person.maidenName,
                jmbg: teacher.person.jmbg,
                oid: teacher.person.oid,
                gender: {id: teacher.person.gender.id},
                teacher: true,

                residence: state.placeOfResidence,
                placeOfBirth: {
                    id: teacher.person.placeOfBirth.id,
                    optLock: teacher.person.placeOfBirth.optLock,
                    country: state.placeOfBirth.country,
                    name: state.placeOfBirth.place,
                    municipality: state.placeOfBirth.country.key === 'bih'?{
                        id: state.placeOfBirth.municipality.id,
                        canton: { id: state.placeOfBirth.canton.id },
                        entity: { id: state.placeOfBirth.entity.id }
                    }: null
                },
            },
            numberOfHoursInOriginPlaceOfEmployment: state.numberOfHoursInOriginPlaceOfEmployment,
            // created: new Date(),
            originPlaceOfEmployment: state.originPlaceOfEmployment,

        };
        axios.put('/teacher/edit', {...obj}).then(
            (res) => {
                toggleEditForm();
                toast('Uspješno ste izmijenili nastavnika/icu.');
                getTeacher();
            }).catch((err) => {
            toast('Došlo je do greške!');
            console.log('err', err)
        });


    };

    useEffect(() => {
        axios.get('/country/all',).then(
            (res)=>{
                let countriesOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'countries', payload: countriesOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/entity/all',).then(
            (res)=>{
                let entityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'entities', payload: entityOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/canton/all',).then(
            (res)=>{
                let cantonOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'cantons', payload: cantonOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/municipality/all',).then(
            (res)=>{
                let municipalityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'municipalities', payload: municipalityOptions});
                // dispatch({type:'filteredMunicipalities', payload: municipalityOptions});

            }).catch((err)=>{
            console.log('err', err)
        });

        if(edit && student){
            dispatch({type:'country', payload: student.student.person.placeOfBirth.country});
            if( !!student.student.person.placeOfBirth.municipality && !!student.student.person.placeOfBirth.municipality.entity ){
                dispatch({type:'entity', payload: student.student.person.placeOfBirth.municipality.entity});
                // dispatch({type:'municipalities', payload:  student.student.person.residence.place.municipality.entity.key !== 'fbih' ? municipalities.filter((el)=>(el.value.entity && el.value.entity.key  === student.student.person.residence.place.municipality.entity.key)) : []});
            }
            if( !!student.student.person.placeOfBirth.municipality && !!student.student.person.placeOfBirth.municipality.canton){
                dispatch({type:'canton', payload: student.student.person.placeOfBirth.municipality.canton});
                // dispatch({type:'municipalities', payload: municipalities.filter((el)=>(el.value.canton && el.value.canton.key  === student.student.person.residence.place.municipality.canton.key))});
                dispatch({type:'municipality', payload: student.student.person.placeOfBirth.municipality});
            }
        }

        if(edit && teacher){
            dispatch({type:'country', payload: teacher.person.placeOfBirth.country});
            if( !!teacher.person.placeOfBirth.municipality && !!teacher.person.placeOfBirth.municipality.entity ){
                dispatch({type:'entity', payload: teacher.person.placeOfBirth.municipality.entity});
                // dispatch({type:'municipalities', payload:  student.student.person.residence.place.municipality.entity.key !== 'fbih' ? municipalities.filter((el)=>(el.value.entity && el.value.entity.key  === student.student.person.residence.place.municipality.entity.key)) : []});
            }
            if( !!teacher.person.placeOfBirth.municipality && !!teacher.person.placeOfBirth.municipality.canton){
                dispatch({type:'canton', payload: teacher.person.placeOfBirth.municipality.canton});
                // dispatch({type:'municipalities', payload: municipalities.filter((el)=>(el.value.canton && el.value.canton.key  === student.student.person.residence.place.municipality.canton.key))});
                dispatch({type:'municipality', payload: teacher.person.placeOfBirth.municipality});
            }
        }
    }, []);

    return (
        <div >
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {'Izmjena podataka'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            {/*placeholder*/}
                            <Icon/>
                       </span>
                    </Header>
                </Segment>

                <Segment clearing>


                    <PlaceForm
                        noAddress
                        noZip
                        oneRowTwoFields
                        handlePlaceChange={handlePlaceChange}
                        formName={'placeOfBirth'}
                        countries={state.countries}
                        entities={state.entities}
                        cantons={state.cantons}
                        municipalities={state.municipalities}
                        country = {state.country}
                        entity = {state.entity}
                        enableEntity = {state.enableEntity}
                        enableCanton = {state.enableCanton}
                        canton = {state.canton}
                        enableMunicipality = {state.enableMunicipality}
                        municipality = {state.municipality}
                        countryErr = {countryErr}
                        placeErr = {placeErr}
                        place = {state.place}

                    />

                    <Button floated={"right"} basic size={"small"} color={"teal"} onClick={student? editStudent : editTeacher}>{ 'Sačuvaj'}</Button>
                </Segment>
            </SegmentGroup>
        </div>
    );
};

export default StudentPlaceOfBirthForm;
