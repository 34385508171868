import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Form, Header, Icon, Input, SegmentGroup} from "semantic-ui-react";
import {DateTimePicker} from "react-widgets";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import axios from "axios";
import {toast} from "react-toastify";
import {UserRoleContext} from "../../Main";
const reducer = (state, action)=> {
    switch (action.type) {
        case 'requestTypes':
            return {...state, requestTypes: action.payload};
        case 'requestType':
            return {...state, requestType: action.payload};
        case 'created':
            return {...state, created: action.payload};
        case 'doneDate':
            return {...state, doneDate: action.payload};
        case 'done':
            return {...state, done: action.payload};
        case 'request':
            return {...state, request: action.payload};
        case 'response':
            return {...state, response: action.payload};
        default:
            return state;

    }
};
const RequestForm = ({add, edit, toggleEditForm, getRequests, request}) => {
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        requestType: edit ? request.editRequestType: null,
        doneDate: edit ? new Date() : new Date(),
        done: edit ? request.done : false,
        request: edit ? request.request : '',
        response: edit ? request.response : '',
        created: edit ? new Date(request.created): new Date(),
        requestTypes: []
    });

    const [isLoading, setIsLoading] = useState(false);

    function addRequest(){
        setIsLoading(true);
        let obj = {
            request: state.request,
            editRequestType: state.requestType,
            created: new Date()
        };
        axios.post('/edit-request/save', {...obj}).then(
            (res) => {
                toggleEditForm();
                toast('Uspješno ste poslali zahtjev.');
                getRequests();
                setIsLoading(false);
            }).catch((err) => {
            console.log('err', err)
        });

    }

    function editRequest(){
        setIsLoading(true);
        let obj= {
            id: request.id,
            optLock: request.optLock,
            request: state.request,
            editRequestType: state.requestType,
            created: state.created,
            done: state.done,
            doneDate: state.doneDate,
            response: state.response
        };

        axios.put('/edit-request/edit', {...obj}).then(
            (res) => {
                toggleEditForm();
                toast('Uspješno ste poslali odgovor.');
                getRequests();
                setIsLoading(false);
            }).catch((err) => {
            console.log('err', err)
        });

    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    useEffect(() => {
        getRequestsTypes();
    },[]);

    function getRequestsTypes(){
        axios.get('/edit-request-type/all',).then(
            (res) => {
                let requestTypes = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type: 'requestTypes', payload: requestTypes});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    return (
        <SegmentGroup>
            <Segment>
                <Header
                    as={'h4'}> {add ? 'Kreiranje zahtjeva' : 'Odgovor na zahtjev'}
                    <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                </Header>
            </Segment>
            <Segment clearing>
                <Form size={'small'} >
                    <Form.Group widths={'equal'} >
                        <Form.Field required>
                            <label>Vrsta</label>
                            <Form.Dropdown
                                disabled={edit}
                                clearable
                                placeholder="Odaberite vrstu"
                                value={state.requestType}
                                onChange={handleInputChange}
                                name="requestType"
                                options={state.requestTypes}
                                search
                                fluid
                                selection
                            />
                        </Form.Field>
                        <Form.Field
                            required
                            disabled
                            //error={documentDateErr}
                            control={DateTimePicker}
                            label="Datum zahtjeva"
                            format="D. M. YYYY."
                            placeholder="Unesite datum"
                            name="created"
                            value={state.created}
                            time={false}
                            onChange={(e) => handleDateChange('created', e)}
                            style={{ borderWidth: '0px' }}
                            //error={openFromDateErr}
                        />
                        {!add &&
                        <Form.Field
                            required
                            disabled
                            control={DateTimePicker}
                            label="Datum obrade zahtjeva"
                            format="D. M. YYYY."
                            placeholder="Unesite datum"
                            name="doneDate"
                            value={state.doneDate}
                            time={false}
                            onChange={(e) => handleDateChange('doneDate', e)}
                            style={{ borderWidth: '0px' }}
                        />
                        }



                    </Form.Group>
                    <Form.Field>
                        <label> Zahtjev </label>
                        <Form.TextArea
                            disabled={activeRole === 'ROLE_MINISTRY' || activeRole === 'ROLE_ADMIN'}
                            placeholder="Unesite zahtjev"
                            rows={3}
                            name="request"
                            value={state.request}
                            onChange={edit?()=>{}:handleInputChange}
                        />
                    </Form.Field>

                    {(activeRole !== 'ROLE_ORGANIZER' || edit)  && <Form.Field>
                        <label> Odgovor </label>
                        <Form.TextArea
                            disabled={activeRole === 'ROLE_ORGANIZER'}
                            placeholder="Unesite odgovor"
                            rows={3}
                            name="response"
                            value={state.response}
                            onChange={handleInputChange}
                        />
                    </Form.Field>}
                    <Button loading={isLoading} disabled={isLoading} floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addRequest : editRequest}>{add ? 'Pošalji' : 'Odgovori'}</Button>
                </Form>
            </Segment>

        </SegmentGroup>
    );
};

export default RequestForm;
