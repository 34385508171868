import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Button, Form, Header, Icon, Loader, Menu, Segment} from "semantic-ui-react";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import {UserRoleContext} from "../../../Main";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import ContestForm from "../../Contest/ContestForm";
import RegistryBookForm from "./RegistryBookForm";
import SearchTableData from "../../Utilities/SearchTableData";
import ReactExport from 'react-data-export';
import PaginationFront from "../../Utilities/PaginationFront";
import moment from "moment";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'registryBooks':
            return {...state, registryBooks: action.payload};
        case 'registryBook':
            return {...state, registryBook: action.payload};
        case 'registryBookData':
            return {...state, registryBookData: action.payload };
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'openTable':
            return {...state, openTable: action.payload};
        case 'currentIdOrganizer':
            return {...state, currentIdOrganizer: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;

    }
}

const RegistryBooks = () => {
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [activePage, setActivePage] = useState(1);
    const [state, dispatch] = useReducer(reducer,{
        registryBooks: [],
        registryBookData: [],
        registryBook: '',
        currentId: null,
        loader: false,
        organizer: '',
        organizers: [],
        openTable: false,
        currentIdOrganizer: null,
        data:[],
        results:[],
        postsPerPage: 20,
        currentPosts: [],
    });

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);


    const columns = [
        { title: 'Naziv matične knjige', width: { wpx: 150 } },
        { title: 'Tip obrazovanja', width: { wpx: 150 } },
        { title: 'Broj mjesta u matičnoj knjizi', width: { wpx: 150 } },
        { title: 'Napomena', width: { wpx: 150 } },
    ];

    if (activeRole !== "ROLE_ORGANIZER") {
        columns.unshift({ title: 'Organizator', width: { wpx: 250 } });
    }

    const multiDataSet = [
        {
            columns: columns,
            data: state.registryBookData,
        }
    ];
    // const multiDataSet = [
    //     {
    //         columns: [
    //             { title: 'Naziv matične knjige', width: { wpx: 150 } },
    //             { title: 'Tip obrazovanja', width: { wpx: 150 } },
    //             { title: 'Broj mjesta u matičnoj knjizi', width: { wpx: 150 } },
    //             { title: 'Napomena', width: { wpx: 150 } },
    //         ],
    //         data: state.registryBookData,
    //     }
    // ];

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function getRegistryBooksForAdmin(id){
        setCurrentId(id);
        setCurrentIdOrganizer(id);
        dispatch({type: 'loader', payload: true});
        dispatch({type: 'openTable', payload: true});
        axios.get(`/registry-book/by-organizer-id/${id}`,).then(
            (res) => {
                let registryBooks = res.data;
                dispatch({type: 'registryBooks', payload: registryBooks});
                dispatch({type: 'data', payload: registryBooks});
                let registryBookData = res.data.map(el => ([
                    { value: el.organizer.name },
                    { value: el.name },
                    { value: el.formal ? 'formalno' : 'neformalno' },
                    { value: el.maxNumberOfFields },
                    { value: el.comment },
                ]));
                dispatch({type: 'registryBookData', payload: registryBookData});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            dispatch({type: 'openTable', payload: true});
            getRegistryBooksForAdmin(value)
        }
        if(name === 'organizer' && !value){
            dispatch({type: 'openTable', payload: true});
            getRegistryBooksAll()
        }
    }

    function deleteRegistryBook(id){
        setCurrentId(id);
        dispatch({type: 'loader', payload: true});
        axios.delete(`/registry-book/delete/${id}`,).then(
            (res) => {
                activeRole === "ROLE_ORGANIZER" ? getRegistryBooks() : getRegistryBooksForAdmin(state.organizer);
                toast('Uspješno ste izbrisali matičnu knjigu!');
                setCurrentId(null);
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getRegistryBooks(){
        dispatch({type: 'loader', payload: true});
        axios.get('/registry-book/by-organizer',).then(
            (res) => {
                let registryBooks = res.data;
                dispatch({type: 'registryBooks', payload: registryBooks});
                dispatch({type: 'data', payload: registryBooks});
                let registryBookData = res.data.map(el => ([
                    { value: el.name },
                    { value: el.formal ? 'formalno' : 'neformalno' },
                    { value: el.maxNumberOfFields },
                    { value: el.comment ? el.comment : '' },
                ]));
                dispatch({type: 'registryBookData', payload: registryBookData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getRegistryBooksAll(){
        dispatch({type: 'loader', payload: true});
        axios.get('/registry-book/all',).then(
            (res) => {
                let registryBooks = res.data;
                dispatch({type: 'registryBooks', payload: registryBooks});
                dispatch({type: 'data', payload: registryBooks});
                let registryBookData = res.data.map(el => ([
                    { value: el.organizer.name },
                    { value: el.name },
                    { value: el.formal ? 'formalno' : 'neformalno' },
                    { value: el.maxNumberOfFields },
                    { value: el.comment ? el.comment : '' },
                ]));
                dispatch({type: 'registryBookData', payload: registryBookData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    useEffect(() => {
        // if (activeRole === "ROLE_ORGANIZER") {getRegistryBooks();}
        // if (activeRole !== "ROLE_ORGANIZER") {getOrganizers();}

        if (activeRole === "ROLE_ORGANIZER") {
            getRegistryBooks()
        }
        else if(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") {
            getOrganizers();
            getRegistryBooksAll();
        }
    },[activeRole]);

    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Spisak matičnih knjiga </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            {(!showEditForm && activeRole !== "ROLE_MINISTRY") && <Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Kreiraj matičnu knjigu
                                </Button>
                            </Menu.Item>}
                            <Menu.Item>
                                <ExcelFile filename="Spisak matičnih knjiga" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={multiDataSet} name="Matične knjige" />
                                </ExcelFile>
                            </Menu.Item>
                            <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Segment>

                {showAddForm &&
                <Segment>
                    <RegistryBookForm add toggleEditForm={toggleAddForm} getRegistryBook={getRegistryBooks} getRegistryBooksForAdmin={getRegistryBooksForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer}/>
                </Segment>}

                {showEditForm &&
                <Segment>
                    <RegistryBookForm edit toggleEditForm={toggleEditForm} getRegistryBook={getRegistryBooks} registryBook={state.registryBook} getRegistryBooksForAdmin={getRegistryBooksForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer}/>
                    {/*<ContestForm edit toggleEditForm={toggleEditForm} getContest={getContests} contest={state.contest} getContestsForAdmin={getContestsForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer}/>*/}
                </Segment>}

                {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Segment><Form size={'small'}>
                    <Form.Group widths="3">
                        <Form.Field>
                            <label>Organizator</label>
                            <Form.Dropdown
                                placeholder="Odaberite organizatora"
                                value={state.organizer}
                                onChange={handleInputChange}
                                name="organizer"
                                options={state.organizers}
                                search
                                fluid
                                selection
                                clearable
                            />
                        </Form.Field>
                    </Form.Group>
                </Form></Segment>}
                <Segment>
                    {state.loader ?
                        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                        :
                        currentPosts.length ?
                            <Table celled size={'small'}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={activeRole !== "ROLE_ORGANIZER" ? 11 : 9} >
                                            <div style={{ paddingLeft: '0px' }}>
                                                <SearchTableData data={state.data} getResults={getResults} filterItem={['name','comment']} />
                                            </div>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>

                                        <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                                        {activeRole !== "ROLE_ORGANIZER"  &&<Table.HeaderCell colSpan={2} >Organizator</Table.HeaderCell>}
                                        <Table.HeaderCell colSpan={2} >Naziv matične knjige</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Tip obrazovanja</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Broj mjesta u matičnoj knjizi</Table.HeaderCell>
                                        <Table.HeaderCell >Napomena</Table.HeaderCell>
                                        {activeRole !== "ROLE_MINISTRY" && <Table.HeaderCell width={1} />}
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        currentPosts.map((el, index) =>
                                        // state.registryBooks.map((el, index)=>
                                            (<Table.Row key={el.id}>
                                                <Table.Cell width={1}>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                                {activeRole !== "ROLE_ORGANIZER"  &&<Table.Cell colSpan={2}>{el.organizer.name}</Table.Cell>}
                                                <Table.Cell colSpan={2}>{el.name}</Table.Cell>
                                                <Table.Cell width={2}>{el.formal ? 'formalno' : 'neformalno'}</Table.Cell>
                                                <Table.Cell width={2}>{el.maxNumberOfFields}</Table.Cell>
                                                <Table.Cell>{el.comment}</Table.Cell>
                                                {activeRole !== "ROLE_MINISTRY" && <Table.Cell width={1} verticalAlign="middle" textAlign="center">
                                                    <Popup size={"small"} content='Izmijeni matičnu knjigu' trigger={
                                                        <Button icon size={"mini"} basic color={"grey"} onClick={() => {setShowEditForm(true); dispatch({type: 'registryBook', payload: el})}}>
                                                            <Icon name='pencil' color={"teal"} />
                                                        </Button>} />
                                                    <Popup size={"small"} content='Obriši matičnu knjigu' trigger={
                                                        <Button icon size={"mini"} basic color={"grey"} disabled={state.currentId && el.id !== state.currentId } onClick={() => {deleteRegistryBook(el.id)}}>
                                                            <Icon name='trash alternate outline' color={"red"} />
                                                        </Button>} />
                                                </Table.Cell>}
                                            </Table.Row>)
                                        )
                                    }


                                </Table.Body>

                                <Table.Footer>
                                </Table.Footer>
                            </Table> : <div>Nema unesenih podataka o matičnim knjigama.</div>}
                    {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                </Segment>
            </Segment.Group>
            <ToastContainer  />
        </div>
    );
};

export default RegistryBooks;
