import React, {useContext, useEffect, useReducer, useState} from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {Form, Header, Input, SegmentGroup} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {DateTimePicker} from "react-widgets";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {UserRoleContext} from "../../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'name':
            return {...state, name: action.payload};
        case 'teachers':
            return {...state, teachers: action.payload};
        case 'firstMember':
            return {...state, firstMember: action.payload};
        case 'secondMember':
            return {...state, secondMember: action.payload};
        case 'thirdMember':
            return {...state, thirdMember: action.payload};
        case 'backupMember':
            return {...state, backupMember: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'comment':
            return {...state, comment: action.payload};
        default:
            return state;

    }
};
const CommitteeForm = ({add, committee, getCommittees, toggleEditForm, getCommitteesForAdmin, edit}) => {

    const [nameErr, setNameErr] = useState(false);
    const [firstMemberErr, setfirstMemberErr] = useState(false);
    const [secondMemberErr, setsecondMemberErr] = useState(false);
    const [thirdMemberErr, setthirdMemberErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [backupMemberErr, setbackupMemberErr] = useState(false);

    const [state, dispatch] = useReducer(reducer,{
        teachers: [],
        name: committee && committee.name ? committee.name : null,
        firstMember: committee && committee.firstMember ? committee.firstMember : null,
        secondMember: committee && committee.secondMember ? committee.secondMember : null,
        thirdMember: committee && committee.thirdMember ? committee.thirdMember : null,
        backupMember: null,
        comment: committee && committee.comment ? committee.comment : null,
        organizers: [],
        organizer: committee && committee.organizer.id ? committee.organizer.id : ''
    });


    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            getTeachersForAdmin(value);
        }
    }

    // useEffect(() => {
    //     getTeachers()
    // },[]);

    function getTeachers(){
        axios.get('/teacher/by-organizer',).then(
            (res) => {
                let teachers = res.data.map((el)=>({...el, key: el.id, text:el.person.firstName + ' ' +  el.person.lastName, value: el}));
                dispatch({type: 'teachers', payload: teachers});
            }).catch((err) => {

        });
    }

    function getTeachersForAdmin(id){
        axios.get(`/teacher/by-organizer-id/${id}`,).then(
            (res) => {
                let teachers = res.data.map((el)=>({...el, key: el.id, text:el.person.firstName + ' ' +  el.person.lastName, value: el}));
                dispatch({type: 'teachers', payload: teachers});
            }).catch((err) => {

        });
    }

    useEffect(() => {
        if(activeRole === "ROLE_ADMIN") {getOrganizers(); getTeachersForAdmin(state.organizer)}
        if (activeRole === "ROLE_ORGANIZER") {getTeachers()}
    },[add, activeRole]);

    function getOrganizers(){
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function addCommittee(){
        if (!state.name) {setNameErr(true);} else {setNameErr(false);}
        if (!state.firstMember) {setfirstMemberErr(true);} else {setfirstMemberErr(false);}
        if (!state.secondMember) {setsecondMemberErr(true);} else {setsecondMemberErr(false);}
        if (!state.thirdMember) {setthirdMemberErr(true);} else {setthirdMemberErr(false);}
        // if (!state.backupMember) {setbackupMemberErr(true);} else {setbackupMemberErr(false);}

        if (!state.name || !state.firstMember ||!state.secondMember || !state.thirdMember) {
            toast('Niste unijeli sve podatke.');
        }
         else {

             if(activeRole === "ROLE_ORGANIZER") {
                 let obj = {
                     name: state.name,
                     createdDate: new Date(),
                     firstMember: state.firstMember,
                     secondMember: state.secondMember,
                     thirdMember: state.thirdMember,
                     fourthMember: null,
                     fifthMember: null,
                     reserveMember: state.backupMember,
                     organizer: null,
                     comment: state.comment
                 };
                 axios.post('committee/save/for-organizer', {...obj}).then(
                     (res) => {
                         toggleEditForm();
                         toast('Uspješno ste dodali komisiju.');
                         getCommittees();
                     }).catch((err) => {
                     console.log('err', err)
                 });
             }
             else
             {
                 let obj = {
                     name: state.name,
                     createdDate: new Date(),
                     firstMember: state.firstMember,
                     secondMember: state.secondMember,
                     thirdMember: state.thirdMember,
                     fourthMember: null,
                     fifthMember: null,
                     reserveMember: state.backupMember,
                     organizer: {id: state.organizer},
                     comment: state.comment
                 };
                 axios.post('committee/save', {...obj}).then(
                     (res) => {
                         toggleEditForm();
                         toast('Uspješno ste dodali komisiju.');
                         getCommitteesForAdmin(state.organizer);
                     }).catch((err) => {
                     console.log('err', err)
                 });
             }

        }
    }

    function editCommittee(){
        if (!state.name) {setNameErr(true);} else {setNameErr(false);}
        if (!state.firstMember) {setfirstMemberErr(true);} else {setfirstMemberErr(false);}
        if (!state.secondMember) {setsecondMemberErr(true);} else {setsecondMemberErr(false);}
        if (!state.thirdMember) {setthirdMemberErr(true);} else {setthirdMemberErr(false);}
        // if (!state.backupMember) {setbackupMemberErr(true);} else {setbackupMemberErr(false);}

        if (!state.name || !state.firstMember ||!state.secondMember || !state.thirdMember) {
            toast('Niste unijeli sve podatke.');
        }
        else {

            if(activeRole === "ROLE_ORGANIZER") {
                let obj = {
                    id: committee.id,
                    optLock: committee.optLock,
                    name: state.name,
                    createdDate: new Date(),
                    firstMember: state.firstMember,
                    secondMember: state.secondMember,
                    thirdMember: state.thirdMember,
                    fourthMember: null,
                    fifthMember: null,
                    reserveMember: state.backupMember,
                    organizer: null,
                    comment: state.comment
                };
                axios.put('committee/edit', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste izmijenili komisiju.');
                        getCommittees();
                    }).catch((err) => {
                    console.log('err', err)
                });
            }
            else
            {
                let obj = {
                    id: committee.id,
                    optLock: committee.optLock,
                    name: state.name,
                    createdDate: new Date(),
                    firstMember: state.firstMember,
                    secondMember: state.secondMember,
                    thirdMember: state.thirdMember,
                    fourthMember: null,
                    fifthMember: null,
                    reserveMember: state.backupMember,
                    organizer: {id: state.organizer},
                    comment: state.comment
                };
                axios.put('committee/edit', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste izmijenili komisiju.');
                        getCommitteesForAdmin(state.organizer);
                    }).catch((err) => {
                    console.log('err', err)
                });
            }

        }
    }

    return (
        <SegmentGroup>
            <Segment>
                <Header
                    as={'h4'}> {add ? 'Dodaj komisiju' : 'Izmijeni podatke'}
                    <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                </Header>
            </Segment>
            <Segment clearing>
                <Form size={'small'} >
                    <Form.Group widths={'equal'} >
                        {activeRole === "ROLE_ADMIN" &&<Form.Field required>
                            <label>Organizator</label>
                            <Form.Dropdown
                                clearable
                                disabled={activeRole === "ROLE_ADMIN" && !add}
                                placeholder="Odaberite organizatora"
                                value={state.organizer}
                                onChange={handleInputChange}
                                name="organizer"
                                options={state.organizers}
                                search
                                fluid
                                selection
                            />
                        </Form.Field>}
                        <Form.Field required>
                            <label>Naziv komisije</label>
                            <Form.Field
                                required
                                control={Input}
                                placeholder="Unesite naziv"
                                value={state.name}
                                name="name"
                                onChange={handleInputChange}
                                error={nameErr}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Predsjednik komisije</label>
                            <Form.Dropdown
                                clearable
                                placeholder="Odaberite nastavnika"
                                value={state.firstMember}
                                onChange={handleInputChange}
                                name="firstMember"
                                options={state.teachers}
                                disabled={edit}
                                search
                                fluid
                                selection
                                error={firstMemberErr}
                            />
                        </Form.Field>
                        <Form.Field required>
                        <label>Drugi član komisije</label>
                        <Form.Dropdown
                            clearable
                            placeholder="Odaberite nastavnika"
                            value={state.secondMember}
                            onChange={handleInputChange}
                            name="secondMember"
                            options={state.teachers}
                            disabled={edit}
                            search
                            fluid
                            selection
                            error={secondMemberErr}
                        />
                    </Form.Field>
                        <Form.Field required>
                            <label>Treći član komisije</label>
                            <Form.Dropdown
                                clearable
                                placeholder="Odaberite nastavnika"
                                value={state.thirdMember}
                                onChange={handleInputChange}
                                name="thirdMember"
                                options={state.teachers}
                                disabled={edit}
                                search
                                fluid
                                selection
                                error={thirdMemberErr}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Napomena</label>
                            <Form.Field
                                control={Input}
                                placeholder="Unesite napomenu"
                                value={state.comment}
                                name="comment"
                                onChange={handleInputChange}
                            />
                        </Form.Field>
                        {/*<Form.Field>*/}
                        {/*    <label>Rezervni član komisije</label>*/}
                        {/*    <Form.Dropdown*/}
                        {/*        clearable*/}
                        {/*        placeholder="Odaberite nastavnika"*/}
                        {/*        value={state.backupMember}*/}
                        {/*        onChange={handleInputChange}*/}
                        {/*        name="backupMember"*/}
                        {/*        options={state.teachers}*/}
                        {/*        search*/}
                        {/*        fluid*/}
                        {/*        selection*/}
                        {/*        error={backupMemberErr}*/}
                        {/*    />*/}
                        {/*</Form.Field>*/}

                    </Form.Group>


                    <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addCommittee : editCommittee}>{add ? 'Dodaj' : 'Izmijeni'}</Button>
                </Form>
            </Segment>

        </SegmentGroup>
    );
};

export default CommitteeForm;
