import React, {useEffect, useState} from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import moment from "moment";
import {Form, Input} from "semantic-ui-react";
import {DateTimePicker} from "react-widgets";
import axios from "axios";

const sspInfo = ({ssp, edit, numberInRegistryBookErr, handleInputChange, handleDateChange, fileNumber, numberInRegistryBook, registryBook, registryBooks, fileDate, dropoutDate, dropoutReason, graduationDateFinal, diplomaNumberFinal}) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [dropoutReasons, setDropoutReasons] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        axios.get('/dropout-reason/all',).then(
            (res)=>{
                let dropoutReasons = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
               setDropoutReasons(dropoutReasons);

            }).catch((err)=>{
            console.log('err', err)
        });
    },[]);
    return (
        <Table celled size={"small"}>
            <Table.Body>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Broj prijave </Table.HeaderCell></Table.Cell>
                    {edit ?
                        <Table.Cell >
                            <Form size={'tiny'}>
                                    <Form.Field
                                        required
                                        control={Input}
                                        placeholder="Unesite broj"
                                        value={fileNumber}
                                        name="fileNumber"
                                        onChange={handleInputChange}
                                        // error={this.state.jmbgErr}
                                    />
                            </Form>
                        </Table.Cell>:
                        <Table.Cell width={10}>{ssp?ssp.fileNumber:''}</Table.Cell>}
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Datum podnošenja prijave</Table.HeaderCell></Table.Cell>
                    {edit ? <Table.Cell >
                            <Form size={'tiny'}>
                                <Form.Field
                                    required
                                    control={DateTimePicker}
                                    format="D. M. YYYY."
                                    placeholder="Unesite datum"
                                    name="fileDate"
                                    value={fileDate}
                                    time={false}
                                    onChange={(e) => handleDateChange('fileDate', e)}
                                    style={{ borderWidth: '0px' }}
                                />
                            </Form>
                        </Table.Cell>:
                        <Table.Cell>{ssp && ssp.fileDate?moment(ssp.fileDate).format('D. M. YYYY.'):''}</Table.Cell>
                    }

                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Datum unosa u sistem</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{ssp?moment(ssp.created).format('D. M. YYYY.'):''}</Table.Cell>

                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Datum početka obrazovnog procesa</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{ssp?moment(ssp.startDate).format('D. M. YYYY.'):''}</Table.Cell>

                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell> Organizator </Table.HeaderCell></Table.Cell>
                    <Table.Cell width={10}>{ssp?ssp.studyProgram.organizerOccupation.organizer.name:''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Vrsta obrazovanja </Table.HeaderCell></Table.Cell>
                    <Table.Cell width={10}>{ssp?ssp.studyProgram.supplementaryEducationType.name:''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Struka | zanimanje </Table.HeaderCell></Table.Cell>
                    <Table.Cell width={10}><strong>{ssp?ssp.studyProgram.organizerOccupation.occupation.profession.name:''}</strong>{ssp? ' | ' + ssp.studyProgram.organizerOccupation.occupation.nameBosnian:''}</Table.Cell>
                </Table.Row>
                {/*<Table.Row>*/}
                {/*    <Table.Cell> <Table.HeaderCell>Zanimanje</Table.HeaderCell></Table.Cell>*/}
                {/*    <Table.Cell width={10}>{ssp?ssp.studyProgram.organizerOccupation.occupation.nameBosnian:''}</Table.Cell>*/}
                {/*</Table.Row>*/}
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Nastavni program </Table.HeaderCell></Table.Cell>
                    <Table.Cell width={10}>{ssp?ssp.studyProgram.name:''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Matična knjiga </Table.HeaderCell></Table.Cell>
                    {edit ?
                        <Table.Cell >
                            <Form size={'tiny'}>
                                <Form.Dropdown
                                clearable
                                placeholder="Odaberite matičnu knjigu"
                                value={registryBook}
                                onChange={handleInputChange}
                                name="registryBook"
                                options={registryBooks}
                                search
                                fluid
                                selection
                            />
                            </Form>
                        </Table.Cell>:
                        <Table.Cell width={10}>{ssp?ssp.registryBook.name:''}</Table.Cell>}
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Broj u matičnoj knjizi </Table.HeaderCell></Table.Cell>
                    {edit ?
                        <Table.Cell >
                            <Form size={'tiny'}>
                                <Form.Field
                                    required
                                    control={Input}
                                    error={numberInRegistryBookErr}
                                    // type="numberInRegistryBook"
                                    placeholder="Unesite broj u matičnoj knjizi"
                                    value={numberInRegistryBook}
                                    name="numberInRegistryBook"
                                    onChange={handleInputChange}
                                />
                            </Form>
                        </Table.Cell>:
                        <Table.Cell width={10}>{ssp?ssp.numberInRegistryBook:''}</Table.Cell>}
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Datum završetka</Table.HeaderCell></Table.Cell>
                    {edit ? <Table.Cell >
                            <Form size={'tiny'}>
                                <Form.Field
                                    // required
                                    control={DateTimePicker}
                                    format="D. M. YYYY."
                                    placeholder="Unesite datum"
                                    name="graduationDateFinal"
                                    value={graduationDateFinal}
                                    time={false}
                                    onChange={(e) => handleDateChange('graduationDateFinal', e)}
                                    style={{ borderWidth: '0px' }}
                                />
                            </Form>
                        </Table.Cell>:
                        <Table.Cell>{ssp && ssp.graduationDate?moment(ssp.graduationDate).format('D. M. YYYY.'):''}</Table.Cell>
                    }

                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Broj diplome </Table.HeaderCell></Table.Cell>
                    {edit ?
                        <Table.Cell >
                            <Form size={'tiny'}>
                                <Form.Field
                                    control={Input}
                                    name="diplomaNumberFinal"
                                    value={diplomaNumberFinal}
                                    onChange={handleInputChange}
                                    placeholder="Unesite broj diplome"
                                    // error={contractNumberErr}
                                />
                            </Form>
                        </Table.Cell>:
                        <Table.Cell width={10}>
                            {ssp && ssp.diplomaNumber?ssp.diplomaNumber:''}
                        </Table.Cell>}

                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Datum ispisa</Table.HeaderCell></Table.Cell>
                    {edit ? <Table.Cell >
                            <Form size={'tiny'}>
                                <Form.Field
                                    required
                                    control={DateTimePicker}
                                    format="D. M. YYYY."
                                    placeholder="Unesite datum ispisa"
                                    name="dropoutDate"
                                    value={dropoutDate}
                                    time={false}
                                    onChange={(e) => handleDateChange('dropoutDate', e)}
                                    style={{ borderWidth: '0px' }}
                                />
                            </Form>
                        </Table.Cell>:
                        <Table.Cell>{ssp && ssp.droppedOutDate?moment(ssp.droppedOutDate).format('D. M. YYYY.'):''}</Table.Cell>
                    }

                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Razlog ispisa </Table.HeaderCell></Table.Cell>
                    {edit ?
                        <Table.Cell >
                            <Form size={'tiny'}>
                                <Form.Field required>
                                    <Form.Dropdown
                                        clearable
                                        placeholder="Odaberite razlog"
                                        value={dropoutReason}
                                        onChange={handleInputChange}
                                        name="dropoutReason"
                                        options={dropoutReasons}
                                        search
                                        fluid
                                        selection
                                    />
                                </Form.Field>
                            </Form>
                        </Table.Cell>:
                        <Table.Cell width={10}>
                            {ssp && ssp.dropoutReason?ssp.dropoutReason.name:''}
                        </Table.Cell>}

                </Table.Row>
                {/*<Table.Row >*/}
                {/*    <Table.Cell><Table.HeaderCell> Tip </Table.HeaderCell></Table.Cell>*/}
                {/*    <Table.Cell>{ssp?ssp.studentType.name:''}</Table.Cell>*/}
                {/*</Table.Row>*/}
                {/*<Table.Row>*/}
                {/*    <Table.Cell> <Table.HeaderCell>JIB</Table.HeaderCell></Table.Cell>*/}
                {/*    <Table.Cell>{ssp?ssp.jib:''}</Table.Cell>*/}
                {/*</Table.Row>*/}
                {/*<Table.Row>*/}
                {/*    <Table.Cell> <Table.HeaderCell>PIB</Table.HeaderCell></Table.Cell>*/}
                {/*    <Table.Cell>{ssp?ssp.pib:''}</Table.Cell>*/}
                {/*</Table.Row>*/}
                {/*<Table.Row>*/}
                {/*    <Table.Cell> <Table.HeaderCell>Broj upisa u sudski registar</Table.HeaderCell></Table.Cell>*/}
                {/*    <Table.Cell>{ssp?ssp.courtRegisterInputNumber:''}</Table.Cell>*/}
                {/*</Table.Row>*/}

                {/*<Table.Row>*/}
                {/*    <Table.Cell> <Table.HeaderCell>Država</Table.HeaderCell></Table.Cell>*/}
                {/*    <Table.Cell>{ssp?ssp.residence.place.country.name:''}</Table.Cell>*/}
                {/*</Table.Row>*/}
                {/*<Table.Row>*/}
                {/*    <Table.Cell> <Table.HeaderCell>Mjesto</Table.HeaderCell></Table.Cell>*/}
                {/*    <Table.Cell>{ssp?ssp.residence.place.name:''}</Table.Cell>*/}
                {/*</Table.Row>*/}
                {/*<Table.Row>*/}
                {/*    <Table.Cell> <Table.HeaderCell>Adresa</Table.HeaderCell></Table.Cell>*/}
                {/*    <Table.Cell>{ssp?ssp.residence.address:''}</Table.Cell>*/}
                {/*</Table.Row>*/}
                {/*<Table.Row>*/}
                {/*    <Table.Cell> <Table.HeaderCell>E-mail</Table.HeaderCell></Table.Cell>*/}
                {/*    <Table.Cell>{ssp?ssp.email:''}</Table.Cell>*/}
                {/*</Table.Row>*/}
                {/*<Table.Row>*/}
                {/*    <Table.Cell> <Table.HeaderCell>Telefon</Table.HeaderCell></Table.Cell>*/}
                {/*    <Table.Cell>{ssp?ssp.phone:''}</Table.Cell>*/}
                {/*</Table.Row>*/}
            </Table.Body>
        </Table>
    );
};

export default sspInfo;
