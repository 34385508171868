import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Form, Header, Input, SegmentGroup} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import {DateTimePicker} from "react-widgets";
import {UserRoleContext} from "../../../Main";
import moment from "moment/moment";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'grade':
            return {...state, grade: action.payload};
        case 'grades':
            return {...state, grades: action.payload};
        case 'gradesWithoutFilter':
            return {...state, gradesWithoutFilter: action.payload};
        case 'enrollmentDate':
            return {...state, enrollmentDate: action.payload};
        case 'completedDate':
            return {...state, completedDate: action.payload};
        case 'gradeDiplomaDate':
            return {...state, gradeDiplomaDate: action.payload};
        case 'gradeDiplomaNumber':
            return {...state, gradeDiplomaNumber: action.payload};
        case 'educationalGroupHead':
            return {...state, educationalGroupHead: action.payload};
        case 'educationalGroupHeads':
            return {...state, educationalGroupHeads: action.payload};
        case 'recognized':
            return {...state, recognized: action.payload};
        default:
            return state;

    }
};
const SspGradeForm = ({add, edit, toggleEditForm, grade, sspId, getSspGrades, sspGrades, isCompleted, isRecognized}) => {
    const [gradeErr, setGradeErr] = useState(false);
    const [enrollmentDateErr, setEnrollmentDateErr] = useState(false);
    const [completedDateErr, setCompletedDateErr] = useState(false);
    const [educationalGroupHeadErr, setEducationalGroupHeadErr] = useState(false);

    const lastCompletedGrade = JSON.parse(localStorage.getItem('lastCompletedGrade')).number;
    const startEducationDate = localStorage.getItem('startEducationDate')
    const supplementaryEducationTypeKey = localStorage.getItem('supplementaryEducationTypeKey')
    const gradeDurationMonths = supplementaryEducationTypeKey === 'primary education'? (grade?.grade.number < 6) ?3:6:parseInt(localStorage.getItem('gradeDurationMonths'))
    const organizerId = localStorage.getItem('organizerId')
    const {activeRole} = useContext(UserRoleContext);

    function handleInputChange(event, { name, value }) {
        if(name === 'recognized'){
            dispatch({type: name, payload: !state.recognized});
        }else{
            dispatch({type: name, payload: value});
        }

    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    const [state, dispatch] = useReducer(reducer,{
        grades: [],
        grade: add ? null :  grade?.grade.id,
        enrollmentDate: add ? null :  grade.enrollmentDate ? new Date(grade.enrollmentDate): null,
        completedDate: add ? null :  grade.completedDate ? new Date(grade.completedDate): null,
        educationalGroupHead: add ? null : grade?.educationalGroupHead,
        educationalGroupHeads: [],
        // gradeDiplomaNumber: add ? null :  grade?.gradeDiplomaNumber,
        // gradeDiplomaDate: add ? null :  grade?.gradeDiplomaDate,

    });

    useEffect(()=>{
        getGrades();
        if (activeRole === "ROLE_ORGANIZER") {getEducationalGroupHeads();}
        else {getEducationalGroupHeadsForAdmin()}
    },[activeRole]);

    // function getGrades(){
    //
    //     axios.get(`student-study-program/not-passed-grades/${sspId}`,).then(
    //         (res) => {
    //             let grades = res.data.sort((a, b) => a.number - b.number).map((el)=>({...el, key: el.id, text:el.name, value: el.id}))
    //             dispatch({type: 'grades', payload: grades});
    //             dispatch({type: 'loader', payload: false});
    //         }).catch((err) => {
    //         console.log('err', err)
    //     });
    // }


    function getGrades(){
        const spId = parseInt(localStorage.getItem('studyProgramId'))
        axios.get(`/study-program/grades/${spId}`,).then(
            (res) => {
                let gradesWithoutFilter = res.data.sort((a, b) => a.number - b.number).map((el)=>({...el, key: el.id, text:el.name, value: el.id}))
                let grades = gradesWithoutFilter.filter((e)=>(!sspGrades.find((el)=>(el.studentStudyProgramGrade.grade.id === e.id && (el.studentStudyProgramGrade.gradeDiplomaDate || !el.studentStudyProgramGrade.completed)))));
                // let grades = res.data.sort((a, b) => a.number - b.number).map((el)=>({...el, key: el.id, text:el.name, value: el.id})).filter((e)=>(e.number<=lastCompletedGrade));
                dispatch({type: 'grades', payload: grades});
                dispatch({type: 'gradesWithoutFilter', payload: gradesWithoutFilter});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }
    function getEducationalGroupHeads(){

        axios.get('/educational-group-head/by-organizer',).then(
            (res) => {
                let educationalGroupHeads = res.data.map((el)=>({...el, key: el.id, text:el.firstName + ' ' + el.lastName, value: el}));
                dispatch({type: 'educationalGroupHeads', payload: educationalGroupHeads});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getEducationalGroupHeadsForAdmin(){

        axios.get(`/educational-group-head/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let educationalGroupHeads = res.data.map((el)=>({...el, key: el.id, text:el.firstName + ' ' + el.lastName, value: el}));
                dispatch({type: 'educationalGroupHeads', payload: educationalGroupHeads});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function sspGradeEnrollment() {
        if(state.enrollmentDate &&  (new Date(state.enrollmentDate) < (sspGrades.length === 0 ? new Date(startEducationDate) : new Date(startEducationDate).getTime() + ( (state.gradesWithoutFilter?.find(e=> (e.id === state.grade))?.number-1) * gradeDurationMonths * 30 * (1000 * 60 *60 *24)) ) || new Date(state.enrollmentDate) > (sspGrades.length === 0?new Date(startEducationDate).getTime() + ( 7 * (1000 * 60 *60 *24)):new Date(state.enrollmentDate)))){
            toast('Datumi iz pogresnog intervala.');
            return;
        }
        if (!state.grade) {setGradeErr(true);} else {setGradeErr(false);}
        if (!state.enrollmentDate) {setEnrollmentDateErr(true);} else {setEnrollmentDateErr(false);}
        // if (!state.completedDate) {setCompletedDateErr(true);} else {setCompletedDateErr(false);}
        if (!state.educationalGroupHead) {setEducationalGroupHeadErr(true);} else {setEducationalGroupHeadErr(false);}

        if (!state.grade ||!state.enrollmentDate ||  !state.educationalGroupHead) {
            toast('Niste unijeli sve podatke.');
        }else {

            let obj = {
                studentStudyProgram: {id: sspId},
                grade: {id: state.grade},
                enrollmentDate: state.enrollmentDate,
                completedDate: state.completedDate,
                educationalGroupHead: state.educationalGroupHead,
                recognized: state.recognized,
                //def value
                completed: false,
                droppedOut: false,
                gradeDiplomaDate: null,
                gradeDiplomaNumber: '',
                comment: ''
            };
            axios.post('student-study-program-grade/save', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste upisali polaznika u odabrani razred.');
                    getSspGrades();
                }).catch((err) => {

                if (err.response.data.message === 'This course is already added for passed study program!') {
                    toast('Polaznik je već upisan u odabrani razred.');
                } else {
                    toast('Došlo je do greške! Polaznik nije upisan u odabrani razred.');
                }
                console.log('err', err)
            });
        }

    }

    function editSspGrade() {

        if(state.enrollmentDate &&  (new Date(state.enrollmentDate) < (sspGrades.length === 0 ? new Date(startEducationDate) : new Date(startEducationDate).getTime() + ( (state.gradesWithoutFilter?.find(e=> (e.id === state.grade))?.number-1) * gradeDurationMonths * 30 * (1000 * 60 *60 *24)) ) || new Date(state.enrollmentDate) > (sspGrades.length === 0?new Date(startEducationDate).getTime() + ( 7 * (1000 * 60 *60 *24)):new Date(state.enrollmentDate)))){
            toast('Datumi iz pogresnog intervala.');
            return;
        }
        if (!state.enrollmentDate) {setEnrollmentDateErr(true);} else {setEnrollmentDateErr(false);}
        // if (!state.completedDate) {setCompletedDateErr(true);} else {setCompletedDateErr(false);}
        if (!state.educationalGroupHead) {setEducationalGroupHeadErr(true);} else {setEducationalGroupHeadErr(false);}

        if (!state.enrollmentDate || !state.educationalGroupHead) {
            toast('Niste unijeli sve podatke.');
        }else {

            let obj = {
                id: grade.id,
                optLock: grade.optLock,
                studentStudyProgram: grade.studentStudyProgram,
                grade: grade.grade,
                enrollmentDate: state.enrollmentDate,
                completedDate: state.completedDate,
                educationalGroupHead: state.educationalGroupHead,
                recognized: state.recognized,
                //def value
                completed: grade.completed,
                droppedOut: grade.droppedOut,
                gradeDiplomaDate: grade.gradeDiplomaDate,
                gradeDiplomaNumber: grade.gradeDiplomaNumber,
                comment: grade.comment
            };
            axios.put('student-study-program-grade/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Podaci su uspješno sačuvani.');
                    getSspGrades();
                }).catch((err) => {

                // if (err.response.data.message === 'This course is already added for passed study program!') {
                //     toast('Polaznik je već upisan u odabrani razred.');
                // } else {
                //     toast('Došlo je do greške! Polaznik nije upisan u odabrani razred.');
                // }
                console.log('err', err)
            });
        }

    }

    return (
        <SegmentGroup>
            <Segment>
                <Header
                    as={'h4'}> {add ? 'Upiši u razred' : 'Izmijeni podatke'}
                    <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                </Header>
            </Segment>
            {
                edit? <Segment clearing>
                    <Form size={'small'} >
                        <Form.Group widths={'equal'} >
                            <Form.Field required>
                                <label>Razred</label>
                                <Form.Dropdown
                                    clearable
                                    disabled={!add}
                                    placeholder="Odaberite razred"
                                    value={state.grade}
                                    onChange={handleInputChange}
                                    name="grade"
                                    options={edit? state.gradesWithoutFilter:state.grades}
                                    search
                                    fluid
                                    selection
                                    error={gradeErr}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>Voditelj obrazovne grupe</label>
                                <Form.Dropdown
                                    clearable
                                    placeholder="Odaberite voditelja obrazovne grupe"
                                    value={state.educationalGroupHead}
                                    onChange={handleInputChange}
                                    name="educationalGroupHead"
                                    options={state.educationalGroupHeads}
                                    search
                                    fluid
                                    selection
                                    error={educationalGroupHeadErr}
                                />
                            </Form.Field>
                            <Form.Field
                                required
                                disabled={!state.grade}
                                control={DateTimePicker}
                                label="Početak obrazovnog procesa za razred"
                                format="D. M. YYYY."
                                placeholder="Unesite datum"
                                name="enrollmentDate"
                                value={state.enrollmentDate}
                                time={false}
                                onChange={(e) => handleDateChange('enrollmentDate', e)}
                                max={new Date(startEducationDate).getTime() + ( 7 * (1000 * 60 *60 *24))}
                                // max={sspGrades.length === 0?new Date(startEducationDate).getTime() + ( 7 * (1000 * 60 *60 *24)):undefined}
                                min={sspGrades.length === 0 ? new Date(startEducationDate) : new Date(startEducationDate).getTime() + ( (state.gradesWithoutFilter?.find(e=> (e.id === state.grade))?.number-1) * gradeDurationMonths * 30 * (1000 * 60 *60 *24)) }
                                style={{ borderWidth: '0px' }}
                                error={enrollmentDateErr}
                            />
                            {/*<Form.Field*/}
                            {/*    // required*/}
                            {/*    control={DateTimePicker}*/}
                            {/*    label="Kraj obrazovnog procesa"*/}
                            {/*    format="D. M. YYYY."*/}
                            {/*    placeholder="Unesite datum"*/}
                            {/*    name="completedDate"*/}
                            {/*    value={state.completedDate}*/}
                            {/*    time={false}*/}
                            {/*    onChange={(e) => handleDateChange('completedDate', e)}*/}
                            {/*    style={{ borderWidth: '0px' }}*/}
                            {/*    error={completedDateErr}*/}
                            {/*/>*/}

                        </Form.Group>
                        <Form.Field>
                            <label> Razred priznat u cjelosti </label>

                            <Form.Checkbox
                                name={'recognized'}
                                onChange={handleInputChange}
                                checked={state.recognized}
                                label='priznat'
                            />
                        </Form.Field>
                        <Button compact floated={"right"} basic size={"small"} color={"black"} onClick={toggleEditForm}>{'Odustani'}</Button>
                        <Button compact floated={"right"} basic size={"small"} color={"teal"} onClick={add?sspGradeEnrollment:editSspGrade}>{add ? 'Upiši u razred' : 'Sačuvaj'}</Button>

                    </Form>
                </Segment>:  ((sspGrades.length === 0) && (new Date().getTime() > new Date(startEducationDate).getTime() +  7 * (1000 * 60 *60 *24)))
                    ?
                    <Segment>Nije moguće upisati polaznika u razred jer je rok za njegov upis u sistem istekao. Polaznika mozete upisati  samo sedam dana nakon pocetka njegovog obrazovnog procesa. Pocetak obrazovnog procesa na koji je upisan ovaj polaznik je {moment(startEducationDate).format('D. M. YYYY.')}</Segment>
                    :
                    !isCompleted ? <Segment>Nije moguće upisati polaznika u naredni razred sve dok ne završi prethodni razred.</Segment>:
                        <Segment clearing>
                            <Form size={'small'} >
                                <Form.Group widths={'equal'} >
                                    <Form.Field required>
                                        <label>Razred</label>
                                        <Form.Dropdown
                                            clearable
                                            disabled={!add}
                                            placeholder="Odaberite razred"
                                            value={state.grade}
                                            onChange={handleInputChange}
                                            name="grade"
                                            options={state.grades}
                                            search
                                            fluid
                                            selection
                                            error={gradeErr}
                                        />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>Voditelj obrazovne grupe</label>
                                        <Form.Dropdown
                                            clearable
                                            placeholder="Odaberite voditelja obrazovne grupe"
                                            value={state.educationalGroupHead}
                                            onChange={handleInputChange}
                                            name="educationalGroupHead"
                                            options={state.educationalGroupHeads}
                                            search
                                            fluid
                                            selection
                                            error={educationalGroupHeadErr}
                                        />
                                    </Form.Field>
                                    <Form.Field
                                        required
                                        disabled={!state.grade}
                                        control={DateTimePicker}
                                        label="Početak obrazovnog procesa za razred"
                                        format="D. M. YYYY."
                                        placeholder="Unesite datum"
                                        name="enrollmentDate"
                                        value={state.enrollmentDate}
                                        time={false}
                                        onChange={(e) => handleDateChange('enrollmentDate', e)}
                                        max={sspGrades.length === 0?new Date(startEducationDate).getTime() + ( 7 * (1000 * 60 *60 *24)):undefined}
                                        min={sspGrades.length === 0 ? new Date(startEducationDate) : new Date(startEducationDate).getTime() + ( (state.grades?.find(e=> (e.id === state.grade))?.number-1) * gradeDurationMonths * 30 * (1000 * 60 *60 *24)) }
                                        style={{ borderWidth: '0px' }}
                                        error={enrollmentDateErr}
                                    />
                                    {/*<Form.Field*/}
                                    {/*    // required*/}
                                    {/*    control={DateTimePicker}*/}
                                    {/*    label="Kraj obrazovnog procesa"*/}
                                    {/*    format="D. M. YYYY."*/}
                                    {/*    placeholder="Unesite datum"*/}
                                    {/*    name="completedDate"*/}
                                    {/*    value={state.completedDate}*/}
                                    {/*    time={false}*/}
                                    {/*    onChange={(e) => handleDateChange('completedDate', e)}*/}
                                    {/*    style={{ borderWidth: '0px' }}*/}
                                    {/*    error={completedDateErr}*/}
                                    {/*/>*/}

                                </Form.Group>
                                <Form.Field>
                                    <label> Razred priznat u cjelosti </label>

                                    <Form.Checkbox
                                        name={'recognized'}
                                        onChange={handleInputChange}
                                        checked={state.recognized}
                                        label='priznat'
                                    />
                                </Form.Field>
                                <Button compact floated={"right"} basic size={"small"} color={"black"} onClick={toggleEditForm}>{'Odustani'}</Button>
                                <Button compact floated={"right"} basic size={"small"} color={"teal"} onClick={add?sspGradeEnrollment:editSspGrade}>{add ? 'Upiši u razred' : 'Sačuvaj'}</Button>

                            </Form>
                        </Segment>

            }
        </SegmentGroup>
    );
};

export default SspGradeForm;
