import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Form, Header, Icon, Input, SegmentGroup, Select} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import axios from "axios";
import { DateTimePicker } from 'react-widgets';
import { toast } from 'react-toastify';
import {UserRoleContext} from "../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'studyPrograms':
            return {...state, studyPrograms: action.payload};
        case 'studyProgram':
            return {...state, studyProgram: action.payload};
        case 'openFromDate':
            return {...state, openFromDate: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'openToDate':
            return {...state, openToDate: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'placeOfPublication':
            return {...state, placeOfPublication: action.payload};
        default:
            return state;

    }
}

const ContestForm = ({ add, toggleEditForm, getContest, contest, getContestsForAdmin, setCurrentIdOrganizer}) => {
    const [openFromDateErr, setopenFromDateErr] = useState(false);
    const [openToDateErr, setopenToDateErr] = useState(false);
    const [placeOfPublicationErr, setplaceOfPublicationErr] = useState(false);
    const [studyProgramErr, setstudyProgramErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        studyPrograms: [],
        studyProgram: contest && contest.studyProgram.id ? contest.studyProgram.id : null,
        placeOfPublication: contest && contest.placeOfPublication ? contest.placeOfPublication : '',
        openFromDate: contest && contest.openFromDate ? new Date(contest.openFromDate):  new Date(),
        openToDate: contest && contest.openToDate ? new Date(contest.openToDate): new Date(),
        organizers: [],
        organizer: contest && contest.studyProgram.organizerOccupation.organizer ? contest.studyProgram.organizerOccupation.organizer.id : '',
        currentId: null,
    });

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            getStudyProgramsByOrganizerId(value);
        }
    }

    function handleDateChange(name, date) {
       dispatch({type: name, payload: date});
    }

    useEffect(() => {
        if (!add && activeRole === "ROLE_ADMIN") { getOrganizers(); getStudyProgramsByOrganizerId(state.organizer);}
        if(activeRole === "ROLE_ORGANIZER") {getStudyPrograms()}
        // else {getOrganizers(); getStudyPrograms()}
        if(activeRole !== "ROLE_ORGANIZER") getOrganizers()
    },[add, activeRole]);

    function getOrganizers(){
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function getStudyProgramsByOrganizerId(id){
        setCurrentId(id);
        axios.get(`/study-program/by-organizer-id/${id}`,).then(
            (res) => {
                let studyPrograms = res.data.map((el)=>({...el, key: el.id,
                    content: <div><strong>{el.name}</strong> { ' | ' + el.organizerOccupation.occupation.nameBosnian}</div>,
                    text:el.name + ' | ' + el.organizerOccupation.occupation.nameBosnian,
                    value: el.id}));
                dispatch({type: 'studyPrograms', payload: studyPrograms});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    // key: el.id,
    //     content: <div><strong>{el.occupation.profession.name}</strong> { ' | ' + el.occupation.nameBosnian}</div>,
    //     text: el.occupation.profession.name + ' | ' + el.occupation.nameBosnian,
    //     value: el}

    function getStudyPrograms(){
        axios.get('/study-program/by-organizer',).then(
            (res) => {
                let studyPrograms = res.data.map((el)=>({...el, key: el.id,
                    content: <div><strong>{el.name}</strong> { ' | ' + el.organizerOccupation.occupation.nameBosnian}</div>,
                    text:el.name + ' | ' + el.organizerOccupation.occupation.nameBosnian,
                    value: el.id}));
                dispatch({type: 'studyPrograms', payload: studyPrograms});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function editContest(){
        if ( !state.openFromDate || !state.openToDate || !state.placeOfPublication || !state.studyProgram) {
            toast('Niste unijeli sve podatke.');
            if (!state.placeOfPublication) { setplaceOfPublicationErr(true); } else { setplaceOfPublicationErr(false); }
            if (!state.openFromDate) { setopenFromDateErr(true); } else { setopenFromDateErr(false); }
            if (!state.openToDate) { setopenToDateErr(true); } else { setopenToDateErr(false); }
            if (!state.studyProgram) { setstudyProgramErr(true); } else { setstudyProgramErr(false); }
        } else {
            let obj = {
                id: contest.id,
                optLock: contest.optLock,
                openFromDate: state.openFromDate,
                openToDate: state.openToDate,
                placeOfPublication: state.placeOfPublication,
                studyProgram: {id: state.studyProgram}
            };
            axios.put('/contest/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste izmijenili konkurs.');
                    activeRole === "ROLE_ADMIN" ? getContestsForAdmin(state.organizer) : getContest();
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function addContest(){
        if (!state.openFromDate || !state.openToDate || !state.placeOfPublication || !state.studyProgram) {
            toast('Niste unijeli sve podatke.');
            if (!state.placeOfPublication) { setplaceOfPublicationErr(true); } else { setplaceOfPublicationErr(false); }
            if (!state.openFromDate) { setopenFromDateErr(true); } else { setopenFromDateErr(false); }
            if (!state.openToDate) { setopenToDateErr(true); } else { setopenToDateErr(false); }
            if (!state.studyProgram) { setstudyProgramErr(true); } else { setstudyProgramErr(false); }
        } else {
            let obj = {
                created: new Date(),
                openFromDate: state.openFromDate,
                openToDate: state.openToDate,
                placeOfPublication: state.placeOfPublication,
                studyProgram: {id: state.studyProgram}
            };
            axios.post('/contest/save', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste dodali konkurs.');
                    activeRole === "ROLE_ADMIN" ? getContestsForAdmin(state.organizer) : getContest();
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }
    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {add?'Dodavanje novog konkursa':'Izmjena podataka'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths={'equal'}>
                            {activeRole === "ROLE_ADMIN" &&<Form.Field required>
                                <label>Organizator</label>
                                <Form.Dropdown
                                    clearable
                                    disabled={activeRole === "ROLE_ADMIN" && !add}
                                    placeholder="Odaberite organizatora"
                                    value={state.organizer}
                                    onChange={handleInputChange}
                                    name="organizer"
                                    options={state.organizers}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>}

                            <Form.Field
                                required
                                control={DateTimePicker}
                                label="Datum otvaranja konkursa"
                                format="D. M. YYYY."
                                placeholder="Unesite datum"
                                name="openFromDate"
                                value={state.openFromDate ? new Date(state.openFromDate) : new Date(contest.openFromDate)}
                                time={false}
                                onChange={(e) => handleDateChange('openFromDate', e)}
                                style={{ borderWidth: '0px' }}
                                error={openFromDateErr}
                            />

                            <Form.Field
                                required
                                control={DateTimePicker}
                                label="Datum zatvaranja konkursa"
                                format="D. M. YYYY."
                                placeholder="Unesite datum"
                                name="openToDate"
                                value={state.openToDate ? new Date(state.openToDate) : new Date(contest.openToDate)}
                                time={false}
                                onChange={(e) => handleDateChange('openToDate', e)}
                                style={{ borderWidth: '0px' }}
                                error={openToDateErr}
                            />

                            <Form.Field
                                required
                                label="Mjesto objavljivanja konkursa"
                                control={Input}
                                name="placeOfPublication"
                                value={state.placeOfPublication}
                                onChange={handleInputChange}
                                placeholder="Unesite mjesto"
                                error={placeOfPublicationErr}
                            />

                            <Form.Field
                                required
                                control={Select}
                                clearable
                                options={state.studyPrograms}
                                placeholder="Odaberite nastavni program"
                                label="Nastavni program"
                                onChange={handleInputChange}
                                name="studyProgram"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={state.studyProgram}
                                search
                                error={studyProgramErr}

                            />
                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addContest : editContest}>{add ? 'Dodaj' : 'Sačuvaj'}</Button>
                    </Form>
                </Segment>
            </SegmentGroup>


        </div>
    );
};

export default ContestForm;
