import React, {useEffect, useReducer} from 'react';
import {useLocation, useParams} from "react-router-dom";
import userService from "../../service/UserService";
import {GridRow, Header, Segment} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import axios from "axios";


const reducer = (state, action)=>{
    switch (action.type) {
        case 'data':
            return {...state, data: action.payload};
        default:
            return state;
    }
};
const QrScan = () => {
    const [state, dispatch] = useReducer(reducer,{ data: null, loader: false,} );

    const location = useLocation();
    let { documentTypeId, sspId  } = useParams();

    useEffect(() => {
        if (!location.pathname.startsWith("/verifikacija-dokumenta/")) {
            userService.doLogin();
        }
        if(sspId && documentTypeId){
            let ssp = parseInt(sspId)
            let documentType = parseInt(documentTypeId)
            getData(ssp, documentType);
        }
    },[location]);
    function getData(sspId= 68, documentTypeId= 11){
        axios.get(`qr-code-info/${documentTypeId}/${sspId}`,).then(
            (res) => {
                let data = res.data;
                dispatch({type: 'data', payload: data});
            }).catch((err) => {
            console.log('err', err)
        });
    }
    return (
        location.pathname.startsWith("/verifikacija-dokumenta/") && <div>

                {/*<Grid columns={3}>*/}
                {/*    <GridRow>*/}
                {/*        <Grid.Column></Grid.Column>*/}
                {/*        <Grid.Column>*/}
                            <Segment placeholder clearing>
                                <Header as={'h4'} icon textAlign={"center"}>
                                    <Icon color={"teal"} name='check circle' />
                                    Rezultat verifikacije

                                </Header>
                            <Table celled collapsing compact >

                                <Table.Body>

                                    <Table.Row >
                                        <Table.Cell textAlign={"right"} width={8} >Dokument</Table.Cell>
                                        <Table.Cell width={8} ><strong>{state.data?.documentTypeName}</strong></Table.Cell>
                                    </Table.Row>

                                    <Table.Row >
                                        <Table.Cell textAlign={"right"} width={8} >Organizator</Table.Cell>
                                        <Table.Cell width={8} ><strong>{state.data?.organizerName}</strong></Table.Cell>
                                    </Table.Row>

                                    <Table.Row >
                                        <Table.Cell width={8} textAlign={"right"} >Ime i prezime</Table.Cell>
                                        <Table.Cell width={8} ><strong>{state.data?.studentName}</strong></Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell width={8} textAlign={"right"} >Zanimanje</Table.Cell>
                                        <Table.Cell width={8} ><strong>{state.data?.occupation}</strong></Table.Cell>
                                    </Table.Row>

                                </Table.Body>

                            </Table>

                            </Segment>
                            <Header as={'h6'} textAlign={'center'}>Izvor verifikacije: www.ks.iskoom.edu.ba</Header>
                {/*        </Grid.Column>*/}
                {/*    </GridRow>*/}

                {/*    <Grid.Column ></Grid.Column>*/}

                {/*</Grid>*/}


            {/*<h1>SSP ID: {id}</h1>*/}
            {/*<h1>Document Type ID: {id}</h1>*/}
        </div>
    );
};

export default QrScan;
