import React, {useEffect, useState} from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import moment from "moment";
import {Form, Input, Loader} from "semantic-ui-react";
import {DateTimePicker} from "react-widgets";


const TeacherEmployments = ({ edit, teacherEmployments}) => {

    const [employments, setTeacherEmployments] = useState(teacherEmployments);

    const handleInputChangeNew = (updatedTeacherEmployments) => {
        setTeacherEmployments(updatedTeacherEmployments);
    };

    // const handleDateChangeNew = (fieldName, index, value) => {
    //     const updatedTeacherEmployments = [...teacherEmployments];
    //     if (value) {
    //         updatedTeacherEmployments[index][fieldName] = value;
    //     } else {
    //         updatedTeacherEmployments[index][fieldName] = moment(teacherEmployments[index][fieldName]);
    //     }
    //     handleInputChangeNew(updatedTeacherEmployments);
    // };

    const handleDateChangeNew = (fieldName, index, value) => {
        const updatedTeacherEmployments = [...teacherEmployments];
        if (value !== undefined) {
            updatedTeacherEmployments[index][fieldName] = value;
        } else {
            updatedTeacherEmployments[index][fieldName] = null;
        }
        handleInputChangeNew(updatedTeacherEmployments);
    };

    return (
        <>
            <Table celled size={'small'}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>R. br.</Table.HeaderCell>
                        <Table.HeaderCell>Organizator</Table.HeaderCell>
                        <Table.HeaderCell>Početak angažmana</Table.HeaderCell>
                        <Table.HeaderCell>Kraj angažmana</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        teacherEmployments.map((el, index) => (
                            <Table.Row key={el.id}>
                                <Table.Cell>{index+1}.</Table.Cell>
                                <Table.Cell>{el.organizer.name}</Table.Cell>
                                <Table.Cell>{edit ?
                                    <Form.Field
                                        required
                                        control={DateTimePicker}
                                        format="D. M. YYYY."
                                        placeholder="Unesite datum"
                                        name="employmentStartDate"
                                        value={new Date(el.employmentStartDate)}
                                        time={false}
                                        onChange={(value) => handleDateChangeNew('employmentStartDate', index, value)}
                                        style={{ borderWidth: '0px' }}
                                    />
                                    : `${moment(el.employmentStartDate).format('D. M. YYYY.')}`}
                                </Table.Cell>
                                <Table.Cell>{edit ?
                                    <Form.Field
                                        control={DateTimePicker}
                                        format="D. M. YYYY."
                                        placeholder="Unesite datum"
                                        name="employmentEndDate"
                                        value={el.employmentEndDate ? new Date(el.employmentEndDate) : null}
                                        time={false}
                                        onChange={(value) => handleDateChangeNew('employmentEndDate', index, value)}
                                        style={{ borderWidth: '0px' }}
                                    />
                                    : el.employmentEndDate ? `${moment(el.employmentEndDate).format('D. M. YYYY.')}` : ''}
                                </Table.Cell>
                            </Table.Row>
                        ))
                    }
                </Table.Body>
            </Table>
        </>
    );
};

export default TeacherEmployments;
