import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Form, Header, Input, Menu, SegmentGroup} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import {DateTimePicker} from "react-widgets";
import {extractFileNameFromHeaders} from "../../Utilities/Util";
import download from "downloadjs";
import {UserRoleContext} from "../../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'gradeDiplomaDate':
            return {...state, gradeDiplomaDate: action.payload};
        case 'gradeDiplomaNumber':
            return {...state, gradeDiplomaNumber: action.payload};
        case 'completedDate':
            return {...state, completedDate: action.payload};
        default:
            return state;

    }
};
const DiplomaForm = ({add, edit, toggleEditForm, grade, sspId, getSspGrades, isCompleted, isGradeCompleted}) => {
    const [gradeDiplomaNumberErr, setGradeDiplomaNumberErr] = useState(false);
    const [gradeDiplomaDateErr, setGradeDiplomaDateErr] = useState(false);
    const [completedDateErr, setCompletedDateErr] = useState(false);

    const supplementaryEducationTypeKey = localStorage.getItem('supplementaryEducationTypeKey')
    const gradeDurationMonths = supplementaryEducationTypeKey === 'primary education'? (grade?.grade.number < 6) ?3:6:parseInt(localStorage.getItem('gradeDurationMonths'))

    // const lastCompletedGrade = JSON.parse(localStorage.getItem('lastCompletedGrade')).number;
    const organizerId = localStorage.getItem('organizerId')
    const {activeRole} = useContext(UserRoleContext);

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    const [state, dispatch] = useReducer(reducer,{
        gradeDiplomaDate:  grade.gradeDiplomaDate ? new Date(grade.gradeDiplomaDate): new Date(),
        gradeDiplomaNumber:  grade?.gradeDiplomaNumber,
        completedDate:  grade.completedDate ? new Date(grade.completedDate): new Date(),


    });

     function downloadGradeDiploma() {
        axios({
            method: 'post',
            url: `/document/enrollment-form/${grade.id}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });
    }



    function editSspGrade(withDownload = false) {

        if (!state.gradeDiplomaDate) {setGradeDiplomaDateErr(true);} else {setGradeDiplomaDateErr(false);}
        if (!state.gradeDiplomaNumber) {setGradeDiplomaNumberErr(true);} else {setGradeDiplomaNumberErr(false);}
        if (!state.completedDate) {setCompletedDateErr(true);} else {setCompletedDateErr(false);}

        if (!state.gradeDiplomaDate || !state.gradeDiplomaNumber || !state.completedDate ) {
            toast('Niste unijeli sve podatke.');
        }else {

            let obj = {...grade,
                gradeDiplomaNumber: state.gradeDiplomaNumber,
                gradeDiplomaDate: state.gradeDiplomaDate,
                completedDate: state.completedDate,
                completed: true,
            };
            axios.put('student-study-program-grade/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Podaci su uspješno sačuvani.');
                    getSspGrades();
                }).catch((err) => {

                if(withDownload){
                    downloadGradeDiploma();
                }
                // if (err.response.data.message === 'This course is already added for passed study program!') {
                //     toast('Polaznik je već upisan u odabrani razred.');
                // } else {
                //     toast('Došlo je do greške! Polaznik nije upisan u odabrani razred.');
                // }
                console.log('err', err)
            });
        }

    }

    return (
        <SegmentGroup>
            <Segment>
                <Header
                    as={'h4'}> {'Podaci za svjedočanstvo - ' + grade.grade.name + ' razred'}
                    <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                </Header>
            </Segment>
            <Segment clearing>
                {/*ako je danasnji datum veci od datuma kraja razreda*/}
                {activeRole === "ROLE_ORGANIZER" &&
                    ((new Date().getTime() < new Date(grade.enrollmentDate).getTime() + (gradeDurationMonths * 30 * (1000 * 60 *60 *24))))
                    ?
                    'Podatke o kraju razreda i svjedočanstvu za razred nije moguće unijeti prije isteka minimalnog trajanja razreda.'
                    : <Form size={'small'} >
                        <Form.Group widths={'equal'} >
                            <Form.Field
                                required
                                control={DateTimePicker}
                                label="Kraj obrazovnog procesa"
                                format="D. M. YYYY."
                                placeholder="Unesite datum"
                                name="completedDate"
                                value={state.completedDate}
                                time={false}
                                onChange={(e) => handleDateChange('completedDate', e)}
                                style={{ borderWidth: '0px' }}
                                error={completedDateErr}
                            />
                            <Form.Field
                                required
                                // error={documentDateErr}
                                control={DateTimePicker}
                                label="Datum svjedočanstva"
                                format="D. M. YYYY."
                                placeholder="Unesite datum"
                                name="gradeDiplomaDate"
                                value={state.gradeDiplomaDate}
                                //datum se može unositi tek nakon sto istekne trajanje razreda
                                max={new Date()}
                                min={new Date(grade.enrollmentDate).getTime() + grade.studentStudyProgram.studyProgram.gradeDurationMonths * 31 * (1000 * 60 *60 *24)}
                                time={false}
                                onChange={(e) => handleDateChange('gradeDiplomaDate', e)}
                                style={{ borderWidth: '0px' }}
                                error={gradeDiplomaDateErr}
                            />

                            <Form.Field
                                required
                                control={Input}
                                label="Djelovodni broj svjedočanstva"
                                placeholder="Unesite broj"
                                value={state.gradeDiplomaNumber}
                                name="gradeDiplomaNumber"
                                onChange={handleInputChange}
                                error={gradeDiplomaNumberErr}
                            />

                            { grade && grade.gradeDiplomaSerialNumber &&<Form.Field
                                disabled
                                control={Input}
                                label="Serijski broj"
                                value={grade.gradeDiplomaSerialNumber}
                                name="gradeDiplomaSerialNumber"
                            />}

                        </Form.Group>
                        <Button compact floated={"right"} basic size={"small"} color={"teal"} onClick={editSspGrade}>{'Sačuvaj podatke'}</Button>

                    </Form>

                }
            </Segment>
            <ToastContainer/>
        </SegmentGroup>
    );
};

export default DiplomaForm;
