import React from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";

const OtherInfo = ({numberOfHoursInOriginPlaceOfEmployment, originPlaceOfEmployment, employmentEndDate, employmentStartDate}) => {
    return (
        <div>
            <Table celled size={"small"}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell> <Table.HeaderCell>Matično zaposlenje</Table.HeaderCell></Table.Cell>
                        <Table.Cell>{originPlaceOfEmployment?originPlaceOfEmployment:''}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell> <Table.HeaderCell>Broj sati sedmično (matično zaposlenje)</Table.HeaderCell></Table.Cell>
                        <Table.Cell>{numberOfHoursInOriginPlaceOfEmployment?numberOfHoursInOriginPlaceOfEmployment:''}</Table.Cell>
                    </Table.Row>
                    {/*<Table.Row>*/}
                    {/*    <Table.Cell> <Table.HeaderCell>Datum početka angažmana na organizatoru</Table.HeaderCell></Table.Cell>*/}
                    {/*    <Table.Cell>{employmentStartDate?employmentStartDate:''}</Table.Cell>*/}
                    {/*</Table.Row>*/}
                    {/*<Table.Row>*/}
                    {/*    <Table.Cell> <Table.HeaderCell>Datum kraja angažmana na organizatoru</Table.HeaderCell></Table.Cell>*/}
                    {/*    <Table.Cell>{employmentEndDate?employmentEndDate:''}</Table.Cell>*/}
                    {/*</Table.Row>*/}
                    {/*<Table.Row>*/}
                    {/*    <Table.Cell> <Table.HeaderCell>Adresa</Table.HeaderCell></Table.Cell>*/}
                    {/*    <Table.Cell>{residence?residence.address:''}</Table.Cell>*/}
                    {/*</Table.Row>*/}
                </Table.Body>
            </Table>
        </div>
    );
};

export default OtherInfo;
