import Keycloak from "keycloak-js";
import Main from "../Main";

const kc = new Keycloak(process.env.REACT_APP_EUNSA_KEYCLOAK_JSON_URL);

const initKeycloak = (renderApp, renderPublicApp) => {
    kc.init({
        onLoad: "check-sso", checkLoginIframe: false
    })
        .then((authenticated) => {
            if(authenticated){
                renderApp()
            } else{
                renderPublicApp();
                    // doLogin();
            }
        })

};

const doLogin = kc.login;
const doLogout = kc.logout;
const getToken = () => kc.token;
const updateToken = (successCallback) => kc.updateToken(50000)
    .then(successCallback)
    .catch(doLogin);
const getUsername = () => kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => kc.hasRealmRole(role));

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    getToken,
    getUsername,
    updateToken,
    hasRole
};

export default UserService;
