import React, {useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Button, Header, Icon, Menu} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import axios from "axios";
import AndragogyDocumentationForm from "./AndragogyDocumentationForm";
import {Link} from "react-router-dom";

const reducer = (state, action)=>{
    switch (action.type) {
        case 'documentation':
            return {...state, documentation: action.payload, loader:  false };
        case 'currentId':
            return {...state, currentId: action.payload};
        default:
            return state;
    }
};
const AndragogyDocumentation = () => {
    const [state, dispatch] = useReducer(reducer,{ documentation:[1], loader: true} );
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    // useEffect(() => {
    //     getRequests();
    // },[]);

    function getDocumentation(){
        axios.get('/request/by-organizer',).then(
            (res) => {
                let requests = res.data;
                dispatch({type: 'requests', payload: requests});
            }).catch((err) => {
            console.log('err', err)
        });
    }
    return (
        <Segment.Group>
            <Segment className={'customPadding'} clearing color={"teal"}>

                <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                    <Menu.Item >
                        <Header as={'h3'} floated={"left"}> Zaduženja andragoškom dokumentacijom </Header>
                    </Menu.Item>
                    <Menu.Menu position={'right'}>
                        <Menu.Item>

                            <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                <Icon name='add' color={"teal"}/>
                                Dodaj zaduženje
                            </Button>

                        </Menu.Item>
                        {/*<Menu.Item>*/}
                        {/*    <Button icon labelPosition='left' size="mini" basic color="black">*/}
                        {/*        <Icon name='file pdf outline' color={"red"} />*/}
                        {/*        Preuzmi spisak*/}
                        {/*    </Button>*/}
                        {/*</Menu.Item>*/}
                        {/*<Menu.Item>*/}
                        {/*    <Button icon labelPosition='left' size="mini" basic color="black">*/}
                        {/*        <Icon name='file excel outline' color={"green"}/>*/}
                        {/*        Preuzmi spisak*/}
                        {/*    </Button>*/}
                        {/*</Menu.Item>*/}
                    </Menu.Menu>

                </Menu>

            </Segment>
            {showAddForm &&
            <Segment>
                {/*send add or edit as props*/}
                <AndragogyDocumentationForm add toggleEditForm={toggleAddForm} getRequests={getDocumentation}/>
            </Segment>
            }
            {showEditForm &&
            <Segment>
                {/*send add or edit as props*/}
                <AndragogyDocumentationForm edit toggleEditForm={toggleEditForm} getRequests={getDocumentation}/>
            </Segment>
            }
            <Segment clearing>
                <Table celled size={'small'}>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell width={1} >R. br.</Table.HeaderCell>
                            <Table.HeaderCell>Organizator</Table.HeaderCell>
                            <Table.HeaderCell>Datum zaduženja</Table.HeaderCell>
                            <Table.HeaderCell>Godina</Table.HeaderCell>
                            <Table.HeaderCell>Broj</Table.HeaderCell>
                            <Table.HeaderCell>Ukupno</Table.HeaderCell>
                            <Table.HeaderCell>Uplaćeno</Table.HeaderCell>
                            <Table.HeaderCell width={3}/>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            state.documentation.map((el, index)=>
                                (<Table.Row key={el.id}>
                                    <Table.Cell width={1}>{index+1}.</Table.Cell>

                                    {/*<Table.Cell>{el.person.jmbg}</Table.Cell>*/}
                                    {/*<Table.Cell>{el.person.firstName} {el.person.lastName}</Table.Cell>*/}
                                    {/*<Table.Cell>{el.originPlaceOfEmployment}</Table.Cell>*/}
                                    {/*<Table.Cell>{el.numberOfHoursInOriginPlaceOfEmployment}</Table.Cell>*/}
                                    {/*<Table.Cell>{moment(el.employmentStartDate).format('D. M. YYYY.')} -  {moment(el.employmentEndDate).format('D. M. YYYY.')}</Table.Cell>*/}
                                    {/*/!*<Table.Cell>{el.organizer.name}</Table.Cell>*!/*/}
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell width={1} verticalAlign="middle" textAlign="center">
                                        <Popup size={"small"} content='Preuzmi račun' trigger={
                                            <Button icon size={"mini"} basic color={"grey"}
                                                // disabled={state.currentId && el.teacher.id !== state.currentId } onClick={() => {deleteTeacherById(el.teacher.id)}}
                                            >
                                                <Icon name='download' color={"teal"} /> Račun
                                            </Button>}
                                        />

                                        <Popup size={"small"} content='Preuzmi dopis' trigger={
                                        <Button icon size={"mini"} basic color={"grey"}
                                            // disabled={state.currentId && el.teacher.id !== state.currentId } onClick={() => {deleteTeacherById(el.teacher.id)}}
                                        >
                                            <Icon name='download' color={"teal"} /> Dopis
                                        </Button>}
                                    />
                                        <Popup size={"small"} content='Preuzmi adresu' trigger={
                                            <Button icon size={"mini"} basic color={"grey"}
                                                // disabled={state.currentId && el.teacher.id !== state.currentId } onClick={() => {deleteTeacherById(el.teacher.id)}}
                                            >
                                                <Icon name='download' color={"teal"} /> Adresa
                                            </Button>}
                                        />
                                    </Table.Cell>
                                    <Table.Cell width={1} verticalAlign="middle" textAlign="center">
                                        <Link to='/andragoska-dokumantacija/detalji' state={{ name: el.name, id: el.id}}>
                                            <Popup size={"small"} content='Detalji' trigger={ <Button icon size={"mini"} basic color={"grey"}>
                                                <Icon name='ellipsis horizontal' />
                                            </Button>} />
                                        </Link>

                                        <Popup size={"small"} content={'Izmijeni podatke'} trigger={ <Button icon size={"mini"} basic color={"grey"} onClick={toggleEditForm}>
                                            <Icon name='pencil' color={'teal'}/>
                                        </Button>} />


                                        <Popup size={"small"} content='Obriši zaduženje' trigger={
                                            <Button icon size={"mini"} basic color={"grey"}
                                                    // disabled={state.currentId && el.teacher.id !== state.currentId } onClick={() => {deleteTeacherById(el.teacher.id)}}
                                            >
                                                <Icon name='trash alternate outline' color={"red"} />
                                            </Button>}
                                        />
                                    </Table.Cell>
                                </Table.Row>)
                            )
                        }


                    </Table.Body>

                    <Table.Footer>
                        {/*<Table.Row>*/}
                        {/*    <Table.HeaderCell colSpan='11' textAlign='center'>*/}
                        {/*        <Menu  pagination>*/}
                        {/*            <Menu.Item as='a' icon>*/}
                        {/*                <Icon name='chevron left' />*/}
                        {/*            </Menu.Item>*/}
                        {/*            <Menu.Item as='a'>1</Menu.Item>*/}
                        {/*            <Menu.Item as='a'>2</Menu.Item>*/}
                        {/*            <Menu.Item as='a'>3</Menu.Item>*/}
                        {/*            <Menu.Item as='a'>4</Menu.Item>*/}
                        {/*            <Menu.Item as='a' icon>*/}
                        {/*                <Icon name='chevron right' />*/}
                        {/*            </Menu.Item>*/}
                        {/*        </Menu>*/}
                        {/*    </Table.HeaderCell>*/}
                        {/*</Table.Row>*/}
                    </Table.Footer>
                </Table>
            </Segment>

        </Segment.Group>
    );
};

export default AndragogyDocumentation;
