import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Form, Header, Icon, Input, SegmentGroup, Select} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {toast} from "react-toastify";
import axios from "axios";
import {UserRoleContext} from "../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'documentType':
            return {...state, documentType: action.payload};
        case 'documentTypeOptions':
            return {...state, documentTypeOptions: action.payload};
        case 'rangeFrom':
            return {...state, rangeFrom: action.payload};
        case 'rangeTo':
            return {...state, rangeTo: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'comment':
            return {...state, comment: action.payload};
       default:
            return state;

    }
};
const DocumentTypeForm = ({add, toggleEditForm, getDocumentTypes, getDocumentTypesForAdmin, setCurrentIdOrganizer}) => {
    const [documentTypeErr, setDocumentTypeErr] = useState(false);
    const [rangeFromErr, setRangeFromErr] = useState(false);
    // const [rangeToErr, setRangeToErr] = useState(false);
    const [organizerErr, setOrganizerErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        documentType: '',
        documentTypeOptions: [],
        organizer: '',
        organizers: [],
        rangeFrom: null,
        rangeTo: null,
        comment: ''

    });

    useEffect(() => {
        if(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") {getOrganizers()}
        getDocumentTypeOptions()
    },[activeRole]);

    function getOrganizers(){
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getDocumentTypeOptions(){
        axios.get('/form-type/all',).then(
            (res) => {
                let documentTypeOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el.id}));
                dispatch({type:'documentTypeOptions', payload: documentTypeOptions});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function addDocumentType(){
        if (!state.documentType || !state.rangeFrom || ((activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY" )&& !state.organizer)) {
            toast('Niste unijeli sve podatke.');
            if (!state.documentType) { setDocumentTypeErr(true); } else { setDocumentTypeErr(false); }
            if (!state.rangeFrom) { setRangeFromErr(true); } else { setRangeFromErr(false); }
            // if (!state.rangeTo) { setRangeToErr(true); } else { setRangeToErr(false); }
            if (activeRole === "ROLE_ADMIN" && !state.organizer) { setOrganizerErr(true); } else { setOrganizerErr(false); }
        } else {
            if (activeRole === "ROLE_ORGANIZER") {
                let obj = {
                    createdDate: new Date(),
                    rangeFrom: state.rangeFrom,
                    rangeTo: !state.rangeTo ? state.rangeFrom : state.rangeTo,
                    formType: {id: state.documentType},
                    comment: state.comment
                };
                axios.post('/form/save/for-organizer', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste dodali zaduženje obrazaca.');
                        getDocumentTypes();
                    }).catch((err) => {
                    if (err.response.data.message === 'rangeFrom must be smaller than rangeTo!') {
                        toast('Početak opsega zaduženih serijskih brojeva mora biti manji od kraja opsega!');
                    }
                    console.log('err', err)
                });
            }
            else{
                let obj = {
                    createdDate: new Date(),
                    rangeFrom: state.rangeFrom,
                    rangeTo: !state.rangeTo ? state.rangeFrom : state.rangeTo,
                    formType: {id: state.documentType},
                    comment: state.comment,
                    organizer: {id: state.organizer},
                };
                axios.post('/form/save', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste dodali zaduženje obrazaca.');
                        getDocumentTypesForAdmin(state.organizer);
                        setCurrentIdOrganizer(state.organizer);
                    }).catch((err) => {
                    console.log('err', err)
                });
            }
        }
    }
    // function editDocumentType(){
    //     if (!state.documentType || !state.rangeFrom || !state.rangeTo) {
    //         toast('Niste unijeli sve podatke.');
    //         if (!state.documentType) { setDocumentTypeErr(true); } else { setDocumentTypeErr(false); }
    //         if (!state.rangeFrom) { setRangeFromErr(true); } else { setRangeFromErr(false); }
    //         if (!state.rangeTo) { setRangeToErr(true); } else { setRangeToErr(false); }
    //     } else {}
    // }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {'Unos novog zaduženja'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths={'equal'}>

                            {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Form.Field required>
                                <label>Organizator</label>
                                <Form.Dropdown
                                    clearable
                                    error={organizerErr}
                                    // disabled={activeRole === "ROLE_ADMIN" && !add}
                                    placeholder="Odaberite organizatora"
                                    value={state.organizer}
                                    onChange={handleInputChange}
                                    name="organizer"
                                    options={state.organizers}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>}

                            <Form.Field
                                required
                                control={Select}
                                options={state.documentTypeOptions}
                                placeholder="Odaberite vrstu obrasca"
                                label="Obrazac za"
                                onChange={handleInputChange}
                                name="documentType"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={state.documentType}
                                search
                                error={documentTypeErr}

                            />

                            <Form.Field
                                required
                                error={rangeFromErr}
                                label="Opseg zaduženih serijskih brojeva - od"
                                control={Input}
                                name="rangeFrom"
                                type="number"
                                value={state.rangeFrom}
                                onChange={handleInputChange}
                                placeholder="od"
                            />
                            <Form.Field
                                // required
                                // error={rangeToErr}
                                label="Opseg zaduženih serijskih brojeva - do"
                                control={Input}
                                name="rangeTo"
                                type="number"
                                value={state.rangeTo}
                                onChange={handleInputChange}
                                placeholder="do"
                            />

                            <Form.Field
                                label="Napomena"
                                control={Input}
                                name="comment"
                                value={state.comment}
                                onChange={handleInputChange}
                                placeholder="Unesite napomenu"
                            />

                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={addDocumentType}>{'Dodaj'}</Button>
                    </Form>
                </Segment>
            </SegmentGroup>


        </div>
    );
};

export default DocumentTypeForm;
