import React, {useContext, useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Divider, Form, GridColumn, Header, Icon, Input, SegmentGroup, Select} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import axios from "axios";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import {toast, ToastContainer} from "react-toastify";
import {DateTimePicker} from "react-widgets";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import PlaceForm from "../Utilities/PlaceForm";
import {UserRoleContext} from "../../Main";
import PlaceInfo from "../Organizers/OrganizerDetails/PlaceInfo";
import OtherInfo from "./OtherInfo";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'firstName':
            return {...state, firstName: action.payload};
        case 'lastName':
            return {...state, lastName: action.payload};
        case 'maidenName':
            return {...state, maidenName: action.payload};
        case 'gender':
            return {...state, gender: action.payload};
        case 'genderOptions':
            return {...state, genderOptions: action.payload};
        case 'originPlaceOfEmployment':
            return {...state, originPlaceOfEmployment: action.payload};
        case 'numberOfHoursInOriginPlaceOfEmployment':
            return {...state, numberOfHoursInOriginPlaceOfEmployment: action.payload};
        case 'jmbg':
            return {...state, jmbg: action.payload};
        case 'oid':
            return {...state, oid: action.payload};
        case 'dateOfBirth':
            return {...state, dateOfBirth: action.payload};
        case 'employmentEndDate':
            return {...state, employmentEndDate: action.payload};
        case 'employmentStartDate':
            return {...state, employmentStartDate: action.payload};
        case 'countries':
            return {...state, countries: action.payload};
        case 'countryPlaceOfBirth':
            return {...state, countryPlaceOfBirth: action.payload};
        case 'countryPlaceOfResidence':
            return {...state, countryPlaceOfResidence: action.payload};
        case 'entities':
            return {...state, entities: action.payload };
        case 'entityPlaceOfBirth':
            return {...state, entityPlaceOfBirth: action.payload};
        case 'entityPlaceOfResidence':
            return {...state, entityPlaceOfResidence: action.payload};
        case 'cantons':
            return {...state, cantons: action.payload };
        case 'cantonPlaceOfBirth':
            return {...state, cantonPlaceOfBirth: action.payload };
        case 'cantonPlaceOfResidence':
            return {...state, cantonPlaceOfResidence: action.payload };
        case 'municipalities':
            return {...state, municipalities: action.payload };
        case 'municipalityPlaceOfBirth':
            return {...state, municipalityPlaceOfBirth: action.payload };
        case 'municipalityPlaceOfResidence':
            return {...state, municipalityPlaceOfResidence: action.payload };
        case 'placeOfResidence':
            return {...state, placeOfResidence: action.payload};
        case 'placeOfBirth':
            return {...state, placeOfBirth: action.payload};
        case 'placeOfBirthBackObj':
            return {...state, placeOfBirthBackObj: action.payload};
        case 'placeOfResidenceBackObj':
            return {...state, placeOfResidenceBackObj: action.payload};
        case 'otherInfoObj':
            return {...state, otherInfoObj: action.payload};
        case 'place':
            return {...state, place: action.payload};
        case 'address':
            return {...state, address: action.payload };
        case 'zip':
            return {...state, zip: action.payload };
        case 'person':
            return {...state, person: action.payload};
        case 'open':
            return {...state, open: action.payload};
        case 'personExist':
            return {...state, personExist: action.payload};
        case 'personError':
            return {...state, personError: action.payload};
        case 'teacherExist':
            return {...state, teacherExist: action.payload};
        case 'exist':
            return {...state, exist: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'showPlaceInfo':
            return {...state, showPlaceInfo: action.payload};
        case 'showOtherInfo':
            return {...state, showOtherInfo: action.payload};
        default:
            return state;

    }
};

const TeacherForm = ({toggleEditForm, getTeachers, add, edit, getTeachersForAdmin, setCurrentIdOrganizer}) => {
    const [organizerErr, setOrganizerErr] = useState(false);
    const [countryBirthErr, setCountryBirthErr] = useState(false);
    const [placeOfBirthErr, setPlaceOfBirthErr] = useState(false);
    const [countryErr, setCountryErr] = useState(false);
    const [placeErr, setPlaceErr] = useState(false);
    const [addressErr, setAddressErr] = useState(false);
    const [zipErr, setZipErr] = useState(false);
    const [firstNameErr, setFirstNameErr] = useState(false);
    const [lastNameErr, setLastNameErr] = useState(false);
    const [oidErr, setOidErr] = useState(false);
    const [jmbgErr, setJmbgErr] = useState(false);
    const [genderErr, setGenderErr] = useState(false);
    const [originPlaceOfEmploymentErr, setOriginPlaceOfEmploymentErr] = useState(false);
    const [numberOfHoursInOriginPlaceOfEmploymentErr, setNumberOfHoursInOriginPlaceOfEmploymentErr] = useState(false);
    // const [employmentEndDateErr, setEmploymentEndDateErr] = useState(false);
    const [employmentStartDate, setEmploymentStartDateErr] = useState(false);
    const [dateOfBirthErr, setDateOfBirthErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        firstName: '',
        lastName: '',
        maidenName: '',
        gender: null,
        genderOptions: [],
        jmbg: '',
        oid: '',
        originPlaceOfEmployment: '',
        numberOfHoursInOriginPlaceOfEmployment: '',
        employmentStartDate: new Date(),
        employmentEndDate: null,
        dateOfBirth: new Date(),
        person: '',
        open: false,
        personExist: false,
        personError: false,
        teacherExist: false,
        exist: null,
        organizers: [],
        organizer: '',

        // options
        filteredMunicipalities: [],
        countries:[],
        entities: [],
        cantons: [],
        municipalities: [],

        // fields
        country: '',
        canton: '',
        municipality:  '',
        place: '',
        address:'',
        zip:'',

        placeOfResidence: '',
        placeOfBirth: '',
        placeOfBirthBackObj: null,
        placeOfResidenceBackObj: null,
        otherInfoObj: null,

        // disable properties
        enableEntity: false,
        enableCanton: false,
        enableMunicipality: false,
        showPlaceInfo : false,
        showOtherInfo: false,
    });

    function addTeacher(){
        if ( !state.firstName || !state.lastName  || !state.jmbg || !state.gender || !state.dateOfBirth
            || (!state.person && !state.placeOfBirth.country) || (!state.person &&!state.placeOfBirth.place)
            || (!state.person &&!state.placeOfResidence.country) || (!state.person &&!state.placeOfResidence.place)
            || (!state.person &&!state.placeOfResidence.address) || (!state.person &&!state.placeOfResidence.zip)
            || (activeRole === "ROLE_ADMIN" && !state.organizer)
            // || !state.placeOfBirth.place || !state.placeOfResidence.country || !state.placeOfResidence.place || !state.placeOfResidence.address || !state.placeOfResidence.zip
            || !state.employmentStartDate) {
            toast('Niste unijeli sve podatke.');
            if (!state.firstName) { setFirstNameErr(true); } else { setFirstNameErr(false); }
            if (!state.lastName) { setLastNameErr(true); } else { setLastNameErr(false); }
            if (!state.jmbg) {setJmbgErr(true);} else {if (state.jmbg.length !== 13 ){toast('JMBG mora biti 13 cifara.');setJmbgErr(true)}else{setJmbgErr(false);}}
            if (!state.gender) { setGenderErr(true); } else { setGenderErr(false); }
            if (!state.dateOfBirth) { setDateOfBirthErr(true); } else { setDateOfBirthErr(false); }
            if (activeRole === "ROLE_ADMIN" && !state.organizer) { setOrganizerErr(true); } else { setOrganizerErr(false); }
            if (!state.person && !state.placeOfBirth.country) {setCountryBirthErr(true);} else {setCountryBirthErr(false);}
            // if (!state.teacherExist && !state.placeOfBirth.country) {setCountryBirthErr(true);} else {setCountryBirthErr(false);}
            if (!state.person && !state.placeOfBirth.place) {setPlaceOfBirthErr(true);} else {setPlaceOfBirthErr(false);}
            //
            if (!state.person && !state.placeOfResidence.country) {setCountryErr(true);} else {setCountryErr(false);}
            if (!state.person && !state.placeOfResidence.place) {setPlaceErr(true);} else {setPlaceErr(false);}
            if (!state.person && !state.placeOfResidence.address) {setAddressErr(true);} else {setAddressErr(false);}
            if (!state.person && !state.placeOfResidence.zip) {setZipErr(true);} else {setZipErr(false);}

            // if (!state.employmentEndDate) { setEmploymentEndDateErr(true); } else { setEmploymentEndDateErr(false); }
            if (!state.employmentStartDate) { setEmploymentStartDateErr(true); } else { setEmploymentStartDateErr(false); }
            // if (!state.numberOfHoursInOriginPlaceOfEmployment) { setNumberOfHoursInOriginPlaceOfEmploymentErr(true); } else { setNumberOfHoursInOriginPlaceOfEmploymentErr(false); }
            // if (!state.originPlaceOfEmployment) { setOriginPlaceOfEmploymentErr(true); } else { setOriginPlaceOfEmploymentErr(false); }
        } else if (state.jmbg.length !== 13){
            toast('JMBG mora imati 13 cifara.');
        } else {
            if (activeRole === "ROLE_ADMIN") {
                let obj = {
                    employmentEndDate: state.employmentEndDate ? new Date(state.employmentEndDate) : null,
                    employmentStartDate: new Date(state.employmentStartDate),
                    organizer: {id: state.organizer},
                    teacher: {
                        person: !state.personExist ? {
                            created: new Date(),
                            dateOfBirth: state.dateOfBirth,
                            firstName: state.firstName,
                            lastName: state.lastName,
                            jmbg: state.jmbg,
                            maidenName: state.maidenName,
                            oid: null,
                            teacher: true,
                            gender: {id: state.gender.id},
                            //id
                            placeOfBirth: {
                                name: state.placeOfBirth.place,
                                country: {
                                    id: state.placeOfBirth.country.id
                                },
                                municipality: state.placeOfBirth.country.key === 'bih' ? {
                                    id: state.placeOfBirth.municipality.id,
                                    canton: {id: state.placeOfBirth.canton.id},
                                    entity: {id: state.placeOfBirth.entity.id}
                                } : null
                            },
                            residence: {
                                address: state.placeOfResidence.address,
                                zipCode: state.placeOfResidence.zip,
                                place: {
                                    name: state.placeOfResidence.place,
                                    country: {
                                        id: state.placeOfResidence.country.id
                                    },
                                    municipality: state.placeOfResidence.country.key === 'bih' ? {
                                        id: state.placeOfResidence.municipality.id,
                                        canton: {id: state.placeOfResidence.canton.id},
                                        entity: {id: state.placeOfResidence.entity.id}
                                    } : null
                                }
                            },
                        } : {id: state.person.person.id},
                        numberOfHoursInOriginPlaceOfEmployment: state.numberOfHoursInOriginPlaceOfEmployment,
                        created: new Date(),
                        originPlaceOfEmployment: state.originPlaceOfEmployment,
                        id: state.teacherExist ? state.person.id : null
                    }
                };
                axios.post('/teacher-organizer/save', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste dodali nastavnika!');
                        getTeachersForAdmin(state.organizer);
                        setCurrentIdOrganizer(state.organizer)
                    }).catch((err) => {
                    console.log('err', err)
                    toast('Došlo je do greške!');
                });
            }
            else
            {
                let obj = {
                    employmentEndDate: state.employmentEndDate ? new Date(state.employmentEndDate) : null,
                    employmentStartDate: new Date(state.employmentStartDate),
                    organizer: null,
                    teacher: {
                        person: !state.personExist ? {
                            created: new Date(),
                            dateOfBirth: state.dateOfBirth,
                            firstName: state.firstName,
                            lastName: state.lastName,
                            jmbg: state.jmbg,
                            maidenName: state.maidenName,
                            oid: null,
                            teacher: true,
                            gender: {id: state.gender.id},
                            //id
                            placeOfBirth: {
                                name: state.placeOfBirth.place,
                                country: {
                                    id: state.placeOfBirth.country.id
                                },
                                municipality: state.placeOfBirth.country.key === 'bih' ? {
                                    id: state.placeOfBirth.municipality.id,
                                    canton: {id: state.placeOfBirth.canton.id},
                                    entity: {id: state.placeOfBirth.entity.id}
                                } : null
                            },
                            residence: {
                                address: state.placeOfResidence.address,
                                zipCode: state.placeOfResidence.zip,
                                place: {
                                    name: state.placeOfResidence.place,
                                    country: {
                                        id: state.placeOfResidence.country.id
                                    },
                                    municipality: state.placeOfResidence.country.key === 'bih' ? {
                                        id: state.placeOfResidence.municipality.id,
                                        canton: {id: state.placeOfResidence.canton.id},
                                        entity: {id: state.placeOfResidence.entity.id}
                                    } : null
                                }
                            },
                        }: {id: state.person.person.id} ,
                        numberOfHoursInOriginPlaceOfEmployment: state.numberOfHoursInOriginPlaceOfEmployment,
                        created: new Date(),
                        originPlaceOfEmployment : state.originPlaceOfEmployment,
                        id: state.teacherExist ? state.person.id : null
                    }
                };
                axios.post('/teacher-organizer/save/for-organizer',{...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste dodali nastavnika!');
                        getTeachers();
                    }).catch((err) => {
                    console.log('err', err)
                    toast('Došlo je do greške!');
                });
            }
        }
    }

    function getOrganizers(){
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    useEffect(() => {
        if (activeRole !== "ROLE_ORGANIZER") {getOrganizers()}
        axios.get('/gender/all',).then(
            (res)=>{
                let genderOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'genderOptions', payload: genderOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/occupation-type/all',).then(
            (res)=>{
                let occupationOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'occupationOptions', payload: occupationOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/country/all',).then(
            (res)=>{
                let countriesOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'countries', payload: countriesOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/entity/all',).then(
            (res)=>{
                let entityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'entities', payload: entityOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/canton/all',).then(
            (res)=>{
                let cantonOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'cantons', payload: cantonOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/municipality/all',).then(
            (res)=>{
                let municipalityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'municipalities', payload: municipalityOptions});

            }).catch((err)=>{
            console.log('err', err)
        });
    }, [activeRole]);

    function handleInputChange(event, { name, value }) {
        if(name === 'jmbg' && value.length <= 13){
            if(value.length === 13){
                checkPerson(value);
            }else{
                dispatch({type: 'firstName', payload: ''});
                dispatch({type: 'lastName', payload: ''});
                dispatch({type: 'maidenName', payload: ''});
                dispatch({type: 'gender', payload: null});
                dispatch({type: 'dateOfBirth', payload: new Date()});
                dispatch({type: 'placeOfResidence', payload: null});
                dispatch({type: 'placeOfBirth', payload: null});
                dispatch({type: 'placeOfBirthBackObj', payload: ''});
                dispatch({type: 'placeOfResidenceBackObj', payload: ''});
                dispatch({type: 'showPlaceInfo', payload: false});
                dispatch({type: 'showOtherInfo', payload: false});
                dispatch({type: 'numberOfHoursInOriginPlaceOfEmployment', payload: ''});
                dispatch({type: 'originPlaceOfEmployment', payload: ''});
                dispatch({type: 'employmentStartDate', payload: new Date()});
                dispatch({type: 'employmentEndDate', payload: null});
                dispatch({type: 'personExist', payload: false });
                dispatch({type: 'teacherExist', payload: false });
            }
            dispatch({type: name, payload: value});
        }
        if (name === 'gender') { dispatch({type: 'maidenName', payload: ''}); }

        if(name !== 'jmbg'){
            dispatch({type: name, payload: value});
        }
    }

    function handlePlaceChange(placeName, obj) {
        dispatch({type: placeName, payload: obj});
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    function reset(){
        dispatch({type: 'person', payload: ''});
        dispatch({type: 'open', payload: false});
        dispatch({type: 'personExist', payload: false});
        dispatch({type: 'placeOfBirth', payload: ''});
        dispatch({type: 'placeOfResidence', payload: ''});
        dispatch({type: 'personError', payload: false});
        dispatch({type: 'teacherExist', payload: false});
    }

    function checkPerson(jmbg) {
        reset()
        // axios.get(`teacher/by-jmbg/${state.jmbg}`)
        axios.get(`teacher/by-jmbg/${jmbg}`)
            .then(res => {
                if (res.data.person!==null && res.data.id !==null) {
                    let person = res.data;
                    dispatch({type: 'person', payload: person});
                    dispatch({type: 'teacherExist', payload: true});
                    axios.get(activeRole === "ROLE_ADMIN" ? `teacher-organizer/check-if-exists/for-admin/${res.data.id}/${state.organizer}` : `teacher-organizer/check-if-exists/${res.data.id}`)
                        .then(ress => {
                            let exist = ress.data.id;
                            dispatch({type: 'exist', payload: exist});
                            console.log('tu', ress.data.id)
                            if(exist)
                            {
                                toast('Nastavnik/ica je već kreiran/a.');
                                dispatch({type: 'firstName', payload: person.person.firstName});
                                dispatch({type: 'lastName', payload: person.person.lastName});
                                dispatch({type: 'oid', payload: person.person.oid});
                                dispatch({type: 'gender', payload: person.person.gender});
                                dispatch({type: 'maidenName', payload: person.person.maidenName});
                                dispatch({type: 'dateOfBirth', payload: person.person.dateOfBirth});
                                dispatch({type: 'placeOfBirthBackObj', payload: person.person.placeOfBirth});
                                dispatch({type: 'placeOfResidenceBackObj', payload:person.person.residence});
                                dispatch({type: 'originPlaceOfEmployment', payload:person.originPlaceOfEmployment});
                                dispatch({type: 'numberOfHoursInOriginPlaceOfEmployment', payload:person.numberOfHoursInOriginPlaceOfEmployment});
                                dispatch({type: 'employmentStartDate', payload:person.employmentStartDate});
                                dispatch({type: 'employmentEndDate', payload:person.employmentStartDate});
                                dispatch({type: 'showPlaceInfo', payload: true});
                                dispatch({type: 'showOtherInfo', payload: true});
                            }
                            else
                            {
                                toast('Dopunite podatke o angažmanu nastavnika/ice!');
                                dispatch({type: 'open', payload: true});
                                dispatch({type: 'personExist', payload: true});
                                dispatch({type: 'firstName', payload: person.person.firstName});
                                dispatch({type: 'lastName', payload: person.person.lastName});
                                dispatch({type: 'oid', payload: person.person.oid});
                                dispatch({type: 'gender', payload: person.person.gender});
                                dispatch({type: 'maidenName', payload: person.person.maidenName});
                                dispatch({type: 'dateOfBirth', payload: person.person.dateOfBirth});
                                dispatch({type: 'placeOfBirthBackObj', payload: person.person.placeOfBirth});
                                dispatch({type: 'placeOfResidenceBackObj', payload:person.person.residence});
                                dispatch({type: 'originPlaceOfEmployment', payload:person.originPlaceOfEmployment});
                                dispatch({type: 'numberOfHoursInOriginPlaceOfEmployment', payload:person.numberOfHoursInOriginPlaceOfEmployment});
                                dispatch({type: 'showPlaceInfo', payload: true});
                            }
                        }).catch((err) => {
                        console.log('err', err)
                    });
                    // let person = res.data;
                    // dispatch({type: 'person', payload: person});
                    // dispatch({type: 'teacherExist', payload: true});
                    // toast('Nastavnik/ica je već kreiran/a.');
                    // dispatch({type: 'firstName', payload: person.person.firstName});
                    // dispatch({type: 'lastName', payload: person.person.lastName});
                    // dispatch({type: 'oid', payload: person.person.oid});
                    // dispatch({type: 'gender', payload: person.person.gender});
                    // dispatch({type: 'maidenName', payload: person.person.maidenName});
                    // dispatch({type: 'dateOfBirth', payload: person.person.dateOfBirth});
                    // dispatch({type: 'placeOfBirthBackObj', payload: person.person.placeOfBirth});
                    // dispatch({type: 'placeOfResidenceBackObj', payload:person.person.residence});
                    // dispatch({type: 'originPlaceOfEmployment', payload:person.originPlaceOfEmployment});
                    // dispatch({type: 'numberOfHoursInOriginPlaceOfEmployment', payload:person.numberOfHoursInOriginPlaceOfEmployment});
                    // dispatch({type: 'employmentStartDate', payload:person.employmentStartDate});
                    // dispatch({type: 'employmentEndDate', payload:person.employmentStartDate});
                    // dispatch({type: 'showPlaceInfo', payload: true});
                    // dispatch({type: 'showOtherInfo', payload: true});
                }
                if (res.data.person!==null && res.data.id ===null) {
                    let person = res.data;
                    dispatch({type: 'person', payload: person});
                    toast('Osoba sa ovim JMBG-om već postoji. Dopunite preostale podatke potrebne za kreiranje nastavnika!');
                    dispatch({type: 'open', payload: true});
                    dispatch({type: 'personExist', payload: true});
                    dispatch({type: 'firstName', payload: person.person.firstName});
                    dispatch({type: 'lastName', payload: person.person.lastName});
                    dispatch({type: 'oid', payload: person.person.oid});
                    dispatch({type: 'gender', payload: person.person.gender});
                    dispatch({type: 'maidenName', payload: person.person.maidenName});
                    dispatch({type: 'dateOfBirth', payload: person.person.dateOfBirth});
                    dispatch({type: 'placeOfBirthBackObj', payload: person.person.placeOfBirth});
                    dispatch({type: 'placeOfResidenceBackObj', payload:person.person.residence});
                    dispatch({type: 'showPlaceInfo', payload: true});
                    // dispatch({type: 'showOtherInfo', payload: true});

                }
            })
            .catch(err => {
                if (err.response.data.message === 'More than one person found with passed jmbg!')
                {
                    dispatch({type: 'personError', payload: true});
                    toast('Pronađeno je više osoba sa unesenim JMBG-om!');
                }
                else if (err.response.data.message === 'No person found with passed jmbg!') {
                    //osoba sa unesenim jmbgom ne postoji
                    toast('Unesite potrebne podatke!');
                    dispatch({type: 'open', payload: true});
                    dispatch({type: 'country', payload: ''});
                    dispatch({type: 'entity', payload: ''});
                }
                else { toast('Došlo je do greške.'); }
            });
    }


    return (
        <div>

            <SegmentGroup raised>
                <Segment>
                    <Header
                        as={'h4'}> {add ? 'Dodavanje novog nastavnika' : 'Izmjena podataka'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Button floated={"right"} icon size={"mini"} basic>
                                {/*<Icon />*/}
                                <Popup
                                    trigger={<Icon link basic name="info"/>}
                                    content="Ukoliko osoba sa unesenim JMBG-om postoji u sistemu otvorit će Vam se njegovi/njeni podaci. Ukoliko te osobe nema u sistemu,
                            otvorit će se forma za unos nove osobe. Također, osoba može biti unesena u sistem, a da joj nedostaju podaci o zaposlenju, pa će Vam sistem tražiti samo unos podataka o zaposlenju."
                                    hideOnScroll
                                    position="left center"
                                />
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    {<Form size={'small'}>
                        {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Form.Group widths="4"><Form.Field required>
                            <label>Organizator</label>
                            <Form.Dropdown
                                clearable
                                disabled={(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && !add}
                                placeholder="Odaberite organizatora"
                                value={state.organizer}
                                onChange={handleInputChange}
                                name="organizer"
                                options={state.organizers}
                                search
                                error={organizerErr}
                                fluid
                                selection
                            />
                        </Form.Field>
                        </Form.Group>}
                        <Grid columns={2} divided stackable>
                            <GridColumn>
                                <Divider horizontal>
                                    <Header as="h4">
                                        Osnovni podaci
                                    </Header>
                                </Divider>
                                <Form size={'small'} >
                                    <Form.Group widths={'equal'}>
                                        <Form.Field
                                            error={jmbgErr}
                                            required
                                            //disabled
                                            label="JMBG"
                                            control={Input}
                                            name="jmbg"
                                            value={state.jmbg}
                                            onChange={handleInputChange}
                                            placeholder="Unesite JMBG"
                                        />
                                        <Form.Field
                                            required
                                            error={dateOfBirthErr}
                                            control={DateTimePicker}
                                            disabled={state.personExist || state.teacherExist || state.jmbg.length !== 13}
                                            label="Datum rođenja"
                                            format="D. M. YYYY."
                                            placeholder="Unesite datum"
                                            name="dateOfBirth"
                                            value={new Date(state.dateOfBirth)}
                                            time={false}
                                            onChange={(e) => handleDateChange('dateOfBirth', e)}
                                            style={{ borderWidth: '0px' }}
                                        />
                                        {/*<Form.Field*/}
                                        {/*    error={oidErr}*/}
                                        {/*    disabled={state.personExist}*/}
                                        {/*    required*/}
                                        {/*    label="OID"*/}
                                        {/*    control={Input}*/}
                                        {/*    name="oid"*/}
                                        {/*    type="number"*/}
                                        {/*    value={state.oid}*/}
                                        {/*    onChange={handleInputChange}*/}
                                        {/*    placeholder="Unesite OID"*/}
                                        {/*/>*/}
                                    </Form.Group>
                                    <Form.Group widths={'equal'} >
                                        <Form.Field
                                            required
                                            disabled={state.personExist || state.teacherExist || state.jmbg.length !== 13}
                                            error={firstNameErr}
                                            label="Ime"
                                            control={Input}
                                            name="firstName"
                                            value={state.firstName}
                                            onChange={handleInputChange}
                                            placeholder="Unesite ime"
                                        />
                                        <Form.Field
                                            disabled={state.personExist || state.teacherExist || state.jmbg.length !== 13}
                                            required
                                            error={lastNameErr}
                                            label="Prezime"
                                            control={Input}
                                            name="lastName"
                                            value={state.lastName}
                                            onChange={handleInputChange}
                                            placeholder="Unesite prezime"
                                        />
                                    </Form.Group>

                                    <Form.Group widths={'equal'}>
                                        <Form.Field
                                            error={genderErr}
                                            required
                                            disabled={state.personExist || state.teacherExist || state.jmbg.length !== 13}
                                            control={Select}
                                            clearable
                                            options={state.genderOptions.filter(el => el.value.name !== 'nepoznato')}
                                            placeholder="Odaberite spol"
                                            label="Spol"
                                            onChange={handleInputChange}
                                            name="gender"
                                            selectOnNavigation={false}
                                            openOnFocus={false}
                                            value={state.gender}
                                            search
                                        />
                                        <Form.Field
                                            disabled={!state.gender || state.gender.key === 'm' || state.personExist || state.teacherExist || state.jmbg.length !== 13}
                                            label="Djevojačko prezime"
                                            control={Input}
                                            name="maidenName"
                                            value={state.maidenName}
                                            onChange={handleInputChange}
                                            placeholder="Unesite djevojačko prezime"
                                        />

                                    </Form.Group>
                                    <br/>
                                    <Divider horizontal>
                                        <Header as="h4">
                                            Podaci o mjestu rođenja
                                        </Header>
                                    </Divider>
                                    {!state.showPlaceInfo ? <PlaceForm
                                        size={'small'}
                                        handlePlaceChange={handlePlaceChange}
                                        formName={'placeOfBirth'}
                                        countries={state.countries}
                                        entities={state.entities}
                                        cantons={state.cantons}
                                        municipalities={state.municipalities}
                                        country = {state.countryPlaceOfBirth}
                                        entity = {state.entityPlaceOfBirth}
                                        enableEntity = {state.enableEntity}
                                        enableCanton = {state.enableCanton}
                                        canton = {state.cantonPlaceOfBirth}
                                        enableMunicipality = {state.enableMunicipality}
                                        municipality = {state.municipalityPlaceOfBirth}
                                        place = {state.place}
                                        address = {state.address}
                                        zip = {state.zip}
                                        allDisabled={state.personExist || state.teacherExist || state.jmbg.length !== 13}
                                        showPlaceInfo={state.showPlaceInfo}

                                        threeRowsThreeFields
                                        noAddress
                                        noZip
                                        countryErr = {countryBirthErr}
                                        placeErr = {placeOfBirthErr}

                                    />:
                                        <PlaceInfo placeOfBirth={state.placeOfBirthBackObj}/>}
                                </Form>
                            </GridColumn>
                            <GridColumn>
                                <Divider horizontal>
                                    <Header as="h4">
                                        Podaci o mjestu stanovanja
                                    </Header>
                                </Divider>
                                {!state.showPlaceInfo ?  <PlaceForm
                                    size={'small'}
                                    threeRowsThreeFields
                                    handlePlaceChange={handlePlaceChange}
                                    formName={'placeOfResidence'}
                                    countries={state.countries}
                                    entities={state.entities}
                                    cantons={state.cantons}
                                    municipalities={state.municipalities}
                                    country = {state.countryPlaceOfResidence}
                                    entity = {state.entityPlaceOfResidence}
                                    enableEntity = {state.enableEntity}
                                    enableCanton = {state.enableCanton}
                                    canton = {state.cantonPlaceOfResidence}
                                    enableMunicipality = {state.enableMunicipality}
                                    municipality = {state.municipalityPlaceOfResidence}
                                    place = {state.place}
                                    address = {state.address}
                                    zip = {state.zip}

                                    allDisabled={state.personExist || state.teacherExist || state.jmbg.length !== 13}

                                    countryErr = {countryErr}
                                    placeErr = {placeErr}
                                    addressErr = {addressErr}
                                    zipErr = {zipErr}

                                />:
                                    <PlaceInfo residence={state.placeOfResidenceBackObj}/>}
                                {!state.showOtherInfo &&<br/>}
                                <Divider horizontal>
                                    <Header as="h4">
                                        Ostali podaci
                                    </Header>
                                </Divider>
                                {!state.showOtherInfo ? <Form size={'small'} >
                                    <Form.Group widths={'equal'} >
                                        <Form.Field
                                            // required
                                            disabled={state.teacherExist || state.jmbg.length !== 13}
                                            // error={originPlaceOfEmploymentErr}
                                            label="Matično zaposlenje"
                                            control={Input}
                                            name="originPlaceOfEmployment"
                                            value={state.originPlaceOfEmployment}
                                            onChange={handleInputChange}
                                            placeholder="Unesite matično zaposlenje"
                                        />
                                        <Form.Field
                                            // required
                                            disabled={state.teacherExist || state.jmbg.length !== 13}
                                            // error={numberOfHoursInOriginPlaceOfEmploymentErr}
                                            label="Broj sati sedmično (matično zaposlenje)"
                                            control={Input}
                                            name="numberOfHoursInOriginPlaceOfEmployment"
                                            type="number"
                                            value={state.numberOfHoursInOriginPlaceOfEmployment}
                                            onChange={handleInputChange}
                                            placeholder="Unesite broj sati"
                                        />
                                    </Form.Group>
                                    <Form.Group widths={'equal'} >
                                        <Form.Field
                                            required
                                            disabled={state.exist || state.jmbg.length !== 13}
                                            error={employmentStartDate}
                                            control={DateTimePicker}
                                            label="Datum početka angažmana na organizatoru"
                                            format="D. M. YYYY."
                                            placeholder="Unesite datum"
                                            name="employmentStartDate"
                                            value={state.employmentStartDate}
                                            time={false}
                                            onChange={(e) => handleDateChange('employmentStartDate', e)}
                                            style={{ borderWidth: '0px' }}
                                        />
                                        <Form.Field
                                            // required
                                            disabled={state.exist || state.jmbg.length !== 13}
                                            // error={employmentEndDateErr}
                                            control={DateTimePicker}
                                            label="Datum kraja angažmana na organizatoru"
                                            format="D. M. YYYY."
                                            placeholder="Unesite datum"
                                            name="employmentEndDate"
                                            value={state.employmentEndDate ? state.employmentEndDate : null}
                                            time={false}
                                            onChange={(e) => handleDateChange('employmentEndDate', e)}
                                            style={{ borderWidth: '0px' }}
                                        />
                                    </Form.Group>
                                </Form>:
                                    <OtherInfo
                                        numberOfHoursInOriginPlaceOfEmployment={state.numberOfHoursInOriginPlaceOfEmployment}
                                        originPlaceOfEmployment={state.originPlaceOfEmployment}
                                        employmentStartDate={state.employmentStartDate}
                                        employmentEndDate={state.employmentEndDate}
                                    />}

                            </GridColumn>

                        </Grid>
                        {!state.exist &&<Button floated={"right"} basic size={"small"} color={"teal"}
                                onClick={addTeacher}>{add ? 'Kreiraj' : 'Sačuvaj'}</Button>}
                    </Form>}
                </Segment>
            </SegmentGroup>
            <ToastContainer hideProgressBar/>
        </div>
    );
};

export default TeacherForm;
