import React, {useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Button, Header, Icon, Loader, Menu, Tab} from "semantic-ui-react";
import {toast, ToastContainer} from "react-toastify";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import UserForm from "./UserForm";
import axios from "axios";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Requests from "../Utilities/Requests";
import organizers from "../Organizers/Organizers";
import ReactExport from 'react-data-export';
import moment from "moment";
import SearchTableData from "../Utilities/SearchTableData";

const reducer = (state, action)=>{
    switch (action.type) {
        case 'current':
            return {...state, current: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload, loader:  false };
        case 'loader':
            return {...state, loader: action.payload};
        case 'users':
            return {...state, users: action.payload};
        case 'organizerId':
            return {...state, organizerId: action.payload};
        case 'usersData':
            return {...state, usersData: action.payload};
        case 'usersDataArchived':
            return {...state, usersDataArchived: action.payload};
        case 'activeIndex':
            return {...state, activeIndex: action.payload};
        case 'usersActive':
            return {...state, usersActive: action.payload};
        case 'usersArchived':
            return {...state, usersArchived: action.payload};
        case 'dataActiveFilter':
            return {...state, dataActiveFilter: action.payload};
        case 'dataArchivedFilter':
            return {...state, dataArchivedFilter: action.payload};
        case 'results':
            return {...state, results: action.payload};
        default:
            return state;
    }
};

const Users = () => {
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [state, dispatch] = useReducer(reducer,{
        loader: false,
        users: [],
        usersData: [],
        usersDataArchived: [],
        current: null,
        organizerId: null,
        organizers:[],
        activeIndex: 0,
        usersActive: [],
        usersArchived: [],
        dataActiveFilter:[],
        dataArchivedFilter: [],
        results:[],
    } );

    const [showAddForm, setShowAddForm] = useState(false);
    const [reset, setReset] = useState(false);
    const [edit, setEdit] = useState(false);

    function handleTabChange(e, { activeIndex }) {
        setReset(true)
        getResults([])
        console.log('re', state.results)
        dispatch({type: 'activeIndex', payload: activeIndex});
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
    }

    function getDataToRenderActive() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.usersActive;
    }

    function getDataToRenderArchived() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.usersArchived;
    }

    const panes = [
        {
            menuItem: 'Aktivni korisnici',
            render: () => (<Tab.Pane attached={false} as={'div'}>

                {getDataToRenderActive().length ?<> <br/> <Table celled size={'small'}>
                {/*{state.users.filter((e=>(!e.archived && (state.organizerId === null || e.userOrganizer.organizer?.id === state.organizerId.id)))).length ?<> <br/> <Table celled size={'small'}>*/}
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan={9} >
                                <div style={{ paddingLeft: '0px' }}>
                                    <SearchTableData data={state.dataActiveFilter} resetValue={reset} getResults={getResults} activeIndex={state.activeIndex} organizerId={state.organizerId} filterItem={['organizerFilter', 'personFirstNameFilter', 'personLastNameFilter', 'organizerEmailFilter', 'organizerUsernameFilter', 'roleFilter']} />
                                </div>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>

                            <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Ime i prezime korisnika</Table.HeaderCell>
                            <Table.HeaderCell>Korisničko ime</Table.HeaderCell>
                            <Table.HeaderCell colSpan={2}>Korisnički e-mail</Table.HeaderCell>
                            <Table.HeaderCell>Uloga na sistemu</Table.HeaderCell>
                            <Table.HeaderCell>Organizator</Table.HeaderCell>
                            <Table.HeaderCell width={1}/>

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            getDataToRenderActive().map((el, index) =>
                            // state.users.filter((e=>(!e.archived && (state.organizerId === null || e.userOrganizer.organizer?.id === state.organizerId.id)))).map((el, index) =>
                                (<Table.Row key={el.userOrganizer.id}>
                                    <Table.Cell width={1}>{index + 1}.</Table.Cell>
                                    <Table.Cell
                                        width={3}>{el.userOrganizer.user.person.firstName} {el.userOrganizer.user.person.lastName}</Table.Cell>
                                    <Table.Cell>{el.userOrganizer.user.username}</Table.Cell>
                                    <Table.Cell colSpan={2}>{el.userOrganizer.user.email}</Table.Cell>
                                    <Table.Cell>{el.role.name}</Table.Cell>
                                    <Table.Cell>{el.userOrganizer.organizer !== null ? el.userOrganizer.organizer.name : ''}</Table.Cell>
                                    <Table.Cell verticalAlign="middle" textAlign="center" width={1}>
                                        <Popup size={"small"}
                                               content='Izmijeni'
                                               trigger={
                                                   <Button
                                                       icon
                                                       size={"tiny"}
                                                       basic
                                                       compact
                                                       onClick={()=>openEditForm(el)}
                                                   >
                                                       <Icon name='pencil' color={"teal"}/>
                                                   </Button>
                                               }
                                        />
                                        <Popup size={"small"}
                                               content='Arhiviraj ulogu'
                                               trigger={
                                                   <Button
                                                       icon
                                                       size={"tiny"}
                                                       basic
                                                       compact
                                                       onClick={()=>archiveRole(el)}
                                                   >
                                                       <Icon name='archive' color={"red"}/>
                                                   </Button>
                                               }
                                        />

                                    </Table.Cell>
                                </Table.Row>)
                            )
                        }


                    </Table.Body>

                </Table> </> : <div><br/>Nema unesenih podataka o korisnicima.</div>}

            </Tab.Pane>)
        },
        {
            menuItem: 'Arhivirani korisnici',
            render: () => (<Tab.Pane attached={false} as={'div'}>
                {getDataToRenderArchived().length ?<> <br/>  <Table celled size={'small'}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan={9} >
                                <div style={{ paddingLeft: '0px' }}>
                                    <SearchTableData data={state.dataArchivedFilter} resetValue={reset} getResults={getResults} activeIndex={state.activeIndex} organizerId={state.organizerId} filterItem={['organizerFilter', 'personFirstNameFilter', 'personLastNameFilter', 'organizerEmailFilter', 'organizerUsernameFilter', 'roleFilter']} />
                                </div>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>

                            <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Ime i prezime korisnika</Table.HeaderCell>
                            <Table.HeaderCell>Korisničko ime</Table.HeaderCell>
                            <Table.HeaderCell colSpan={2}>Korisnički e-mail</Table.HeaderCell>
                            <Table.HeaderCell>Uloga na sistemu</Table.HeaderCell>
                            <Table.HeaderCell>Organizator</Table.HeaderCell>
                            <Table.HeaderCell width={1}/>

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            getDataToRenderArchived().map((el, index) =>
                            // state.users.filter((e=>(e.archived))).map((el, index) =>
                                (<Table.Row key={el.userOrganizer.id}>
                                    <Table.Cell width={1}>{index + 1}.</Table.Cell>
                                    <Table.Cell
                                        width={3}>{el.userOrganizer.user.person.firstName} {el.userOrganizer.user.person.lastName}</Table.Cell>
                                    <Table.Cell>{el.userOrganizer.user.username}</Table.Cell>
                                    <Table.Cell colSpan={2}>{el.userOrganizer.user.email}</Table.Cell>
                                    <Table.Cell>{el.role.name}</Table.Cell>
                                    <Table.Cell>{el.userOrganizer.organizer !== null ? el.userOrganizer.organizer.name : ''}</Table.Cell>
                                    <Table.Cell verticalAlign="middle" textAlign="center" width={1}>
                                        <Popup size={"small"}
                                               content='Aktiviraj ulogu'
                                               trigger={
                                                   <Button
                                                       icon
                                                       size={"tiny"}
                                                       basic
                                                       compact
                                                       onClick={()=>activateRole(el)}
                                                   >
                                                       <Icon name='archive' color={"teal"}/>
                                                   </Button>
                                               }
                                        />
                                    </Table.Cell>
                                </Table.Row>)
                            )
                        }


                    </Table.Body>

                </Table></> : <div><br/>Nema arhiviranih korisnika.</div>}

            </Tab.Pane>)
        }
    ];

    const multiDataSet = [
        {
            columns: [
                { title: 'Ime korisnika', width: { wpx: 250 } },
                { title: 'Prezime korisnika', width: { wpx: 250 } },
                { title: 'Korisničko ime', width: { wpx: 250 } },
                { title: 'Korisnički e-mail', width: { wpx: 250 } },
                { title: 'Uloga na sistemu', width: { wpx: 200 } },
                { title: 'Organizator', width: { wpx: 500 } },
            ],
            data: state.usersData,
        }
    ];

    const multiDataSetArchived = [
        {
            columns: [
                { title: 'Ime korisnika', width: { wpx: 250 } },
                { title: 'Prezime korisnika', width: { wpx: 250 } },
                { title: 'Korisničko ime', width: { wpx: 250 } },
                { title: 'Korisnički e-mail', width: { wpx: 250 } },
                { title: 'Uloga na sistemu', width: { wpx: 200 } },
                { title: 'Organizator', width: { wpx: 500 } },
            ],
            data: state.usersDataArchived,
        }
    ];
    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };
    let openEditForm = (el) =>{

        setCurrent(el)
        setEdit(!edit)
        toggleAddForm()
    };

    useEffect(() => {
        localStorage.removeItem('organizerId')
        dispatch({type: 'loader', payload: true});
        getOrganizers();
        getUsers();
    },[]);


    function setCurrent(value){
        dispatch({type: 'current', payload: value});
    }

    function getOrganizers(){
        Requests.getAllOrganizers().then(res => {
            dispatch({type: 'organizers', payload: res});
        });
    }

    function getUsers(){

        axios.get('/user-role/all',).then(
            (res) => {
                let users = res.data;
                dispatch({type: 'users', payload: users});
                if(parseInt(localStorage.getItem('organizerId')))
                {
                    let usersActive = res.data.filter((e=>(!e.archived && (e.userOrganizer.organizer?.id === parseInt(localStorage.getItem('organizerId'))))))
                    let dataActiveFilter = res.data.map(el => ({
                        ...el,
                        organizerFilter: el.userOrganizer.organizer !== null && el.userOrganizer.organizer.name,
                        personFirstNameFilter: el.userOrganizer.user.person.firstName,
                        personLastNameFilter: el.userOrganizer.user.person.lastName,
                        organizerEmailFilter: el.userOrganizer.user.email,
                        organizerUsernameFilter: el.userOrganizer.user.username,
                        roleFilter: el.role.name,

                    })).filter((e=>(!e.archived && (e.userOrganizer.organizer?.id === parseInt(localStorage.getItem('organizerId'))))))
                    dispatch({type: 'usersActive', payload: usersActive});
                    dispatch({type: 'dataActiveFilter', payload: dataActiveFilter});

                    let usersData = usersActive.map(el => ([
                        { value: el.userOrganizer.user.person.firstName},
                        { value: el.userOrganizer.user.person.lastName},
                        { value: el.userOrganizer.user.username},
                        { value: el.userOrganizer.user.email },
                        { value: el.role.name},
                        { value: el.userOrganizer.organizer !== null ? el.userOrganizer.organizer.name : ''},
                    ]));
                    dispatch({type: 'usersData', payload: usersData});

                    let usersArchived = res.data.filter((e=>(e.archived && (e.userOrganizer.organizer?.id === parseInt(localStorage.getItem('organizerId'))))))
                    let dataArchivedFilter = res.data.map(el => ({
                        ...el,
                        organizerFilter: el.userOrganizer.organizer !== null && el.userOrganizer.organizer.name,
                        personFirstNameFilter: el.userOrganizer.user.person.firstName,
                        personLastNameFilter: el.userOrganizer.user.person.lastName,
                        organizerEmailFilter: el.userOrganizer.user.email,
                        organizerUsernameFilter: el.userOrganizer.user.username,
                        roleFilter: el.role.name,

                    })).filter((e=>(e.archived && (e.userOrganizer.organizer?.id === parseInt(localStorage.getItem('organizerId'))))))
                    dispatch({type: 'usersArchived', payload: usersArchived});
                    dispatch({type: 'dataArchivedFilter', payload: dataArchivedFilter});

                    let usersDataArchived = usersArchived.map(el => ([
                        { value: el.userOrganizer.user.person.firstName},
                        { value: el.userOrganizer.user.person.lastName},
                        { value: el.userOrganizer.user.username},
                        { value: el.userOrganizer.user.email },
                        { value: el.role.name},
                        { value: el.userOrganizer.organizer !== null ? el.userOrganizer.organizer.name : ''},
                    ]));
                    dispatch({type: 'usersDataArchived', payload: usersDataArchived});

                }
                else
                {
                    let usersActive = res.data.filter((e=>(!e.archived)))
                    let dataActiveFilter = res.data.map(el => ({
                    ...el,
                    organizerFilter: el.userOrganizer.organizer !== null && el.userOrganizer.organizer.name,
                    personFirstNameFilter: el.userOrganizer.user.person.firstName,
                    personLastNameFilter: el.userOrganizer.user.person.lastName,
                    organizerEmailFilter: el.userOrganizer.user.email,
                    organizerUsernameFilter: el.userOrganizer.user.username,
                    roleFilter: el.role.name,

                })).filter((e=>(!e.archived)))
                    dispatch({type: 'usersActive', payload: usersActive});
                    dispatch({type: 'dataActiveFilter', payload: dataActiveFilter});

                    let usersData = usersActive.map(el => ([
                        { value: el.userOrganizer.user.person.firstName},
                        { value: el.userOrganizer.user.person.lastName},
                        { value: el.userOrganizer.user.username},
                        { value: el.userOrganizer.user.email },
                        { value: el.role.name},
                        { value: el.userOrganizer.organizer !== null ? el.userOrganizer.organizer.name : ''},
                    ]));
                    dispatch({type: 'usersData', payload: usersData});

                    let usersArchived = res.data.filter((e=>(e.archived)))
                    let dataArchivedFilter = res.data.map(el => ({
                        ...el,
                        organizerFilter: el.userOrganizer.organizer !== null && el.userOrganizer.organizer.name,
                        personFirstNameFilter: el.userOrganizer.user.person.firstName,
                        personLastNameFilter: el.userOrganizer.user.person.lastName,
                        organizerEmailFilter: el.userOrganizer.user.email,
                        organizerUsernameFilter: el.userOrganizer.user.username,
                        roleFilter: el.role.name,

                    })).filter((e=>(e.archived)))
                    dispatch({type: 'usersArchived', payload: usersArchived});
                    dispatch({type: 'dataArchivedFilter', payload: dataArchivedFilter});

                    let usersDataArchived = usersArchived.map(el => ([
                        { value: el.userOrganizer.user.person.firstName},
                        { value: el.userOrganizer.user.person.lastName},
                        { value: el.userOrganizer.user.username},
                        { value: el.userOrganizer.user.email },
                        { value: el.role.name},
                        { value: el.userOrganizer.organizer !== null ? el.userOrganizer.organizer.name : ''},
                    ]));
                    dispatch({type: 'usersDataArchived', payload: usersDataArchived});
                }

                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }
    function archiveRole(userRole){
        setCurrent(userRole);
        axios.put(`/user-role/archive/${userRole.id}`,).then(
            (res) => {
                getUsers();
                toast('Uspješno ste arhivirali korisničku ulogu.');
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function activateRole(userRole){
        setCurrent(userRole);
        axios.put(`/user-role/activate/${userRole.id}`,).then(
            (res) => {
                getUsers();
                toast('Uspješno ste aktivirali korisničku ulogu.');
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizerId'){
            localStorage.setItem('organizerId', value.id);
            getUsers()
        }

    }

    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Spisak korisnika </Header>
                        </Menu.Item>
                       <Menu.Menu position={'right'}>
                           {!showAddForm && <Menu.Item>
                                {/*<Link to='/polaznici/kreiranje' state={{ add: true}}>*/}
                                <Button icon labelPosition='left' size="mini" basic color="black"
                                        onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Kreiraj korisnika
                                </Button>
                                {/*</Link>*/}
                            </Menu.Item>}
                            <Menu.Item>
                                <ExcelFile filename="Spisak korisnika" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={state.activeIndex === 0 ? multiDataSet : multiDataSetArchived} name="Korisnici" />
                                </ExcelFile>
                            </Menu.Item>
                           <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                           </Menu.Item>
                        </Menu.Menu>

                    </Menu>

                </Segment>

                <Segment>
                {showAddForm ?
                    edit ?
                        <UserForm
                        edit
                        userRole={state.current}
                        toggleAddForm={toggleAddForm}
                        getUsers={getUsers}
                        setEdit={setEdit}
                        organizers={state.organizers}
                        />
                        :
                        <UserForm
                        toggleAddForm={toggleAddForm}
                        getUsers={getUsers}
                        setEdit={setEdit}
                        organizers={state.organizers}
                        />
                    :
                    <Form size={'small'}>
                    <Form.Group widths="3">
                        <Form.Field>
                            <label>Organizator</label>
                            <Form.Dropdown
                                clearable
                                placeholder="Odaberite organizatora"
                                value={state.organizerId}
                                onChange={handleInputChange}
                                name="organizerId"
                                options={state.organizers}
                                search
                                fluid
                                selection
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                }</Segment>
                <Segment>

                    {state.loader ?
                        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                        : <Tab
                            // menu={{secondary: true, pointing: true, color: 'teal'}}
                            menu={{
                                secondary: true,
                                pointing: true,
                                color: 'teal',
                                fluid: true,
                                attached: true,
                                tabular: true
                            }}
                            grid={{paneWidth: 13, tabWidth: 3}}
                            panes={panes}
                            activeIndex={state.activeIndex} onTabChange={handleTabChange} />
                        }
                </Segment>
            </Segment.Group>
            <ToastContainer/>

        </div>
    );
};

export default Users;
