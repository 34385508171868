import React, {useContext, useEffect, useReducer, useState} from 'react';
import {UserRoleContext} from "../../Main";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {Button, Header, Icon, Loader, Menu, Segment} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import DocumentSuppliesForm from "./DocumentSuppliesForm";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'supplies':
            return {...state, supplies: action.payload};
        default:
            return state;

    }
};
const DocumentSupplies = () => {
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        supplies: [1],
        currentId: null,

    });

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function deleteSupply(id){
        setCurrentId(id);
        dispatch({type: 'loader', payload: true});
        axios.delete(`/contest/delete/${id}`,).then(
            (res) => {
                // getSupplies();
                toast('Uspješno ste izbrisali obrazac!');
                setCurrentId(null);
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getSupplies(){}

    useEffect(() => {
        //getSupplies();
    },[activeRole]);

    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Zalihe obrazaca </Header>
                        </Menu.Item>
                        {!showEditForm && <Menu.Menu position={'right'}>
                            <Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Dodaj zalihe
                                </Button>
                            </Menu.Item>
                        </Menu.Menu>}

                    </Menu>

                </Segment>

                {showAddForm &&
                <Segment>
                    <DocumentSuppliesForm add toggleEditForm={toggleAddForm} getSupplies={getSupplies}/>
                </Segment>}

                {showEditForm &&
                <Segment>
                    <DocumentSuppliesForm toggleEditForm={toggleEditForm} getSupplies={getSupplies}/>
                </Segment>}


                <Segment>
                    {state.loader ?
                        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                        :
                        state.supplies && state.supplies.length ?
                            <Table celled size={'small'}>
                                <Table.Header>
                                    <Table.Row>

                                        <Table.HeaderCell width={1} >R. br.</Table.HeaderCell>
                                        <Table.HeaderCell colSpan={2} >Naziv obrasca</Table.HeaderCell>
                                        <Table.HeaderCell >Količina</Table.HeaderCell>
                                        <Table.HeaderCell />
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        state.supplies.map((el, index)=>
                                            (<Table.Row key={el.id}>
                                                <Table.Cell>{index+1}.</Table.Cell>
                                                <Table.Cell colSpan={2} />
                                                <Table.Cell/>
                                                {/*<Table.Cell colSpan={2}>{el.studyProgram.organizerOccupation.organizer.name}</Table.Cell>*/}
                                                {/*<Table.Cell><strong>{el.studyProgram.name}</strong> | {el.studyProgram.organizerOccupation.occupation.nameBosnian}</Table.Cell>*/}
                                                {/*<Table.Cell>{moment(el.openFromDate).format('D. M. YYYY.')}</Table.Cell>*/}
                                                {/*<Table.Cell>{moment(el.openToDate).format('D. M. YYYY.')}</Table.Cell>*/}
                                                {/*<Table.Cell>{moment(el.created).format('D. M. YYYY.')}</Table.Cell>*/}
                                                {/*<Table.Cell>{el.placeOfPublication}</Table.Cell>*/}
                                                <Table.Cell verticalAlign="middle" textAlign="center">
                                                    <Popup size={"small"} content='Izmijeni podatke' trigger={
                                                        <Button icon size={"mini"} basic color={"grey"} onClick={() => {setShowEditForm(true);}}>
                                                            <Icon name='pencil' color={"teal"} />
                                                        </Button>} />
                                                    <Popup size={"small"} content='Obriši' trigger={
                                                        <Button icon size={"mini"} basic color={"grey"}
                                                             disabled onClick={() => {deleteSupply(el.id)}}
                                                        >
                                                            <Icon name='trash alternate outline' color={"red"} />
                                                        </Button>} />
                                                </Table.Cell>
                                            </Table.Row>)
                                        )
                                    }


                                </Table.Body>

                                <Table.Footer>
                                    {/*<Table.Row>*/}
                                    {/*    <Table.HeaderCell colSpan='11' textAlign='center'>*/}
                                    {/*        <Menu  pagination>*/}
                                    {/*            <Menu.Item as='a' icon>*/}
                                    {/*                <Icon name='chevron left' />*/}
                                    {/*            </Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>1</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>2</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>3</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>4</Menu.Item>*/}
                                    {/*            <Menu.Item as='a' icon>*/}
                                    {/*                <Icon name='chevron right' />*/}
                                    {/*            </Menu.Item>*/}
                                    {/*        </Menu>*/}
                                    {/*    </Table.HeaderCell>*/}
                                    {/*</Table.Row>*/}
                                </Table.Footer>
                            </Table> : <div>Nema dodanih zaliha.</div>}

                </Segment>
            </Segment.Group>
            <ToastContainer  />
        </div>
    );
};

export default DocumentSupplies;
