import React, {useContext, useReducer, useState} from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {Form, Header, HeaderContent, Input, Menu, Message, Modal} from "semantic-ui-react";
import moment from "moment";
import {DateTimePicker} from "react-widgets";
import {UserRoleContext} from "../../Main";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import StudyProgramForm from "./StudyProgramForm";
import {toast, ToastContainer} from "react-toastify";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'name':
            return {...state, name: action.payload};
        case 'cancelReason':
            return {...state, cancelReason: action.payload};
        default:
            return state;

    }
};
const StudyProgramInfo = ({studyProgram, verifyProgram, cancelProgram, unverifyProgram, getStudyProgram, setCurrentIdOrganizer}) => {
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        name: studyProgram ? studyProgram.name : '',
    });
    const [areYouSureForVerify, setAreYouSureForVerify] = useState(false);
    const [areYouSureForUnverify, setAreYouSureForUnverify] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [open, setOpen] = React.useState(false) // modal
    const [showEditForm, setShowEditForm ] = useState(false);
    const [cancelReasonErr, setCancelReasonErr] = useState(false);

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    let toggleAreYouSureForVerify = () =>{
        setAreYouSureForVerify(!areYouSureForVerify);
    };

    let toggleAreYouSureForUnverify = () =>{
        setAreYouSureForUnverify(!areYouSureForUnverify);
    };

    let toggleOpenForm = () =>{
        setOpenForm(!openForm);
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };


    function formValidation(){
        if (!state.cancelReason) {
            toast("Razlog je obavezan!");
            if (!state.cancelReason) { setCancelReasonErr(true); } else { setCancelReasonErr(false); }
            return false;
        }else{
           if(state.cancelReason.length < 3){
               toast("Unesite validan razlog. Razlog mora biti barem dužine 3 karaktera!");
               return false;
           }
        }
        return true;
    }

    return (
        <div>
            {studyProgram && showEditForm &&
                <StudyProgramForm edit toggleEditForm={toggleEditForm}  studyProgram={studyProgram} dontRefreshList setCurrentIdOrganizer={setCurrentIdOrganizer} getStudyProgram={getStudyProgram}/>
            }
            {studyProgram &&
            <Table celled size={"small"}>
                <Table.Body>
                    <Table.Row negative={!studyProgram.verified}>
                        <Table.Cell colSpan={'2'} >
                            {studyProgram.verified ? <i>Program je verifikovan.</i> : studyProgram.readyForVerification ?<i>Program je spreman za verifikaciju!</i> :<i>Program nije verifikovan!</i>}
                            {activeRole !== "ROLE_MINISTRY" && <Popup
                                    size={"small"}
                                       content={openForm?'Zatvori':'Izmijeni'}
                                       trigger={
                                           <Button
                                               disabled={studyProgram.verified || studyProgram.readyForVerification}
                                               floated={"right"}
                                               icon
                                               size={"tiny"}
                                               basic
                                               compact
                                                onClick={toggleEditForm}
                                               // onClick={toggleOpenForm}
                                           >
                                               <Icon name={openForm?'close': 'pencil'} color={openForm?'grey':"teal"} />
                                           </Button>
                                       }
                                />}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Naziv programa </Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                required
                                control={Input}
                                placeholder="Unesite broj"
                                value={state.name}
                                name="fileNumber"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.name}</Table.Cell>
                        }
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Broj rješenja </Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                required
                                control={Input}
                                placeholder="Unesite broj"
                                value={state.documentNumber}
                                name="documentNumber"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.documentNumber}</Table.Cell>
                        }
                    </Table.Row><Table.Row>
                        <Table.Cell> <Table.HeaderCell> Datum rješenja </Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}>

                                <Form.Field
                                    required
                                    control={DateTimePicker}
                                    format="D. M. YYYY."
                                    placeholder="Unesite datum"
                                    name="documentDate"
                                    value={state.documentDate}
                                    time={false}
                                    onChange={(e) => handleDateChange('documentDate', e)}
                                    style={{ borderWidth: '0px' }}
                                />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.documentDate && moment(studyProgram.documentDate).format('D. M. YYYY.')}</Table.Cell>
                        }
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Datum početka izvođenja programa </Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}>
                                <Form.Field
                                    required
                                    control={DateTimePicker}
                                    format="D. M. YYYY."
                                    placeholder="Unesite datum"
                                    name="startDate"
                                    value={state.startDate}
                                    time={false}
                                    onChange={(e) => handleDateChange('startDate', e)}
                                    style={{ borderWidth: '0px' }}
                                />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.startDate && moment(studyProgram.startDate).format('D. M. YYYY.')}</Table.Cell>
                        }
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Datum kraja izvođenja programa</Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}>
                                <Form.Field
                                    required
                                    control={DateTimePicker}
                                    format="D. M. YYYY."
                                    placeholder="Unesite datum"
                                    name="endDate"
                                    value={state.endDate}
                                    time={false}
                                    onChange={(e) => handleDateChange('endDate', e)}
                                    style={{ borderWidth: '0px' }}
                                />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.endDate && moment(studyProgram.endDate).format('D. M. YYYY.')}</Table.Cell>
                        }
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Vrsta obrazovanja </Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                required
                                control={Input}
                                placeholder="Unesite vrstu"
                                value={state.supplementaryEducationType}
                                name="fileNumber"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.supplementaryEducationType.name}</Table.Cell>
                        }
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Zanimanje </Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                required
                                control={Input}
                                placeholder="Unesite broj"
                                value={state.organizerOccupation}
                                name="fileNumber"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.organizerOccupation.occupation.nameBosnian}</Table.Cell>
                        }
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Stečeni stepen stručne spreme </Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                required
                                control={Input}
                                placeholder="Unesite broj"
                                value={state.educationLevel}
                                name="fileNumber"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.educationLevel.name}</Table.Cell>
                        }
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Ukupan broj nastavnih sati  </Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                required
                                control={Input}
                                placeholder="Unesite broj"
                                value={state.durationHours}
                                name="durationHours"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.durationHours}</Table.Cell>
                        }
                    </Table.Row>
                    {studyProgram && studyProgram.supplementaryEducationType.formal && <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Broj razreda (trajanje nastavnog programa)</Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                required
                                control={Input}
                                placeholder="Unesite broj"
                                value={state.duration}
                                name="duration"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.duration}</Table.Cell>
                        }
                    </Table.Row>}
                    {studyProgram && studyProgram.supplementaryEducationType.formal &&<Table.Row>
                        <Table.Cell> <Table.HeaderCell> Trajanje jednog razreda u mjesecima </Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                required
                                control={Input}
                                placeholder="Unesite broj"
                                value={state.gradeDurationMonths}
                                name="gradeDurationMonths"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.gradeDurationMonths}</Table.Cell>
                        }
                    </Table.Row>
                    }
                     <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Minimalni broj mjeseci za realizaciju programa </Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                required
                                control={Input}
                                placeholder="Unesite broj"
                                value={state.durationMonths}
                                name="durationMonths"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.durationMonths}</Table.Cell>
                        }
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Napomena </Table.HeaderCell></Table.Cell>


                        {openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                required
                                control={Input}
                                placeholder="Napomena"
                                value={state.comment}
                                name="comment"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                            </Form></Table.Cell>:
                            <Table.Cell >{studyProgram && studyProgram.comment}</Table.Cell>
                        }
                    </Table.Row>
                    {!studyProgram.verified && studyProgram.declineVerificationReason && <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Razlog odbijanja verifikacije </Table.HeaderCell></Table.Cell>
                        <Table.Cell>{studyProgram && studyProgram.declineVerificationReason}</Table.Cell>
                    </Table.Row>}
                    {/* <Table.Row>*/}
                    {/*    <Table.Cell> <Table.HeaderCell> Verifikacija </Table.HeaderCell></Table.Cell>*/}


                    {/*    {openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field*/}
                    {/*            required*/}
                    {/*            control={Input}*/}
                    {/*            placeholder="Unesite broj"*/}
                    {/*            value={state.verifiedBy}*/}
                    {/*            name="verifiedBy"*/}
                    {/*            onChange={handleInputChange}*/}
                    {/*            // error={this.state.jmbgErr}*/}
                    {/*        />*/}
                    {/*        </Form></Table.Cell>:*/}
                    {/*        <Table.Cell >{studyProgram && studyProgram.verifiedBy}</Table.Cell>*/}
                    {/*    }*/}
                    {/*</Table.Row>*/}
                    {studyProgram.verified && !openForm && <Table.Row>
                        <Table.Cell> <Table.HeaderCell> Datum verifikacije u sistemu </Table.HeaderCell></Table.Cell>
                            <Table.Cell >{studyProgram && moment(studyProgram.verificationDate).format('D. M. YYYY.') }</Table.Cell>
                    </Table.Row>}



                    {(activeRole === "ROLE_MINISTRY" || activeRole === "ROLE_ADMIN") && <Table.Row >
                        <Table.Cell colSpan={openForm ? 3: 2} textAlign="right">
                            {studyProgram.verified ?
                                !areYouSureForVerify ?
                                    <Button icon labelPosition='left' size="mini" basic color="black"  onClick={toggleAreYouSureForVerify}>
                                        <Icon name='attention' color={"red"}/>
                                        Poništi verifikaciju
                                    </Button> :
                                    <>
                                        <Header as={'h5'}> Da li ste sigurni? </Header>
                                        <Button size={"mini"} primary color={"teal"} onClick={()=>{setAreYouSureForVerify(!areYouSureForVerify); unverifyProgram()}}>Da</Button>
                                        <Button size={"mini"} basic color={"black"} onClick={toggleAreYouSureForVerify}>Ne</Button>
                                    </> :
                                    !areYouSureForUnverify ?
                                        <>
                                            {(activeRole === "ROLE_ADMIN" || studyProgram.readyForVerification) && <Button icon labelPosition='left' size="mini" basic color="black"
                                                     onClick={toggleAreYouSureForUnverify}>
                                                <Icon name='check circle' color={"blue"}/>
                                                Verifikuj program
                                            </Button>}
                                            {studyProgram.readyForVerification && <Modal
                                                dimmer={'inverted'}
                                                open={open}
                                                trigger={
                                                    <Button icon labelPosition='left' size="mini" basic color="black">
                                                        <Icon name='attention' color={"red"}/>
                                                        Odbij verifikaciju
                                                    </Button>
                                                }
                                                onClose={() => setOpen(false)}
                                                onOpen={() => setOpen(true)}
                                            >
                                                <Header color={'red'} icon='attention' content='Odbij verifikaciju'/>
                                                <Modal.Content>
                                                    <Form size={'large'}>
                                                        <Form.TextArea
                                                            required
                                                            error={cancelReasonErr}
                                                            onChange={handleInputChange}
                                                            value={state.cancelReason}
                                                            name="cancelReason"
                                                            label='Razlog odbijanja verifikacije'
                                                            placeholder='Navedite razlog zbog kojeg se odbija verifikacija...'
                                                        />
                                                    </Form>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button color='black' basic onClick={() => {
                                                        setOpen(false);
                                                    }}>
                                                        Odustani
                                                    </Button>
                                                    <Button color='red' basic onClick={() => {
                                                        if (formValidation()){
                                                        cancelProgram(state.cancelReason);
                                                        setOpen(false)
                                                        }
                                                    }}>
                                                        Odbij
                                                    </Button>
                                                </Modal.Actions>
                                            </Modal>}

                                        </>

                                        :
                                            <>
                                                <Header as={'h5'}> Da li ste sigurni? </Header>
                                                <Button size={"mini"} primary color={"teal"} onClick={()=>{setAreYouSureForUnverify(!areYouSureForUnverify); verifyProgram()} }>Da</Button>
                                                <Button size={"mini"} basic color={"black"} onClick={toggleAreYouSureForUnverify}>Ne</Button>
                                            </>
                                }
                        </Table.Cell>

                    </Table.Row>}

                </Table.Body>
                <ToastContainer/>
            </Table>}
        </div>
    );
};

export default StudyProgramInfo;
