import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Form, Grid, GridColumn, GridRow, Header, Input, Loader, Menu, Segment, SegmentGroup} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import moment from "moment";
import {DateTimePicker} from "react-widgets";
import axios from "axios";
import {UserRoleContext} from "../../../Main";
import {toast, ToastContainer} from "react-toastify";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import _ from "lodash";
import AddRecognizedCourse from "./AddRecognizedCourse";
import {extractFileNameFromHeaders} from "../../Utilities/Util";
import download from "downloadjs";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'fileNumber':
            return {...state, fileNumber: action.payload};
        case 'teachersCouncilFileNumber':
            return {...state, teachersCouncilFileNumber: action.payload};
        case 'teachersCouncilFileDate':
            return {...state, teachersCouncilFileDate: action.payload};
        case 'fileDate':
            return {...state, fileDate: action.payload};
        case 'languages':
            return {...state, languages: action.payload};
        case 'language':
            return {...state, language: action.payload};
        case 'secondLanguage':
            return {...state, secondLanguage: action.payload};
        case 'committee':
            return {...state, committee: action.payload};
        case 'committeeOptions':
            return {...state, committeeOptions: action.payload};
        case 'recognizedDiplomaNumber':
            return {...state, recognizedDiplomaNumber: action.payload};
        case 'recognizedDiplomaDate':
            return {...state, recognizedDiplomaDate: action.payload};
        case 'recognition':
            return {...state, recognition: action.payload};
        case 'openForm':
            return {...state, openForm: action.payload};
        case 'showNoRecognitionMessage':
            return {...state, showNoRecognitionMessage: action.payload};
        case 'previousEducation':
            return {...state, previousEducation: action.payload};
        case 'grade':
            return {...state, grade: action.payload};
        case 'gradeOptions':
            return {...state, gradeOptions: action.payload};
        case 'recognizedGrades':
            return {...state, recognizedGrades: action.payload};
        case 'gradeDiplomaNumber':
            return {...state, gradeDiplomaNumber: action.payload};
        case 'gradeDiplomaDate':
            return {...state, gradeDiplomaDate: action.payload};
        case 'comment':
            return {...state, comment: action.payload};
        case 'courses':
            return {...state, courses: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        default:
            return state;

    }
};

const RecognizedCourseDocument = () => {
    const organizerId = localStorage.getItem('organizerId');
    const lastCompletedGrade = JSON.parse(localStorage.getItem('lastCompletedGrade')).number;
    const personId = localStorage.getItem('personId');
    const sspId = localStorage.getItem('sspId');
    const studyProgramId = localStorage.getItem('studyProgramId');
    const duration = localStorage.getItem('duration');
    const gradDate = localStorage.getItem('gradDate');
    const droppedDate = localStorage.getItem('droppedDate');

    const [edit, setEdit] = useState(false);

    const [fileNumberErr, setFileNumberErr] = useState(false);
    const [fileDateErr, setFileDateErr] = useState(false);
    const [teachersCouncilFileNumberErr, setTeachersCouncilFileNumberErr] = useState(false);
    const [teachersCouncilFileDateErr, setTeachersCouncilFileDateErr] = useState(false);
    const [committeeErr, setCommitteeErr] = useState(false);

    const [state, dispatch] = useReducer(reducer,{
        teachersCouncilFileNumber: null,
        fileDate: new Date(),
        teachersCouncilFileDate: new Date(),
        fileNumber: null,
        languages:[],
        gradeOptions:[],
        committeeOptions:[],
        recognizedGrades:[],
        recognizedDiplomaNumber: null,
        recognizedDiplomaDate: new Date(),
        gradeDiplomaDate: new Date(),
        language: null,
        secondLanguage: null,
        openForm: true,
        previousEducation: null,
        grade: null,
        comment: null,
        gradeDiplomaNumber: null,
        showNoRecognitionMessage: false,
        currentId: null,
        loader: true
    });
    // const location = useLocation();
    // const {name, sspId, personId} = location.state;
    const [openGradeForm, setOpenGradeForm] = useState(false);
    const [segments, setSegments] = useState(false);
    const [areYouSure, setAreYouSure] = useState(false);

    const [gradeErr, setGradeErr] = useState(false);
    const [gradeDiplomaNumberErr, setGradeDiplomaNumberErr] = useState(false);
    const [gradeDiplomaDateErr, setGradeDiplomaDateErr] = useState(false);

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    let toggleOpenGradeForm = () =>{
        setOpenGradeForm(!openGradeForm);
        if (!openGradeForm) getGrades();
    };

    function getGrades(){

        axios.get('/grade/all',).then(
            (res) => {
                let grades = res.data.sort((a, b) => a.number - b.number).map((el)=>({...el, key: el.id, text:el.name, value: el.id})).filter((e)=>(e.number<=lastCompletedGrade ));
                dispatch({type: 'gradeOptions', payload: grades});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

// for first form
    function getLookups() {
        if (activeRole === "ROLE_ORGANIZER") {getCommittee();}
        else {getCommitteeForAdmin()}
        // getCommittee();
        axios.get('/language/all',).then(
            (res) => {
                let languageOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type: 'languages', payload: languageOptions});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function openForm(){
        // setEdit(true);
        dispatch({type: 'fileNumber', payload: state.recognition.fileNumber});
        dispatch({type: 'fileDate', payload: new Date(state.recognition.fileDate)});
        dispatch({type: 'teachersCouncilFileNumber', payload: state.recognition.teachersCouncilFileNumber});
        dispatch({type: 'teachersCouncilFileDate', payload:  new Date(state.recognition.teachersCouncilFileDate)});
        dispatch({type: 'committee', payload: state.recognition.committee});

        dispatch({type: 'openForm', payload: true});
        getLookups();
    }

    function showNoRecognitionMessage(){
        dispatch({type: 'showNoRecognitionMessage', payload: true});
    }

    function getPreviousEducation() {

        axios.get(`/previous-education/by-person-id/${personId}`,).then(
            (res) => {
                let previousEducation = res.data[0];
                dispatch({type: 'previousEducation', payload: previousEducation});
            }).catch((err) => {
            console.log('err', err)
        });

    }

    function deleteRecognizedGrade(id){
        setCurrentId(id);

        axios.delete(`/recognized-grade/delete/${id}`,).then(
            (res) => {
                getRecognizedGrades(state.recognition.id);
                toast('Uspješno ste izbrisali dokument!');
                setCurrentId(null);

            }).catch((err) => {
            console.log('err', err)
        });
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function getRecognizedGrades(recognitionId) {
        axios.get(`/recognized-grade/by-recognition/${recognitionId}`,).then(
            (res) => {
                let recognizedGrades = res.data.sort((a, b) => a.grade.number - b.grade.number);
                setAreYouSure(false);
                dispatch({type: 'recognizedGrades', payload: recognizedGrades});
            }).catch((err) => {
            console.log('err', err)
        });

    }

    function getRecognition() {
        dispatch({type: 'loader', payload: true});
        axios.get(`/recognition/by-student-study-program/${sspId}`,).then(
            (res) => {

                if (res.data.id === null){
                    setSegments(false);
                    activeRole === "ROLE_ORGANIZER" && openForm();
                    activeRole === "ROLE_MINISTRY" && showNoRecognitionMessage();
                    activeRole === "ROLE_ADMIN" && openForm();
                }else{
                    setSegments(true);
                    getRecognizedGrades(res.data.id);
                    dispatch({type: 'recognition', payload: res.data});
                    dispatch({type: 'openForm', payload: false});
                }
                dispatch({type: 'loader', payload: false});

            }).catch((err) => {
            dispatch({type: 'loader', payload: false});
            console.log('err', err)
        });
    }

    const {activeRole} = useContext(UserRoleContext);
    useEffect(() => {
        getPreviousEducation();
        getRecognition();
        getLookups();

    },[activeRole]);

    function getCommitteeForAdmin(){
        axios.get(`/committee/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let committees = res.data.map((el)=>({key: el.key, text: (el.name), description: el.firstMember.person.lastName + ', ' + el.secondMember.person.lastName + ', ' +  el.thirdMember.person.lastName, value: el}));
                dispatch({type: 'committeeOptions', payload: committees});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getCommittee(){
        axios.get('/committee/by-organizer',).then(
            (res) => {
                let committees = res.data.map((el)=>({key: el.key, text: (el.name), description: el.firstMember.person.lastName + ', ' + el.secondMember.person.lastName + ', ' +  el.thirdMember.person.lastName, value: el}));
                dispatch({type: 'committeeOptions', payload: committees});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function addRecognition(){
        if (!state.fileNumber) {setFileNumberErr(true);} else {setFileNumberErr(false);}
        if (!state.fileDate) {setFileDateErr(true);} else {setFileDateErr(false);}
        if (!state.teachersCouncilFileNumber) {setTeachersCouncilFileNumberErr(true);} else {setTeachersCouncilFileNumberErr(false);}
        if (!state.teachersCouncilFileDate) {setTeachersCouncilFileDateErr(true);} else {setTeachersCouncilFileDateErr(false);}
        if (!state.committee) {setCommitteeErr(true);} else {setCommitteeErr(false);}

        if(!state.fileNumber || !state.fileDate || !state.teachersCouncilFileNumber || !state.teachersCouncilFileDate || !state.committee){
            toast('Niste unijeli sve podatke.');
        }else{
            let obj = {
                studentStudyProgram: {id: localStorage.getItem('sspId')},
                fileNumber: state.fileNumber,
                fileDate: state.fileDate,
                teachersCouncilFileNumber: state.teachersCouncilFileNumber,
                teachersCouncilFileDate: state.teachersCouncilFileDate,
                // foreignLanguage: state.languages[0],
                // otherForeignLanguage:state.languages[0],
                committee:  state.committee
            };
            if(edit){
                obj = {...obj, optLock: state.recognition.optLock, id: state.recognition.id}
                axios.put('/recognition/edit', {...obj}).then(
                    (res) => {
                        getRecognition();
                        toast('Uspješno ste izmijenili podatke.');

                    }).then(()=>{
                        setEdit(false);
                })
                    .catch((err) => {
                    console.log('err', err)
                });
            }else{
                axios.post('/recognition/save', {...obj}).then(
                    (res) => {
                        getRecognition();
                        toast('Uspješno ste dodali podatke.');

                    }).catch((err) => {
                    console.log('err', err)
                });
            }

        }


    }

    function addRecognizedGrade() {
        if (!state.grade) {setGradeErr(true);} else {setGradeErr(false);}
        if (!state.gradeDiplomaNumber) {setGradeDiplomaNumberErr(true);} else {setGradeDiplomaNumberErr(false);}
        if (!state.gradeDiplomaDate) {setGradeDiplomaDateErr(true);} else {setGradeDiplomaDateErr(false);}

        if(!state.grade || !state.gradeDiplomaNumber || !state.gradeDiplomaDate){
            toast('Niste unijeli sve podatke!')
        } else {
            let obj = {
                recognition: state.recognition,
                grade: {id: state.grade},
                gradeDiplomaNumber: state.gradeDiplomaNumber,
                gradeDiplomaDate: state.gradeDiplomaDate,
                comment: state.comment,
            };
            axios.post('/recognized-grade/save', {...obj}).then(
                (res) => {
                    getRecognizedGrades(state.recognition.id);
                    toggleOpenGradeForm();
                    toast('Uspješno ste dodali podatke.');

                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    // useEffect(() => {
    //     getCourses(studyProgramId);
    // },[duration]);

    function returnNTimes(n) {

        return _.times(n);
    }

    // function getCourses(id){
    //     // dispatch({type: 'loader', payload: true});
    //     dispatch({type: 'openTable', payload: true});
    //     axios.get(`/course-study-program/by-study-program/${id}`,).then(
    //         (res) => {
    //             let courses = res.data;
    //             dispatch({type: 'courses', payload: courses});
    //             // dispatch({type: 'loader', payload: false});
    //         }).catch((err) => {
    //         console.log('err', err)
    //     });
    // }

    function downloadRecognitionInformationForm(){
        axios({
            method: 'post',
            url: `/document/recognition-information-form/${sspId}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });
    }

    return (
        <div>

            <SegmentGroup >
                <Segment className={'customPadding'} clearing color={"teal"}>
                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>
                        <Menu.Item ><Header as={'h3'} floated={"left"}>
                            { localStorage.getItem('name')}
                            <span>
                            <Icon/></span>
                            <Header.Subheader>
                                <i>Rješenje o razlici i dodatnim predmetima u programu prekvalifikacije</i>
                            </Header.Subheader>
                        </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            <Menu.Item>
                            <span>
                                <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                    <Icon name='arrow left'/>
                                </Button>
                                <Icon/>
                            </span>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Segment>
                <Segment clearing>
                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>
                    <Menu.Menu position={'right'}>
                    <Menu.Item>
                        <Button icon labelPosition='left' size="mini" basic color="black" onClick={downloadRecognitionInformationForm}>
                            <Icon name='file pdf outline' color={"green"} />
                            Rješenje o razlici predmeta
                        </Button>
                    </Menu.Item>
                    </Menu.Menu>
                    </Menu>
                </Segment>
                <Segment>
                    <Grid columns={2} stackable>
                        <GridRow>
                            <GridColumn>
                                {state.loader?<div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                                    :<Table celled size={"small"}>
                                        <Table.Header>
                                            <Table.HeaderCell colSpan={state.openForm?2:1}> Rješenje o razlici predmeta </Table.HeaderCell>
                                            {!state.openForm && <Table.HeaderCell textAlign="center" >
                                                <Popup size={"small"}
                                                       content='Izmijeni'
                                                       trigger={
                                                           <Button
                                                               icon
                                                               disabled={(gradDate !== 'null' && activeRole==="ROLE_ORGANIZER") || (droppedDate !== 'null' && activeRole==="ROLE_ORGANIZER")}
                                                               size={"tiny"}
                                                               basic
                                                               compact
                                                               onClick={() =>{openForm(); setEdit(true);}}
                                                           >
                                                               <Icon name='pencil' color={"teal"} />
                                                           </Button>
                                                       }
                                                />
                                            </Table.HeaderCell>}

                                        </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell> <Table.HeaderCell> Djelovodni broj rješenja</Table.HeaderCell></Table.Cell>


                                            {state.openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                                required
                                                control={Input}
                                                placeholder="Unesite broj"
                                                value={state.fileNumber}
                                                name="fileNumber"
                                                onChange={handleInputChange}
                                                error={fileNumberErr}
                                            />
                                            </Form></Table.Cell>:
                                                <Table.Cell >{state.recognition && state.recognition.fileNumber}</Table.Cell>
                                            }
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell><Table.HeaderCell> Datum rješenja</Table.HeaderCell></Table.Cell>


                                            {state.openForm ? <Table.Cell >
                                                <Form size={'tiny'}>
                                                    <Form.Field
                                                        required
                                                        error={fileDateErr}
                                                        control={DateTimePicker}
                                                        format="D. M. YYYY."
                                                        placeholder="Unesite datum"
                                                        name="fileDate"
                                                        value={state.fileDate}
                                                        time={false}
                                                        onChange={(e) => handleDateChange('fileDate', e)}
                                                        style={{ borderWidth: '0px' }}
                                                    />
                                                </Form>
                                            </Table.Cell>: <Table.Cell>{state.recognition && moment(state.recognition.fileDate).format('D. M. YYYY.')}</Table.Cell>}
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell> <Table.HeaderCell>Broj odluke Nastavničkog vijeća </Table.HeaderCell></Table.Cell>

                                            {state.openForm ? <Table.Cell>
                                                <Form size={'tiny'}><Form.Field
                                                required
                                                control={Input}
                                                placeholder="Unesite broj"
                                                value={state.teachersCouncilFileNumber}
                                                name="teachersCouncilFileNumber"
                                                onChange={handleInputChange}
                                                error={teachersCouncilFileNumberErr}
                                                />
                                                </Form>
                                            </Table.Cell>:
                                                <Table.Cell >{state.recognition && state.recognition.teachersCouncilFileNumber}</Table.Cell>
                                            }

                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell><Table.HeaderCell> Datum donošenja odluke</Table.HeaderCell></Table.Cell>


                                            {state.openForm ? <Table.Cell>
                                                <Form size={'tiny'}>

                                                        <Form.Field
                                                            required
                                                            control={DateTimePicker}
                                                            format="D. M. YYYY."
                                                            placeholder="Unesite datum"
                                                            name="teachersCouncilFileDate"
                                                            value={state.teachersCouncilFileDate}
                                                            time={false}
                                                            onChange={(e) => handleDateChange('teachersCouncilFileDate', e)}
                                                            style={{ borderWidth: '0px' }}
                                                            error={teachersCouncilFileDateErr}
                                                        />

                                                </Form>
                                            </Table.Cell>:
                                                <Table.Cell>{state.recognition && moment(state.recognition && state.recognition.teachersCouncilFileDate).format('D. M. YYYY.')}</Table.Cell>
                                            }
                                        </Table.Row>

                                        {state.openForm && <Table.Row>
                                            <Table.Cell> <Table.HeaderCell>Komisija</Table.HeaderCell></Table.Cell>

                                           <Table.Cell>
                                                <Form size={'tiny'}>
                                                    <Form.Field required>
                                                        <Form.Dropdown
                                                            error={committeeErr}
                                                            clearable
                                                            placeholder="Odaberite komisiju"
                                                            value={state.committee}
                                                            onChange={handleInputChange}
                                                            name="committee"
                                                            options={state.committeeOptions}
                                                            search
                                                            fluid
                                                            selection
                                                        />
                                                    </Form.Field>
                                                </Form>
                                            </Table.Cell>
                                        </Table.Row>
                                        }
                                        {state.openForm && <Table.Row >
                                            <Table.Cell colSpan={state.openForm ? 3: 2} textAlign="right"> <Button size={"mini"} basic color={"teal"} onClick={addRecognition}>Sačuvaj</Button>
                                                <Button disabled={!state.recognition} size={"mini"} basic color={"black"} onClick={()=>{ dispatch({type: 'openForm', payload: false});}}>Odustani</Button></Table.Cell>

                                        </Table.Row>}

                                    </Table.Body>

                                </Table>}
                                {!state.loader && state.recognition && state.recognition.committee && <Table celled size={"small"}>
                                    <Table.Header>
                                         <Table.HeaderCell colSpan={2}> Komisija</Table.HeaderCell>


                                    </Table.Header>
                                    <Table.Body>

                                        <Table.Row>
                                            <Table.Cell> <Table.HeaderCell> Prvi član komisije</Table.HeaderCell></Table.Cell>

                                            <Table.Cell width={10}>{state.recognition.committee.firstMember.person.firstName + ' ' + state.recognition.committee.firstMember.person.lastName}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell><Table.HeaderCell> Drugi član komisije</Table.HeaderCell></Table.Cell>


                                            <Table.Cell>{state.recognition.committee.secondMember.person.firstName + ' ' + state.recognition.committee.secondMember.person.lastName}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell> <Table.HeaderCell>Treći član komisije</Table.HeaderCell></Table.Cell>


                                            <Table.Cell>{state.recognition.committee.thirdMember.person.firstName + ' ' + state.recognition.committee.thirdMember.person.lastName}</Table.Cell>
                                            {/*<Table.Cell>{authPerson && moment(authPerson.documentDate).format('D. M. YYYY.')}</Table.Cell>*/}
                                        </Table.Row>
                                        {/*<Table.Row>*/}
                                        {/*    <Table.Cell> <Table.HeaderCell>Rezervni član komisije</Table.HeaderCell></Table.Cell>*/}

                                        {/*    <Table.Cell>{state.recognition.committee.reserveMember && state.recognition.committee.reserveMember.person.firstName + ' ' + state.recognition.committee.reserveMember.person.lastName}</Table.Cell>*/}
                                        {/*</Table.Row>*/}
                                    </Table.Body>
                                </Table>}
                            </GridColumn>

                            <GridColumn>
                                {!state.loader && segments && state.previousEducation && <Table celled size={"small"}>
                                    <Table.Header>

                                            <Table.HeaderCell colSpan={2}> Dokument </Table.HeaderCell>

                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell> <Table.HeaderCell> Broj dokumenta </Table.HeaderCell></Table.Cell>

                                            <Table.Cell width={10}>{state.previousEducation.documentNumber}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell> <Table.HeaderCell> Tip dokumenta </Table.HeaderCell></Table.Cell>

                                            <Table.Cell width={10}>{state.previousEducation.documentType.name}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.Cell><Table.HeaderCell> Datum izdavanja diplome</Table.HeaderCell></Table.Cell>

                                            <Table.Cell>{state.previousEducation.documentDate && moment(state.previousEducation.documentDate).format('D. M. YYYY.')}</Table.Cell>

                                        </Table.Row>
                                    </Table.Body>
                                </Table>}
                                { !state.loader && segments && <Table celled size={"small"}>
                                    <Table.Header>
                                        <Table.HeaderCell colSpan={3}>Dokumenti na osnovu kojih se vrši priznavanje</Table.HeaderCell>
                                        <Table.HeaderCell >
                                            {!openGradeForm &&
                                            <Popup size={"small"}
                                                   content='Dodaj podatke o svjedočanstvu'
                                                   trigger={
                                                       <Button
                                                           icon
                                                           size={"tiny"}
                                                           basic
                                                           compact
                                                           disabled={(gradDate !== 'null' && activeRole==="ROLE_ORGANIZER") || (droppedDate !== 'null' && activeRole==="ROLE_ORGANIZER")}
                                                           onClick={toggleOpenGradeForm}
                                                       >
                                                           <Icon name='add' color={"teal"} />
                                                       </Button>
                                                   }
                                            />
                                                // <Button  size={"tiny"} color={"teal"} circular as={Icon} icon={'add'} basic onClick={toggleOpenGradeForm}/>

                                            }
                                        </Table.HeaderCell>
                                    </Table.Header>
                                    <Table.Body>
                                        {segments && (state.recognizedGrades.length !== 0 || openGradeForm) ? <><Table.Row>
                                            <Table.Cell width={4}> <Table.HeaderCell>Svjedočanstvo</Table.HeaderCell></Table.Cell>
                                            <Table.Cell width={4}> <Table.HeaderCell>Djelovodni broj</Table.HeaderCell></Table.Cell>
                                            <Table.Cell width={4} > <Table.HeaderCell>Datum</Table.HeaderCell></Table.Cell>
                                            <Table.Cell width={3}/>
                                        </Table.Row>
                                        {openGradeForm && <Table.Row>

                                             <Table.Cell width={4}>
                                                 <Form size={'tiny'}>
                                                     <Form.Field required>
                                                         <Form.Dropdown
                                                             //label={'Prikazuju se samo razredi do zadnje upisanog'}
                                                             error={gradeErr}
                                                             clearable
                                                             placeholder="Odaberite razred"
                                                             value={state.grade}
                                                             onChange={handleInputChange}
                                                             name="grade"
                                                             options={state.gradeOptions.filter((e)=>(!state.recognizedGrades.find((el)=>(el.grade.id === e.id))))}
                                                             search
                                                             fluid
                                                             selection
                                                         />
                                                     </Form.Field>
                                                 </Form>
                                            </Table.Cell>
                                            <Table.Cell width={4}>
                                                <Form size={'tiny'}><Form.Field
                                                    required
                                                    error={gradeDiplomaNumberErr}
                                                    control={Input}
                                                    placeholder="Unesite broj"
                                                    value={state.gradeDiplomaNumber}
                                                    name="gradeDiplomaNumber"
                                                    onChange={handleInputChange}
                                                    // error={this.state.jmbgErr}
                                                />
                                                </Form>
                                            </Table.Cell>
                                            <Table.Cell width={4}>
                                                <Form size={'tiny'}>

                                                    <Form.Field
                                                        required
                                                        error={gradeDiplomaDateErr}
                                                        control={DateTimePicker}
                                                        format="D. M. YYYY."
                                                        placeholder="Unesite datum"
                                                        name="gradeDiplomaDate"
                                                        value={state.gradeDiplomaDate}
                                                        time={false}
                                                        onChange={(e) => handleDateChange('gradeDiplomaDate', e)}
                                                        style={{ borderWidth: '0px' }}
                                                    />


                                                </Form>
                                            </Table.Cell>
                                            <Table.Cell textAlign="center" width={3}>
                                                <Form size={'tiny'} >
                                                    <Form.Field>
                                                        <Popup size={"small"}
                                                               content='Dodaj'
                                                               trigger={
                                                                   <Button
                                                                       icon
                                                                       size={"tiny"}
                                                                       basic

                                                                       compact
                                                                       onClick={addRecognizedGrade}
                                                                   >
                                                                       <Icon name='check' color={"teal"} />
                                                                   </Button>
                                                               }
                                                        />
                                                        <Popup size={"small"}
                                                               content='Odustani'
                                                               trigger={
                                                                   <Button
                                                                       icon
                                                                       size={"tiny"}
                                                                       basic
                                                                       compact
                                                                       onClick={toggleOpenGradeForm}
                                                                   >
                                                                       <Icon name='close' color={"black"} />
                                                                   </Button>
                                                               }
                                                        />


                                                        {/*<Button size={"tiny"} basic color={'teal'} circular as={Icon} icon={'check'} onClick={addRecognizedGrade}/>*/}
                                                        {/*<Button size={"tiny"} basic color={'black'} circular as={Icon} icon={'close'} onClick={toggleOpenGradeForm}/>*/}
                                                    </Form.Field>
                                                </Form>
                                            </Table.Cell>
                                        </Table.Row>}
                                        {
                                            state.recognizedGrades.map((el, index)=>
                                                ( <Table.Row>
                                                    <Table.Cell> <Table.HeaderCell>{el.grade.name} razred </Table.HeaderCell></Table.Cell>

                                                    <Table.Cell>{el.gradeDiplomaNumber}</Table.Cell>

                                                    <Table.Cell>{moment(el.gradeDiplomaDate).format('D. M. YYYY.')}</Table.Cell>
                                                    <Table.Cell textAlign="center" width={3}>
                                                        <Form size={'tiny'} >

                                                            {areYouSure && state.currentId === el.id ? <Form.Field>
                                                                    <Popup size={"small"}
                                                                           content='Obriši'
                                                                           trigger={
                                                                               <Button
                                                                                   icon
                                                                                   size={"tiny"}
                                                                                   basic
                                                                                   compact
                                                                                   onClick={() => {
                                                                                       deleteRecognizedGrade(el.id)
                                                                                   }}
                                                                               >
                                                                                   <Icon name='check' color={"red"}/>
                                                                               </Button>
                                                                           }
                                                                    />
                                                                    <Popup size={"small"}
                                                                           content='Odustani'
                                                                           trigger={
                                                                               <Button
                                                                                   icon
                                                                                   size={"tiny"}
                                                                                   basic
                                                                                   compact
                                                                                   onClick={()=>{
                                                                                       setCurrentId(null);
                                                                                       setAreYouSure(false);
                                                                                   }}
                                                                               >
                                                                                   <Icon name='close' color={"black"}/>
                                                                               </Button>
                                                                           }
                                                                    />
                                                                </Form.Field> :

                                                                <Form.Field>
                                                                    <Popup size={"small"}
                                                                           content='Obriši'
                                                                           trigger={
                                                                               <Button
                                                                                   disabled={state.currentId && el.id !== state.currentId}
                                                                                   icon
                                                                                   size={"tiny"}
                                                                                   basic
                                                                                   color={"gray"}
                                                                                   compact
                                                                                   onClick={() => {
                                                                                       setCurrentId(el.id);
                                                                                       setAreYouSure(true);
                                                                                   }}
                                                                               >
                                                                                   <Icon name='trash alternate outline'
                                                                                         color={"red"}/>
                                                                               </Button>
                                                                           }
                                                                    />

                                                                </Form.Field>
                                                            }
                                                        </Form>
                                                    </Table.Cell>
                                                </Table.Row>)
                                            )
                                        }
                                     </>: <Table.Row>
                                            <Table.Cell colSpan={4}> Nema dodatih dokumenata. </Table.Cell>
                                        </Table.Row>
                                    }
                                    </Table.Body>
                                </Table>}
                            </GridColumn>
                        </GridRow>
                    </Grid>

                </Segment>
                {segments && <Segment>

                    <Grid stackable columns={duration}>
                    {duration && returnNTimes(duration).map((el, index)=>(
                        <GridColumn>
                            <AddRecognizedCourse gradeNumber={index+1} studyProgramId={studyProgramId} recognitionId={state.recognition ? state.recognition.id : null} committee={state.recognition.committee}/>
                        </GridColumn>

                    ))}
                    </Grid>
                </Segment>}
                <ToastContainer />
            </SegmentGroup>
        </div>
    );
};

export default RecognizedCourseDocument;
