import React, {useContext, useEffect, useReducer, useState} from 'react';
import moment from "moment";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Checkbox, Divider, Form, Grid, GridColumn, Header, Input, Loader, Menu, SegmentGroup} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import {DateTimePicker} from "react-widgets";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {extractFileNameFromHeaders} from "../../Utilities/Util";
import download from "downloadjs";
import {UserRoleContext} from "../../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'openRegistrationForm':
            return {...state, openRegistrationForm: action.payload};
        case 'openCertificationForm':
            return {...state, openCertificationForm: action.payload};
        // registration form
        case 'courseOptions':
            return {...state, courseOptions: action.payload};
        case 'courses':
            return {...state, courses: action.payload};
        case 'place':
            return {...state, place: action.payload};
        case 'fileDate':
            return {...state, fileDate: action.payload};
        case 'startDate':
            return {...state, startDate: action.payload};
        case 'endDate':
            return {...state, endDate: action.payload};
        case 'documentDate':
            return {...state, documentDate: action.payload};
        case 'documentNumber':
            return {...state, documentNumber: action.payload};
        // certification form
        case 'examDate':
            return {...state, examDate: action.payload};
        case 'certificationNumber':
            return {...state, certificationNumber: action.payload};
        case 'certificationDate':
            return {...state, certificationDate: action.payload};
        case 'serialNumber':
            return {...state, serialNumber: action.payload};
        case 'title':
            return {...state, title: action.payload};
        case 'informalInfo':
            return {...state, informalInfo: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'serialNumbers':
            return {...state, serialNumbers: action.payload};
        case 'educationalGroupHead':
            return {...state, educationalGroupHead: action.payload};
        case 'educationalGroupHeads':
            return {...state, educationalGroupHeads: action.payload};
        default:
            return state;

    }
};

const InformalEducation = () => {
    const studyProgramId = localStorage.getItem('studyProgramId');
    const supplementaryEducationTypeName = localStorage.getItem('supplementaryEducationTypeName');
    const startEducationDate = localStorage.getItem('startEducationDate'); // pocetak obrazovnog procesa za polaznika
    const sspId = localStorage.getItem('sspId');
    const organizerId = localStorage.getItem('organizerId');
    const formType = localStorage.getItem('formType');
    const {activeRole} = useContext(UserRoleContext);

    const [hourFlag, setHourFlag] = useState(false);

    const [placeErr, setPlaceErr] = useState(false);
    const [fileDateErr, setFileDateErr] = useState(false);
    const [startDateErr, setStartDateErr] = useState(false);
    const [endDateErr, setEndDateErr] = useState(false);
    const [documentDateErr, setDocumentDateErr] = useState(false);
    const [documentNumberErr, setDocumentNumberErr] = useState(false);

    const [examDateErr, setExamDateErr] = useState(false);
    const [certificationNumberErr, setCertificationNumberErr] = useState(false);
    const [certificationDateErr, setCertificationDateErr] = useState(false);
    const [serialNumberErr, setSerialNumberrr] = useState(false);
    const [titleErr, setTitleErr] = useState(false);
    const [educationalGroupHeadErr, setEducationalGroupHeadErr] = useState(false);

    const gradDate = localStorage.getItem('gradDate');
    const droppedDate = localStorage.getItem('droppedDate');

    const [state, dispatch] = useReducer(reducer,{
        openCertificationForm: false,
        openRegistrationForm: false,
        place: '',
        documentDate: null,
        examDate: null,
        startDate: null,
        fileDate: null,
        endDate: null,
        certificationDate: null,
        certificationNumber: null,
        documentNumber: null,
        courseOptions: [],
        courses: [],
        serialNumber: '',
        title: '',
        informalInfo: null,
        serialNumbers: [],
        educationalGroupHead: null,
        educationalGroupHeads: []

    });

    useEffect(() => {

        getInformalInfo(sspId);
        getAllSerialNumbers();


    },[]);
    function getAllSerialNumbers(){
        if(activeRole === "ROLE_ORGANIZER")
        {
            if (formType === "secondary education" || formType === "secondary education - upskilling" || formType === "secondary education - reskilling")
            {getSerialNumbers(2)}
            else if (formType === "primary education")
            {getSerialNumbers(1)}
            else if (formType === "training" || formType === "qualification") {getSerialNumbers(3)}
            else{getSerialNumbers(0)}
        }
        else{
            if (formType === "secondary education" || formType === "secondary education - upskilling" || formType === "secondary education - reskilling")
            {getSerialNumbersAdmin(2)}
            else if (formType === "primary education")
            {getSerialNumbersAdmin(1)}
            else if (formType === "training" || formType === "qualification") {getSerialNumbersAdmin(3)}
            else{getSerialNumbersAdmin(0)}
        }
    }

    function getSerialNumbers(formTypeId){
        axios.get(`form/unused-serial-numbers/by-form-type-and-organizer/${formTypeId}`,).then(
            (res) => {
                let serialNumbers = res.data.map((el)=>({key: el, text: el, value: el}));
                dispatch({type: 'serialNumbers', payload: serialNumbers});
            }).catch((err) => {
            // if (err.response.data.message === 'Forms with passed type id not found!') {
            //     toast('Ne postoje obrasci za ovaj tip obrazovanja!');
            // }
            // else {
            //     toast('Došlo je do greške')
            // }
            console.log('err', err)
        });
    }

    function getSerialNumbersAdmin(formTypeId){
        axios.get(`form/unused-serial-numbers/by-form-type-and-organizer/${formTypeId}/${organizerId}`,).then(
            (res) => {
                let serialNumbers = res.data.map((el)=>({key: el, text: el, value: el}));
                dispatch({type: 'serialNumbers', payload: serialNumbers});
            }).catch((err) => {
            // if (err.response.data.message === 'Forms with passed type id not found!') {
            //     toast('Ne postoje obrasci za ovaj tip obrazovanja!');
            // }
            // else {
            //     toast('Došlo je do greške')
            // }
            console.log('err', err)
        });
    }

    function downloadBlankRegistrationFormForInformalEducation(){
        axios({
            method: 'get',
            url: '/document/exam-registration-form-for-informal-education/blank',
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });

    }

    function downloadRegistrationFormForInformalEducation(){
        axios({
            method: 'post',
            url: `document/exam-registration-form-for-informal-education/${sspId}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });

    }

    function downloadInformalEducationGraduationCertificate(){
        axios({
            method: 'post',
            url: `document/informal-education-graduation-certificate/${sspId}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });

    }

    function certificationFormValidation(){
        if (!state.examDate) { setExamDateErr(true); } else { setExamDateErr(false); }
        if (!state.certificationNumber) { setCertificationNumberErr(true); } else { setCertificationNumberErr(false); }
        if (!state.certificationDate) { setCertificationDateErr(true); } else { setCertificationDateErr(false); }
        if (!state.serialNumber) { setSerialNumberrr(true); } else { setSerialNumberrr(false); }
        if (!state.title) { setTitleErr(true); } else { setTitleErr(false); }

        if (!state.examDate || !state.certificationNumber || !state.certificationDate || !state.serialNumber
            || !state.title)
        {
            toast("Niste unijeli sve podatke!");
            return false;
        }
        return true;
    }

    function formValidation(){
        if(state.startDate ){
            if(state.startDate < new Date(startEducationDate)){
                toast('Datum ne smije biti prije datuma obrazovonog procesa polaznika. Datum početka obrazovonog procesa za ovog polaznika je ' + moment(startEducationDate).format('D. M. YYYY.'))
                setStartDateErr(true);
                return false;
            }else{
                setStartDateErr(false);
            }


        }
        if (!state.place) { setPlaceErr(true); } else { setPlaceErr(false); }
        if (!state.fileDate) { setFileDateErr(true); } else { setFileDateErr(false); }
        if (!state.startDate) { setStartDateErr(true); } else { setStartDateErr(false); }
        if (!state.endDate) { setEndDateErr(true); } else { setEndDateErr(false); }
        if (!state.documentDate) { setDocumentDateErr(true); } else { setDocumentDateErr(false); }
        if (!state.documentNumber) { setDocumentNumberErr(true); } else { setDocumentNumberErr(false); }
        if (!state.educationalGroupHead) { setEducationalGroupHeadErr(true); } else { setEducationalGroupHeadErr(false); }
        if (!state.place || !state.fileDate || !state.startDate || !state.endDate || !state.documentDate
            || !state.documentNumber || !state.educationalGroupHead)
        {
            toast("Niste unijeli sve podatke!");
            return false;
        }
        return true;
    }

    function saveCertificationForm(){
        if(certificationFormValidation()){
            dispatch({type: 'loader', payload: true});
            let obj = {
                id: state.informalInfo.id,
                optLock: state.informalInfo.optLock,
                courseStudyPrograms: state.courses.map((e)=>({id:e})),
                fileDate: state.certificationDate,
                examDate: state.examDate,
                examRegistrationDate: state.fileDate,
                fileNumber: state.certificationNumber,
                placeOfPublication: state.place,
                protocolDate: state.documentDate,
                protocolNumber: state.documentNumber,
                schoolingStartDate: state.startDate,
                schoolingEndDate: state.endDate,
                serialNumber: state.serialNumber,
                studentStudyProgram: {id: parseInt(sspId)},
                titleText: state.title,
            };
            axios.put('informal-education-document-information/edit', {...obj}).then(
                (res) => {
                    getInformalInfo(sspId);
                    getAllSerialNumbers();
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function saveRegistrationForm(){

        if(formValidation()){
            dispatch({type: 'loader', payload: true});
            let obj = {
                courseStudyPrograms: state.courses.map((e)=>({id:e})),
                fileDate: state.certificationDate,
                examDate: state.examDate,
                examRegistrationDate: state.fileDate,
                fileNumber: state.certificationNumber,
                placeOfPublication: state.place,
                protocolDate: state.documentDate,
                protocolNumber: state.documentNumber,
                schoolingStartDate: state.startDate,
                schoolingEndDate: state.endDate,
                serialNumber: state.serialNumber,
                educationalGroupHead: state.educationalGroupHead,
                studentStudyProgram: {id: parseInt(sspId)},
                titleText: state.title,

            };
            if(state.informalInfo && state.informalInfo.id){
                obj={...obj,
                    id: state.informalInfo.id,
                    optLock: state.informalInfo.optLock,
                };
                axios.put('informal-education-document-information/edit', {...obj}).then(
                    (res) => {
                        getInformalInfo(sspId);
                    }).catch((err) => {
                    console.log('err', err)
                });
            }else{
                axios.post('informal-education-document-information/save', {...obj}).then(
                    (res) => {
                        getInformalInfo(sspId);
                    }).catch((err) => {
                    console.log('err', err)
                });
             }
        }


    }

    function getInformalInfo(sspId){
            dispatch({type: 'loader', payload: true});
            axios.get(`informal-education-document-information/by-student-study-program/${sspId}`,).then(
                (res) => {
                    let informalInfo = res.data;
                    if(informalInfo.id){
                        dispatch({type: 'informalInfo', payload: informalInfo});
                        dispatch({type: 'place', payload: informalInfo.placeOfPublication});
                        dispatch({type: 'examDate', payload: informalInfo.examDate});
                        dispatch({type: 'documentDate', payload: informalInfo.protocolDate});
                        dispatch({type: 'documentNumber', payload: informalInfo.protocolNumber});
                        dispatch({type: 'endDate', payload: informalInfo.schoolingEndDate});
                        dispatch({type: 'startDate', payload: informalInfo.schoolingStartDate});
                        dispatch({type: 'serialNumber', payload: informalInfo.serialNumber});
                        dispatch({type: 'title', payload: informalInfo.titleText});
                        dispatch({type: 'fileDate', payload: informalInfo.examRegistrationDate});
                        dispatch({type: 'certificationDate', payload: informalInfo.fileDate});
                        dispatch({type: 'certificationNumber', payload: informalInfo.fileNumber});
                        dispatch({type: 'courses', payload: informalInfo.courseStudyPrograms !== 0 ?informalInfo.courseStudyPrograms.map((el)=>(el.id)): []});
                        dispatch({type: 'loader', payload: false});
                        dispatch({type: 'educationalGroupHead', payload: informalInfo.educationalGroupHead});
                        dispatch({type: 'openRegistrationForm', payload: false});
                        dispatch({type: 'openCertificationForm', payload: false});
                    }else{
                        dispatch({type: 'loader', payload: false});
                        dispatch({type: 'openRegistrationForm', payload: false});
                        dispatch({type: 'openCertificationForm', payload: false});
                    }

                }).catch((err) => {
                console.log('err', err)
            });

    }

    function openRegistrationForm(){
        dispatch({type: 'openRegistrationForm', payload: true});
        getCourses(studyProgramId);
        if (activeRole === "ROLE_ORGANIZER") {getEducationalGroupHeads()}
        else {
            getEducationalGroupHeadsForAdmin()

        };
    }
    function openCertificationForm(){
        dispatch({type: 'openCertificationForm', payload: true});
        if(state.serialNumber) dispatch({type: 'serialNumbers', payload: [...state.serialNumbers, {key: state.serialNumber, text: state.serialNumber, value: state.serialNumber}]});
        // getLookups();
    }

    function handleInputChange(event, { name, value }) {

        dispatch({type: name, payload: value});
    }

    function handleDateChange(name, date) {
        if(name === 'startDate'){
            if(date < new Date(startEducationDate)){
                setStartDateErr(true);
                toast('Datum ne smije biti prije datuma obrazovonog procesa polaznika. Datum početka obrazovonog procesa za ovog polaznika je ' + moment(startEducationDate).format('D. M. YYYY.'))
            }else{
                setStartDateErr(false);
            }
        }
        dispatch({type: name, payload: date});
    }

    function getCourses(id){
        axios.get(`/course-study-program/by-study-program/${id}`,).then(
            (res) => {
                let courses = res.data.map((el)=>({key: el.id, text: el.course.nameBosnian + ' | ' + el.course.comment, value: el.id}));
                dispatch({type: 'courseOptions', payload: courses});
                // dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getEducationalGroupHeads(){

        axios.get('/educational-group-head/by-organizer',).then(
            (res) => {
                let educationalGroupHeads = res.data.map((el)=>({...el, key: el.id, text:el.firstName + ' ' + el.lastName, value: el}));
                dispatch({type: 'educationalGroupHeads', payload: educationalGroupHeads});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getEducationalGroupHeadsForAdmin(){

        axios.get(`/educational-group-head/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let educationalGroupHeads = res.data.map((el)=>({...el, key: el.id, text:el.firstName + ' ' + el.lastName, value: el}));
                dispatch({type: 'educationalGroupHeads', payload: educationalGroupHeads});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    return (
        <div>
            <Segment.Group>
                <Segment className={'customPadding'} clearing color={"teal"}>
                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>
                        <Menu.Item ><Header as={'h3'} floated={"left"}>
                            {localStorage.getItem('name')}
                            <span>
                            <Icon/></span>
                            <Header.Subheader>
                                <i>Ime i prezime polaznika/ice</i>
                            </Header.Subheader>
                        </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            <Menu.Item>
                            <span>
                                 <Button icon labelPosition='left' size="mini" basic color="black" onClick={downloadBlankRegistrationFormForInformalEducation} >
                                                            <Icon name='download' color={"teal"} />
                                                            Preuzmi praznu prijavu i zapisnik
                                                        </Button>
                                <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                    <Icon name='arrow left'/>
                                </Button>
                                <Icon/>
                            </span>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Segment>
                <Segment>
                { state.loader? <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div> :
                    <Grid columns={2} stackable>
                        <GridColumn>
                            <SegmentGroup>
                                <Segment clearing>
                                    <Header as={'h5'} floated={"left"}>Prijava i zapisnik
                                    <Header.Subheader>
                                        <i>Podaci potrebni za štampanje prijave i zapisnika za ispit</i>
                                    </Header.Subheader>
                                    </Header>
                                    {!state.openRegistrationForm && !(activeRole === "ROLE_ORGANIZER" && (gradDate !== 'null' || droppedDate !== 'null')) ?<Popup size={"small"}
                                           content={state.informalInfo && state.informalInfo.id ? 'Izmijeni': 'Dodaj podatke'}
                                           trigger={
                                               <Button
                                                   floated={"right"}
                                                   icon
                                                   size={"tiny"}
                                                   basic
                                                   compact
                                                   disabled={!state.informalInfo && activeRole === "ROLE_MINISTRY"}
                                                   onClick={openRegistrationForm}
                                               >
                                                   <Icon name={state.informalInfo && state.informalInfo.id ? 'pencil' : 'add'} color={"teal"} />
                                               </Button>
                                           }
                                    />: ''}
                                </Segment>
                                {(state.openRegistrationForm || (state.informalInfo && state.informalInfo.id)) && <Segment>
                                    <Table celled size={"small"}>
                                        <Table.Body>

                                            <Table.Row>
                                                <Table.Cell width={6}> <Table.HeaderCell> Mjesto izdavanja</Table.HeaderCell></Table.Cell>


                                                {state.openRegistrationForm ?
                                                    <Table.Cell width={10}>
                                                        <Form size={'tiny'}>
                                                            <Form.Field
                                                                error={placeErr}
                                                                required
                                                                control={Input}
                                                                placeholder="Mjesto izdavanja"
                                                                value={state.place}
                                                                name="place"
                                                                onChange={handleInputChange}
                                                            />
                                                        </Form>
                                                        {/*<Table.Cell >{state.recognition && state.recognition.fileNumber}</Table.Cell>*/}
                                                    </Table.Cell>:
                                                    <Table.Cell width={10}>{state.place}</Table.Cell>

                                                }
                                            </Table.Row>
                                            <Table.Row >
                                                <Table.Cell><Table.HeaderCell> Datum prijave ispita</Table.HeaderCell></Table.Cell>

                                                {state.openRegistrationForm ? <Table.Cell >
                                                    <Form size={'tiny'}>
                                                        <Form.Field
                                                            error={fileDateErr}
                                                            required
                                                            control={DateTimePicker}
                                                            format="D. M. YYYY."
                                                            placeholder="Unesite datum"
                                                            name="fileDate"
                                                            value={state.fileDate ? new Date(state.fileDate): null}
                                                            time={false}
                                                            onChange={(e) => handleDateChange('fileDate', e)}
                                                            style={{ borderWidth: '0px' }}
                                                        />
                                                    </Form>
                                                </Table.Cell>: <Table.Cell>{state.fileDate ? moment(state.fileDate).format('D. M. YYYY.'): ''}</Table.Cell>}
                                            </Table.Row>
                                            <Table.Row >
                                                <Table.Cell><Table.HeaderCell> Početak obrazovnog procesa </Table.HeaderCell></Table.Cell>

                                                {state.openRegistrationForm ? <Table.Cell >
                                                    <Form size={'tiny'}>
                                                        <Form.Field
                                                            required
                                                            error={startDateErr}
                                                            control={DateTimePicker}
                                                            format="D. M. YYYY."
                                                            placeholder="Unesite datum"
                                                            name="startDate"
                                                            value={state.startDate ? new Date(state.startDate): new Date(startEducationDate)}
                                                            time={false}
                                                            disabled
                                                            // max={new Date(startEducationDate).getTime() + ( 7 * (1000 * 60 *60 *24))}
                                                            // min={new Date(startEducationDate).getTime() - ( 7 * (1000 * 60 *60 *24))}
                                                            onChange={(e) => handleDateChange('startDate', e)}
                                                            style={{ borderWidth: '0px' }}
                                                        />
                                                    </Form>
                                                </Table.Cell>: <Table.Cell>{state.startDate ? moment(state.startDate).format('D. M. YYYY.'): ''}</Table.Cell>}
                                            </Table.Row>
                                            <Table.Row >
                                                <Table.Cell><Table.HeaderCell> Kraj obrazovnog procesa </Table.HeaderCell></Table.Cell>

                                                {state.openRegistrationForm ? <Table.Cell >
                                                    <Form size={'tiny'}>
                                                        <Form.Field
                                                            required
                                                            error={endDateErr}
                                                            control={DateTimePicker}
                                                            format="D. M. YYYY."
                                                            placeholder="Unesite datum"
                                                            name="endDate"
                                                            value={state.endDate ? new Date(state.endDate): null}
                                                            time={false}
                                                            min={new Date(startEducationDate).getTime() + ( 30 * (1000 * 60 *60 *24))}
                                                            onChange={(e) => handleDateChange('endDate', e)}
                                                            style={{ borderWidth: '0px' }}
                                                        />
                                                    </Form>
                                                </Table.Cell>: <Table.Cell>{state.endDate ? moment(state.endDate).format('D. M. YYYY.'):''}</Table.Cell>}
                                            </Table.Row>
                                            <Table.Row >
                                                <Table.Cell><Table.HeaderCell> Datum protokola </Table.HeaderCell></Table.Cell>

                                                {state.openRegistrationForm ? <Table.Cell >
                                                    <Form size={'tiny'}>
                                                        <Form.Field
                                                            required
                                                            error={documentDateErr}
                                                            control={DateTimePicker}
                                                            format="D. M. YYYY."
                                                            placeholder="Unesite datum"
                                                            name="documentDate"
                                                            value={state.documentDate ? new Date(state.documentDate): null}
                                                            time={false}
                                                            onChange={(e) => handleDateChange('documentDate', e)}
                                                            style={{ borderWidth: '0px' }}
                                                        />
                                                    </Form>
                                                </Table.Cell>: <Table.Cell>{state.documentDate ? moment(state.documentDate).format('D. M. YYYY.'): ''}</Table.Cell>}
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell> <Table.HeaderCell> Broj protokola </Table.HeaderCell></Table.Cell>


                                                {state.openRegistrationForm ?
                                                    <Table.Cell >
                                                        <Form size={'tiny'}>
                                                            <Form.Field
                                                                required
                                                                error={documentNumberErr}
                                                                control={Input}
                                                                placeholder="Broj protokola"
                                                                value={state.documentNumber}
                                                                name="documentNumber"
                                                                onChange={handleInputChange}
                                                                // error={this.state.jmbgErr}
                                                            />
                                                        </Form>
                                                        {/*<Table.Cell >{state.recognition && state.recognition.fileNumber}</Table.Cell>*/}
                                                    </Table.Cell>:
                                                    <Table.Cell >{state.documentNumber}</Table.Cell>

                                                }
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell> <Table.HeaderCell> Voditelj obrazovne grupe </Table.HeaderCell></Table.Cell>


                                                {state.openRegistrationForm ?
                                                    <Table.Cell >
                                                        <Form size={'tiny'}>
                                                            <Form.Field required>
                                                                <Form.Dropdown
                                                                    clearable
                                                                    placeholder="Odaberite voditelja obrazovne grupe"
                                                                    value={state.educationalGroupHead}
                                                                    onChange={handleInputChange}
                                                                    name="educationalGroupHead"
                                                                    options={state.educationalGroupHeads}
                                                                    error={educationalGroupHeadErr}
                                                                    search
                                                                    fluid
                                                                    selection
                                                                />
                                                            </Form.Field>
                                                        </Form>
                                                        {/*<Table.Cell >{state.recognition && state.recognition.fileNumber}</Table.Cell>*/}
                                                    </Table.Cell>:
                                                    <Table.Cell >{state.educationalGroupHead?.firstName + ' ' + state.educationalGroupHead?.lastName}</Table.Cell>

                                                }
                                            </Table.Row>
                                           { state.openRegistrationForm && <Table.Row>
                                                <Table.Cell width={6}> <Table.HeaderCell>Konfiguracija dokumenta</Table.HeaderCell></Table.Cell>


                                                 <Table.Cell width={10}>
                                                        <Form size={'tiny'}>
                                                            <Form.Field required>
                                                                <Form.Checkbox
                                                                    onChange={()=>setHourFlag(!hourFlag)}
                                                                    checked={hourFlag}
                                                                    label='Prikaži sate'
                                                                />
                                                                <Form.Checkbox disabled label='Prikaži tekstualni unos' />
                                                            </Form.Field>
                                                        </Form>
                                                    </Table.Cell>


                                            </Table.Row>}
                                            <Table.Row>
                                                <Table.Cell width={6}> <Table.HeaderCell>Predmeti / moduli / ispiti</Table.HeaderCell></Table.Cell>


                                                {state.openRegistrationForm ? <Table.Cell width={10}>
                                                        <Form size={'tiny'}>
                                                            <Form.Field required>
                                                                <Form.Dropdown
                                                                    clearable
                                                                    multiple
                                                                    placeholder="Odaberite opcije"
                                                                    value={state.courses}
                                                                    onChange={handleInputChange}
                                                                    name="courses"
                                                                    options={state.courseOptions}
                                                                    search
                                                                    fluid
                                                                    selection
                                                                />
                                                            </Form.Field>
                                                        </Form>
                                                    </Table.Cell>
                                                    : <Table.Cell width={10}>
                                                        {state.informalInfo && state.informalInfo.courseStudyPrograms && state.informalInfo.courseStudyPrograms.length !== 0 && state.informalInfo.courseStudyPrograms.map((el, index)=>(<Table.Row> {index +1 }. {el.course.nameBosnian} {el.course.comment? '| ' + el.course.comment: '' }</Table.Row>))}
                                                        </Table.Cell>
                                                }
                                            </Table.Row>
                                            {state.openRegistrationForm ? <Table.Row >
                                                <Table.Cell colSpan={state.openRegistrationForm ? 3: 2} textAlign="right">
                                                    <Button size={"mini"} basic color={"teal"} onClick={saveRegistrationForm} >Sačuvaj</Button>
                                                    <Button  size={"mini"} basic color={"black"} onClick={()=>{dispatch({type: 'openRegistrationForm', payload: false});}}>Odustani</Button>
                                                </Table.Cell>

                                            </Table.Row>:
                                                <Table.Row >
                                                    <Table.Cell colSpan={state.openRegistrationForm ? 3: 2} textAlign="right">
                                                        <Button disabled={activeRole === "ROLE_MINISTRY"} icon labelPosition='left' size="mini" basic color="black" onClick={downloadRegistrationFormForInformalEducation} >
                                                            <Icon name='download' color={"teal"} />
                                                            Preuzmi prijavu i zapisnik
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>

                                            }

                                        </Table.Body>

                                    </Table>
                                </Segment>}
                            </SegmentGroup>
                        </GridColumn>
                        <GridColumn>
                            {state.informalInfo && state.informalInfo.id && <SegmentGroup>
                                <Segment clearing>
                                    <Header as={'h5'} floated={"left"}>Uvjerenje
                                    <Header.Subheader>
                                        <i>Podaci potrebni za štampanje uvjerenja za {supplementaryEducationTypeName}</i>
                                    </Header.Subheader>

                                </Header>
                                    {(!state.openCertificationForm)&& !(activeRole === "ROLE_ORGANIZER" && (gradDate !== 'null' || droppedDate !== 'null')) ?
                                    <Popup size={"small"}
                                           content={state.informalInfo && state.informalInfo.id ? 'Izmijeni': 'Dodaj podatke'}
                                           trigger={
                                               <Button
                                                   floated={"right"}
                                                   icon
                                                   size={"tiny"}
                                                   basic
                                                   compact
                                                   onClick={openCertificationForm}
                                               >
                                                   <Icon name={state.informalInfo && state.informalInfo.id ?'pencil': 'add'} color={"teal"} />
                                               </Button>
                                           }
                                    /> : ''}
                                </Segment>
                                {state.informalInfo && state.informalInfo.id && <Segment>
                                    <Table celled size={"small"}>
                                        <Table.Body>
                                            <Table.Row >
                                                <Table.Cell width={6}><Table.HeaderCell> Datum polaganja </Table.HeaderCell></Table.Cell>

                                                {state.openCertificationForm ? <Table.Cell >
                                                    <Form size={'tiny'}>
                                                        <Form.Field
                                                            required
                                                            error={examDateErr}
                                                            control={DateTimePicker}
                                                            format="D. M. YYYY."
                                                            placeholder="Unesite datum"
                                                            name="examDate"
                                                            value={state.examDate ? new Date(state.examDate): null}
                                                            time={false}
                                                            onChange={(e) => handleDateChange('examDate', e)}
                                                            style={{ borderWidth: '0px' }}
                                                        />
                                                    </Form>
                                                </Table.Cell>: <Table.Cell>{state.examDate ? moment(state.examDate).format('D. M. YYYY.'): ''}</Table.Cell>}
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell> <Table.HeaderCell> Djelovodni broj </Table.HeaderCell></Table.Cell>


                                                {state.openCertificationForm ?
                                                    <Table.Cell >
                                                        <Form size={'tiny'}>
                                                            <Form.Field
                                                                required
                                                                error={certificationNumberErr}
                                                                control={Input}
                                                                placeholder="Mjesto izdavanja"
                                                                value={state.certificationNumber}
                                                                name="certificationNumber"
                                                                onChange={handleInputChange}
                                                                // error={this.state.jmbgErr}
                                                            />
                                                        </Form>
                                                        {/*<Table.Cell >{state.recognition && state.recognition.fileNumber}</Table.Cell>*/}
                                                    </Table.Cell>:
                                                    <Table.Cell >{state.certificationNumber}</Table.Cell>

                                                }
                                            </Table.Row>
                                            <Table.Row >
                                                <Table.Cell><Table.HeaderCell> Datum izdavanja isprave </Table.HeaderCell></Table.Cell>

                                                {state.openCertificationForm ? <Table.Cell >
                                                    <Form size={'tiny'}>
                                                        <Form.Field
                                                            required
                                                            error={certificationDateErr}
                                                            control={DateTimePicker}
                                                            format="D. M. YYYY."
                                                            placeholder="Unesite datum"
                                                            name="certificationDate"
                                                            value={state.certificationDate ? new Date(state.certificationDate): null}
                                                            time={false}
                                                            onChange={(e) => handleDateChange('certificationDate', e)}
                                                            style={{ borderWidth: '0px' }}
                                                        />
                                                    </Form>
                                                </Table.Cell>: <Table.Cell>{state.certificationDate ? moment(state.certificationDate).format('D. M. YYYY.'): ''}</Table.Cell>}
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell> <Table.HeaderCell> Serijski broj (sa obrasca) </Table.HeaderCell></Table.Cell>


                                                {state.openCertificationForm ?
                                                    <Table.Cell >
                                                        {!state.serialNumber || activeRole !== "ROLE_ORGANIZER" ? <Form size={'tiny'}>
                                                            <Form.Dropdown
                                                                error={serialNumberErr}
                                                                clearable
                                                                // disabled={!state.serialNumber}
                                                                placeholder="Odaberite broj"
                                                                value={state.serialNumber}
                                                                onChange={handleInputChange}
                                                                name="serialNumber"
                                                                options={state.serialNumbers}
                                                                search
                                                                fluid
                                                                selection
                                                            />
                                                        </Form>  : <Form.Field>{state.serialNumber}</Form.Field>}
                                                    </Table.Cell> :
                                                    <Table.Cell >{state.serialNumber}</Table.Cell>

                                                }
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell> <Table.HeaderCell> Polaznik je stekao znanja, vještine i sposobnosti za ...
                                                   <Header as={'h5'}> <Header.Subheader>
                                                        <i>(nastavi rečenicu)</i>
                                                    </Header.Subheader>
                                                   </Header>
                                                </Table.HeaderCell></Table.Cell>


                                                {state.openCertificationForm ?
                                                    <Table.Cell >
                                                        <Form size={'tiny'}>
                                                            <Form.Field
                                                                required
                                                                error={titleErr}
                                                                control={Input}
                                                                placeholder="Zvanje"
                                                                value={state.title}
                                                                name="title"
                                                                onChange={handleInputChange}
                                                                // error={this.state.jmbgErr}
                                                            />
                                                        </Form>
                                                        {/*<Table.Cell >{state.recognition && state.recognition.fileNumber}</Table.Cell>*/}
                                                    </Table.Cell>:
                                                    <Table.Cell >{state.title}</Table.Cell>

                                                }
                                            </Table.Row>
                                            {state.openCertificationForm ? <Table.Row >
                                                <Table.Cell colSpan={state.openCertificationForm ? 3: 2} textAlign="right">
                                                    <Button size={"mini"} basic color={"teal"} onClick={saveCertificationForm}>Sačuvaj</Button>
                                                    <Button size={"mini"} basic color={"black"} onClick={()=>{dispatch({type: 'openCertificationForm', payload: false});}}>Odustani</Button>
                                                </Table.Cell>

                                            </Table.Row>:
                                                <Table.Row >
                                                    <Table.Cell colSpan={state.openRegistrationForm ? 3: 2} textAlign="right">
                                                        <Button disabled={activeRole === "ROLE_MINISTRY"} icon labelPosition='left' size="mini" basic color="black" onClick={downloadInformalEducationGraduationCertificate} >
                                                            <Icon name='download' color={"teal"} />
                                                            Preuzmi uvjerenje
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            }

                                        </Table.Body>

                                    </Table>
                                </Segment>}
                            </SegmentGroup>}
                        </GridColumn>

                    </Grid>
                }
                </Segment>
                <ToastContainer />
            </Segment.Group>
        </div>
    );
};

export default InformalEducation;
