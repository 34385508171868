export const lookup = [
    // path ---> front route
    // url ---> back url
    {
        name: 'Države',
        path: '/drzave',
        urls: {
            get: '/country/all',
            add: '/country/save',
            delete: '/country/delete'
        },
        additionalInformation: ['officialName'],
        columns: [
                {
                    name: 'name',
                    simpleName: 'Naziv države',
                    type: 'text'
                },
                {
                    name: 'officialName',
                    simpleName: 'Oficijelni naziv',
                    type: 'text'
                },

            ]
    },
    {
        name: 'Kantoni',
        path: '/kantoni',
        urls: {
            get: '/canton/all',
            add: '/canton/save',
            delete: '/canton/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Kanton',
                type: 'text'
            },
        ]
    },
    {
        name: 'Dokumenti',
        path: '/dokumenti',
        urls: {
            get: '/document-type/all',
            add: '/document-type/save',
            delete: '/document-type/delete'
        },
        additionalInformation: ['graduationDocument'],
        columns: [
            {
                name: 'name',
                simpleName: 'Dokument',
                type: 'text'
            },
            {
                name: 'graduationDocument',
                simpleName: 'Završni dokument',
                type: 'text'
            },
        ]
    },
    {
        name: 'Razlozi ispisa',
        path: '/razlozi-ispisa',
        urls: {
            get: '/dropout-reason/all',
            add: '/dropout-reason/save',
            delete: '/dropout-reason/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Razlozi ispisa',
                type: 'text'
            },
        ]
    },
    {
        name: 'Vrsta zahtjeva',
        path: '/vrsta-zahtjeva',
        urls: {
            get: '/edit-request-type/all',
            add: '/edit-request-type/save',
            delete: '/edit-request-type/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Vrsta zahtjeva',
                type: 'text'
            },
        ]
    },
    {
        name: 'Nivo obrazovanja',
        path: '/nivo-obrazovanja',
        urls: {
            get: '/education-level/all',
            add: '/education-level/save',
            delete: '/education-level/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Nivo obrazovanja',
                type: 'text'
            },
        ]
    },
    {
        name: 'Entiteti',
        path: '/entiteti',
        urls: {
            get: '/entity/all',
            add: '/entity/save',
            delete: '/entity/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Entitet',
                type: 'text'
            },
        ]
    },
    {
        name: 'Tip obrazovanja',
        path: '/tip-obrazovanja',
        urls: {
            get: '/form-type/all',
            add: '/form-type/save',
            delete: '/form-type/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Tip obrazovanja',
                type: 'text'
            },
        ]
    },
    {
        name: 'Spol',
        path: '/spol',
        urls: {
            get: '/gender/all',
            add: '/gender/save',
            delete: '/gender/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Spol',
                type: 'text'
            },
        ]
    },
    {
        name: 'Razredi',
        path: '/razredi',
        urls: {
            get: '/grade/all',
            add: '/grade/save',
            delete: '/grade/delete'
        },
        additionalInformation: ['number'],
        columns: [
            {
                name: 'name',
                simpleName: 'Razred',
                type: 'text'
            },
            {
                name: 'number',
                simpleName: 'Broj razreda',
                type: 'text'
            },
        ]
    },
    {
        name: 'Jezici',
        path: '/jezici',
        urls: {
            get: '/language/all',
            add: '/language/save',
            delete: '/language/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Jezik',
                type: 'text'
            },
        ]
    },
    {
        name: 'Vrsta e-maila',
        path: '/vrsta-emaila',
        urls: {
            get: '/mail-type/all',
            add: '/mail-type/save',
            delete: '/mail-type/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Vrsta e-maila',
                type: 'text'
            },
        ]
    },
    {
        name: 'Ocjene',
        path: '/ocjene',
        urls: {
            get: '/mark/all',
            add: '/mark/save',
            delete: '/mark/delete'
        },
        additionalInformation: ['number'],
        columns: [
            {
                name: 'name',
                simpleName: 'Ocjena',
                type: 'text'
            },
            {
                name: 'number',
                simpleName: 'Broj ocjene',
                type: 'text'
            },

        ]
    },
    {
        name: 'Općine',
        path: '/opcine',
        urls: {
            get: '/municipality/all',
            add: '/municipality/save',
            delete: '/municipality/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Općina',
                type: 'text'
            }
        ]
    },
    {
        name: 'Radni status',
        path: '/radni-status',
        urls: {
            get: '/occupation-type/all',
            add: '/occupation-type/save',
            delete: '/occupation-type/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Radni status',
                type: 'text'
            },
        ]
    },
    {
        name: 'Tip ustanove',
        path: '/tip-ustanove',
        urls: {
            get: '/organizer-type/all',
            add: '/organizer-type/save',
            delete: '/organizer-type/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Tip ustanove',
                type: 'text'
            },
        ]
    },
    {
        name: 'Struke',
        path: '/struke',
        urls: {
            get: '/profession/all',
            add: '/profession/save',
            delete: '/profession/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Naziv struke (bosanski jezik)',
                type: 'text'
            },
            {
                name: 'nameCroatian',
                simpleName: 'Naziv struke (hrvatski jezik)',
                type: 'text'
            },
            {
                name: 'nameSerbian',
                simpleName: 'Naziv struke (srpski jezik)',
                type: 'text'
            },
        ]
    },
    {
        name: 'Vrsta obrazovanja',
        path: '/vrsta-obrazovanja',
        urls: {
            get: '/supplementary-education-type/all',
            add: '/supplementary-education-type/save',
            delete: '/supplementary-education-type/delete'
        },
        additionalInformation: ['formal'],
        columns: [
            {
                name: 'name',
                simpleName: 'Vrsta obrazovanja',
                type: 'text'
            },
            {
                name: 'formal',
                simpleName: 'Formalno/neformalno',
                type: 'text'
            },
        ]
    },
    {
        name: 'Uloge',
        path: '/uloge',
        urls: {
            get: '/role/all',
            add: '/role/save',
            delete: '/role/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Naziv uloge',
                type: 'text'
            }
        ]
    },
    {
        name: 'Tip zaposlenja',
        path: '/tip-zaposlenja',
        urls: {
            get: '/occupation-type/all',
            add: '/occupation-type/save',
            delete: '/occupation-type/delete'
        },
        additionalInformation: null,
        columns: [
            {
                name: 'name',
                simpleName: 'Tip zaposlenja',
                type: 'text'
            }
        ]
    },
];
