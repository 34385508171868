import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Link, Navigate} from "react-router-dom";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Grid, GridColumn, Header, Menu, Tab} from "semantic-ui-react";
import PersonForm from "./PersonForm";
import axios from "axios";
import PreviousEducationForm from "../Utilities/PreviousEducationForm";
import StudentForm from "./StudentForm";
import {toast, ToastContainer} from "react-toastify";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {UserRoleContext} from "../../Main";
import moment from "moment";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'student':
            return {...state, student: action.payload};
        case 'firstName':
            return {...state, firstName: action.payload};
        case 'lastName':
            return {...state, lastName: action.payload};
        case 'maidenName':
            return {...state, maidenName: action.payload};
        case 'gender':
            return {...state, gender: action.payload};
        case 'genderOptions':
            return {...state, genderOptions: action.payload};
        case 'motherFirstName':
            return {...state, motherFirstName: action.payload};
        case 'motherLastName':
            return {...state, motherLastName: action.payload};
        case 'fatherFirstName':
            return {...state, fatherFirstName: action.payload};
        case 'fatherLastName':
            return {...state, fatherLastName: action.payload};
        case 'jmbg':
            return {...state, jmbg: action.payload};
        case 'oid':
            return {...state, oid: action.payload};
        case 'dateOfBirth':
            return {...state, dateOfBirth: action.payload};
        case 'citizenship':
            return {...state, citizenship: action.payload};
        // student
        case 'occupationTypes':
            return {...state, occupationTypes: action.payload};
        case 'occupationType':
            return {...state, occupationType: action.payload};
        case 'workExperience':
            return {...state, workExperience: action.payload};
        case 'financingType':
            return {...state, financingType: action.payload};
        case 'fileNumber':
            return {...state, fileNumber: action.payload};
        case 'registryBook':
            return {...state, registryBook: action.payload};
        case 'registryBooks':
            return {...state, registryBooks: action.payload};
        case 'numberInRegistryBook':
            return {...state, numberInRegistryBook: action.payload};
        case 'fileDate':
            return {...state, fileDate: action.payload};
        case 'startDate':
            return {...state, startDate: action.payload};
        case 'studyType':
            return {...state, studyType: action.payload};
        case 'studyTypes':
            return {...state, studyTypes: action.payload};
        case 'occupation':
            return {...state, occupation: action.payload};
        case 'occupations':
            return {...state, occupations: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'studyProgram':
            return {...state, studyProgram: action.payload};
        case 'studyPrograms':
            return {...state, studyPrograms: action.payload};
        case 'languages':
            return {...state, languages: action.payload};
        case 'generation':
            return {...state, generation: action.payload};
        case 'language':
            return {...state, language: action.payload};
        case 'comment':
            return {...state, comment: action.payload};
        // previous education
        case 'institutionName':
            return {...state, institutionName: action.payload };
        case 'diplomaNumber':
            return {...state, diplomaNumber: action.payload };
        case 'diplomaDate':
            return {...state, diplomaDate: action.payload };
        case 'educationLevel':
            return {...state, educationLevel: action.payload};
        case 'educationLevels':
            return {...state, educationLevels: action.payload};
        case 'documentType':
            return {...state, documentType: action.payload};
        case 'title':
            return {...state, title: action.payload};
        case 'documentTypes':
            return {...state, documentTypes: action.payload};
        case 'grade':
            return {...state, grade: action.payload};
        case 'grades':
            return {...state, grades: action.payload};
        case 'countries':
            return {...state, countries: action.payload};
        case 'entities':
            return {...state, entities: action.payload };
        case 'cantons':
            return {...state, cantons: action.payload };
        case 'municipalities':
            return {...state, municipalities: action.payload };

        case 'placeOfResidence':
            return {...state, placeOfResidence: action.payload};
        case 'placeOfBirth':
            return {...state, placeOfBirth: action.payload};
        case 'placeOfBirthBackObj':
            return {...state, placeOfBirthBackObj: action.payload};
        case 'placeOfResidenceBackObj':
            return {...state, placeOfResidenceBackObj: action.payload};
        case 'activeIndex':
            return {...state, activeIndex: action.payload};

        case 'disablePerson':
            return {...state, disablePerson: action.payload};
        case 'disableStudent':
            return {...state, disableStudent: action.payload};
        case 'showPlaceInfo':
            return {...state, showPlaceInfo: action.payload};
        case 'numberAvailability':
            return {...state, numberAvailability: action.payload};
        default:
            return state;

    }
};
const AddStudent = () => {
    const {activeRole} = useContext(UserRoleContext);

    const [jmbgErr, setjmbgErr] = useState(false);
    const [dateOfBirthErr, setdateOfBirthErr] = useState(false);
    const [firstNameErr, setfirstNameErr] = useState(false);
    const [lastNameErr, setlastNameErr] = useState(false);
    const [genderErr, setgenderErr] = useState(false);
    const [motherLastNameErr, setmotherLastNameErr] = useState(false);
    const [motherFirstNameErr, setmotherFirstNameErr] = useState(false);
    const [fatherLastNameErr, setfatherLastNameErr] = useState(false);
    const [fatherFirstNameErr, setfatherFirstNameErr] = useState(false);
    const [citizenshipErr, setcitizenshipErr] = useState(false);

    const [countryErr, setCountryErr] = useState(false);
    const [placeErr, setPlaceErr] = useState(false);
    const [countryRErr, setCountryRErr] = useState(false);
    const [placeRErr, setPlaceRErr] = useState(false);
    const [addressErr, setAddressErr] = useState(false);
    const [zipErr, setZipErr] = useState(false);

    const [institutionNameErr, setinstitutionNameErr] = useState(false);
    const [diplomaNumberErr, setdiplomaNumberErr] = useState(false);
    const [diplomaDateErr, setdiplomaDateErr] = useState(false);
    const [educationLevelErr, seteducationLevelErr] = useState(false);
    const [documentTypeErr, setdocumentTypeErr] = useState(false);
    const [titleErr, settitleErr] = useState(false);
    const [gradeErr, setgradeErr] = useState(false);

    const [fileNumberErr, setfileNumberErr] = useState(false);
    const [registryBookErr, setregistryBookErr] = useState(false);
    const [numberInRegistryBookErr, setnumberInRegistryBookErr] = useState(false);
    const [fileDateErr, setfileDateErr] = useState(false);
    const [startDateErr, setstartDateErr] = useState(false);
    const [studyTypeErr, setstudyTypeErr] = useState(false);
    const [occupationErr, setoccupationErr] = useState(false);
    const [occupationTypeErr, setoccupationTypeErr] = useState(false);

    const [studyProgramErr, setstudyProgramErr] = useState(false);
    const [languageErr, setlanguageErr] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [state, dispatch] = useReducer(reducer,{
        student: null,

        firstName: '',
        lastName: '',
        maidenName: '',
        gender: null,
        genderOptions: [],
        organizers: [],
        fatherFirstName: '',
        fatherLastName: '',
        motherFirstName: '',
        motherLastName: '',
        jmbg: '',
        oid: '',
        dateOfBirth: new Date(),
        citizenship: '',

        // student
        fileNumber: '',
        registryBook: '',
        registryBooks: [],
        numberInRegistryBook: '',
        studyType: '',
        studyTypes: [],
        occupation: '',
        occupations: [],
        studyProgram: '',
        studyPrograms: [],
        languages: [],
        occupationTypes: [],
        occupationType: null,
        financingType: '',
        workExperience: '',
        generation: '',
        language: '',
        comment: '',
        fileDate: new Date(),
        startDate: new Date(),

        //previous education
        institutionName: '',
        diplomaNumber: '',
        diplomaDate: new Date(),
        title: '',
        educationLevel: '',
        educationLevels: [],
        documentType: '',
        documentTypes: [],
        grade: '',
        grades: [],

        // options
        countries: [],
        entities: [],
        cantons: [],
        municipalities: [],
        // fields
        placeOfResidence: '',
        placeOfBirth: '',
        placeOfBirthBackObj: null,
        placeOfResidenceBackObj: null,
        //tab
        activeIndex: 0,

        //form
        disablePerson: false,
        disableStudent: false,

        showPlaceInfo: false,
        numberAvailability: false

    });
    const panes = [
        {
            menuItem: 'Osnovni podaci',
            render: () => (<Tab.Pane as={"div"}>
                        <PersonForm
                            student
                            jmbg={state.jmbg}
                            dateOfBirth={state.dateOfBirth}
                            firstName={state.firstName}
                            lastName={state.lastName}
                            maidenName={state.maidenName}
                            gender={state.gender}
                            genderOptions={state.genderOptions}
                            motherLastName={state.motherLastName}
                            motherFirstName={state.motherFirstName}
                            fatherLastName={state.fatherLastName}
                            fatherFirstName={state.fatherFirstName}
                            handleInputChange={handleInputChange}
                            handleDateChange={handleDateChange}
                            handlePlaceChange={handlePlaceChange}
                            placeOfBirth={state.placeOfBirth}
                            citizenship={state.citizenship}
                            placeOfResidence={state.placeOfResidence}
                            placeOfBirthBackObj={state.placeOfBirthBackObj}
                            placeOfResidenceBackObj={state.placeOfResidenceBackObj}
                            countries={state.countries}
                            entities={state.entities}
                            cantons={state.cantons}
                            municipalities={state.municipalities}
                            nextStep={nextStep}

                            jmbgErr={jmbgErr}
                            dateOfBirthErr={dateOfBirthErr}
                            firstNameErr={firstNameErr}
                            lastNameErr={lastNameErr}
                            genderErr={genderErr}
                            motherLastNameErr={motherLastNameErr}
                            motherFirstNameErr={motherFirstNameErr}
                            fatherLastNameErr={fatherLastNameErr}
                            fatherFirstNameErr={fatherFirstNameErr}
                            citizenshipErr={citizenshipErr}

                            countryErr={countryErr}
                            placeErr={placeErr}

                            countryRErr={countryRErr}
                            placeRErr={placeRErr}
                            addressErr={addressErr}
                            zipErr={zipErr}

                            disablePerson={state.disablePerson}
                            disableStudent={state.disableStudent}
                            showPlaceInfo={state.showPlaceInfo}
                        />
            </Tab.Pane>)
        },
        // {
        //     menuItem: 'Prethodno obrazovanje',
        //     render: () => (<Tab.Pane as={"div"}>
        //                 <PreviousEducationForm
        //                     showNextButton
        //                     institutionName={state.institutionName}
        //                     diplomaNumber={state.diplomaNumber}
        //                     diplomaDate={state.diplomaDate}
        //                     educationLevel={state.educationLevel}
        //                     educationLevels={state.educationLevels}
        //                     title={state.title}
        //                     grade={state.grade}
        //                     grades={state.grades}
        //                     handleInputChange={handleInputChange}
        //                     handleDateChange={handleDateChange}
        //                     nextStep={nextStep}
        //                 />
        //        </Tab.Pane>)
        // },
        {
            menuItem: 'Prijava',
            render: () => (<Tab.Pane as={"div"}>
                <Grid columns={2}>
                    <GridColumn>
                        <StudentForm
                            fileNumber={state.fileNumber}
                            numberInRegistryBook={state.numberInRegistryBook}
                            registryBook={state.registryBook}
                            registryBooks={state.registryBooks}
                            fileDate={state.fileDate}
                            startDate={state.startDate}
                            studyType={state.studyType}
                            studyTypes={state.studyTypes}
                            occupation={state.occupation}
                            occupations={state.occupations}
                            occupationTypes={state.occupationTypes}
                            occupationType={state.occupationType}
                            workExperience={state.workExperience}
                            financingType={state.financingType}
                            organizer={state.organizer}
                            organizers={state.organizers}
                            studyProgram={state.studyProgram}
                            studyPrograms={state.studyPrograms}
                            generation={state.generation}
                            language={state.language}
                            languages={state.languages}
                            comment={state.comment}
                            handleInputChange={handleInputChange}
                            handleDateChange={handleDateChange}
                            // addStudent={addStudent}
                            showOrganizerField={activeRole === "ROLE_ADMIN"}


                            fileNumberErr={fileNumberErr}
                            registryBookErr={registryBookErr}
                            numberInRegistryBookErr={numberInRegistryBookErr}
                            fileDateErr={fileDateErr}
                            startDateErr={startDateErr}
                            studyTypeErr={studyTypeErr}
                            occupationErr={occupationErr}
                            occupationTypeErr={occupationTypeErr}
                            studyProgramErr={studyProgramErr}
                            languageErr={languageErr}

                        />
                    </GridColumn>
                    <GridColumn>
                        <PreviousEducationForm
                            removeButtons
                            institutionName={state.institutionName}
                            diplomaNumber={state.diplomaNumber}
                            diplomaDate={state.diplomaDate}
                            educationLevel={state.educationLevel}
                            educationLevels={state.educationLevels}
                            documentType={state.documentType}
                            documentTypes={state.documentTypes}
                            title={state.title}
                            grade={state.grade}
                            grades={state.grades}
                            handleInputChange={handleInputChange}
                            handleDateChange={handleDateChange}
                            // nextStep={nextStep}

                            institutionNameErr={institutionNameErr}
                            diplomaNumberErr={diplomaNumberErr}
                            diplomaDateErr={diplomaDateErr}
                            educationLevelErr={educationLevelErr}
                            documentTypeErr={documentTypeErr}
                            titleErr={titleErr}
                            gradeErr={gradeErr}
                            gradIsNotMandatory={state.studyType.key === 'primary education'}

                        />
                    </GridColumn>
                </Grid>
                <Link to='/polaznici' ><Button floated={"right"} basic size={"small"} color={"black"} >{'Odustani'}</Button></Link>
                <Button floated={"right"} basic size={"small"} color={"teal"} onClick={addStudent}>{ 'Kreiraj polaznika' }</Button>
               </Tab.Pane>)
        }

    ];

    useEffect(()=>{
        (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY" ) && axios.get('/organizer/all',).then(
            (res) => {
                let organizerOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type: 'organizers', payload: organizerOptions});
            }).catch((err) => {
            console.log('err', err)
        });
        axios.get('/supplementary-education-type/all',).then(
            (res)=>{
                let studyTypes = res.data.map((el)=>({key: el.key, text: el.name, value: el, description: el.formal? 'formalno': 'neformalno'}));
                dispatch({type:'studyTypes', payload: studyTypes});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/document-type/all',).then(
            (res)=>{
                let documentTypes = res.data.filter((e)=>(e.graduationDocument === true)).map((el)=>({key: el.key, text: el.name, value: el}));
                // let documentTypes = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'documentTypes', payload: documentTypes});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/gender/all',).then(
            (res)=>{
                let genderOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'genderOptions', payload: genderOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/country/all').then(
            (res)=>{
                let countriesOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'countries', payload: countriesOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/entity/all',).then(
            (res)=>{
                let entityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'entities', payload: entityOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/canton/all',).then(
            (res)=>{
                let cantonOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'cantons', payload: cantonOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/municipality/all',).then(
            (res)=>{
                let municipalityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'municipalities', payload: municipalityOptions});

            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/language/all',).then(
            (res) => {
                let languageOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type: 'languages', payload: languageOptions});
            }).catch((err) => {
            console.log('err', err)
        });
        axios.get('/education-level/all',).then(
            (res)=>{
                let educationLevels = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'educationLevels', payload: educationLevels});

            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/occupation-type/all',).then(
            (res)=>{
                let occupationTypes = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'occupationTypes', payload: occupationTypes});

            }).catch((err)=>{
            console.log('err', err)
        });

        axios.get('/grade/all',).then(
            (res)=>{
                let grades =  res.data.sort((a, b) => a.number - b.number).map((el)=>({...el, key: el.id, text:el.name, value: el.id}))
                dispatch({type:'grades', payload: grades});

            }).catch((err)=>{
            console.log('err', err)
        });

        if (activeRole === "ROLE_ORGANIZER"){
                // axios.get('/registry-book/not-full/by-organizer',).then(
                //     (res)=>{
                //         console.log('', state.studyType.formal)
                //         let registryBooks = res.data.map((el)=>({key: el.key, text: el.name, value: el})).filter((e) => state.studyType.formal === e.formal);
                //         dispatch({type:'registryBooks', payload: registryBooks});
                //
                //     }).catch((err)=>{
                //     console.log('err', err)
                // });
            axios.get('/organizer-occupation/by-organizer',).then(
                (res) => {
                    let organizerOccupationOptions = res.data.map((el)=>({key: el.id,
                        content: <div><strong>{el.occupation.profession.name}</strong> { ' | ' + el.occupation.nameBosnian}</div>,
                        text: el.occupation.profession.name + ' | ' + el.occupation.nameBosnian,
                        value: el}));
                    dispatch({type: 'occupations', payload: organizerOccupationOptions});
                }).catch((err) => {
                console.log('err', err)
            });
        }
    },[activeRole]);

    function getOrganizerOccupation(id){
        axios.get(`/organizer-occupation/by-organizer-id/${id}`,).then(
            (res) => {
                let organizerOccupationOptions = res.data.map((el)=>({key: el.id, text: el.occupation.profession.name + ' | ' + el.occupation.nameBosnian, value: el}));
                dispatch({type: 'occupations', payload: organizerOccupationOptions});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getRegistryBooks() {
        axios.get('/registry-book/not-full/by-organizer',).then(
            (res)=>{
                let registryBooks = res.data.filter((e) =>  state.studyType.formal === e.formal).map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'registryBooks', payload: registryBooks});

            }).catch((err)=>{
            console.log('err', err)
        });
    }

    function numberAvailabilityCheck(registryBookId, number){
    axios.get(`registry-book/number-availability-check/${registryBookId}/${number}`,).then(
        (res) => {
            let numberAvailability = res.data;
            dispatch({type: 'numberAvailability', payload: numberAvailability});
        }).catch((err) => {
        console.log('err', err)
    });
}

    function getStudyPrograms(organizerOccupationId, studyTypeId){
        axios.get(`study-program/by-organizer-occupation-and-supplementary-education-type/${organizerOccupationId}/${studyTypeId}`,).then(
            (res) => {
                let studyPrograms = res.data.map((el)=>({key: el.id, text: el.name, value: el}));
                dispatch({type: 'studyPrograms', payload: studyPrograms});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function checkJmbg(jmbg){
        axios.get(`student/by-jmbg/${jmbg}`,).then(
            (res) => {
                let student = res.data;
                let personExist = res.data.person.id;
                let studentExist = res.data.id;
                dispatch({type: 'student', payload: student});

                if(personExist){
                    axios.get(`/person-citizenship/by-person-id/${personExist}`,).then(
                        (res) => {
                            let citizenship = res.data;
                            dispatch({type: 'citizenship', payload: citizenship[0].country});
                        }).catch((err) => {
                        console.log('err', err)
                    });
                    dispatch({type: 'firstName', payload: student.person.firstName});
                    dispatch({type: 'lastName', payload: student.person.lastName});
                    dispatch({type: 'maidenName', payload: student.person.maidenName});
                    dispatch({type: 'gender', payload: student.person.gender});
                    dispatch({type: 'dateOfBirth', payload: new Date(student.person.dateOfBirth)});
                    dispatch({type: 'placeOfBirthBackObj', payload: student.person.placeOfBirth});
                    dispatch({type: 'placeOfResidenceBackObj', payload:student.person.residence});
                    dispatch({type: 'placeOfResidence', payload:
                            {
                                country: student.person.residence.place.country,
                                place:  student.person.residence.place.name,
                                address:  student.person.residence.address,
                                zip:  student.person.residence.zipCode,
                            }});
                    dispatch({type: 'placeOfBirth', payload:
                            {
                                country: student.person.placeOfBirth.country,
                                place:  student.person.placeOfBirth.name,
                            }
                       });

                    dispatch({type: 'showPlaceInfo', payload: true});

                    dispatch({type: 'disablePerson', payload: true });
                }

                if(studentExist){
                    dispatch({type: 'motherFirstName', payload: student.motherFirstName});
                    dispatch({type: 'motherLastName', payload: student.motherLastName});
                    dispatch({type: 'fatherFirstName', payload: student.fatherFirstName});
                    dispatch({type: 'fatherLastName', payload: student.fatherLastName});

                    dispatch({type: 'disableStudent', payload: true });
                }
                if(studentExist){
                    toast('Podaci za osobu sa ovim JMBG-om već postoje, unesite samo preostale podatke za prijavu.')
                }else if (personExist){
                    toast('Podaci za osobu sa ovim JMBG-om već postoje, unesite samo preostale podatke za prijavu!')
                }

            }).catch((err) => {
            if (err.response.data.message === 'More than one person found with passed jmbg!') {
                toast('Za navedeni JMBG postoji greška u bazi, javite se administratoru za dalje upute! ');

            }
            dispatch({type: 'student', payload: null});
            console.log('err', err)
        });


    }

    function handleInputChange(event, { name, value }) {

        if(name === 'jmbg' && value.length <= 13){
            if(value.length === 13){
                checkJmbg(value);
            }else{
                dispatch({type: 'firstName', payload: ''});
                dispatch({type: 'lastName', payload: ''});
                dispatch({type: 'maidenName', payload: ''});
                dispatch({type: 'gender', payload: null});
                dispatch({type: 'dateOfBirth', payload: new Date()});
                dispatch({type: 'placeOfResidence', payload: ''});
                dispatch({type: 'citizenship', payload: ''});
                dispatch({type: 'placeOfBirth', payload: ''});

                dispatch({type: 'showPlaceInfo', payload: false});

                dispatch({type: 'motherFirstName', payload: ''});
                dispatch({type: 'motherLastName', payload: ''});
                dispatch({type: 'fatherFirstName', payload: ''});
                dispatch({type: 'fatherLastName', payload: ''});

                dispatch({type: 'disablePerson', payload: false });
                dispatch({type: 'disableStudent', payload: false });
            }
            dispatch({type: name, payload: value});
        }

        if(name === 'organizer'){
            getOrganizerOccupation(value.id);
        }

        if(name === 'occupation'){
            if(state.studyType){
                dispatch({type: 'studyProgram', payload: ''})
                dispatch({type: 'registryBook', payload: ''})
                getStudyPrograms(value.id, state.studyType.id);
                getRegistryBooks();
            }

        }

        if(name === 'studyType'){
            dispatch({type: 'occupation', payload: ''})
            dispatch({type: 'studyProgram', payload: ''})
            dispatch({type: 'registryBook', payload: ''})
            if(state.occupation){
                getStudyPrograms(state.occupation.id, value.id);
            }

        }

        if(name === 'numberInRegistryBook' && value)
        {
            numberAvailabilityCheck(state.registryBook.id, value);
            if (value < 1 || value > state.registryBook.maxNumberOfFields )
            {
                setnumberInRegistryBookErr(true)
                toast('Potrebno je unijeti matični broj koji se nalazi u intervalu 1 - ' + state.registryBook.maxNumberOfFields + '.');
            }
            else{
                setnumberInRegistryBookErr(false)
            }
        }

        // if(state.studyType && state.occupation){
        //     dispatch({type: name, payload: value})
        // }

        if(name !== 'jmbg'){
            dispatch({type: name, payload: value});
        }

    }


    function handleDateChange(name, date) {
        //60 dana unazad i jedan dan unaprijed
        if(name === 'fileDate'){
            if(date.getTime() > new Date().getTime() + (1000 * 60 *60 *24) || date.getTime() < new Date().getTime() - 61*(1000 * 60 *60 *24)){
                setfileDateErr(true);
                toast('Datum podnošenja prijave se mora nalaziti između datuma ' + moment(new Date().getTime() - 60*(1000 * 60 *60 *24)).format('D. M. YYYY.') + ' i ' + moment(new Date().getTime() + (1000 * 60 *60 *24)).format('D. M. YYYY.'));
            }else{
                setfileDateErr(false);
            }
        }
        //7 dana unazad i 7 dana unaprijed
        if(name === 'startDate'){
            if(date.getTime() > new Date().getTime() + 7*(1000 * 60 *60 *24) || date.getTime() < new Date().getTime() - 8*(1000 * 60 *60 *24)){
                setstartDateErr(true);
                toast('Početak obrazovnog procesa se mora nalaziti između datuma ' + moment(new Date().getTime() - 7*(1000 * 60 *60 *24)).format('D. M. YYYY.') + ' i ' + moment(new Date().getTime() + 7*(1000 * 60 *60 *24)).format('D. M. YYYY.'));
            }else{
                setstartDateErr(false);
            }
        }
        dispatch({type: name, payload: date});
    }

    function handlePlaceChange(placeName, obj) {
        dispatch({type: placeName, payload: obj});
    }

    function handleTabChange(e, { activeIndex }) {
        dispatch({type: 'activeIndex', payload: activeIndex});
    }

    function nextStep(index){
        dispatch({type: 'activeIndex', payload: index});
    }

    function addStudent(){
        if(state.numberInRegistryBook && state.numberAvailability === false)
        {
            setnumberInRegistryBookErr(true);
            toast('Matični broj je već zauzet!');
            return;
        }
        if (!state.jmbg) {setjmbgErr(true);} else {if (state.jmbg.length !== 13 ){toast('13');setjmbgErr(true)}else{setjmbgErr(false);}}
        if (!state.dateOfBirth) {setdateOfBirthErr(true);} else {setdateOfBirthErr(false);}
        if (!state.firstName) {setfirstNameErr(true);} else {setfirstNameErr(false);}
        if (!state.lastName) {setlastNameErr(true);} else {setlastNameErr(false);}
        if (!state.gender) {setgenderErr(true);} else {setgenderErr(false);}
        if (!state.citizenship) {setcitizenshipErr(true);} else {setcitizenshipErr(false);}
        if (!state.motherLastName) {setmotherLastNameErr(true);} else {setmotherLastNameErr(false);}
        if (!state.motherFirstName) {setmotherFirstNameErr(true);} else {setmotherFirstNameErr(false);}
        if (!state.fatherLastName) {setfatherLastNameErr(true);} else {setfatherLastNameErr(false);}
        if (!state.fatherFirstName) {setfatherFirstNameErr(true);} else {setfatherFirstNameErr(false);}

        if (!state.placeOfBirth.country) {setCountryErr(true);} else {setCountryErr(false);}
        if (!state.placeOfBirth.place) {setPlaceErr(true);} else {setPlaceErr(false);}

        if (!state.placeOfResidence.country) {setCountryRErr(true);} else {setCountryRErr(false);}
        if (!state.placeOfResidence.place) {setPlaceRErr(true);} else {setPlaceRErr(false);}
        if (!state.placeOfResidence.address) {setAddressErr(true);} else {setAddressErr(false);}
        if (!state.placeOfResidence.zip) {setZipErr(true);} else {setZipErr(false);}


        if (!state.institutionName) {setinstitutionNameErr(true);} else {setinstitutionNameErr(false);}
        if (!state.diplomaNumber) {setdiplomaNumberErr(true);} else {setdiplomaNumberErr(false);}
        if (!state.diplomaDate) {setdiplomaDateErr(true);} else {setdiplomaDateErr(false);}
        if (!state.educationLevel) {seteducationLevelErr(true);} else {seteducationLevelErr(false);}
        if (!state.documentType) {setdocumentTypeErr(true);} else {setdocumentTypeErr(false);}
        if (!state.title) {settitleErr(true);} else {settitleErr(false);}
        if (state.studyType.key !== 'primary education' && !state.grade) {setgradeErr(true);} else {setgradeErr(false);}

        if (!state.language) {setlanguageErr(true);} else {setlanguageErr(false);}
        if (!state.fileNumber) {setfileNumberErr(true);} else {setfileNumberErr(false);}
        if (!state.registryBook) {setregistryBookErr(true);} else {setregistryBookErr(false);}
        if (!state.numberInRegistryBook || ((state.numberInRegistryBook <1 || state.numberInRegistryBook>state.registryBook.maxNumberOfFields))) {setnumberInRegistryBookErr(true);} else {setnumberInRegistryBookErr(false);}
        if (!state.fileDate) {setfileDateErr(true);} else {setfileDateErr(false);}
        if (!state.startDate) {setstartDateErr(true);} else {setstartDateErr(false);}
        if (!state.studyType) {setstudyTypeErr(true);} else {setstudyTypeErr(false);}
        if (!state.occupation) {setoccupationErr(true);} else {setoccupationErr(false);}
        if (!state.occupationType) {setoccupationTypeErr(true);} else {setoccupationTypeErr(false);}
        if (!state.studyProgram) {setstudyProgramErr(true);} else {setstudyProgramErr(false);}

        if (!state.jmbg ||!state.placeOfResidence.address ||!state.placeOfResidence.zip ||!state.placeOfResidence.country ||!state.placeOfResidence.place ||!state.placeOfBirth.place ||!state.placeOfBirth.country ||
            !state.dateOfBirth ||!state.firstName ||!state.lastName ||!state.gender ||!state.motherLastName || !state.citizenship
            ||!state.motherFirstName ||!state.fatherLastName ||!state.fatherFirstName ||!state.institutionName ||
            !state.diplomaNumber || !state.diplomaDate || !state.educationLevel || !state.documentType ||
            !state.title || (state.studyType.key !== 'primary education' && !state.grade) || !state.language || !state.fileNumber || !state.numberInRegistryBook || !state.registryBook || !state.fileDate || !state.startDate || !state.studyType
            || !state.occupation || !state.occupationType || !state.studyProgram ) {
            toast('Niste unijeli sve podatke.');


        } else if (state.jmbg.length !== 13){
            toast('JMBG mora imati 13 cifara.');
        }else {
            let obj = {
                chosenLanguage: state.language,
                comment: state.comment,
                lastCompletedGrade: {id: state.grade},
                created: new Date(),
                fileDate: state.fileDate,
                fileNumber: state.fileNumber,
                registryBook: state.registryBook,
                numberInRegistryBook: parseInt(state.numberInRegistryBook) ,
                startDate: state.startDate,
                occupationType: state.occupationType,
                financingType: state.financingType,
                workExperience: state.workExperience,
                student: {
                    created: new Date(),
                    fatherFirstName: state.fatherFirstName,
                    fatherLastName: state.fatherLastName,
                    motherFirstName: state.motherFirstName,
                    motherLastName: state.motherLastName,
                    person: {
                        created: new Date(),
                        dateOfBirth: state.dateOfBirth,
                        firstName: state.firstName,
                        lastName: state.lastName,
                        maidenName: state.maidenName,
                        jmbg: state.jmbg,
                        oid: null,
                        gender: {id: state.gender.id},
                        placeOfBirth: {
                            country: state.placeOfBirth.country,
                            name: state.placeOfBirth.place,
                            municipality: state.placeOfBirth.country.key === 'bih' ? state.placeOfBirth.municipality : null
                        },
                        residence: {
                            address: state.placeOfResidence.address,
                            zipCode: state.placeOfResidence.zip,
                            place: {
                                country: state.placeOfResidence.country,
                                name: state.placeOfResidence.place,
                                municipality: state.placeOfResidence.country.key === 'bih' ? state.placeOfResidence.municipality : null
                            }

                        },

                    }
                },
                studyProgram: state.studyProgram

            };
            if(state.student?.id){
                obj = {...obj, student:{ ...state.student }}

            }
            else if(state.student?.person.id){
                obj = {...obj, student:{ ...obj.student, person: {...state.student.person}}}
            }
            axios.post('/student-study-program/save', {...obj}).then(
                (res) => {
                    //toast('Uspješno ste dodali polaznika.');
                    let objPrevEdu = {
                        institutionName: state.institutionName,
                        documentType: state.documentType,
                        documentNumber: state.diplomaNumber,
                        documentDate: state.diplomaDate,
                        educationLevel: state.educationLevel,
                        title: state.title,
                        person: res.data.student.person
                    };
                    axios.post('/previous-education/save', {...objPrevEdu}).then(
                        (res) => {
                            toast('Uspješno ste dodali polaznika.');
                            // setRedirect(true);
                        }).catch((err) => {
                        console.log('err', err)
                    });
                    let objCitizenship = {
                        country: state.citizenship,
                        priority: 1,
                        person: res.data.student.person
                    };
                    axios.post('person-citizenship/save', {...objCitizenship}).then(
                        (res) => {
                            toast('Uspješno ste dodali polaznika.');
                            setRedirect(true);
                        }).catch((err) => {
                        console.log('err', err)
                    });
                }).catch((err) => {
                if (err.response.data.message === 'This student is already enrolled in a study program and has not dropped out nor graduated!') {
                    toast('Nije dozvoljeno upisati polaznika koji je trenutno upisan na neki drugi program.');

                }
                else if (err.response.data.message === 'This student is already enrolled in a formal study program and has not dropped out nor graduated!') {
                    toast('Ovaj student je već upisan na formalni program i nije ispisan niti je diplomirao!');

                }
                else {
                    toast('Došlo je do greške! Polaznik nije upisan.');

                }
                console.log('err', err)
            });
        }
    }
    if(redirect)return  <Navigate to={'/polaznici'}/>
    return (

        <div>
            <Segment.Group>
                <Segment color='teal' clearing className={'customPadding'}>
                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>
                        <Menu.Item > <Header as={'h3'}>
                            Kreiranje polaznika
                        </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            <Menu.Item>
                            <span>
                                <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                    <Icon name='arrow left'/>
                                </Button>
                                <Icon/>
                            </span>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>

                </Segment>
                <Segment clearing>
                    <Tab
                         //
                         menu={{ secondary: true, pointing: true, color: 'teal' }}
                         panes={panes}
                         activeIndex={state.activeIndex}
                         onTabChange={handleTabChange}
                    />
                </Segment>

            </Segment.Group>
            <ToastContainer/>
        </div>
    );
};

export default AddStudent;
