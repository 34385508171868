import React, {Fragment, useContext, useEffect, useReducer, useState} from 'react';
import {Button, Form, Header, Icon, Loader, Menu, Segment} from "semantic-ui-react";
import {toast, ToastContainer} from 'react-toastify';
import {UserRoleContext} from "../../Main";
import EnrolledStudents from "./EnrolledStudents";
import AllTeachers from "./AllTeachers";
import DocumentTypes from "./DocumentTypes";
import axios from "axios";


const reducer = (state, action)=> {
    switch (action.type) {
        case 'reportId':
            return {...state, reportId: action.payload};
        default:
            return state;

    }
}

const Statistics = () => {
    const {activeRole} = useContext(UserRoleContext);

    const [state, dispatch] = useReducer(reducer,{
        reportId: localStorage.getItem('reportId') ? parseInt(localStorage.getItem('reportId')) : null,
    });

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        localStorage.setItem('reportId', value);
    }


    const reports = [
        {
            key: 0,
            value: 0,
            text: 'Ukupan broj polaznika'
        },
        {
            key: 1,
            value: 1,
            text: 'Polaznici koji su završili školovanje'
        },
        {
            key: 2,
            value: 2,
            text: 'Polaznici koji su odustali od školovanja'
        },
        {
            key: 3,
            value: 3,
            text: 'Polaznici koji su trenutno na školovanju'
        },
        {
            key: 4,
            value: 4,
            text: 'Polaznici formalnog obrazovanja'
        },
        {
            key: 5,
            value: 5,
            text: 'Polaznici neformalnog obrazovanja'
        },
        {
            key: 6,
            value: 6,
            text: 'Polaznici po strukama'
        },
        {
            key: 7,
            value: 7,
            text: 'Polaznici po zanimanjima'
        },
        {
            key: 8,
            value: 8,
            text: 'Nastavnici'
        },
        {
            key: 9,
            value: 9,
            text: 'Zaduženja obrazaca'
        }
    ].filter(Boolean);


    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Statistika </Header>
                        </Menu.Item>
                    </Menu>

                </Segment>

                <Segment><Form size={'small'}>
                    <Form.Group widths="3">
                        <Form.Field>
                            <label>Izvještaji</label>
                            <Form.Dropdown
                                placeholder="Odaberite izvještaj"
                                value={state.reportId}
                                onChange={handleInputChange}
                                name="reportId"
                                options={reports}
                                search
                                fluid
                                selection
                                clearable
                            />
                        </Form.Field>
                    </Form.Group>
                </Form></Segment>

                {(localStorage.getItem('reportId') === '0') &&
                <Segment clearing>
                    <EnrolledStudents reportId={localStorage.getItem('reportId')} allStudents  />
                </Segment>}

                {localStorage.getItem('reportId') === '1' &&
                <Segment clearing>
                    <EnrolledStudents reportId={localStorage.getItem('reportId')} gradStudents  />
                </Segment>}

                {localStorage.getItem('reportId') === '2' &&
                <Segment clearing>
                    <EnrolledStudents reportId={localStorage.getItem('reportId')} droppedOutStudents  />
                </Segment>}

                {localStorage.getItem('reportId') === '3' &&
                <Segment clearing>
                    <EnrolledStudents reportId={localStorage.getItem('reportId')} currentStudents  />
                </Segment>}

                {localStorage.getItem('reportId') === '4' &&
                <Segment clearing>
                    <EnrolledStudents reportId={localStorage.getItem('reportId')} formalEducation  />
                </Segment>}

                {localStorage.getItem('reportId') === '5' &&
                <Segment clearing>
                    <EnrolledStudents reportId={localStorage.getItem('reportId')} informalEducation  />
                </Segment>}

                {localStorage.getItem('reportId') === '6' &&
                <Segment clearing>
                    <EnrolledStudents reportId={localStorage.getItem('reportId')} profession />
                </Segment>}

                {localStorage.getItem('reportId') === '7' &&
                <Segment clearing>
                    <EnrolledStudents reportId={localStorage.getItem('reportId')} occupation />
                </Segment>}

                {localStorage.getItem('reportId') === '8' &&
                <Segment clearing>
                    <AllTeachers reportId={localStorage.getItem('reportId')}  />
                </Segment>}

                {localStorage.getItem('reportId') === '9' &&
                <Segment clearing>
                    <DocumentTypes reportId={localStorage.getItem('reportId')}  />
                </Segment>}

            </Segment.Group>
            <ToastContainer  />
        </div>
    );
};

export default Statistics;
