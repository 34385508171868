import React, {useContext, useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Button, Form, Header, Icon, Loader, Menu} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import axios from "axios";
import CommitteeForm from "./CommitteeForm";
import {UserRoleContext} from "../../../Main";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import moment from "moment";
import {toast, ToastContainer} from "react-toastify";
import SearchTableData from "../../Utilities/SearchTableData";
import PaginationFront from "../../Utilities/PaginationFront";
import ReactExport from 'react-data-export';

const reducer = (state, action)=>{
    switch (action.type) {
        case 'committees':
            return {...state, committees: action.payload, loader:  false };
        case 'committeesData':
            return {...state, committeesData: action.payload, loader: false};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'committee':
            return {...state, committee: action.payload };
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;
    }
};
const Committee = () => {
    const {activeRole} = useContext(UserRoleContext);
    const [activePage, setActivePage] = useState(1);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [state, dispatch] = useReducer(reducer,{
        committees:[],
        committeesData: [],
        committee: null,
        currentId: null,
        organizer: '',
        organizers: [],
        loader: true,
        data:[],
        results:[],
        postsPerPage: 20,
        currentPosts: [],
    } );
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    const columns = [
        { title: 'Naziv komisije', width: { wpx: 150 } },
        { title: 'Datum kreiranja', width: { wpx: 150 } },
        { title: 'Ime (predsjednik komsije)', width: { wpx: 200 } },
        { title: 'Prezime (predsjednik komsije)', width: { wpx: 200 } },
        { title: 'Ime (ispitivač)', width: { wpx: 200 } },
        { title: 'Prezime (ispitivač)', width: { wpx: 200 } },
        { title: 'Ime (treći član)', width: { wpx: 200 } },
        { title: 'Prezime (treći član)', width: { wpx: 200 } },
        { title: 'Napomena', width: { wpx: 200 } },
    ];

    if (activeRole !== "ROLE_ORGANIZER") {
        columns.unshift({ title: 'Organizator', width: { wpx: 350 } });
    }

    const multiDataSet = [
        {
            columns: columns,
            data: state.committeesData,
        }
    ];

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    useEffect(() => {
        if (activeRole === "ROLE_ORGANIZER") {
            getCommittees()
        }
        else if (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") {
            getOrganizers();
            getCommitteesAll();
        }
        // activeRole === "ROLE_ORGANIZER" && getCommittees();
        // activeRole === "ROLE_MINISTRY" && getOrganizers();
        // activeRole === "ROLE_ADMIN" && getOrganizers();

    },[activeRole]);

    function getCommittees(){
        dispatch({type: 'loader', payload: true});
        axios.get('/committee/by-organizer',).then(
            (res) => {
                // let committees = res.data;
                let committees = res.data.map(el => ({
                    ...el,
                    firstMemberFirstName: el.firstMember.person.firstName,
                    firstMemberLastName: el.firstMember.person.lastName,
                    secondMemberFirstName: el.secondMember.person.firstName,
                    secondMemberLastName: el.secondMember.person.lastName,
                    thirdMemberFirstName: el.thirdMember.person.firstName,
                    thirdMemberLastName: el.thirdMember.person.lastName
                }))
                dispatch({type: 'committees', payload: committees});
                dispatch({type: 'data', payload: committees});
                let committeesData = res.data.map(el => ([
                    { value: el.name },
                    { value: moment(el.createdDate).format('D. M. YYYY.') },
                    { value: el.firstMember.person.firstName },
                    { value: el.firstMember.person.lastName},
                    { value: el.secondMember.person.firstName },
                    { value: el.secondMember.person.lastName },
                    { value: el.thirdMember.person.firstName },
                    { value: el.thirdMember.person.lastName },
                    { value: el.comment ? el.comment : '' },
                ]));
                dispatch({type: 'committeesData', payload: committeesData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getCommitteesAll(){
        dispatch({type: 'loader', payload: true});
        axios.get('/committee/all',).then(
            (res) => {
                // let committees = res.data;
                let committees = res.data.map(el => ({
                    ...el,
                    firstMemberFirstName: el.firstMember.person.firstName,
                    firstMemberLastName: el.firstMember.person.lastName,
                    secondMemberFirstName: el.secondMember.person.firstName,
                    secondMemberLastName: el.secondMember.person.lastName,
                    thirdMemberFirstName: el.thirdMember.person.firstName,
                    thirdMemberLastName: el.thirdMember.person.lastName
                }))
                dispatch({type: 'committees', payload: committees});
                dispatch({type: 'data', payload: committees});
                let committeesData = res.data.map(el => ([
                    { value: el.organizer.name },
                    { value: el.name },
                    { value: moment(el.createdDate).format('D. M. YYYY.') },
                    { value: el.firstMember.person.firstName },
                    { value: el.firstMember.person.lastName},
                    { value: el.secondMember.person.firstName },
                    { value: el.secondMember.person.lastName },
                    { value: el.thirdMember.person.firstName },
                    { value: el.thirdMember.person.lastName },
                    { value: el.comment ? el.comment : '' },
                ]));
                dispatch({type: 'committeesData', payload: committeesData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    //for admin
    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getCommitteeForAdmin(id){
        axios.get(`/committee/by-organizer-id/${id}`).then(
            (res) => {
                // let committees = res.data;
                let committees = res.data.map(el => ({
                    ...el,
                    firstMemberFirstName: el.firstMember.person.firstName,
                    firstMemberLastName: el.firstMember.person.lastName,
                    secondMemberFirstName: el.secondMember.person.firstName,
                    secondMemberLastName: el.secondMember.person.lastName,
                    thirdMemberFirstName: el.thirdMember.person.firstName,
                    thirdMemberLastName: el.thirdMember.person.lastName
                }))
                dispatch({type: 'committees', payload: committees});
                dispatch({type: 'data', payload: committees});
                let committeesData = res.data.map(el => ([
                    { value: el.organizer.name },
                    { value: el.name },
                    { value: moment(el.createdDate).format('D. M. YYYY.') },
                    { value: el.firstMember.person.firstName },
                    { value: el.firstMember.person.lastName},
                    { value: el.secondMember.person.firstName },
                    { value: el.secondMember.person.lastName },
                    { value: el.thirdMember.person.firstName },
                    { value: el.thirdMember.person.lastName },
                    { value: el.comment ? el.comment : '' },
                ]));
                dispatch({type: 'committeesData', payload: committeesData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            dispatch({type: 'openTable', payload: true});
            getCommitteeForAdmin(value);
        }
        if(name === 'organizer' && !value){
            dispatch({type: 'openTable', payload: true});
            getCommitteesAll()
        }
    }

    function deleteCommitteeById(id){
        setCurrentId(id);
        axios.delete(`/committee/delete/${id}`,).then(
            (res) => {
                activeRole && activeRole === "ROLE_ORGANIZER" ? getCommittees() : activeRole && getCommitteeForAdmin(state.organizer);
                toast('Uspješno ste izbrisali komisiju!');
                setCurrentId(null);

            }).catch((err) => {

            if (err.response.data.message === 'This committee cannot be deleted because of existing recognitions for it!') {
                toast('Komisija se nalazi na nekom rješenju za priznavanje pa je nije dozvoljeno obrisati.');
            } else if(err.response.data.message === 'This committee cannot be deleted because of existing exam marks for it!'){
                toast('Komisija je zadužena za neke ocjene pa je nije dozvoljeno obrisati.');
            }
            else {
                toast('Došlo je do greške! Brisanje komisije nije uspjelo.')
            }
            //toast('Nije dozvoljeno brisanje komisije koja je povezana sa nekim priznavanjem ili ispitom!');
            setCurrentId(null);

        });
    }

    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>
                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Spisak komisija </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            {activeRole !== "ROLE_MINISTRY" &&<Menu.Item>
                                    <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                        <Icon name='add' color={"teal"}/>
                                        Dodaj komisiju
                                    </Button>
                            </Menu.Item>}
                            <Menu.Item>
                                <ExcelFile filename="Spisak komisije" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={multiDataSet} name="Komisija" />
                                </ExcelFile>
                            </Menu.Item>
                            <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Segment>

                {showAddForm &&
                    <Segment>
                        <CommitteeForm add toggleEditForm={toggleAddForm} getCommittees={getCommittees} getCommitteesForAdmin={getCommitteeForAdmin}   />
                    </Segment>
                }

                {showEditForm && state.committee &&
                <Segment>
                    <CommitteeForm edit toggleEditForm={toggleEditForm} getCommittees={getCommittees} committee={state.committee} getCommitteesForAdmin={getCommitteeForAdmin} />
                </Segment>
                }
                {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Segment><Form size={'small'}>
                    <Form.Group widths="3">
                        <Form.Field>
                            <label>Organizator</label>
                            <Form.Dropdown
                                placeholder="Odaberite organizatora"
                                value={state.organizer}
                                onChange={handleInputChange}
                                name="organizer"
                                options={state.organizers}
                                search
                                fluid
                                selection
                                clearable
                            />
                        </Form.Field>
                    </Form.Group>
                </Form></Segment>}
                <Segment>
                    {state.loader ?
                        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                        // :(state.organizer || activeRole === "ROLE_ORGANIZER") &&
                        : currentPosts.length !== 0 ?

                            <Table celled size={"small"}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={activeRole !== "ROLE_ORGANIZER" ? 11 : 9} >
                                    <div style={{ paddingLeft: '0px' }}>
                                        <SearchTableData data={state.data} getResults={getResults} filterItem={['name','comment', 'firstMemberFirstName', 'firstMemberLastName', 'secondMemberFirstName', 'secondMemberLastName', 'thirdMemberFirstName', 'thirdMemberLastName']} />
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                                {activeRole !== "ROLE_ORGANIZER"  &&<Table.HeaderCell colSpan={2} >Organizator</Table.HeaderCell>}
                                <Table.HeaderCell>Naziv komisije</Table.HeaderCell>
                                <Table.HeaderCell>Datum kreiranja</Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Header size={"small"}>
                                        Prvi član
                                        <Header.Subheader>
                                            <i>Predsjednik komisije</i>
                                        </Header.Subheader>
                                    </Header>

                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Header size={"small"}>
                                        Drugi član
                                        <Header.Subheader>
                                            <i>Ispitivač</i>
                                        </Header.Subheader>
                                    </Header>
                                </Table.HeaderCell>
                                <Table.HeaderCell>Treći član</Table.HeaderCell>
                                {/*<Table.HeaderCell>Rezervni član</Table.HeaderCell>*/}
                                <Table.HeaderCell>Napomena</Table.HeaderCell>
                                {activeRole !== "ROLE_MINISTRY" &&<Table.HeaderCell/>}
                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {
                                currentPosts.map((el, index) =>
                                // state.committees.map((el, index)=>
                                    (<Table.Row key={el.id}>
                                        <Table.Cell width={1}>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                        {activeRole !== "ROLE_ORGANIZER"  &&<Table.Cell colSpan={2}>{el.organizer.name}</Table.Cell>}
                                        <Table.Cell >{el.name}</Table.Cell>
                                        <Table.Cell >{moment(el.createdDate).format('D. M. YYYY.')}</Table.Cell>
                                        <Table.Cell>{el.firstMember.person.firstName + ' ' + el.firstMember.person.lastName}</Table.Cell>
                                        <Table.Cell>{el.secondMember.person.firstName + ' ' + el.secondMember.person.lastName}</Table.Cell>
                                        <Table.Cell>{el.thirdMember.person.firstName + ' ' + el.thirdMember.person.lastName}</Table.Cell>
                                        {/*<Table.Cell>{el.reserveMember ? el.reserveMember.person.firstName + ' ' + el.reserveMember.person.lastName : ''}</Table.Cell>*/}
                                        <Table.Cell>{el.comment}</Table.Cell>
                                        {activeRole !== "ROLE_MINISTRY" && <Table.Cell verticalAlign="middle" textAlign="center">

                                            <Popup size={"small"} content='Izmijeni komisiju' trigger={
                                                <Button icon size={"mini"} basic color={"grey"}  onClick={()=>{dispatch({type: 'committee', payload: el}); toggleEditForm()}}>
                                                    <Icon name='pencil' color={"teal"} />
                                                </Button>} />

                                            <Popup size={"small"} content='Obriši komisiju' trigger={
                                                <Button icon size={"mini"} basic color={"grey"} disabled={state.currentId && el.id !== state.currentId }
                                                        onClick={() => {deleteCommitteeById(el.id)}}
                                                >
                                                    <Icon name='trash alternate outline' color={"red"} />
                                                </Button>} />

                                        </Table.Cell>}
                                    </Table.Row>)
                                )
                            }

                        </Table.Body>
                    </Table>:
                            <div>Nema unesenih podataka o komisijama.</div>
                    }
                    {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                </Segment>
            </Segment.Group>
            <ToastContainer/>
        </div>

    );
};

export default Committee;
