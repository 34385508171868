import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Button, Form, Header, Icon, GridColumn, GridRow, Loader} from "semantic-ui-react";
import {toast, ToastContainer} from 'react-toastify';
import {UserRoleContext} from "../../Main";
import axios from "axios";
import moment from "moment";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import SearchTableData from "../Utilities/SearchTableData";
import ReactExport from "react-data-export";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {Link} from "react-router-dom";


const reducer = (state, action)=> {
    switch (action.type) {
        case 'teachers':
            return {...state, teachers: action.payload, loader:  false};
        case 'teachersData':
            return {...state, teachersData: action.payload, loader:  false};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        default:
            return state;

    }
}

const AllTeachers = ({reportId}) => {
    const {activeRole} = useContext(UserRoleContext);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [state, dispatch] = useReducer(reducer,{
        teachers: [],
        teachersData: [],
        data: [],
        results: [],
        loader: false,
        organizer: null,
        organizers: []

    });

    const multiDataSet = [
        {
            columns: [
                { title: 'JMBG', width: { wpx: 150 } },
                { title: 'Ime', width: { wpx: 200 } },
                { title: 'Prezime', width: { wpx: 200 } },
                { title: 'Matično zaposlenje', width: { wpx: 200 } },
                { title: 'Broj časova sedmično', width: { wpx: 200 } },
            ],
            data: state.teachersData,
        }
    ];

    function getResults(results) {
        dispatch({type: 'results', payload: results});
    }

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            getTeachersForAdmin(value)
        }
        if(name === 'organizer' && !value && reportId === '8'){
            getTeachersAll()
        }
    }

    useEffect(() => {
        if (activeRole === "ROLE_ORGANIZER" && reportId === '8') {
            getTeachers();
        }
        else {
            getOrganizers()
            if((activeRole === "ROLE_ADMIN" || activeRole=== "ROLE_MINISTRY")  && (reportId === '8'))
            {
                getTeachersAll()
            }
        }
    },[activeRole]);

    function getTeachers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/teacher/by-organizer',).then(
            (res) => {
                // let teachers = res.data;
                let teachers = res.data.map(el => ({
                    ...el,
                    personJMBG: el.person.jmbg,
                    personFirst: el.person.firstName,
                    personLast: el.person.lastName
                }))
                dispatch({type: 'teachers', payload: teachers});
                dispatch({type: 'data', payload: teachers});
                let teachersData = res.data.map(el => ([
                    { value: el.person.jmbg },
                    { value: el.person.firstName },
                    { value: el.person.lastName },
                    { value: el.originPlaceOfEmployment},
                    { value: el.numberOfHoursInOriginPlaceOfEmployment },
                ]));
                dispatch({type: 'teachersData', payload: teachersData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getTeachersAll(){
        dispatch({type: 'loader', payload: true});
        axios.get('/teacher/all',).then(
            (res) => {
                // let teachers = res.data;
                let teachers = res.data.map(el => ({
                    ...el,
                    personJMBG: el.person.jmbg,
                    personFirst: el.person.firstName,
                    personLast: el.person.lastName
                }))
                dispatch({type: 'teachers', payload: teachers});
                dispatch({type: 'data', payload: teachers});
                let teachersData = res.data.map(el => ([
                    { value: el.person.jmbg },
                    { value: el.person.firstName },
                    { value: el.person.lastName },
                    { value: el.originPlaceOfEmployment ? el.originPlaceOfEmployment : ''},
                    { value: el.numberOfHoursInOriginPlaceOfEmployment ? el.numberOfHoursInOriginPlaceOfEmployment : '' },
                ]));
                dispatch({type: 'teachersData', payload: teachersData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function getTeachersForAdmin(id){
        setCurrentId(id);
        setCurrentIdOrganizer(id);
        dispatch({type: 'loader', payload: true});
        dispatch({type: 'openTable', payload: true});
        axios.get(`/teacher/by-organizer-id/${id}`,).then(
            (res) => {
                // let teachers = res.data;
                let teachers = res.data.map(el => ({
                    ...el,
                    personJMBG: el.person.jmbg,
                    personFirst: el.person.firstName,
                    personLast: el.person.lastName
                }))
                dispatch({type: 'teachers', payload: teachers});
                dispatch({type: 'data', payload: teachers});
                let teachersData = res.data.map(el => ([
                    { value: el.person.jmbg },
                    { value: el.person.firstName },
                    { value: el.person.lastName },
                    { value: el.originPlaceOfEmployment},
                    { value: el.numberOfHoursInOriginPlaceOfEmployment },
                ]));
                dispatch({type: 'teachersData', payload: teachersData});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }


    return (
        <div>
            <Form size={'small'}>
                <Form.Group widths="3">
                    {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY" ) &&
                    <Form.Field>
                        <label>Organizator</label>
                        <Form.Dropdown
                            placeholder="Odaberite organizatora"
                            value={state.organizer}
                            onChange={handleInputChange}
                            name="organizer"
                            options={state.organizers}
                            search
                            fluid
                            selection
                            clearable
                        />
                    </Form.Field>}
                </Form.Group>
            </Form>
            {/*{((activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && !state.organizer) ? '' :*/}
            {state.loader ?
                <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                : state.teachers.length ?<Table celled size={"small"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan={8} style={{ padding: '0px' }}>
                                <Grid column={2}>
                                    <Grid.Column width={14}>
                                        <SearchTableData data={state.data} getResults={getResults} filterItem={['originPlaceOfEmployment', 'numberOfHoursInOriginPlaceOfEmployment', 'personJMBG', 'personLast', 'personFirst']} />
                                    </Grid.Column>
                                    <Grid.Column width={2} textAlign="center">
                                        <ExcelFile filename={"Ukupan broj nastavnika"} element={<Popup
                                            trigger={<Button animated="vertical" color='green' basic floated='right' fluid style={{ textAlign: 'center' }}>
                                                <Button.Content hidden> <Icon name="download" /></Button.Content>
                                                <Button.Content visible>
                                                    Excel
                                                </Button.Content>
                                            </Button>}
                                            content="Preuzmi spisak u Excel-u"
                                            hideOnScroll
                                            position="top center"
                                        />}>
                                            <ExcelSheet dataSet={multiDataSet} name="Nastavnici" />
                                        </ExcelFile>
                                    </Grid.Column>
                                </Grid>
                                {/*<div style={{ paddingLeft: '0px' }}>*/}
                                {/*    <SearchTableData data={state.data} getResults={getResults} filterItem={['startDateFilter', 'jmbg', 'firstName', 'lastName', 'supplementaryEducationType', 'occupation', 'nameBosnian', 'studyProgramFilter']} />*/}
                                {/*</div>*/}
                            </Table.HeaderCell>
                            {/*<Table.HeaderCell colSpan={9} >*/}
                            {/*    /!*<div style={{ paddingLeft: '0px' }}>*!/*/}
                            {/*    /!*    <SearchTableData data={state.data} getResults={getResults} filterItem={['originPlaceOfEmployment', 'numberOfHoursInOriginPlaceOfEmployment', 'personJMBG', 'personLast', 'personFirst']} />*!/*/}
                            {/*    /!*</div>*!/*/}
                            {/*</Table.HeaderCell>*/}
                        </Table.Row>
                        <Table.Row>

                            <Table.HeaderCell width={1} >R. br.</Table.HeaderCell>
                            <Table.HeaderCell>JMBG</Table.HeaderCell>
                            <Table.HeaderCell>
                                <Header size={"small"}>
                                    Ime i prezime
                                    <span><Icon/></span>
                                    <Header.Subheader>
                                        <i>Nastavnik/ica</i>
                                    </Header.Subheader>
                                </Header>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Header size={"small"}>
                                    Matično zaposlenje
                                    <span><Icon/></span>
                                    <Header.Subheader>
                                        <i>Institucija</i>
                                    </Header.Subheader>
                                </Header>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Header size={"small"}>
                                    Broj časova sedmično
                                    <span><Icon/></span>
                                    <Header.Subheader>
                                        <i>Matično zaposlenje</i>
                                    </Header.Subheader>
                                </Header>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            getDataToRender().map((el, index) =>
                                // state.teachers.map((el, index)=>
                                (<Table.Row key={el.id}>
                                    <Table.Cell width={1}>{index + 1}.</Table.Cell>
                                    <Table.Cell>{el.person.jmbg}</Table.Cell>
                                    <Table.Cell>{el.person.firstName} {el.person.lastName}</Table.Cell>
                                    <Table.Cell>{el.originPlaceOfEmployment}</Table.Cell>
                                    <Table.Cell>{el.numberOfHoursInOriginPlaceOfEmployment}</Table.Cell>
                                </Table.Row>)
                            )
                        }
                    </Table.Body>
                    <Table.Footer>
                    </Table.Footer>
                </Table>: <div>Nema unesenih podataka o nastavnicima.</div>}

            <ToastContainer  />
        </div>
    );
};

export default AllTeachers;
