import React from 'react';
import { Icon, Pagination } from 'semantic-ui-react';

const PaginationFront = ({postsPerPage, totalPosts, paginate, activePage}) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <center>
            <Pagination
                defaultActivePage={1}
                totalPages={pageNumbers.length}
                onPageChange={paginate}
                activePage={activePage}
                ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                siblingRange={0}
                pointing
                secondary
            />
        </center>
    );
};

export default PaginationFront;
