import React, { useState, useEffect } from 'react';
import { Form, Search } from 'semantic-ui-react';
import _ from 'lodash';

function SearchTableData({ data, filterItem, getResults, resetValue, activeIndex, organizerId }) {
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [value, setValue] = useState('');

    const handleSearchChange = (e, { value }) => {
        setIsLoading(true);
        setValue(value);

        setTimeout(() => {
            if (value.length < 1) {
                setIsLoading(false);
                setResults([]);
                setValue('');
                getResults([]);
                return;
            }

            const re = new RegExp(_.escapeRegExp(value), 'i');

            const isMatch = (result) => {
                return filterItem.some((el) => re.test(result[el]));
            };

            setResults(_.filter(data, isMatch));
            setIsLoading(false);
            getResults(_.filter(data, isMatch));
            // getResults(results);
        }, 300);
    };

    function reset(){
        setValue('')
        setResults([]);
        getResults([]);
    }

    useEffect(() => {
            getResults(results);
    }, [results, getResults]);

    useEffect(() => {

        if(resetValue)
        {
            reset();
            // getResults(results);
        }

    }, [resetValue]);

    useEffect(() => {
        reset();
    }, [activeIndex, organizerId]);

    return (
        <Form>
            <Form.Field fluid>
                <Search
                    input={{ icon: 'search', iconPosition: 'left' }}
                    loading={isLoading}
                    onSearchChange={_.debounce(handleSearchChange, 500, {
                        leading: true,
                    })}
                    placeholder="Pretraga"
                    value={value}
                    open={results.length === 0 && value.length !== 0 && !isLoading}
                    noResultsMessage="Nema podataka."
                />
            </Form.Field>
        </Form>
    );
}

export default SearchTableData;
