import React from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {Header} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import moment from "moment";

const ExamsOnRegistration = ({applicationDate, courses}) => {
    return (
        <div>
            <Table celled size={"small"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Header as={'h5'} floated={"left"}>
                                Prijavljeni ispiti
                                <span>
                                                    <Icon/></span>
                                <Header.Subheader>
                                    <i>Datum prijave: {moment(applicationDate).format('D. M. YYYY.')}</i>
                                </Header.Subheader>
                            </Header>
                        </Table.HeaderCell>

                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>
                            {/*<Checkbox*/}
                            {/*    label='Odaberi sve'*/}
                            {/*    onChange={toggleSelectAllFlag}*/}
                            {/*    checked={selectAllFlag}*/}
                            {/*/>*/}
                        </Table.HeaderCell>

                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {courses.map((el, index)=>(
                        <Table.Row key={index}>
                            <Table.Cell colSpan={2}>

                                {/*<Checkbox*/}
                                {/*    label={el.course.nameBosnian}*/}
                                {/*    onChange={()=>selectCourse(el)}*/}
                                {/*    checked={selectedCourses.length !== 0 ?( selectedCourses.filter((e)=>(e === el)).length !== 0): false}*/}
                                {/*/>*/}

                                {el.courseStudyProgram.course.nameBosnian}

                            </Table.Cell>
                        </Table.Row>))
                    }
                    <Table.Row >
                        {/*<Table.Cell colSpan={2}>*/}
                        {/*    <Button disabled floated={"right"} basic size={"mini"} color={"red"} >{'Ukloni ispite sa prijave'}</Button>*/}
                        {/*</Table.Cell>*/}
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    );
};

export default ExamsOnRegistration;
