import React, {useReducer} from 'react';
import {Form, Input, Select} from 'semantic-ui-react';

const reducer = (state, action)=> {
    switch (action.type) {
        case 'country':
            return {...state,
                country: action.payload,
                enableEntity: action.payload.key === 'bih',
                entity:  '',
                canton:  '',
                municipality:  '',
                enableCanton: action.payload.key === 'bih',
                enableMunicipality: action.payload.key === 'bih',
                // country: action.payload,
                // enableEntity: action.payload.key === 'bih',
                // entity: action.payload ? state.entity: '',
                // canton: action.payload ? state.canton: '',
                // municipality: action.payload ? state.municipality: '',
                // enableCanton: action.payload ? state.enableCanton: false,
                // enableMunicipality: action.payload ? state.enableMunicipality: false,
            };
        case 'entity':
            return {...state,
                entity: action.payload,
                enableCanton: action.payload? action.payload.key === 'fbih': false ,
                canton: action.payload && action.payload.key === 'fbih' ? state.canton: '',
                enableMunicipality: action.payload ? action.payload.key !== 'fbih': false,

            };
        case 'canton':
            return {...state,
                canton: action.payload,
                enableMunicipality: !!action.payload,
                municipality: '',
            };
        case 'municipality':
            return {...state, municipality: action.payload };
        case 'place':
            return {...state, place: action.payload};
        case 'address':
            return {...state, address: action.payload };
        case 'zip':
            return {...state, zip: action.payload };
        default:
            return state;

}
};
const PlaceForm = ({ allDisabled, oneRowTwoFields, threeRowsThreeFields, noPlaceNoAddress, noAddress, size, entities, entity, countries, canton, cantons, municipality, country,
                       place, address, zip, noZip, countryErr, placeErr, addressErr, zipErr, handlePlaceChange, formName, municipalities, enableMunicipality, enableEntity, enableCanton}) => {
    const [state, dispatch] = useReducer(reducer,{
        // fields
        country: country ? country : '',
        entity:  entity ? entity : '',
        canton: canton ? canton : '',
        municipality:  municipality ? municipality : '',
        place: place ? place : '',
        address: address ? address : '',
        zip: zip ? zip : '',
        // disable properties
        enableEntity: enableEntity,
        enableCanton: enableCanton,
        enableMunicipality: enableMunicipality

    });

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        let obj = {
            country: state.country,
            place: state.place,
            zip: state.zip,
            address: state.address,
            entity: state.entity,
            canton: state.canton,
            municipality: state.municipality
        };
        obj = {...obj,
          [name]: value
        };
        handlePlaceChange(formName, obj);
    }

    return (
            <Form size={size}>
                {(threeRowsThreeFields) && <Form.Group widths={'equal'}><Form.Field
                    disabled={allDisabled}
                    required
                    control={Select}
                    clearable
                    options={countries}
                    placeholder="Država"
                    label="Država"
                    onChange={handleInputChange}
                    name="country"
                    selectOnNavigation={false}
                    openOnFocus={false}
                    value={state.country}
                    search
                    error={countryErr}

                />

                <Form.Field
                    disabled={!state.enableEntity || allDisabled}
                    required
                    control={Select}
                    clearable
                    options={entities}
                    placeholder="Entitet"
                    label="Entitet"
                    onChange={handleInputChange}
                    name="entity"
                    selectOnNavigation={false}
                    openOnFocus={false}
                    value={state.entity}
                    search

                />

                <Form.Field
                    disabled={!state.enableCanton || allDisabled}
                    required
                    clearable
                    control={Select}
                    options={cantons}
                    placeholder="Kanton"
                    label="Kanton"
                    onChange={handleInputChange}
                    name="canton"
                    selectOnNavigation={false}
                    openOnFocus={false}
                    value={state.canton}
                    search
                /></Form.Group>}

                {(!threeRowsThreeFields) && <Form.Field
                    disabled={allDisabled}
                    required
                    control={Select}
                    clearable
                    options={countries}
                    placeholder="Država"
                    label="Država"
                    onChange={handleInputChange}
                    name="country"
                    selectOnNavigation={false}
                    openOnFocus={false}
                    value={state.country}
                    search
                    error={countryErr}

                />}

                {(!threeRowsThreeFields) && <Form.Field
                    disabled={!state.enableEntity || allDisabled}
                    required
                    control={Select}
                    clearable
                    options={entities}
                    placeholder="Entitet"
                    label="Entitet"
                    onChange={handleInputChange}
                    name="entity"
                    selectOnNavigation={false}
                    openOnFocus={false}
                    value={state.entity}
                    search

                />}

                {(!threeRowsThreeFields) && <Form.Field
                    disabled={!state.enableCanton || allDisabled}
                    required
                    clearable
                    control={Select}
                    options={cantons}
                    placeholder="Kanton"
                    label="Kanton"
                    onChange={handleInputChange}
                    name="canton"
                    selectOnNavigation={false}
                    openOnFocus={false}
                    value={state.canton}
                    search
                />}
                {(threeRowsThreeFields) && <Form.Group widths={'equal'}>
                <Form.Field
                    required
                    //key={state.municipality.id}
                    disabled={!state.enableMunicipality || allDisabled}
                    control={Select}
                    clearable
                    options={state.canton?municipalities.filter((el)=>(el.value.canton && el.value.canton.key  === state.canton.key)):municipalities.filter((el)=>(el.value.entity && el.value.entity.key  === state.entity.key))}
                    placeholder="Općina"
                    label="Općina"
                    value={state.municipality}
                    onChange={handleInputChange}
                    name="municipality"
                    search
                    openOnFocus={false}


                />
                <Form.Field
                    required
                    //disabled={localStorage.getItem('associate') === 'true' && this.props.currentResidence}
                    disabled={allDisabled}
                    control={Input}
                    placeholder="Mjesto"
                    label="Mjesto"
                    value={state.place}
                    onChange={handleInputChange}
                    name="place"
                    error={placeErr}
                /></Form.Group>}

                {(!threeRowsThreeFields) && <Form.Field
                    required
                    //key={state.municipality.id}
                    disabled={!state.enableMunicipality || allDisabled}
                    control={Select}
                    clearable
                    options={state.canton?municipalities.filter((el)=>(el.value.canton && el.value.canton.key  === state.canton.key)):municipalities.filter((el)=>(el.value.entity && el.value.entity.key  === state.entity.key))}
                    placeholder="Općina"
                    label="Općina"
                    value={state.municipality}
                    onChange={handleInputChange}
                    name="municipality"
                    search
                    openOnFocus={false}


                />}
                {(!threeRowsThreeFields) && <Form.Field
                    required
                    //disabled={localStorage.getItem('associate') === 'true' && this.props.currentResidence}
                    disabled={allDisabled}
                    control={Input}
                    placeholder="Mjesto"
                    label="Mjesto"
                    value={state.place}
                    onChange={handleInputChange}
                    name="place"
                    error={placeErr}
                />}

                {(oneRowTwoFields || threeRowsThreeFields) && <Form.Group widths={'equal'}>
                    {!noZip && <Form.Field
                        //disabled={localStorage.getItem('associate') === 'true' && this.props.currentResidence}
                        control={Input}
                        disabled={allDisabled}
                        placeholder="Poštanski broj"
                        label="Poštanski broj"
                        value={state.zip}
                        onChange={handleInputChange }
                        //error={this.props.addressErr}
                        name="zip"
                        type="number"
                        required
                        error={zipErr}
                    />}
                    {!noAddress && <Form.Field
                        //disabled={localStorage.getItem('associate') === 'true' && this.props.currentResidence}
                        control={Input}
                        disabled={allDisabled}
                        placeholder="Adresa"
                        label="Adresa"
                        value={state.address}
                        onChange={handleInputChange}
                        //error={this.props.addressErr}
                        name="address"
                        required
                        error={addressErr}
                    />}
                </Form.Group>}
                {!noZip && !oneRowTwoFields && !noPlaceNoAddress && !threeRowsThreeFields &&<Form.Field
                    //disabled={localStorage.getItem('associate') === 'true' && this.props.currentResidence}
                    control={Input}
                    disabled={allDisabled}
                    placeholder="Poštanski broj"
                    label="Poštanski broj"
                    value={state.zip}
                    onChange={handleInputChange }
                    name="zip"
                    type="number"
                    required
                    error={zipErr}
                />}
                {!noAddress && !oneRowTwoFields && !noPlaceNoAddress && !threeRowsThreeFields && <Form.Field
                    //disabled={localStorage.getItem('associate') === 'true' && this.props.currentResidence}
                    control={Input}
                    disabled={allDisabled}
                    placeholder="Adresa"
                    label="Adresa"
                    value={state.address}
                    onChange={handleInputChange }
                    //error={this.props.addressErr}
                    name="address"
                    required
                    error={addressErr}
                />}


            </Form>

    );
};

export default PlaceForm;
