import React from 'react';
import MainMenu from "./MainMenu";

const MobileTopHeader = () => {
    return (

            <header className="mobile-top-header">
                <MainMenu dropdown/>
            </header>

    );
};

export default MobileTopHeader;
