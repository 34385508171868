import React, {useContext} from 'react';
import {Grid, Header, Icon, Segment} from "semantic-ui-react";
import {releaseAllKeys} from "@testing-library/user-event/dist/keyboard/keyboardImplementation";
import UserService from "../../service/UserService";
import {UserRoleContext} from "../../Main";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";



const OrganizerHome = () => {
    const username = UserService.getUsername();
    const {activeRoleObj} = useContext(UserRoleContext);
    return (
        <div >
            <Grid columns={3} stackable>
                <Grid.Column>
                    <Segment.Group>
                        <Segment className='textAlignCenter' color='teal'>
                            <Icon name='user' size='huge' />
                        </Segment>
                        <Segment >
                            <Table celled>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell><strong>Korisničko ime:</strong></Table.Cell>
                                        <Table.Cell>{username}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Segment.Group>
                </Grid.Column>
                <Grid.Column>
                    <Segment.Group >
                        <Segment className='textAlignCenter' color='teal'>
                            <Icon name='building outline' size='huge' />
                        </Segment>
                        <Segment >
                            <Table celled>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell><strong>Organizator:</strong></Table.Cell>
                                        <Table.Cell>{activeRoleObj.userOrganizer.organizer.name}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Segment.Group>
                </Grid.Column>
                <Grid.Column>
                    <Segment.Group>
                        <Segment className='textAlignCenter' color='teal'>
                            <Icon name='unlock' size='huge' />
                        </Segment>
                        <Segment >
                            <Table celled>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell><strong>Uloga:</strong></Table.Cell>
                                        <Table.Cell>{activeRoleObj.role.name}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Segment.Group>
                </Grid.Column>
            </Grid>

        </div>
    );
};

export default OrganizerHome;
