import React from 'react';
import axios from "axios";
import download from "downloadjs";
import {toast} from "react-toastify";

export const extractFileNameFromHeaders = (headers) => {
    // regexp to extract filename value from multipart header
    const reg = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const contentDispositionHeader = headers['content-disposition'];
    const effectiveFileNameMatch = reg.exec(contentDispositionHeader);
    // if filename is in header return it without quotes
    if (effectiveFileNameMatch) { return effectiveFileNameMatch[1]; }
    // fallback to a generic name based on file type if no fileName is provided
    const contentTypeHeader = headers['content-type'];
    if (contentTypeHeader === 'application/zip') return 'Documents.zip';
    return 'Document.pdf';
};


export const downloadStudentRegister = () => {

    axios({
        method: 'post',
        url: 'document/register-of-students-form',
        responseType: 'blob',
        // data: { ...obj }
    })
        .then(res => {
            if (res.data.size === 0) {
                return;
            }
            const fileName = extractFileNameFromHeaders(res.headers);
            download(res.data, fileName);
            return res;
        })
        .catch(err => {
            toast('Došlo je do greške!');
        });

};


