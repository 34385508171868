import React, {Fragment, useContext, useEffect, useReducer, useState} from 'react';
import {Form, Header, Icon, Input, SegmentGroup, Button, Segment, Loader, Menu} from "semantic-ui-react";
import axios from "axios";
import {toast, ToastContainer} from 'react-toastify';
import {UserRoleContext} from "../../Main";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import moment from "moment";
import ReactExport from 'react-data-export';
import SearchTableData from "../Utilities/SearchTableData";
import PaginationFront from "../Utilities/PaginationFront";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'subject':
            return {...state, subject: action.payload};
        case 'email':
            return {...state, email: action.payload};
        case 'text':
            return {...state, text: action.payload};
        case 'mailType':
            return {...state, mailType: action.payload};
        case 'mailTypes':
            return {...state, mailTypes: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'comments':
            return {...state, comments: action.payload};
        case 'commentsData':
            return {...state, commentsData: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        default:
            return state;

    }
}

const Comment = () => {

    const [emailErr, setEmailErr] = useState(false);
    const [textErr, setTextErr] = useState(false);
    const [mailTypeErr, setMailTypeErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [activePage, setActivePage] = useState(1);

    const [state, dispatch] = useReducer(reducer,{
       subject: '',
        email: '',
        text: '',
        loader: false,
        mailType: '',
        mailTypes: [],
        comments: [],
        commentsData:[],
        data:[],
        results:[],
        postsPerPage: 20,
        currentPosts: [],
        organizer: '',
        organizers: [],
    });

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    const multiDataSet = [
        {
            columns: [
                { title: 'Ime korisnika', width: { wpx: 250 } },
                { title: 'Prezime korisnika', width: { wpx: 250 } },
                { title: 'E-mail', width: { wpx: 250 } },
                { title: 'Organizator', width: { wpx: 400 } },
                { title: 'Vrsta komentara', width: { wpx: 200 } },
                { title: 'Vrijeme slanje komentara', width: { wpx: 300 } },
                { title: 'Sadržaj', width: { wpx: 1000 } },
            ],
            data: state.commentsData,
        }
    ];

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            dispatch({type: 'openTable', payload: true});
            getCommentsForAdmin(value);
        }
        if(name === 'organizer' && !value){
            // dispatch({type: 'openTable', payload: true});
            getComments();
        }
    }

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    useEffect(() => {
        getMailTypes();
        activeRole === "ROLE_ADMIN" && getComments();
        activeRole === "ROLE_ADMIN" && getOrganizers();
    },[activeRole]);

    function getMailTypes(){
        dispatch({type: 'loader', payload: true});
        axios.get('/mail-type/all',).then(
            (res) => {
                let mailTypes = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'mailTypes', payload: mailTypes});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getComments(){
        dispatch({type: 'loader', payload: true});
        axios.get('/mail/all',).then(
            (res) => {
                let comments = res.data.map(el => ({
                    ...el,
                    dateFilter: moment(el.date).format('D. M. YYYY.'),
                    userFirstName: el.userRole.userOrganizer.user.person.firstName,
                    userLastName: el.userRole.userOrganizer.user.person.lastName,
                    organizerFilter: el.userRole.userOrganizer.organizer.name,
                    mailTypeFilter: el.mailType.name
                }))
                // let comments = res.data;
                dispatch({type: 'comments', payload: comments});
                dispatch({type: 'data', payload: comments});
                let commentsData = res.data.map(el => ([
                    { value: el.userRole.userOrganizer.user.person.firstName},
                    { value: el.userRole.userOrganizer.user.person.lastName},
                    { value: el.mail},
                    { value: el.userRole.userOrganizer.organizer.name },
                    { value: el.mailType.name},
                    { value: moment(el.date).format('D. M. YYYY. - HH:mm')},
                    { value: el.message},
                ]));
                dispatch({type: 'commentsData', payload: commentsData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getCommentsForAdmin(id){
        dispatch({type: 'loader', payload: true});
        axios.get(`/mail/by-organizer-id/${id}`,).then(
            (res) => {
                let comments = res.data.map(el => ({
                    ...el,
                    dateFilter: moment(el.date).format('D. M. YYYY.'),
                    userFirstName: el.userRole.userOrganizer.user.person.firstName,
                    userLastName: el.userRole.userOrganizer.user.person.lastName,
                    organizerFilter: el.userRole.userOrganizer.organizer.name,
                    mailTypeFilter: el.mailType.name
                }))
                // let comments = res.data;
                dispatch({type: 'comments', payload: comments});
                dispatch({type: 'data', payload: comments});
                let commentsData = res.data.map(el => ([
                    { value: el.userRole.userOrganizer.user.person.firstName},
                    { value: el.userRole.userOrganizer.user.person.lastName},
                    { value: el.mail},
                    { value: el.userRole.userOrganizer.organizer.name },
                    { value: el.mailType.name},
                    { value: moment(el.date).format('D. M. YYYY. - HH:mm')},
                    { value: el.message},
                ]));
                dispatch({type: 'commentsData', payload: commentsData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }


    function sendComment(){
        if (!state.email) { setEmailErr(true); } else { setEmailErr(false); }
        if (!state.text) { setTextErr(true); } else { setTextErr(false); }
        if (!state.mailType) { setMailTypeErr(true); } else { setMailTypeErr(false); }
        if (!state.email || !state.text ) {
            toast('Niste unijeli sve podatke.');
        } else {
            let obj = {
                mailType: {id: state.mailType},
                subject: state.subject,
                message: state.text,
                mail: state.email,
                date: new Date()
            };
            axios.post('/mail/send', {...obj}).then(
                (res) => {
                    toast('Vaši komentari i prijedlozi su uspješno poslani.');
                    dispatch({type: 'mailType', payload: ''});
                    dispatch({type: 'subject', payload: ''});
                    dispatch({type: 'text', payload: ''});
                    dispatch({type: 'email', payload: ''});
                }).catch((err) => {
                console.log('Failed', err)
            });
        }
    }
    return (
        <div>
            <SegmentGroup raised>
                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Komentari i prijedlozi </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            {(activeRole === "ROLE_ADMIN") && <Menu.Item>
                                <ExcelFile filename="Spisak komentara i prijedloga" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={multiDataSet} name="Komentari i prijedlozi" />
                                </ExcelFile>
                            </Menu.Item>}
                            <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                        </Menu.Menu>

                    </Menu>

                </Segment>
                {/*<Segment color='teal'>*/}
                {/*    <Header as={'h3'}> Komentari i prijedlozi</Header>*/}
                {/*</Segment>*/}
                {(activeRole === "ROLE_ORGANIZER" || activeRole === "ROLE_MINISTRY") ? <Segment clearing>
                    <Form size={'small'} noValidate>
                        <Form.Group widths={'equal'}>

                            <Form.Field
                                label="Naslov poruke"
                                control={Input}
                                name="subject"
                                value={state.subject}
                                onChange={handleInputChange}
                                placeholder="Unesite naslov poruke"
                            />
                            <Form.Field required>
                                <label>Vrsta komentara</label>
                                <Form.Dropdown
                                    placeholder="Odaberite tip zahtjeva"
                                    value={state.mailType}
                                    onChange={handleInputChange}
                                    name="mailType"
                                    options={state.mailTypes}
                                    error={mailTypeErr}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>
                            <Form.Field
                                required
                                label="Vaša email adresa"
                                control={Input}
                                name="email"
                                value={state.email}
                                onChange={handleInputChange}
                                placeholder="email@adresa"
                                error={emailErr}
                            />
                        </Form.Group>
                        <Form.Field required>
                            <label> Tekst poruke </label>
                            <Form.TextArea
                                style={{ minHeight: 200 }}
                                name="text"
                                value={state.text}
                                onChange={handleInputChange}
                                placeholder='Unesite komentar ili prijedlog'
                                error={textErr}

                            />
                        </Form.Field>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={sendComment}>Pošalji</Button>

                    </Form>
                </Segment>:
                    <Segment>
                        {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Form size={'small'}>
                            <Form.Group widths="3">
                                <Form.Field>
                                    <label>Organizator</label>
                                    <Form.Dropdown
                                        placeholder="Odaberite organizatora"
                                        value={state.organizer}
                                        onChange={handleInputChange}
                                        name="organizer"
                                        options={state.organizers}
                                        clearable
                                        search
                                        fluid
                                        selection
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>}
                        {state.loader ?
                            <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                            :
                            currentPosts.length ?
                                <Table celled size={'small'}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan={activeRole !== "ROLE_ORGANIZER" ? 11 : 9} >
                                                <div style={{ paddingLeft: '0px' }}>
                                                    <SearchTableData data={state.data} getResults={getResults} filterItem={['message', 'mail', 'dateFilter', 'userFirstName', 'userLastName', 'organizerFilter','mailTypeFilter']} />
                                                </div>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>

                                            <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                                            {activeRole !== "ROLE_ORGANIZER"  &&<Table.HeaderCell colSpan={2} >Organizator</Table.HeaderCell>}
                                            <Table.HeaderCell >Korisnik/ica</Table.HeaderCell>
                                            <Table.HeaderCell width={2}>E-mail</Table.HeaderCell>
                                            {/*<Table.HeaderCell >Organizator</Table.HeaderCell>*/}
                                            <Table.HeaderCell width={2}>Vrsta komentara</Table.HeaderCell>
                                            <Table.HeaderCell width={2}>Vrijeme slanja komentara</Table.HeaderCell>
                                            <Table.HeaderCell colSpan={2}>Sadržaj</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            currentPosts.map((el, index)=>
                                                (<Table.Row key={el.id}>
                                                    <Table.Cell width={1}>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                                    {activeRole !== "ROLE_ORGANIZER"  &&<Table.Cell colSpan={2}>{el.userRole.userOrganizer.organizer.name}</Table.Cell>}
                                                    <Table.Cell>{el.userRole.userOrganizer.user.person.firstName} {el.userRole.userOrganizer.user.person.lastName}</Table.Cell>
                                                    <Table.Cell width={2}>{el.mail}</Table.Cell>
                                                    {/*<Table.Cell>{el.userRole.userOrganizer.organizer.name}</Table.Cell>*/}
                                                    <Table.Cell width={2}>{el.mailType.name}</Table.Cell>
                                                    <Table.Cell width={2}>{moment(el.date).format('D. M. YYYY. - HH:mm')}</Table.Cell>
                                                    <Table.Cell colSpan={2}>{el.message}</Table.Cell>
                                                </Table.Row>)
                                            )
                                        }
                                    </Table.Body>

                                    <Table.Footer>
                                    </Table.Footer>
                                </Table> : <div>Nema unesenih podataka o ostalim zahtjevima.</div>}
                        {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                    </Segment>}
            </SegmentGroup>
            <ToastContainer  />
        </div>
    );
};

export default Comment;
