import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Button, Form, Header, Icon, GridColumn, GridRow, Loader} from "semantic-ui-react";
import {toast, ToastContainer} from 'react-toastify';
import {UserRoleContext} from "../../Main";
import axios from "axios";
import moment from "moment";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import SearchTableData from "../Utilities/SearchTableData";
import ReactExport from "react-data-export";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {Link} from "react-router-dom";


const reducer = (state, action)=> {
    switch (action.type) {
        case 'documentTypes':
            return {...state, documentTypes: action.payload, loader:  false};
        case 'documentTypesData':
            return {...state, documentTypesData: action.payload, loader:  false};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        default:
            return state;

    }
}

const AllTeachers = ({reportId}) => {
    const {activeRole} = useContext(UserRoleContext);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [state, dispatch] = useReducer(reducer,{
        documentTypes: [],
        documentTypesData: [],
        data: [],
        results: [],
        loader: false,
        currentId: null,
        organizer: '',
        organizers: [],

    });

    const columns = [
        { title: 'Vrsta obrasca', width: { wpx: 200 } },
        { title: 'Opseg zaduženih serijskih brojeva', width: { wpx: 250 } },
        { title: 'Datum zaduženja', width: { wpx: 150 } },
        { title: 'Napomena', width: { wpx: 150 } },
    ];

    if (activeRole !== "ROLE_ORGANIZER") {
        columns.unshift({ title: 'Organizator', width: { wpx: 350 } });
    }

    const multiDataSet = [
        {
            columns: columns,
            data: state.documentTypesData,
        }
    ];

    // function getProfessions(){
    //     axios.get('/profession/all',).then(
    //         (res) => {
    //             let professions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
    //             dispatch({type: 'professions', payload: professions});
    //         }).catch((err) => {
    //         console.log('err', err)
    //     });
    // }

    function getResults(results) {
        dispatch({type: 'results', payload: results});
    }

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    useEffect(() => {
        if (activeRole === "ROLE_ORGANIZER" && reportId === '9') {
            getDocumentTypes();
        }
        else {
            getOrganizers()
            if((activeRole === "ROLE_ADMIN" || activeRole=== "ROLE_MINISTRY")  && (reportId === '9'))
            {
                getDocumentTypesAll()
            }
        }
    },[activeRole]);

    function getDocumentTypes(){
        dispatch({type: 'loader', payload: true});
        axios.get('/form/by-organizer',).then(
            (res) => {
                // let documentTypes = res.data;
                let documentTypes = res.data.map(el => ({
                    ...el,
                    formTypeFilter: el.formType.name,
                    dateFilter: moment(el.createdDate).format('D. M. YYYY.')
                }))
                dispatch({type: 'documentTypes', payload: documentTypes});
                dispatch({type: 'data', payload: documentTypes});
                let documentTypesData = res.data.map(el => ([
                    { value: el.formType.name},
                    { value: el.rangeFrom === el.rangeTo ? el.rangeFrom : el.rangeFrom +' - ' + el.rangeTo },
                    { value: moment(el.createdDate).format('D. M. YYYY.') },
                    { value: el.comment ? el.comment : ''},
                ]));
                dispatch({type: 'documentTypesData', payload: documentTypesData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getDocumentTypesAll(){
        dispatch({type: 'loader', payload: true});
        axios.get('/form/all',).then(
            (res) => {
                // let documentTypes = res.data;
                let documentTypes = res.data.map(el => ({
                    ...el,
                    formTypeFilter: el.formType.name,
                    dateFilter: moment(el.createdDate).format('D. M. YYYY.')
                }))
                dispatch({type: 'documentTypes', payload: documentTypes});
                dispatch({type: 'data', payload: documentTypes});
                let documentTypesData = res.data.map(el => ([
                    { value: el.organizer.name},
                    { value: el.formType.name},
                    { value: el.rangeFrom === el.rangeTo ? el.rangeFrom : el.rangeFrom +' - ' + el.rangeTo },
                    { value: moment(el.createdDate).format('D. M. YYYY.') },
                    { value: el.comment ? el.comment : ''},
                ]));
                dispatch({type: 'documentTypesData', payload: documentTypesData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            getDocumentTypesForAdmin(value)
        }
        if(name === 'organizer' && !value && reportId === '9'){
            getDocumentTypesAll()
        }
    }

    function getDocumentTypesForAdmin(id){
        setCurrentId(id);
        setCurrentIdOrganizer(id);
        dispatch({type: 'loader', payload: true});
        dispatch({type: 'openTable', payload: true});
        axios.get(`/form/by-organizer-id/${id}`,).then(
            (res) => {
                let documentTypes = res.data.map(el => ({
                    ...el,
                    formTypeFilter: el.formType.name,
                    dateFilter: moment(el.createdDate).format('D. M. YYYY.')
                }))
                // let documentTypes = res.data;
                dispatch({type: 'documentTypes', payload: documentTypes});
                dispatch({type: 'data', payload: documentTypes});
                let documentTypesData = res.data.map(el => ([
                    { value: el.organizer.name},
                    { value: el.formType.name},
                    { value: el.rangeFrom === el.rangeTo ? el.rangeFrom : el.rangeFrom +' - ' + el.rangeTo },
                    { value: moment(el.createdDate).format('D. M. YYYY.') },
                    { value: el.comment ? el.comment : ''},
                ]));
                dispatch({type: 'documentTypesData', payload: documentTypesData});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }


    return (
        <div>
            <Form size={'small'}>
                <Form.Group widths="3">
                    {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY" ) &&
                    <Form.Field>
                        <label>Organizator</label>
                        <Form.Dropdown
                            placeholder="Odaberite organizatora"
                            value={state.organizer}
                            onChange={handleInputChange}
                            name="organizer"
                            options={state.organizers}
                            search
                            fluid
                            selection
                            clearable
                        />
                    </Form.Field>}
                </Form.Group>
            </Form>
            {/*{((activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && !state.organizer) ? '' :*/}
            {state.loader ?
                <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                :
                // state.documentTypes &&
                state.documentTypes.length ?
                    <Table celled size={'small'}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={8} style={{ padding: '0px' }}>
                                    <Grid column={2}>
                                        <Grid.Column width={14}>
                                            <SearchTableData data={state.data} getResults={getResults} filterItem={['comment','formTypeFilter', 'dateFilter']} />
                                        </Grid.Column>
                                        <Grid.Column width={2} textAlign="center">
                                            <ExcelFile filename={"Zaduženja obrazaca"} element={<Popup
                                                trigger={<Button animated="vertical" color='green' basic floated='right' fluid style={{ textAlign: 'center' }}>
                                                    <Button.Content hidden> <Icon name="download" /></Button.Content>
                                                    <Button.Content visible>
                                                        Excel
                                                    </Button.Content>
                                                </Button>}
                                                content="Preuzmi spisak u Excel-u"
                                                hideOnScroll
                                                position="top center"
                                            />}>
                                                <ExcelSheet dataSet={multiDataSet} name="Andragoška dokumentacija" />
                                            </ExcelFile>
                                        </Grid.Column>
                                    </Grid>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell width={1} >R. br.</Table.HeaderCell>
                                {activeRole !== "ROLE_ORGANIZER"  &&<Table.HeaderCell colSpan={2} >Organizator</Table.HeaderCell>}
                                <Table.HeaderCell colSpan={2} >Vrsta obrasca</Table.HeaderCell>
                                <Table.HeaderCell width={3}>
                                    <Header size={"small"}>
                                        Opseg zaduženih serijskih brojeva
                                        <Header.Subheader>
                                            <i>od - do</i>
                                        </Header.Subheader>
                                    </Header>

                                </Table.HeaderCell>
                                {/*<Table.HeaderCell width={3}>Opseg zaduženih serijskih brojeva</Table.HeaderCell>*/}
                                <Table.HeaderCell width={2}>Datum zaduženja</Table.HeaderCell>
                                <Table.HeaderCell >Napomena</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                getDataToRender().map((el, index) =>
                                    // state.documentTypes.map((el, index)=>
                                    (<Table.Row key={el.id}>
                                        <Table.Cell width={1}>{index + 1}.</Table.Cell>
                                        {activeRole !== "ROLE_ORGANIZER"  &&<Table.Cell colSpan={2}>{el.organizer.name}</Table.Cell>}
                                        <Table.Cell colSpan={2}>{el.formType.name}</Table.Cell>
                                        <Table.Cell width={3}>{el.rangeFrom === el.rangeTo ? el.rangeFrom : el.rangeFrom +' - ' + el.rangeTo}</Table.Cell>
                                        <Table.Cell width={2}>{moment(el.createdDate).format('D. M. YYYY.')}</Table.Cell>
                                        <Table.Cell>{el.comment}</Table.Cell>
                                    </Table.Row>)
                                )
                            }
                        </Table.Body>
                        <Table.Footer>
                        </Table.Footer>
                    </Table> : <div>Nema zaduženih obrazaca.</div>}

            <ToastContainer  />
        </div>
    );
};

export default AllTeachers;
