import React, {useContext} from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {Button, Icon, Menu, Popup} from "semantic-ui-react";
import {UserRoleContext} from "../../Main";

const CourseTable = ({courses,  setShowEditForm, setCourse, deleteCourse, disabledDelete, verified, readyForVerification}) => {
    const {activeRole} = useContext(UserRoleContext);
    return (
        <div >
            { courses && courses.length ?
                <Table celled size={'small'} compact >
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                            <Table.HeaderCell>Predmeti / moduli / ispiti (B / H / S)</Table.HeaderCell>
                            <Table.HeaderCell>Trajanje (sati)</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Napomena</Table.HeaderCell>
                            {!verified && !readyForVerification && activeRole !== "ROLE_MINISTRY" && <Table.HeaderCell/>}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            courses && courses.map((el, index) =>
                                (<Table.Row key={el.id}>
                                    <Table.Cell width={1}>{index + 1}.</Table.Cell>
                                    <Table.Cell>{el.course.nameBosnian} / {el.course.nameSerbian} / {el.course.nameCroatian}</Table.Cell>
                                    <Table.Cell>{el.durationHours}</Table.Cell>
                                    <Table.Cell>{el.course.comment}</Table.Cell>
                                   {!verified && !readyForVerification && activeRole !== "ROLE_MINISTRY" &&  <Table.Cell width={2} verticalAlign="middle" textAlign="center">
                                       <Popup
                                            size={"small"}
                                            content='Izmijeni predmet'
                                            trigger={
                                                <Button icon size={"mini"} basic color={"grey"} onClick={() => {setShowEditForm(true); setCourse(el)}}>
                                                    <Icon name='pencil' color={"teal"} />
                                                </Button>
                                            }
                                        />
                                      <Popup size={"small"} content='Obriši predmet' trigger={
                                            <Button
                                                icon
                                                size={"mini"}
                                                basic
                                                color={"grey"}
                                                // disabled={state.currentId && el.id !== state.currentId }
                                                onClick={() => {deleteCourse(el.id)}}
                                            >
                                                <Icon name='trash alternate outline' color={"red"} />
                                            </Button>} />
                                    </Table.Cell>}
                                </Table.Row>)
                            )
                        }
                    </Table.Body>

                </Table> : <div>Nema unesenih podataka o predmetima.</div>
            }
        </div>
    );
};

export default CourseTable;
