import React, {useContext, useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Form, Header, Icon, Input, SegmentGroup, Select} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {toast} from "react-toastify";
import axios from "axios";
import {UserRoleContext} from "../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'nameBosnian':
            return {...state, nameBosnian: action.payload};
        case 'nameSerbian':
            return {...state, nameSerbian: action.payload};
        case 'nameCroatian':
            return {...state, nameCroatian: action.payload};
        case 'comment':
            return {...state, comment: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        default:
            return state;

    }
}
const CourseForm = ({add, toggleEditForm, getCourses, course, studyProgramId, getCoursesForAdmin, setCurrentIdOrganizer}) => {
    const [nameBosnianErr, setNameBosnianErr] = useState(false);
    const [nameSerbianErr, setNameSerbianErr] = useState(false);
    const [nameCroatianErr, setNameCroatianErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        nameBosnian: course && course.nameBosnian ? course.nameBosnian : '',
        nameSerbian: course && course.nameSerbian ? course.nameSerbian : '',
        nameCroatian: course && course.nameCroatian ? course.nameCroatian : '',
        comment: course && course.comment ? course.comment : '',
        organizer: course && course.organizer ? course.organizer.id : '',
        organizers: [],
        loader: false
    });

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    useEffect(() => {
        if(activeRole !== "ROLE_ORGANIZER") getOrganizers()
    },[]);

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function editCourse(){
        if (!state.nameBosnian || !state.nameSerbian || !state.nameCroatian) {
            toast('Niste unijeli sve podatke.');
            if (!state.nameBosnian) { setNameBosnianErr(true); } else { setNameBosnianErr(false); }
            if (!state.nameSerbian) { setNameSerbianErr(true); } else { setNameSerbianErr(false); }
            if (!state.nameCroatian) { setNameCroatianErr(true); } else { setNameCroatianErr(false); }
        } else {
            let obj = {
                id: course.id,
                optLock: course.optLock,
                nameBosnian: state.nameBosnian,
                nameSerbian: state.nameSerbian,
                nameCroatian: state.nameCroatian,
                comment: state.comment,
                organizer: (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") ? {id: state.organizer} : null,
            };
            axios.put('/course/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste izmijenili predmet.');
                    (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") ? getCoursesForAdmin(state.organizer) : getCourses();
                    setCurrentIdOrganizer(state.organizer);
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }
    function addCourse(){
        if (!state.nameBosnian || !state.nameSerbian || !state.nameCroatian) {
            toast('Niste unijeli sve podatke.');
            if (!state.nameBosnian) { setNameBosnianErr(true); } else { setNameBosnianErr(false); }
            if (!state.nameSerbian) { setNameSerbianErr(true); } else { setNameSerbianErr(false); }
            if (!state.nameCroatian) { setNameCroatianErr(true); } else { setNameCroatianErr(false); }
        } else {
            if (activeRole !== "ROLE_ORGANIZER") {
                let obj = {
                    created: new Date(),
                    nameBosnian: state.nameBosnian,
                    nameSerbian: state.nameSerbian,
                    nameCroatian: state.nameCroatian,
                    comment: state.comment,
                    organizer: {id: state.organizer}
                };
                axios.post('/course/save', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste dodali predmet.');
                        getCoursesForAdmin(state.organizer);
                        setCurrentIdOrganizer(state.organizer);
                    }).catch((err) => {
                    if (err.response.data.message === 'This course already exists!') {
                        toast('Predmet sa ovim nazivom već postoji!');
                    } else {
                        toast('Došlo je do greške! Dodavanje predmeta nije uspjelo.');
                    }
                    console.log('err', err)
                });
            }
            else {
                let obj = {
                    created: new Date(),
                    nameBosnian: state.nameBosnian,
                    nameSerbian: state.nameSerbian,
                    nameCroatian: state.nameCroatian,
                    comment: state.comment,
                    organizer: {id: null}
                };
                axios.post('/course/save/for-organizer', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste dodali predmet.');
                        getCourses();
                    }).catch((err) => {
                    console.log('err', err)
                });
            }
        }
    }

    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {add ?'Dodavanje novog predmeta':'Izmjena podataka'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths={'equal'}>

                            {activeRole !== "ROLE_ORGANIZER" &&<Form.Field
                                required
                                disabled={activeRole !== "ROLE_ORGANIZER" && !add}
                                control={Select}
                                clearable
                                options={state.organizers}
                                placeholder="Odaberite organizatora"
                                label="Organizator"
                                onChange={handleInputChange}
                                name="organizer"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={state.organizer}
                                search
                            />}

                            <Form.Field
                                required
                                label="Naziv predmeta na bosanskom jeziku"
                                control={Input}
                                name="nameBosnian"
                                value={state.nameBosnian}
                                onChange={handleInputChange}
                                placeholder="Unesite naziv predmeta na bosanskom jeziku"
                                error={nameBosnianErr}
                            />
                            <Form.Field
                                required
                                label="Naziv predmeta na srpskom jeziku"
                                control={Input}
                                name="nameSerbian"
                                value={state.nameSerbian}
                                onChange={handleInputChange}
                                placeholder="Unesite naziv predmeta na  srpskom jeziku"
                                error={nameSerbianErr}
                            />

                            <Form.Field
                                required
                                label="Naziv predmeta na hrvatskom jeziku"
                                control={Input}
                                name="nameCroatian"
                                value={state.nameCroatian}
                                onChange={handleInputChange}
                                placeholder="Unesite naziv predmeta na hrvatskom jeziku"
                                error={nameCroatianErr}
                            />

                            <Form.Field
                                label="Napomena"
                                control={Input}
                                name="comment"
                                value={state.comment}
                                onChange={handleInputChange}
                                placeholder="Unesite napomenu"
                            />
                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addCourse : editCourse}>{add ? 'Dodaj' : 'Sačuvaj'}</Button>
                    </Form>
                </Segment>
            </SegmentGroup>
        </div>
    );
};

export default CourseForm;
