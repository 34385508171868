import React, {useReducer, useState} from 'react';
import {lookup} from "./lookup";
import {Navigate} from "react-router-dom";
import {Button, Header, Icon, Menu, Popup, Segment, SegmentGroup, Table} from "semantic-ui-react";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'loader':
            return {...state, loader: action.payload};
        default:
            return state;

    }
}
const LkpTemplate = () => {
    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState(null);
    const [state, dispatch] = useReducer(reducer,{
        loader: false,
        redirect: false
    });

    if (redirect) return <Navigate to={path}/>;
    return (
        <div>
                <Segment.Group>
                    <Segment className={'customPadding'} clearing color={"teal"}>

                        <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                            <Menu.Item >
                                <Header as={'h3'} floated={"left"}> Šifrarnici </Header>
                            </Menu.Item>
                            <Menu.Menu position={'right'}>
                                <Menu.Item>
                                    <span>
                                        <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                            <Icon name='arrow left'/>
                                        </Button>
                                        <Icon/>
                                    </span>
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>

                    </Segment>

                    <Segment>
                        <Table celled size={'small'}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                                    <Table.HeaderCell>Naziv</Table.HeaderCell>
                                    <Table.HeaderCell width={2}></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    lookup.map((el, index)=>
                                        (<Table.Row key={index}>
                                                <Table.Cell width={1}>{index+1}.</Table.Cell>
                                                <Table.Cell>{el.name}</Table.Cell>
                                                <Table.Cell width={2} verticalAlign="middle" textAlign="center">
                                                    <Button icon size={"mini"} basic color={"teal"}
                                                            onClick={() => {
                                                        setPath('/sifrarnici' + el.path);
                                                        window.scrollTo(0, 0);
                                                        localStorage.setItem('headerName', el.name);
                                                        setRedirect(true);
                                                    }}>
                                                        {/*<Icon name='pencil' color={"teal"} />*/}
                                                        Pregled šifrarnika
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                            </Table.Body>

                        {/*    onClick={() => {*/}
                        {/*    setPath('/sifrarnici' + el.path);*/}
                        {/*    setRedirect(true);*/}
                        {/*}}*/}

                            {/*<Table.Body>*/}
                            {/*    {*/}
                            {/*        lookup.map((el, index)=>*/}
                            {/*            (<Popup*/}
                            {/*                trigger={<Table.Row key={index}*/}
                            {/*                                    onClick={() => {*/}
                            {/*                                        setPath('/sifrarnici' + el.path);*/}
                            {/*                                        setRedirect(true);*/}
                            {/*                                    }}*/}
                            {/*                >*/}
                            {/*                    <Table.Cell width={1}>{index+1}.</Table.Cell>*/}
                            {/*                    <Table.Cell>{el.name}</Table.Cell>*/}
                            {/*                    <Table.Cell width={2} verticalAlign="middle" textAlign="center">*/}
                            {/*                        <Button icon size={"mini"} basic color={"teal"}>*/}
                            {/*                            /!*<Icon name='pencil' color={"teal"} />*!/*/}
                            {/*                            Pregled šifrarnika*/}
                            {/*                        </Button>*/}
                            {/*                    </Table.Cell>*/}
                            {/*                </Table.Row>}*/}
                            {/*                content="Dodavanje"*/}
                            {/*                hideOnScroll*/}
                            {/*                position="top left"*/}
                            {/*            />))}*/}
                            {/*</Table.Body>*/}
                        </Table>

                    </Segment>
                </Segment.Group>
        </div>
    );
};

export default LkpTemplate;
