import React, {useContext, useEffect} from 'react';
import {useParams} from "react-router-dom";
import * as axios from "axios";
import {Navigate} from "react-router-dom";
import {UserRoleContext} from "../Main";

const PersonSelector = ({}) => {
    const {getActiveRole} = useContext(UserRoleContext);
    let { id } = useParams();

    useEffect(() => {

        axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL + '/u/' + id;
        window.sessionStorage.setItem('u', id);
        window.localStorage.setItem('lastSelectedU', id);
        getActiveRole();
        //window.localStorage.setItem('activeRole', activeRole);

    },[id]);
    return (
       <Navigate to={'/'}/>
    );
};

export default PersonSelector;




