import React, {useContext} from 'react';
import {Grid, Icon, Segment} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import UserService from "../../service/UserService";
import {UserRoleContext} from "../../Main";

const MinistryHome = () => {
    const username = UserService.getUsername();
    const {activeRoleObj} = useContext(UserRoleContext);
    return (
        <div >
            <Grid columns={3} stackable>
                <Grid.Column>
                    <Segment.Group>
                        <Segment className='textAlignCenter' color='teal'>
                            <Icon name='user' size='huge' />
                        </Segment>
                        <Segment >
                            <Table celled>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell><strong>Korisničko ime:</strong></Table.Cell>
                                        <Table.Cell>{username}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Segment.Group>
                </Grid.Column>
                <Grid.Column>
                    <Segment.Group >
                        <Segment className='textAlignCenter' color='teal'>
                            <Icon name='university' size='huge' />
                        </Segment>
                        <Segment >
                            <Table celled>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell><strong>Naziv:</strong></Table.Cell>
                                        <Table.Cell>Ministarstvo za odgoj i obrazovanje</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Segment.Group>
                </Grid.Column>
                <Grid.Column>
                    <Segment.Group>
                        <Segment className='textAlignCenter' color='teal'>
                            <Icon name='unlock' size='huge' />
                        </Segment>
                        <Segment >
                            <Table celled>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell><strong>Uloga:</strong></Table.Cell>
                                        <Table.Cell>{activeRoleObj.role.name}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Segment.Group>
                </Grid.Column>
            </Grid>

        </div>
    );
};

export default MinistryHome;
