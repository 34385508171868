import React, {useContext, useEffect, useReducer, useState} from 'react';
import axios from "axios";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Button, Form, Header, Icon, Loader, Menu} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {Link} from "react-router-dom";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import {toast, ToastContainer} from "react-toastify";
import moment, {duration} from "moment";
import SspGradeForm from "./SspGradeForm";
import GradeInfo from "./GradeInfo";
import DiplomaForm from "./DiplomaForm";
import {extractFileNameFromHeaders} from "../../Utilities/Util";
import download from "downloadjs";
import {UserRoleContext} from "../../../Main";


const reducer = (state, action)=>{
    switch (action.type) {
        case 'serialNumber':
            return {...state, serialNumber: action.payload};
        case 'serialNumbers':
            return {...state, serialNumbers: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'currentEl':
            return {...state, currentEl: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        // case 'formType':
        //     return {...state, formType: action.payload};
        default:
            return state;
    }
};

const StudentGradeList = ({graduationExam, getSspGrades, sspGrades, isCompleted, isRecognized, isLastGradeCompleted}) => {
    const {activeRole} = useContext(UserRoleContext);
    const sspId = localStorage.getItem('sspId');
    const formType = localStorage.getItem('formType');
    const organizerId = localStorage.getItem('organizerId')
    const gradDate = localStorage.getItem('gradDate');
    const droppedDate = localStorage.getItem('droppedDate');

    const [state, dispatch] = useReducer(reducer,{ sspGrades:[], serialNumbers:[], loader: false,} );
    const [showAddForm, setShowAddForm] = useState(false);
    const [showAddSerialNumber, setShowAddSerialNumber] = useState(false);
    const [serialNumberErr, setSerialNumberErr] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showGradeInfo, setShowGradeInfo] = useState(false);
    const [showGradeDiplomaForm, setShowGradeDiplomaForm] = useState(false);

    function toggleGradeInfo(el){
        dispatch({type: 'currentEl', payload: el});
        setShowGradeInfo(!showGradeInfo)
        if(showGradeInfo){dispatch({type: 'currentEl', payload: null});}
    };
    let toggleGradeDiplomaForm = () =>{
        setShowGradeDiplomaForm(!showGradeDiplomaForm)
        setShowEditForm(false)
        setShowAddForm(false)
    };

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
        setShowEditForm(false)
        setShowGradeDiplomaForm(false)
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
        setShowAddForm(false)
        setShowGradeDiplomaForm(false)
    };

    useEffect(() => {
        getSspGrades(sspId);
        if(activeRole === "ROLE_ORGANIZER")
        {
            if (formType === "secondary education" || formType === "secondary education - upskilling" || formType === "secondary education - reskilling")
            {getSerialNumbers(2)}
            else if (formType === "primary education")
            {getSerialNumbers(1)}
            else if (formType === "training" || formType === "qualification") {getSerialNumbers(3)}
            else{getSerialNumbers(0)}
        }
        else
        {
            if (formType === "secondary education" || formType === "secondary education - upskilling" || formType === "secondary education - reskilling")
            {getSerialNumbersForAdmin(2)}
            else if (formType === "primary education")
            {getSerialNumbersForAdmin(1)}
            else if (formType === "training" || formType === "qualification") {getSerialNumbersForAdmin(3)}
            else{getSerialNumbersForAdmin(0)}
        }
        // if (formType === "secondary education" || formType === "secondary education - upskilling" || formType === "secondary education - reskilling")
        // {getSerialNumbers(2)}
        // else if (formType === "primary education")
        // {getSerialNumbers(1)}
        // else if (formType === "training" || formType === "qualification") {getSerialNumbers(3)}
        // else{getSerialNumbers(0)}
    },[]);

    function getSerialNumbers(formTypeId){
        axios.get(`form/unused-serial-numbers/by-form-type-and-organizer/${formTypeId}`,).then(
            (res) => {
                let serialNumbers = res.data.map((el)=>({key: el, text: el, value: el}));
                dispatch({type: 'serialNumbers', payload: serialNumbers});
            }).catch((err) => {
            if (err.response.data.message === 'Forms with passed type id not found!') {
                toast('Ne postoje obrasci za ovaj tip obrazovanja!');
            }
            // else {
            //     toast('Došlo je do greške')
            // }
            console.log('err', err)
        });
    }

    function getSerialNumbersForAdmin(formTypeId){
        axios.get(`form/unused-serial-numbers/by-form-type-and-organizer/${formTypeId}/${organizerId}`,).then(
            (res) => {
                let serialNumbers = res.data?.map((el)=>({key: el, text: el, value: el}));
                dispatch({type: 'serialNumbers', payload: serialNumbers});
            }).catch((err) => {
            if (err.response.data.message === 'Forms with passed type id not found!') {
                toast('Ne postoje obrasci za ovaj tip obrazovanja!');
            }
            // else {
            //     toast('Došlo je do greške')
            // }
            console.log('err', err)
        });
    }

    function downloadGradeDiploma(sspGradeId) {
        axios({
            method: 'post',
            url: `/document/high-school-grade-diploma/${sspGradeId}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                setCurrentId(null);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });
    }


    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function setCurrentEl(value){
        dispatch({type: 'currentEl', payload: value});
    }

    function deleteSspGradeById(studentStudyProgramGradeId) {
        setCurrentId(studentStudyProgramGradeId);

        axios.delete(`student-study-program-grade/delete/${studentStudyProgramGradeId}`,).then(
            (res) => {
                getSspGrades();
                toast('Uspješno ste izbrisali razred!');
                setCurrentId(null);
                setCurrentEl(null);

            }).catch((err) => {
            if (err.response.data.message === 'This ssp grade has existing exam registrations!') {
                toast('Nije dozvoljeno brisanje razreda u kojem je polaznik imao prijavu ispita!');
            } else {
                toast('Došlo je do greške! Brisanje razreda nije uspjelo.')
            }

            setCurrentId(null);

        });

    }

    function setCompletedGrade(el){
        setCurrentEl(el);
        let obj = {...el, completed: true}

        axios.put('student-study-program-grade/edit', {...obj}).then(
            (res) => {

                toast('Podaci su uspješno sačuvani.');
                getSspGrades();
            }).catch((err) => {

            // if (err.response.data.message === 'This course is already added for passed study program!') {
            //     toast('Polaznik je već upisan u odabrani razred.');
            // } else {
            //     toast('Došlo je do greške! Polaznik nije upisan u odabrani razred.');
            // }
            console.log('err', err)
        });
    }
    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function editSspGrade(el, withDownload = false) {

        if (!state.serialNumber) {setSerialNumberErr(true);} else {setSerialNumberErr(false);}


        if (!state.serialNumber) {
            toast('Niste unijeli serijski broj.');
        }else {

            let obj = {...el.studentStudyProgramGrade,
                gradeDiplomaSerialNumber: state.serialNumber
            };
            axios.put('student-study-program-grade/edit', {...obj}).then(
                (res) => {
                    toast('Podaci su uspješno sačuvani.');
                    if(activeRole === "ROLE_ORGANIZER")
                    {
                        if(withDownload){
                            downloadGradeDiploma(state.currentId);
                        }
                    }
                    // if(withDownload){
                    //     downloadGradeDiploma(state.currentId);
                    // }
                    setShowAddSerialNumber(false);
                    setCurrentId(null);
                    getSspGrades();
                    if(activeRole === "ROLE_ORGANIZER")
                    {
                        if (formType === "secondary education" || formType === "secondary education - upskilling" || formType === "secondary education - reskilling")
                        {getSerialNumbers(2)}
                        else if (formType === "primary education")
                        {getSerialNumbers(1)}
                        else if (formType === "training" || formType === "qualification") {getSerialNumbers(3)}
                        else{getSerialNumbers(0)}
                    }
                    else
                    {
                        if (formType === "secondary education" || formType === "secondary education - upskilling" || formType === "secondary education - reskilling")
                        {getSerialNumbersForAdmin(2)}
                        else if (formType === "primary education")
                        {getSerialNumbersForAdmin(1)}
                        else if (formType === "training" || formType === "qualification") {getSerialNumbersForAdmin(3)}
                        else{getSerialNumbersForAdmin(0)}
                    }
                }).catch((err) => {

                // if(withDownload){
                //     downloadGradeDiploma(state.currentId);
                // }

                // if (err.response.data.message === 'This course is already added for passed study program!') {
                //     toast('Polaznik je već upisan u odabrani razred.');
                // } else {
                //     toast('Došlo je do greške! Polaznik nije upisan u odabrani razred.');
                // }
                console.log('err', err)
            });
        }

    }

    return (
        <div>
            {!isLastGradeCompleted && !graduationExam && !showAddForm && !showEditForm && !showGradeInfo && !showGradeDiplomaForm && <><Button floated={"right"} icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm} disabled={gradDate !== 'null' || droppedDate !== 'null'}>
                <Icon name='add' color={"teal"}/>
                Upiši u razred
            </Button> <br/></>}

            {showAddForm &&
                // upis u razred
                <SspGradeForm add toggleEditForm={toggleAddForm} getSspGrades={getSspGrades} sspGrades={sspGrades} sspId={sspId}  isCompleted={isCompleted} isRecognized={isRecognized}/>
            }

            {showGradeDiplomaForm &&
                // svjedocanstvo za razred
                <DiplomaForm  toggleEditForm={toggleGradeDiplomaForm} getSspGrades={getSspGrades} sspId={sspId} grade={state.currentEl} isCompleted={isCompleted} isGradeCompleted={state.currentEl.completed}/>
            }

            {showEditForm &&
                // izmjena upisa u razred
                <SspGradeForm edit toggleEditForm={toggleEditForm} getSspGrades={getSspGrades} sspId={sspId} grade={state.currentEl} sspGrades={sspGrades} isCompleted={isCompleted} isRecognized={isRecognized}/>
            }
            {showGradeInfo &&

                <GradeInfo
                    sspGrade={state.currentEl}
                />
            }



            {state.loader ?
                <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                : sspGrades?.length !== 0 ? <Table celled size={'small'} selectable>
                        <Table.Header>
                            <Table.Row>

                                <Table.HeaderCell >R. br.</Table.HeaderCell>
                                <Table.HeaderCell >Razred</Table.HeaderCell>
                                <Table.HeaderCell>Voditelj obrazovne grupe</Table.HeaderCell>
                                <Table.HeaderCell>Početak i kraj obrazovnog procesa za razred</Table.HeaderCell>
                                <Table.HeaderCell>Datum svjedočanstva</Table.HeaderCell>
                                <Table.HeaderCell>Djelovodni broj svjedočanstva</Table.HeaderCell>
                                <Table.HeaderCell >
                                    <Header size={"small"}>
                                        Serijski broj
                                        <Header.Subheader>
                                            <i>sa obrasca</i>
                                        </Header.Subheader>
                                    </Header>
                                </Table.HeaderCell>
                                {/*<Table.HeaderCell>Serijski broj</Table.HeaderCell>*/}
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                {/*<Table.HeaderCell>Status</Table.HeaderCell>*/}
                                <Table.HeaderCell width={activeRole === "ROLE_ORGANIZER" ? 2:3} />
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                sspGrades.map((el, index)=>
                                    (<Table.Row key={el.studentStudyProgramGrade.id} warning={el.studentStudyProgramGrade.recognized && el.studentStudyProgramGrade.gradeDiplomaDate} positive={!el.studentStudyProgramGrade.recognized && el.studentStudyProgramGrade.gradeDiplomaDate} negative={el.studentStudyProgramGrade.completed && !el.studentStudyProgramGrade.gradeDiplomaDate}>
                                        <Table.Cell>{index+1}.</Table.Cell>
                                        <Table.Cell >{el.studentStudyProgramGrade.grade.name}</Table.Cell>
                                        <Table.Cell  >{el.studentStudyProgramGrade.educationalGroupHead.firstName + ' ' + el.studentStudyProgramGrade.educationalGroupHead.lastName} </Table.Cell>
                                        <Table.Cell >{moment(el.studentStudyProgramGrade.enrollmentDate).format('D. M. YYYY.') + ' - ' + (el.studentStudyProgramGrade.completedDate ? moment(el.studentStudyProgramGrade.completedDate).format('D. M. YYYY.'): '')}</Table.Cell>
                                        <Table.Cell >{el.studentStudyProgramGrade.gradeDiplomaDate && moment(el.studentStudyProgramGrade.gradeDiplomaDate).format('D. M. YYYY.')}</Table.Cell>
                                        <Table.Cell >{el.studentStudyProgramGrade.gradeDiplomaNumber}</Table.Cell>
                                        <Table.Cell>{el.studentStudyProgramGrade.gradeDiplomaSerialNumber ? el.studentStudyProgramGrade.gradeDiplomaSerialNumber : ''}</Table.Cell>
                                        <Table.Cell  verticalAlign="middle" textAlign="center">{el.studentStudyProgramGrade.recognized ?

                                            <Popup size={"small"}
                                                   content='Razred je priznat u cjelosti.'
                                                   trigger={<Icon name='check' color={"green"} />}
                                            />
                                            : el.studentStudyProgramGrade.gradeDiplomaDate ?
                                                <Popup size={"small"}
                                                       content='Polaznik je uspješno završio razred.'
                                                       trigger={<Icon name='check' color={"green"} />}
                                                />
                                                : el.studentStudyProgramGrade.completed? <Popup size={"small"}
                                                                                                content='Polaznik nije položio razred u predviđenom roku.'
                                                                                                trigger={<Icon name='close' color={"red"} />}
                                                /> : 'Upisan'
                                        }</Table.Cell>
                                        {
                                            activeRole === "ROLE_ORGANIZER" ?
                                                state.currentId === el.studentStudyProgramGrade.id && el.studentStudyProgramGrade.gradeDiplomaSerialNumber === null && showAddSerialNumber ?
                                                    <Table.Cell width={activeRole === "ROLE_ORGANIZER" ? 2:3} verticalAlign="middle" textAlign="center">
                                                        <Form size={'tiny'}>
                                                            * Nakon unosa serijskog broja izmjena podataka više neće biti moguća.
                                                            <Form.Field required>
                                                                <Form.Dropdown
                                                                    error={serialNumberErr}
                                                                    clearable
                                                                    placeholder="Odaberite broj"
                                                                    value={state.serialNumber}
                                                                    onChange={handleInputChange}
                                                                    name="serialNumber"
                                                                    options={state.serialNumbers}
                                                                    search
                                                                    fluid
                                                                    selection
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <Button
                                                                    icon
                                                                    size={"tiny"}
                                                                    basic
                                                                    compact
                                                                    onClick={()=>{setCurrentId(el.studentStudyProgramGrade.id); editSspGrade(el,true);}}
                                                                    // onClick={()=>editSspGrade(el,true)}
                                                                >
                                                                    <Icon name='check' color={"teal"} />
                                                                    {/*{state.editMode?'Sačuvaj': 'Priznaj'}*/}
                                                                </Button>
                                                                <Button
                                                                    icon
                                                                    size={"tiny"}
                                                                    basic
                                                                    compact
                                                                    onClick={()=>{setCurrentId(null); setShowAddSerialNumber(false);}}
                                                                >
                                                                    <Icon name='close' color={"black"} />
                                                                </Button>
                                                            </Form.Field>
                                                        </Form>
                                                    </Table.Cell>
                                                    :
                                                    <Table.Cell width= {activeRole === "ROLE_ORGANIZER" ? 2:3} verticalAlign="middle" textAlign="center">

                                                        {!el.studentStudyProgramGrade.completed &&<Popup
                                                            size={"small"}
                                                            content={el.studentStudyProgramGrade.completed ? 'Nije dozvoljeno mijenjati podatke za završen razred.' : 'Izmijeni podatke'}
                                                            trigger={
                                                                <Button

                                                                    icon
                                                                    size={"mini"}
                                                                    basic
                                                                    disabled={(state.currentId && el.studentStudyProgramGrade.id !== state.currentId) || droppedDate !== 'null' }
                                                                    color={"grey"}
                                                                    onClick={el.studentStudyProgramGrade.completed ? true : () => {
                                                                        setCurrentEl(el.studentStudyProgramGrade);
                                                                        toggleEditForm();
                                                                    }}
                                                                >
                                                                    <Icon name='pencil'
                                                                          color={el.studentStudyProgramGrade.completed ? 'gray' : "teal"}/>
                                                                </Button>
                                                            }
                                                        />}
                                                        {!el.studentStudyProgramGrade.gradeDiplomaSerialNumber && !el.studentStudyProgramGrade.recognized && !(el.studentStudyProgramGrade.completed && !el.studentStudyProgramGrade.gradeDiplomaDate)&& <Link to='/polaznici/formalno-obrazovanje/prijava'
                                                                                                                                                                                                                                                               state={{sspGrade: el.studentStudyProgramGrade}}>
                                                            {!(activeRole === "ROLE_ORGANIZER" && (gradDate !== 'null' || droppedDate !== 'null')) ?<Popup size={"small"}
                                                                                                                                                           content={'Unos ocjena i unos/pregled prijava za ' + el.studentStudyProgramGrade.grade.name + ' razred'}
                                                                                                                                                           trigger={<Button icon size={"mini"} basic color={"grey"} disabled={(state.currentId && el.studentStudyProgramGrade.id !== state.currentId) || droppedDate !== 'null'}
                                                                                                                                                                            onClick={() => {
                                                                                                                                                                                localStorage.setItem('sspGrade', JSON.stringify(el.studentStudyProgramGrade))
                                                                                                                                                                            }}>
                                                                                                                                                               <Icon name='file alternate outline' color={"blue"}/>
                                                                                                                                                           </Button>}/>:''}
                                                        </Link>}
                                                        {(!(el.studentStudyProgramGrade.completed && !el.studentStudyProgramGrade.gradeDiplomaDate) && el.notPassedCourseStudyPrograms.length === 0) && <Popup
                                                            size={"small"}
                                                            content={el.studentStudyProgramGrade.gradeDiplomaDate ? 'Izmijeni podatke ' : 'Unos podataka o svjedočanstvu'}
                                                            trigger={
                                                                <Button
                                                                    icon
                                                                    size={"mini"}
                                                                    basic
                                                                    color={"grey"}
                                                                    disabled={(state.currentId && el.studentStudyProgramGrade.id !== state.currentId) || el.studentStudyProgramGrade.gradeDiplomaSerialNumber !==null || droppedDate !== 'null'}
                                                                    onClick={() => {
                                                                        setCurrentEl(el.studentStudyProgramGrade);
                                                                        toggleGradeDiplomaForm();
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        name={el.studentStudyProgramGrade.gradeDiplomaDate ? 'pencil' : 'graduation cap'}
                                                                        color={el.studentStudyProgramGrade.gradeDiplomaDate ? 'teal' : "green"}/>
                                                                </Button>
                                                            }
                                                        />}
                                                        {el.studentStudyProgramGrade.completed &&
                                                            <Link to='/polaznici/formalno-obrazovanje/pregled-ocjena'
                                                                  state={{sspGrade: el.studentStudyProgramGrade}}>
                                                                <Popup size={"small"}
                                                                       content={'Pregled ocjena za ' + el.studentStudyProgramGrade.grade.name + ' razred'}
                                                                       trigger={<Button icon size={"mini"} basic disabled={state.currentId && el.studentStudyProgramGrade.id !== state.currentId}
                                                                                        color={"grey"} onClick={() => {
                                                                           localStorage.setItem('sspGrade', JSON.stringify(el.studentStudyProgramGrade))
                                                                       }}>
                                                                           <Icon name='tasks' color={"blue"}/>
                                                                       </Button>}/>
                                                            </Link>}


                                                        {el.studentStudyProgramGrade.completed && el.studentStudyProgramGrade.gradeDiplomaDate && <Popup
                                                            size={"small"}
                                                            content={'Preuzmi svjedočanstvo'}
                                                            trigger={
                                                                <Button
                                                                    disabled={state.currentId && el.studentStudyProgramGrade.id !== state.currentId}
                                                                    icon
                                                                    size={"mini"}
                                                                    basic
                                                                    color={"grey"}
                                                                    onClick={()=> {
                                                                        setCurrentId(el.studentStudyProgramGrade.id);
                                                                        el.studentStudyProgramGrade.gradeDiplomaSerialNumber ? downloadGradeDiploma(el.studentStudyProgramGrade.id)
                                                                            :
                                                                            setShowAddSerialNumber(true);
                                                                    }}
                                                                >
                                                                    <Icon name='download'
                                                                          color={"green"}/>
                                                                </Button>
                                                            }
                                                        />}


                                                        {!el.studentStudyProgramGrade.completed && !el.studentStudyProgramGrade.recognized && <Popup
                                                            size={"small"}
                                                            content={'Označi razred kao nepoložen (obnova razreda)'}
                                                            trigger={
                                                                <Button
                                                                    icon
                                                                    size={"mini"}
                                                                    basic
                                                                    disabled={(state.currentId && el.studentStudyProgramGrade.id !== state.currentId) || droppedDate !== 'null'}
                                                                    color={"grey"}
                                                                    onClick={() => {

                                                                        setCompletedGrade(el.studentStudyProgramGrade);
                                                                    }}
                                                                >
                                                                    <Icon name={'graduation cap'}
                                                                          color={"red"}/>
                                                                </Button>
                                                            }
                                                        />}

                                                        {!el.studentStudyProgramGrade.gradeDiplomaDate && <Popup size={"small"}
                                                                                                                 content={el.studentStudyProgramGrade.completed ? 'Nije dozvoljeno brisanje završenog razreda.' : 'Obriši razred'}
                                                                                                                 trigger={
                                                                                                                     <Button icon size={"mini"} basic
                                                                                                                             color={"grey"}
                                                                                                                             disabled={(state.currentId && el.studentStudyProgramGrade.id !== state.currentId) || droppedDate !== 'null'}
                                                                                                                             onClick={el.studentStudyProgramGrade.gradeDiplomaDate ? true : () => {
                                                                                                                                 deleteSspGradeById(el.studentStudyProgramGrade.id)
                                                                                                                             }}>
                                                                                                                         <Icon
                                                                                                                             name='trash alternate outline'
                                                                                                                             color={el.studentStudyProgramGrade.gradeDiplomaDate ? 'gray' : "red"}/>
                                                                                                                     </Button>}/>}

                                                    </Table.Cell>:

                                                state.currentId === el.studentStudyProgramGrade.id && showAddSerialNumber ?
                                                    <Table.Cell width={activeRole === "ROLE_ORGANIZER" ? 2:3} verticalAlign="middle" textAlign="center">
                                                        <Form size={'tiny'}>
                                                            <Form.Field required>
                                                                <Form.Dropdown
                                                                    error={serialNumberErr}
                                                                    clearable
                                                                    placeholder="Odaberite broj"
                                                                    value={state.serialNumber}
                                                                    onChange={handleInputChange}
                                                                    name="serialNumber"
                                                                    options={state.serialNumbers}
                                                                    search
                                                                    fluid
                                                                    selection
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <Button
                                                                    icon
                                                                    size={"tiny"}
                                                                    basic
                                                                    compact
                                                                    onClick={()=>{setCurrentId(el.studentStudyProgramGrade.id); editSspGrade(el,true);}}
                                                                    // onClick={()=>editSspGrade(el,true)}
                                                                >
                                                                    <Icon name='check' color={"teal"} />
                                                                    {/*{state.editMode?'Sačuvaj': 'Priznaj'}*/}
                                                                </Button>
                                                                <Button
                                                                    icon
                                                                    size={"tiny"}
                                                                    basic
                                                                    compact
                                                                    onClick={()=>{setCurrentId(null); setShowAddSerialNumber(false);}}
                                                                >
                                                                    <Icon name='close' color={"black"} />
                                                                </Button>
                                                            </Form.Field>
                                                        </Form>
                                                    </Table.Cell>
                                                    :
                                                    <Table.Cell width={activeRole === "ROLE_ORGANIZER" ? 2:3} verticalAlign="middle" textAlign="center">

                                                        <Popup
                                                            size={"small"}
                                                            content={'Izmijeni podatke o razredu'}
                                                            trigger={
                                                                <Button

                                                                    icon
                                                                    size={"mini"}
                                                                    basic
                                                                    disabled={state.currentId && el.studentStudyProgramGrade.id !== state.currentId}
                                                                    color={"grey"}
                                                                    onClick={ () => {
                                                                        setCurrentEl(el.studentStudyProgramGrade);
                                                                        toggleEditForm();
                                                                    }}
                                                                >
                                                                    <Icon name='pencil'
                                                                          color={el.studentStudyProgramGrade.completed ? 'gray' : "teal"}/>
                                                                </Button>
                                                            }
                                                        />
                                                        {<Link to='/polaznici/formalno-obrazovanje/prijava' state={{sspGrade: el.studentStudyProgramGrade}}>
                                                            <Popup size={"small"}
                                                                   content={'Unos ocjena i unos/pregled prijava za ' + el.studentStudyProgramGrade.grade.name + ' razred'}
                                                                   trigger={<Button icon size={"mini"} basic color={"grey"} disabled={state.currentId && el.studentStudyProgramGrade.id !== state.currentId}
                                                                                    onClick={() => {
                                                                                        localStorage.setItem('sspGrade', JSON.stringify(el.studentStudyProgramGrade))
                                                                                    }}>
                                                                       <Icon name='file alternate outline' color={"blue"}/>
                                                                   </Button>}/>
                                                        </Link>}
                                                        <Popup
                                                            size={"small"}
                                                            content={el.studentStudyProgramGrade.gradeDiplomaDate ? 'Izmijeni podatke o svjedočanstvu ' : 'Unos podataka o svjedočanstvu'}
                                                            trigger={
                                                                <Button
                                                                    icon
                                                                    size={"mini"}
                                                                    basic
                                                                    color={"grey"}
                                                                    disabled={(state.currentId && el.studentStudyProgramGrade.id !== state.currentId)}
                                                                    onClick={() => {
                                                                        setCurrentEl(el.studentStudyProgramGrade);
                                                                        toggleGradeDiplomaForm();
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        name={el.studentStudyProgramGrade.gradeDiplomaDate ? 'pencil' : 'graduation cap'}
                                                                        color={el.studentStudyProgramGrade.gradeDiplomaDate ? 'teal' : "green"}/>
                                                                </Button>
                                                            }
                                                        />
                                                        <Link to='/polaznici/formalno-obrazovanje/pregled-ocjena'
                                                              state={{sspGrade: el.studentStudyProgramGrade}}>
                                                            <Popup size={"small"}
                                                                   content={'Pregled ocjena za ' + el.studentStudyProgramGrade.grade.name + ' razred'}
                                                                   trigger={<Button icon size={"mini"} basic disabled={state.currentId && el.studentStudyProgramGrade.id !== state.currentId}
                                                                                    color={"grey"} onClick={() => {
                                                                       localStorage.setItem('sspGrade', JSON.stringify(el.studentStudyProgramGrade))
                                                                   }}>
                                                                       <Icon name='tasks' color={"blue"}/>
                                                                   </Button>}/>
                                                        </Link>


                                                        <Popup
                                                            size={"small"}
                                                            content={'Unesi serijski broj za svjedočanstvo'}
                                                            trigger={
                                                                <Button
                                                                    disabled={state.currentId && el.studentStudyProgramGrade.id !== state.currentId}
                                                                    icon
                                                                    size={"mini"}
                                                                    basic
                                                                    color={"grey"}
                                                                    onClick={()=> {
                                                                        setCurrentId(el.studentStudyProgramGrade.id);
                                                                        // el.studentStudyProgramGrade.gradeDiplomaSerialNumber ? downloadGradeDiploma(el.studentStudyProgramGrade.id)
                                                                        //     :
                                                                        setShowAddSerialNumber(true);
                                                                    }}
                                                                >
                                                                    <Icon name='download'
                                                                          color={"green"}/>
                                                                </Button>
                                                            }
                                                        />


                                                        <Popup
                                                            size={"small"}
                                                            content={'Označi razred kao nepoložen (obnova razreda)'}
                                                            trigger={
                                                                <Button
                                                                    icon
                                                                    size={"mini"}
                                                                    basic
                                                                    disabled={state.currentId && el.studentStudyProgramGrade.id !== state.currentId}
                                                                    color={"grey"}
                                                                    onClick={() => {

                                                                        setCompletedGrade(el.studentStudyProgramGrade);
                                                                    }}
                                                                >
                                                                    <Icon name={'graduation cap'}
                                                                          color={"red"}/>
                                                                </Button>
                                                            }
                                                        />

                                                        <Popup size={"small"}
                                                               content={el.studentStudyProgramGrade.completed ? 'Nije dozvoljeno brisanje završenog razreda.' : 'Obriši razred'}
                                                               trigger={
                                                                   <Button icon size={"mini"} basic
                                                                           color={"grey"}
                                                                           disabled={state.currentId && el.studentStudyProgramGrade.id !== state.currentId}
                                                                           onClick={el.studentStudyProgramGrade.gradeDiplomaDate ? true : () => {
                                                                               deleteSspGradeById(el.studentStudyProgramGrade.id)
                                                                           }}>
                                                                       <Icon
                                                                           name='trash alternate outline'
                                                                           color={el.studentStudyProgramGrade.gradeDiplomaDate ? 'gray' : "red"}/>
                                                                   </Button>}/>

                                                    </Table.Cell>}
                                    </Table.Row>)
                                )

                            }


                        </Table.Body>
                        <Table.Footer>
                        </Table.Footer>
                    </Table>:
                    <p><i>* Polaznik trenutno nije upisan u neki razred. <strong>Ukoliko polaznik ima podatke o razlici predmeta potrebno ih je unijeti prije upisa u razrede.</strong></i> </p>
            }



            <ToastContainer  />
        </div>
    );
};

export default StudentGradeList;
