import React, {useContext, useEffect, useReducer, useState} from 'react';
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {Header, Icon, Input, Segment, SegmentGroup} from "semantic-ui-react";
import {DateTimePicker} from "react-widgets";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import {extractFileNameFromHeaders} from "../Utilities/Util";
import download from "downloadjs";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'jmbg':
            return {...state, jmbg: action.payload};
        case 'firstName':
            return {...state, firstName: action.payload};
        case 'lastName':
            return {...state, lastName: action.payload};
        case 'email':
            return {...state, email: action.payload};
        case 'username':
            return {...state, username: action.payload};
        case 'roles':
            return {...state, roles: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'role':
            return {...state, role: action.payload};
        default:
            return state;
    }
};

const UserForm = ({organizers, toggleAddForm, getUsers, userRole, edit, setEdit}) => {

    const [state, dispatch] = useReducer(reducer,{
        jmbg: '',
        firstName: '',
        lastName: '',
        username: '',
        roles: [],
        role: null,
        organizer: null,
        email: '',
    });

    const [jmbgErr, setJmbgErr] = useState(false);
    const [firstNameErr, setFirstNameErr] = useState(false);
    const [lastNameErr, setLastNameErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [organizerErr, setOrganizerErr] = useState(false);
    const [roleErr, setRoleErr] = useState(false);
    const [usernameErr, setUsernameErr] = useState(false);
    const [disablePerson, setDisablePerson] = useState(false);
    const [disableUser, setDisableUser] = useState(false);

    function checkJmbg(jmbg){
        setDisablePerson(false)
        setDisableUser(false)
        resetValues()
        axios.get(`person/by-jmbg/${jmbg}`,).then(
            (res) => {
                let personExist = res.data.id;
                let person = res.data;

                if(personExist){
                    setDisablePerson(true)
                    dispatch({type: 'firstName', payload: person.firstName});
                    dispatch({type: 'lastName', payload: person.lastName});
                    axios.get(`/user/by-person-id/${person.id}`,).then(
                        (ress) => {
                            setDisableUser(true)
                            dispatch({type: 'email', payload: ress.data.email});
                            dispatch({type: 'username', payload: ress.data.username});
                        }).catch((err) => {
                        console.log('err', err)
                    });
                }

                if (personExist){
                    toast('Podaci za osobu sa ovim JMBG-om već postoje, unesite samo preostale podatke!')
                }

            }).catch((err) => {
            if (err.response.data.message === 'More than one person found with passed jmbg!') {
                toast('Za navedeni JMBG postoji greška u bazi, javite se administratoru za dalje upute! ');

            }
            //resetValues();
        });
    }

    function handleInputChange(event, { name, value }) {
        if(name === 'jmbg'){
            checkJmbg(value)
        }
        dispatch({type: name, payload: value})
    }

    function formValidation(){
        if (!state.jmbg || !state.firstName || !state.lastName || !state.role || (state.role && state.role.key === 'ROLE_ORGANIZER' && !state.organizer)
            || !state.email || !state.username )
        {
            toast("Niste unijeli sve podatke!");
            if (!state.jmbg) { setJmbgErr(true); } else { setJmbgErr(false); }
            if (!state.firstName) { setFirstNameErr(true); } else { setFirstNameErr(false); }
            if (!state.lastName) { setLastNameErr(true); } else { setLastNameErr(false); }
            if (!state.email) { setEmailErr(true); } else { setEmailErr(false); }
            if (state.role && state.role.key === 'ROLE_ORGANIZER' && !state.organizer) { setOrganizerErr(true); } else { setOrganizerErr(false); }
            if (!state.role) { setRoleErr(true); } else { setRoleErr(false); }
            if (!state.username) { setUsernameErr(true); } else { setUsernameErr(false); }

            return false;
        }
        return true;
    }
    function addUser(){
        if (formValidation()){
            let obj = {
                // id: id,
                // optLock: optLock,
                jmbg: state.jmbg,
                firstName: state.firstName,
                lastName: state.lastName,
                username: state.username,
                email: state.email,
                organizer: state.organizer,
                role: state.role,

            };

            axios.post('/user-role/save', {...obj}).then(
                (res) => {
                    if(res !== null){
                        axios({
                            method: 'post',
                            url: `document/credentials/${res.data.id}`,
                            responseType: 'blob',
                            // data: { ...obj }
                        }).then(
                            (response) => {
                                toggleAddForm();
                                toast('Uspješno ste dodali korisnika.');
                                getUsers();
                                const fileName = extractFileNameFromHeaders(response.headers);
                                download(response.data, fileName);
                            }).catch((err) => {
                            toast('Došlo je do greške!');
                        })
                    }else{
                        toggleAddForm();
                        toast('Uspješno ste dodali ulogu korisniku.');
                        getUsers();
                    }


                }).catch((err) => {
                if (err.response.data.message === 'This username is already taken!') {
                    toast('Korisničko ime je već zauzeto, odaberite novo.');
                } else if (err.response.data.message === 'More than one person found with passed jmbg!'){
                    toast('Dvije osobe pronađene sa istim JMBG/JIB-om.');
                } else if (err.response.data.message === 'This email is already taken!'){
                    toast('E-mail je već zauzet, unesite novi.');
                } else {
                    toast('Došlo je do greške!');
                }
                console.log('err', err)
            });
        }


    }

    function editUser(){
        if (formValidation()){
            let obj = {
                ...userRole,
                userOrganizer: {
                    ...userRole.userOrganizer,
                    user: {
                        ...userRole.userOrganizer.user,
                        email: state.email,
                        person: {
                            ...userRole.userOrganizer.user.person,
                            firstName: state.firstName,
                            lastName: state.lastName,
                            jmbg: state.jmbg

                        }
                    }
                }
            };

            axios.put('/user-role/edit', {...obj}).then(
                (res) => {
                    toggleAddForm();
                    toast('Uspješno ste izmijenili korisnika.');
                    getUsers();
                }).catch((err) => {
                console.log('err', err)
            });
        }


    }

    function setValues(){
        dispatch({type: 'email', payload: userRole.userOrganizer.user.email});
        dispatch({type: 'firstName', payload: userRole.userOrganizer.user.person.firstName});
        dispatch({type: 'lastName', payload: userRole.userOrganizer.user.person.lastName});
        dispatch({type: 'jmbg', payload: userRole.userOrganizer.user.person.jmbg});
        dispatch({type: 'organizer', payload: userRole.userOrganizer.organizer});
        dispatch({type: 'role', payload: userRole.role});
        dispatch({type: 'username', payload: userRole.userOrganizer.user.username});
    }

    function resetValues(){
        dispatch({type: 'email', payload: ''});
        dispatch({type: 'firstName', payload: ''});
        dispatch({type: 'lastName', payload: ''});
        dispatch({type: 'jmbg', payload: ''});
        dispatch({type: 'organizer', payload: null});
        dispatch({type: 'role', payload: ''});
        dispatch({type: 'username', payload: ''});
    }

    useEffect(() => {
        edit && setValues()
        getRoles();
    },[]);

    function getRoles(){

        axios.get('/role/all',).then(
            (res) => {
                let roles = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type: 'roles', payload: roles});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    return (
        <SegmentGroup raised>
            <Segment >
                <Header as={'h4'}> {edit?'Izmjena podataka korisnika':'Dodavanje novog korisnika'}
                    <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={()=> {
                               resetValues();
                               toggleAddForm();
                               setEdit(false);
                           }}>
                               <Icon name='close'/>
                           </Button>
                        {/*placeholder*/}
                        <Icon/>
                       </span>
                </Header>
            </Segment>
            <Segment clearing>
                <Form size={'small'} >
                    <Form.Group widths={'equal'} >
                        <Form.Field
                            required
                            error={jmbgErr}
                            label="JMBG/JIB"
                            control={Input}
                            name="jmbg"
                            value={state.jmbg}
                            onChange={handleInputChange}
                            placeholder="Unesite JMBG"
                        />
                        <Form.Field
                            required
                            error={firstNameErr}
                            label="Ime"
                            control={Input}
                            name="firstName"
                            value={state.firstName}
                            onChange={handleInputChange}
                            placeholder="Unesite ime"
                            disabled={disablePerson}
                        />
                        <Form.Field
                            required
                            error={lastNameErr}
                            label="Prezime"
                            control={Input}
                            name="lastName"
                            value={state.lastName}
                            onChange={handleInputChange}
                            placeholder="Unesite prezime"
                            disabled={disablePerson}
                        />
                        <Form.Field required  error={roleErr}>
                            <label>Uloga</label>
                            <Form.Dropdown
                                disabled={edit}
                                clearable
                                placeholder="Odaberite ulogu"
                                value={state.role}
                                onChange={handleInputChange}
                                name="role"
                                options={state.roles}
                                search
                                fluid
                                selection
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths={'equal'}>
                        <Form.Field
                            required
                            error={usernameErr}
                            label="Korisničko ime"
                            control={Input}
                            name="username"
                            value={state.username}
                            onChange={handleInputChange}
                            placeholder="Unesite korisničko ime"
                            disabled={disableUser}
                        />
                        <Form.Field
                            required
                            error={emailErr}
                            label="Korisnički e-mail"
                            control={Input}
                            name="email"
                            value={state.email}
                            onChange={handleInputChange}
                            placeholder="Unesite e-mail"
                            disabled={disableUser}
                        />
                        <Form.Field required={(state.role && state.role.key === 'ROLE_ORGANIZER')}  error={organizerErr}>
                            <label>Organizator</label>
                            <Form.Dropdown
                                disabled={(state.role && state.role.key !== 'ROLE_ORGANIZER') || edit}
                                clearable
                                placeholder="Odaberite organizatora"
                                value={state.organizer}
                                onChange={handleInputChange}
                                name="organizer"
                                options={organizers}
                                search
                                fluid
                                selection
                            />
                        </Form.Field>


                    </Form.Group>

                </Form>
                <Button floated={"right"} basic size={"small"} color={"teal"} onClick={edit?editUser:addUser}>{edit?'Sačuvaj izmjene': 'Kreiraj korisnika'}</Button>
            </Segment>

        </SegmentGroup>
    );
};

export default UserForm;
