import React from 'react';
import MainMenu from "./MainMenu";

const Header = () => {

    return (

        <header className="headerClass">
            <MainMenu/>
        </header>
    );
};

export default Header;
