import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import QrScan from "../../components/public/QRScan";


function PublicRoutes() {
    return (

        <Routes>
            <Route exact index path="/" element={<QrScan />} />
            <Route exact index path="/verifikacija-dokumenta/:documentTypeId/:sspId" element={<QrScan />} />
            <Route exact index path="/invalid-route" element={<QrScan />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>

    );
}

export default PublicRoutes;
