import React, {useContext, useEffect, useReducer} from 'react';
import axios from "axios";
import moment from "moment";
import MarksOnRegistration from "./MarksOnRegistration";
import {Loader, Message, Segment} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {toast, ToastContainer} from "react-toastify";
import {UserRoleContext} from "../../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'registeredCourses':
            return {...state, registeredCourses: action.payload};
        case 'selectedCourses':
            return {...state, selectedCourses: action.payload};
        case 'committeeOptions':
            return {...state, committeeOptions: action.payload};
        case 'marks':
            return {...state, marks: action.payload};
        case 'mark':
            return {...state, marks: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        default:
            return state;

    }
};
const Marks = ({registrationId, registration, toggleMarkForm}) => {
    const sspGrade = JSON.parse(localStorage.getItem('sspGrade'));
    const organizerId = localStorage.getItem('organizerId')
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        marks: [],
        mark: null,
        registeredCourses: [],
        selectedCourses: [],
        committeeOptions: [],
        loader: false,
        editForm: false,

    });

    useEffect(()=>{
        getRegisteredCourses();
        getMarks();
        if (activeRole === "ROLE_ORGANIZER") {getCommittees();}
        else {getCommitteesForAdmin()}
        // getCommittees();
    },[]);

    function getMarks(){
        axios.get('/mark/all',).then(
            (res) => {
                let marks = res.data.map((el)=>({key: el.id, text: el.number, description:  el.name, value: el.id})).reverse();
                dispatch({type: 'marks', payload: marks});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getCommittees(){
        axios.get('/committee/by-organizer',).then(
            (res) => {
                let committees = res.data.map((el)=>({key: el.key, text: (el.name), value: el}));
                dispatch({type: 'committeeOptions', payload: committees});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getCommitteesForAdmin(){
        axios.get(`/committee/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let committees = res.data.map((el)=>({key: el.key, text: (el.name), value: el}));
                dispatch({type: 'committeeOptions', payload: committees});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getRegisteredCourses(loader=true){
        if(loader){dispatch({type: 'loader', payload: true});}

        axios.get(`student-study-program-exam-mark/by-exam-registration/${registrationId}`,).then(
            (res) => {
                let courses = res.data;
                dispatch({type: 'registeredCourses', payload: courses});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });

    }

    function addMarks() {
        axios.put('/student-study-program-exam-mark/edit-list', [...state.registeredCourses])
            .then((res) => {
                toggleMarkForm()
                getRegisteredCourses(false);
                toast('Podaci o ocjenama su uspješno sačuvani.')
            }).catch((err) => {
            console.log('err', err)
        });

    }

    function handleInputChange(event, { name, value, el }) {
       // dispatch({type: name, payload: value});
        if(name === 'finalMark'){
            let obj = {...el, finalMark: value?{id: value}:null, markEntryDate: el.markEntryDate?el.markEntryDate: new Date() };
            let registeredCourses = state.registeredCourses.filter((e)=>e.id!==el.id);
            registeredCourses =[...registeredCourses, obj];
            dispatch({type: 'registeredCourses', payload: registeredCourses.sort((a, b) =>a.courseStudyProgram.id - b.courseStudyProgram.id)});
        }
        if(name === 'verbalExamMark'){
            let obj = {...el, verbalExamMark: value?{id: value}:null, markEntryDate: el.markEntryDate?el.markEntryDate:new Date() };
            let registeredCourses = state.registeredCourses.filter((e)=>e.id!==el.id);
            registeredCourses =[...registeredCourses, obj];
            dispatch({type: 'registeredCourses', payload: registeredCourses.sort((a, b) => a.courseStudyProgram.id - b.courseStudyProgram.id)});

        }
        if(name === 'writtenExamMark'){
            let obj = {...el, writtenExamMark: value?{id: value}:null, markEntryDate: el.markEntryDate?el.markEntryDate:new Date() };
            let registeredCourses = state.registeredCourses.filter((e)=>e.id!==el.id);
            registeredCourses =[...registeredCourses, obj];
            dispatch({type: 'registeredCourses', payload: registeredCourses.sort((a, b) => a.courseStudyProgram.id - b.courseStudyProgram.id)});

        }
        if(name === 'committee'){
            let obj = {...el, committee: value?value:null, markEntryDate: el.markEntryDate?el.markEntryDate:new Date() };
            let registeredCourses = state.registeredCourses.filter((e)=>e.id!==el.id);
            registeredCourses =[...registeredCourses, obj];
            dispatch({type: 'registeredCourses', payload: registeredCourses.sort((a, b) => a.courseStudyProgram.id - b.courseStudyProgram.id)});

        }


    }

    function handleDateChange(name, date, el) {

        if(name === 'markDate'){
            let obj = {...el, markDate: date ? date:null, markEntryDate: el.markEntryDate?el.markEntryDate:new Date() };
            let registeredCourses = state.registeredCourses.filter((e)=>e.id!==el.id);
            registeredCourses =[...registeredCourses, obj];
            dispatch({type: 'registeredCourses', payload: registeredCourses.sort((a, b) => a.courseStudyProgram.id - b.courseStudyProgram.id)});

        }
    }

    return (
        state.loader?
            <div ><Loader active inline="centered" /></div>
            :
                    <Segment clearing>
                        <Button floated={"right"} icon size={"mini"} basic onClick={toggleMarkForm}>
                            <Icon name='close'/>
                        </Button>
                        <br/>
                        <br/>
                        <MarksOnRegistration
                            courses={state.registeredCourses}
                            applicationDate={registration.applicationDate}
                            addMarks={addMarks}
                            getRegisteredCourses={getRegisteredCourses}
                            marks={state.marks}
                            committees={state.committeeOptions}
                            handleInputChange={handleInputChange}
                            handleDateChange={handleDateChange}
                            toggleMarkForm={toggleMarkForm}
                            // selectAllFlag={selectAllFlag}
                            // selectCourse={selectCourses}
                            // selectedCourses={state.selectedCourses}
                            // toggleSelectAllFlag={toggleSelectAllFlag}
                        />
                    </Segment>




    );
};

export default Marks;
