import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {GridColumn, GridRow, Header, Loader, Menu, Segment, SegmentGroup} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import moment from "moment";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import axios from "axios";
import PreviousEducation from "../Utilities/PreviousEducation";
import PersonInfo from "../Utilities/PersonInfo";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import StudentPlaceOfBirthForm from "../Students/StudentPlaceOfBirthForm";
import PlaceInfo from "../Organizers/OrganizerDetails/PlaceInfo";
import StudentPlaceOfResidenceForm from "../Students/StudentPlaceOfResidenceForm";
import {toast, ToastContainer} from "react-toastify";
import PreviousEducationForm from "../Utilities/PreviousEducationForm";
import {UserRoleContext} from "../../Main";
import OriginPlaceOfEmployment from "./OriginPlaceOfEmployment";
import TeacherEmployments from "./TeacherEmployments";

const reducer = (state, action)=>{
    switch (action.type) {
        case 'previousEducations':
            return {...state, previousEducations: action.payload, loader:  false };
        case 'teacher':
            return {...state, teacher: action.payload, loader:  false };
        case 'municipalities':
            return {...state, municipalities: action.payload };
        // previous education
        case 'institutionName':
            return {...state, institutionName: action.payload };
        case 'diplomaNumber':
            return {...state, diplomaNumber: action.payload };
        case 'diplomaDate':
            return {...state, diplomaDate: action.payload };
        case 'educationLevel':
            return {...state, educationLevel: action.payload};
        case 'documentType':
            return {...state, documentType: action.payload};
        case 'title':
            return {...state, title: action.payload};
        case 'educationLevels':
            return {...state, educationLevels: action.payload};
        case 'documentTypes':
            return {...state, documentTypes: action.payload};
        // osnovni podaci
        case 'firstName':
            return {...state, firstName: action.payload};
        case 'lastName':
            return {...state, lastName: action.payload};
        case 'maidenName':
            return {...state, maidenName: action.payload};
        case 'gender':
            return {...state, gender: action.payload};
        case 'dateOfBirth':
            return {...state, dateOfBirth: action.payload};
        case 'teacherEmployments':
            return {...state, teacherEmployments: action.payload};
        case 'originPlaceOfEmployment':
            return {...state, originPlaceOfEmployment: action.payload};
        case 'numberOfHoursInOriginPlaceOfEmployment':
            return {...state, numberOfHoursInOriginPlaceOfEmployment: action.payload};
        case 'employmentStartDate':
            return {...state, employmentStartDate: action.payload};
        case 'employmentEndDate':
            return {...state, employmentEndDate: action.payload};
        default:
            return state;
    }
};

const TeacherDetails = () => {
    const location = useLocation();
    const {name, id, personId, organizer, teacher} = location.state;
    const [showEditOriginPlaceOfEmploymentForm, setEditOriginPlaceOfEmploymenForm] = useState(false);
    const [showEditTeacherEmploymentsForm, setEditTeacherEmploymentsForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showEditPlaceForm, setShowEditPlaceForm] = useState(false);
    const [showEditPlaceOfResidenceForm, setShowEditPlaceOfResidenceForm] = useState(false);
    const [showPreviousEducationForm, setShowPreviousEducationForm] = useState(false);
    const [showAddPreviousEducationForm, setShowAddPreviousEducationForm] = useState(false);
    const [originPlaceOfEmploymentErr, setOriginPlaceOfEmploymentErr] = useState(false);
    const [numberOfHoursInOriginPlaceOfEmploymentErr, setNumberOfHoursInOriginPlaceOfEmploymentErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        teacher: null,
        previousEducations:[],
        teacherEmployments: [],
        loader: true,
        institutionName: '',
        diplomaDate: new Date(),
        diplomaNumber: '',
        title: '',
        educationLevel: '',
        documentType: '',
        originPlaceOfEmployment: null,
        numberOfHoursInOriginPlaceOfEmployment: null
    } );

    useEffect(() => {
        // if (activeRole === "ROLE_ORGANIZER")  {getTeacherEmployments();}
        // if (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") {getTeacherEmploymentsForAdmin();}
        getTeacherEmployments();
        getTeacherById();
        getPreviousEducation();
    },[activeRole]);

    let toggleOriginPlaceOfEmploymentForm = () =>{
        setEditOriginPlaceOfEmploymenForm(!showEditOriginPlaceOfEmploymentForm)
    };

    let toggleTeacherEmploymentsForm = () =>{
        setEditTeacherEmploymentsForm(!showEditTeacherEmploymentsForm)
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    // function getTeacherEmployments(){
    //     axios.get('/teacher-organizer/by-organizer',).then(
    //         (res) => {
    //             let teacherEmployments = res.data;
    //             dispatch({type: 'teacherEmployments', payload: teacherEmployments});
    //         }).catch((err) => {
    //         console.log('err', err)
    //     });
    // }

    function getTeacherEmployments(){
        axios.get(`/teacher-organizer/by-teacher-id/${teacher.id}`,).then(
            (res) => {
                let teacherEmployments = res.data;
                dispatch({type: 'teacherEmployments', payload: teacherEmployments});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    // function getTeacherEmploymentsForAdmin(){
    //     axios.get(`/teacher-organizer/by-organizer-id/${organizer}`,).then(
    //         (res) => {
    //             let teacherEmployments = res.data;
    //             dispatch({type: 'teacherEmployments', payload: teacherEmployments});
    //         }).catch((err) => {
    //         console.log('err', err)
    //     });
    // }

    let getLookupsForPreviousEducation = () =>{

        axios.get('/education-level/all',).then(
            (res)=>{
                let educationLevels = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'educationLevels', payload: educationLevels});

            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/document-type/all',).then(
            (res)=>{
                let documentTypes = res.data.filter((e)=>(e.graduationDocument === true)).map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'documentTypes', payload: documentTypes});

            }).catch((err)=>{
            console.log('err', err)
        });

    };

    let togglePreviousEducationForm = () =>{
        getLookupsForPreviousEducation();
        setShowPreviousEducationForm(!showPreviousEducationForm)
    };


    let toggleAddPreviousEducationForm = () =>{
        getLookupsForPreviousEducation();
        setShowAddPreviousEducationForm(!showAddPreviousEducationForm)
    };

    function getPreviousEducation(){
        axios.get(`/previous-education/by-person-id/${personId}`,).then(
            (res) => {
                let previousEducations = res.data.reverse();
                dispatch({type: 'previousEducations', payload: previousEducations});
                dispatch({type: 'institutionName', payload: previousEducations[0].institutionName});
                dispatch({type: 'diplomaNumber', payload: previousEducations[0].documentNumber});
                dispatch({type: 'diplomaDate', payload: new Date(previousEducations[0].documentDate)});
                dispatch({type: 'educationLevel', payload: previousEducations[0].educationLevel});
                dispatch({type: 'documentType', payload: previousEducations[0].documentType});
                dispatch({type: 'title', payload: previousEducations[0].title});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function editPreviousEducation(){

        let objPrevEdu = {
            id: state.previousEducations[0].id,
            optLock: state.previousEducations[0].optLock,
            institutionName: state.institutionName,
            documentNumber: state.diplomaNumber,
            documentDate: state.diplomaDate,
            educationLevel: state.educationLevel,
            documentType: state.documentType,
            title: state.title,
            person: {id: personId}
        };
        axios.put('/previous-education/edit', {...objPrevEdu}).then(
            (res) => {
                toast('Podaci su uspješno izmijenjeni.');
                getPreviousEducation();
                togglePreviousEducationForm()
            }).catch((err) => {
            toast('Došlo je do greške.');
            console.log('err', err)
        });
    }

    function addPreviousEducation(){
        let objPrevEdu = {
            institutionName: state.institutionName,
            documentNumber: state.diplomaNumber,
            documentDate: state.diplomaDate,
            documentType: state.documentType,
            educationLevel: state.educationLevel,
            title: state.title,
            person: {id: personId}
        };
        axios.post('/previous-education/save', {...objPrevEdu}).then(
            (res) => {
                toast('Uspješno ste dodali prethodno obrazovanje.');
                getPreviousEducation();
                toggleAddPreviousEducationForm();
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getTeacherById() {
        axios.get(`/teacher/${id}`,).then(
            (res) => {
                let teacher = res.data;
                dispatch({type: 'teacher', payload: teacher});
                dispatch({type: 'originPlaceOfEmployment', payload: teacher.originPlaceOfEmployment});
                dispatch({type: 'numberOfHoursInOriginPlaceOfEmployment', payload: teacher.numberOfHoursInOriginPlaceOfEmployment});
            }).catch((err) => {
            console.log('err', err)
        });

    }

    function  editPersonInfo(){
        let obj = {
            ...state.teacher.person,
            firstName: state.firstName ? state.firstName : state.teacher.person.firstName,
            lastName: state.lastName ? state.lastName : state.teacher.person.lastName,
            maidenName: state.maidenName ? state.maidenName : state.teacher.person.maidenName,
            gender: state.gender ? state.gender : state.teacher.person.gender,
            dateOfBirth: state.dateOfBirth ? state.dateOfBirth : state.teacher.person.dateOfBirth,

        };

        axios.put('/person/edit', {...obj}).then(
            (res) => {
                toast('Podaci su uspješno sačuvani.');
                getTeacherById();
                toggleEditForm()
            }).catch((err) => {
            toast('Došlo je do greške!');
            console.log('err', err)
        });
    }

    function  editoriginPlaceOfEmployment(){
        if (state.originPlaceOfEmployment && !state.numberOfHoursInOriginPlaceOfEmployment){
            toast("Potrebno je da unesete i broj sati na matičnom zaposlenju!")
            if (!state.numberOfHoursInOriginPlaceOfEmployment) { setNumberOfHoursInOriginPlaceOfEmploymentErr(true); setOriginPlaceOfEmploymentErr(false); } else { setNumberOfHoursInOriginPlaceOfEmploymentErr(false); }
        }
        else if(!state.originPlaceOfEmployment && state.numberOfHoursInOriginPlaceOfEmployment){
            toast("Potrebno je da unesete matično zaposlenje!")
            if (!state.originPlaceOfEmployment) { setOriginPlaceOfEmploymentErr(true); setNumberOfHoursInOriginPlaceOfEmploymentErr(false); } else { setOriginPlaceOfEmploymentErr(false); }
        }
        else {


            let obj = {
                id: state.teacher.id,
                optLock: state.teacher.optLock,
                originPlaceOfEmployment: state.originPlaceOfEmployment,
                numberOfHoursInOriginPlaceOfEmployment: state.numberOfHoursInOriginPlaceOfEmployment
            };

            axios.put('/teacher/edit', {...obj}).then(
                (res) => {
                    toast('Podaci su uspješno sačuvani.');
                    getTeacherById();
                    toggleOriginPlaceOfEmploymentForm();
                }).catch((err) => {
                toast('Došlo je do greške!');
                console.log('err', err)
            });
        }
    }

    function  editTeacherEmployments(){
        let obj = {
            id: state.teacherEmployments[0].id,
            optLock: state.teacherEmployments[0].optLock,
            teacher: {id: state.teacherEmployments[0].teacher.id},
            organizer: {id: state.teacherEmployments[0].organizer.id},
            employmentStartDate: state.employmentStartDate ? state.employmentStartDate : state.teacherEmployments[0].employmentStartDate,
            employmentEndDate: state.employmentEndDate ? state.employmentEndDate : state.teacherEmployments[0].employmentEndDate
        };

        axios.put('/teacher-organizer/edit', {...obj}).then(
            (res) => {
                toast('Podaci su uspješno sačuvani.');
                getTeacherById();
                toggleTeacherEmploymentsForm();
                getTeacherEmployments();
            }).catch((err) => {
            toast('Došlo je do greške!');
            console.log('err', err)
        });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value})

    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    let toggleEditPlaceForm = () =>{
        setShowEditPlaceForm(!showEditPlaceForm)
    };

    let toggleEditPlaceOfResidenceForm = () =>{
        setShowEditPlaceOfResidenceForm(!showEditPlaceOfResidenceForm)
    };

    return (
        <div>
            <SegmentGroup >
                <Segment className={'customPadding'} clearing color={"teal"}>
                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>
                    <Menu.Item ><Header as={'h3'} floated={"left"}>
                        {name}
                        <span>
                            <Icon/></span>
                        <Header.Subheader>
                            <i>Nastavnik/ica</i>
                        </Header.Subheader>
                    </Header>
                    </Menu.Item>
                    <Menu.Menu position={'right'}>
                        <Menu.Item>
                            <span>
                                <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                    <Icon name='arrow left'/>
                                </Button>
                                <Icon/>
                            </span>
                        </Menu.Item>
                    </Menu.Menu>
                    </Menu>
                </Segment>
                <Segment>
                    <Grid columns={2}>
                        <GridRow>
                            <GridColumn>
                                <SegmentGroup>
                                    <Segment >
                                        <Header as={'h4'}>
                                            <span><Icon  name='address card outline' color={"black"} /></span>
                                            Osnovni podaci
                                            {activeRole !== "ROLE_MINISTRY" &&
                                            <span>
                                                    {/*<Button floated={"right"} icon size={"mini"} disabled basic onClick={toggleEditForm}>*/}
                                                    {/*    <Icon name='pencil alternate' color={"teal"}/>*/}
                                                    {/*</Button>*/}
                                                <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                                                        <Icon name={showEditForm?'close' : 'pencil alternate'} color={showEditForm?'':"teal"}/>
                                                    </Button>
                                                {/*placeholder*/}
                                                <Icon/></span>
                                            }
                                        </Header>
                                    </Segment>
                                    <Segment clearing>
                                        <Table celled size={"small"}>
                                            <Table.Body>
                                                <PersonInfo
                                                    teacher
                                                    person={state.teacher ?state.teacher.person:null}
                                                    // person={state.teacher ? state.teacher.person : null}
                                                    edit={showEditForm}
                                                    handleInputChange={handleInputChange}
                                                    handleDateChange={handleDateChange}
                                                    firstName={state.firstName ? state.firstName : state.teacher ? state.teacher.person.firstName:''}
                                                    lastName={state.lastName ? state.lastName : state.teacher ? state.teacher.person.lastName:''}
                                                    maidenName={state.maidenName ? state.maidenName : state.teacher ? state.teacher.person.maidenName:''}
                                                    gender={state.gender ? state.gender : state.teacher ? state.teacher.person.gender:''}
                                                    dateOfBirth={state.dateOfBirth ? new Date(state.dateOfBirth) : state.teacher ? new Date(state.teacher.person.dateOfBirth):''}
                                                />
                                            </Table.Body>
                                        </Table>
                                        {showEditForm && <Button floated={"right"} basic size={"small"} color={"teal"} onClick={()=>editPersonInfo()}>{'Sačuvaj'}</Button>}
                                    </Segment>

                                </SegmentGroup>
                                <SegmentGroup>
                                    <Segment >
                                        <Header as={'h4'}>
                                            <span><Icon  name='address card outline' color={"black"} /></span>
                                            Mjesto rođenja
                                            {(!showEditPlaceForm && activeRole !== "ROLE_MINISTRY") && <span>
                                                       <Popup size={"small"} content={'Izmijeni podatke'} trigger={
                                                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditPlaceForm} >
                                                               <Icon name='pencil alternate' color={"teal"}/>
                                                           </Button>} />

                                                <Icon/></span>}
                                        </Header>
                                    </Segment>
                                    {showEditPlaceForm && <Segment>
                                        <StudentPlaceOfBirthForm edit toggleEditForm={toggleEditPlaceForm} getTeacher={getTeacherById} teacher={state.teacher}/>
                                    </Segment>}
                                    <Segment>
                                        <PlaceInfo placeOfBirth={state.teacher ? state.teacher.person.placeOfBirth : null}/>
                                    </Segment>
                                </SegmentGroup>
                                <SegmentGroup>
                                    <Segment >
                                        <Header as={'h4'}>
                                            <span><Icon  name='address card outline' color={"black"} /></span>
                                            Prebivalište
                                            {(!showEditPlaceOfResidenceForm && activeRole !== "ROLE_MINISTRY") && <span>
                                                       <Popup size={"small"} content={'Izmijeni podatke'} trigger={
                                                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditPlaceOfResidenceForm}>
                                                               <Icon name='pencil alternate' color={"teal"}/>
                                                           </Button>} />

                                                <Icon/></span>}
                                        </Header>
                                    </Segment>
                                    {showEditPlaceOfResidenceForm && <Segment>
                                        <StudentPlaceOfResidenceForm edit toggleEditForm={toggleEditPlaceOfResidenceForm} getTeacher={getTeacherById} teacher={state.teacher}/>
                                    </Segment>}
                                    <Segment>
                                        <PlaceInfo residence={state.teacher ? state.teacher.person.residence : null}/>
                                    </Segment>
                                </SegmentGroup>
                            </GridColumn>
                            <GridColumn>
                                <SegmentGroup>
                                    <Segment >
                                        <Header as={'h4'}>
                                            <span><Icon  name='graduation' color={"black"} /></span>
                                            Prethodno obrazovanje
                                            <span>
                                                    {(!showPreviousEducationForm && activeRole !== "ROLE_MINISTRY") && <Button floated={"right"} icon size={"mini"} basic onClick={togglePreviousEducationForm}>
                                                        <Icon name='pencil alternate' color={"teal"}/>
                                                    </Button>}
                                                    {(!showAddPreviousEducationForm && activeRole !== "ROLE_MINISTRY") && <Button floated={"right"} icon size={"mini"} basic onClick={toggleAddPreviousEducationForm}>
                                                        <Icon name='add' color={"teal"}/>
                                                    </Button>}
                                                <Icon/></span>
                                        </Header>
                                    </Segment>
                                    { showPreviousEducationForm && <Segment clearing>
                                        <PreviousEducationForm
                                            institutionName={state.institutionName}
                                            diplomaNumber={state.diplomaNumber}
                                            diplomaDate={state.diplomaDate}
                                            educationLevel={state.educationLevel}
                                            educationLevels={state.educationLevels}
                                            documentType={state.documentType}
                                            documentTypes={state.documentTypes}
                                            title={state.title}
                                            handleInputChange={handleInputChange}
                                            handleDateChange={handleDateChange}
                                            edit
                                            editPreviousEducation={editPreviousEducation}
                                            toggleEditForm={togglePreviousEducationForm}
                                            getPreviousEducation={getPreviousEducation}
                                        />
                                    </Segment>}
                                    { showAddPreviousEducationForm &&
                                    <Segment>
                                        <PreviousEducationForm
                                            removeButtons
                                            teacher
                                            diplomaDate={state.diplomaDate}
                                            educationLevels={state.educationLevels}
                                            documentTypes={state.documentTypes}
                                            handleInputChange={handleInputChange}
                                            handleDateChange={handleDateChange}
                                            addPreviousEducation={addPreviousEducation}
                                            add
                                            toggleEditForm={toggleAddPreviousEducationForm}
                                            getPreviousEducation={getPreviousEducation}

                                            // institutionNameErr={institutionNameErr}
                                            // diplomaNumberErr={diplomaNumberErr}
                                            // diplomaDateErr={diplomaDateErr}
                                            // educationLevelErr={educationLevelErr}
                                            // titleErr={titleErr}
                                            // gradeErr={gradeErr}
                                            // gradIsNotMandatory={state.studyType.key === 'primary education'}

                                        />
                                    </Segment>}
                                    <Segment>
                                        <PreviousEducation previousEducation={state.previousEducations.length !== 0 ?state.previousEducations[0]: null}  />
                                    </Segment>
                                </SegmentGroup>
                                <SegmentGroup>
                                    <Segment >
                                        <Header as={'h4'}>
                                            <span><Icon  name='building' color={"black"} /></span>
                                            Matično zaposlenje
                                            {activeRole !== "ROLE_MINISTRY" &&
                                            <span>
                                                <Button floated={"right"} icon size={"mini"} basic onClick={toggleOriginPlaceOfEmploymentForm}>
                                                        <Icon name={showEditOriginPlaceOfEmploymentForm?'close' : 'pencil alternate'} color={showEditOriginPlaceOfEmploymentForm?'':"teal"}/>
                                                    </Button>
                                                <Icon/></span>
                                            }
                                        </Header>
                                    </Segment>
                                    <Segment clearing>
                                        <Table celled size={"small"}>
                                            <Table.Body>
                                                <OriginPlaceOfEmployment
                                                    teacher
                                                    person={state.teacher ? state.teacher:null}
                                                    edit={showEditOriginPlaceOfEmploymentForm}
                                                    handleInputChange={handleInputChange}
                                                    originPlaceOfEmployment={state.originPlaceOfEmployment}
                                                    originPlaceOfEmploymentErr={originPlaceOfEmploymentErr}
                                                    // originPlaceOfEmployment={state.originPlaceOfEmployment ? state.originPlaceOfEmployment : state.teacher ? state.teacher.originPlaceOfEmployment : ''}
                                                    numberOfHoursInOriginPlaceOfEmployment={state.numberOfHoursInOriginPlaceOfEmployment}
                                                    numberOfHoursInOriginPlaceOfEmploymentErr={numberOfHoursInOriginPlaceOfEmploymentErr}
                                                    // numberOfHoursInOriginPlaceOfEmployment={state.numberOfHoursInOriginPlaceOfEmployment ? state.numberOfHoursInOriginPlaceOfEmployment : state.teacher ? state.teacher.numberOfHoursInOriginPlaceOfEmployment : ''}
                                                />
                                            </Table.Body>
                                        </Table>
                                        {showEditOriginPlaceOfEmploymentForm && <Button floated={"right"} basic size={"small"} color={"teal"} onClick={()=>editoriginPlaceOfEmployment()}>{'Sačuvaj'}</Button>}
                                    </Segment>

                                </SegmentGroup>
                                <SegmentGroup>
                                    <Segment >
                                        <Header as={'h4'}>
                                            <span><Icon  name='list' color={"black"} /></span>
                                            Podaci o angažmanima
                                            {activeRole !== "ROLE_MINISTRY" &&
                                            <span>
                                                <Button floated={"right"} icon size={"mini"} basic onClick={toggleTeacherEmploymentsForm}>
                                                        <Icon name={showEditTeacherEmploymentsForm?'close' : 'pencil alternate'} color={showEditTeacherEmploymentsForm?'':"teal"}/>
                                                    </Button>
                                                <Icon/></span>
                                            }
                                        </Header>
                                    </Segment>
                                    <Segment clearing>

                                    <TeacherEmployments
                                        edit={showEditTeacherEmploymentsForm}
                                        teacherEmployments={state.teacherEmployments}
                                        />
                                        {showEditTeacherEmploymentsForm && <Button floated={"right"} basic size={"small"} color={"teal"} onClick={()=>editTeacherEmployments()}>{'Sačuvaj'}</Button>}
                                    </Segment>
                                </SegmentGroup>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Segment>
            </SegmentGroup>
            <ToastContainer/>
        </div>
    );
};

export default TeacherDetails;
