import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Button, Form, Header, Icon, Loader, Menu, Segment} from "semantic-ui-react"

import { toast, ToastContainer } from 'react-toastify';
import axios from "axios";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import EmployerForm from "./EmployerForm";
import {UserRoleContext} from "../../Main";
import SearchTableData from "../Utilities/SearchTableData";
import ReactExport from 'react-data-export';
import PaginationFront from "../Utilities/PaginationFront";

import moment from "moment";


const reducer = (state, action)=> {
    switch (action.type) {
        case 'employers':
            return {...state, employers: action.payload};
        case 'employersData':
            return {...state, employersData: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'employer':
            return {...state, employer: action.payload};
        case 'municipalities':
            return {...state, municipalities: action.payload };
        case 'openTable':
            return {...state, openTable: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'currentIdOrganizer':
            return {...state, currentIdOrganizer: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;

    }
}

const Employers = () => {
    const {activeRole} = useContext(UserRoleContext);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [state, dispatch] = useReducer(reducer,{
        employers: [],
        employersData: [],
        currentId: null,
        employer: '',
        openTable: false,
        organizer: '',
        organizers: [],
        currentIdOrganizer: null,
        loader: false,
        data:[],
        results:[],
        postsPerPage: 20,
        currentPosts: [],
    });

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    const columns = [
        { title: 'Poslodavac', width: { wpx: 200 } },
        { title: 'Grad', width: { wpx: 150 } },
        { title: 'Adresa', width: { wpx: 150 } },
        { title: 'E-mail', width: { wpx: 150 } },
        { title: 'Telefon', width: { wpx: 150 } },
    ];

    if (activeRole !== "ROLE_ORGANIZER") {
        columns.unshift({ title: 'Organizator', width: { wpx: 250 } });
    }

    const multiDataSet = [
        {
            columns: columns,
            data: state.employersData,
        }
    ];

    // const multiDataSet = [
    //     {
    //         columns: [
    //             { title: 'Poslodavac', width: { wpx: 200 } },
    //             { title: 'Grad', width: { wpx: 150 } },
    //             { title: 'Adresa', width: { wpx: 150 } },
    //             { title: 'E-mail', width: { wpx: 150 } },
    //             { title: 'Telefon', width: { wpx: 150 } },
    //         ],
    //         data: state.employersData,
    //     }
    // ];
    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function deleteEmployer(id){
        setCurrentId(id);
        axios.delete(`/employer/delete/${id}`,).then(
            (res) => {
                activeRole === "ROLE_ORGANIZER" && getEmployers();
                activeRole === "ROLE_ADMIN" && getEmployersForAdmin(state.organizer);
                // getEmployers();
                toast('Uspješno ste izbrisali poslodavca!');
                setCurrentId(null);
            }).catch((err) => {
                 if (err.response.data.message === 'Contracts for passed employer has not been deleted!') {
                        toast('Ugovori za ovog poslodavca nisu obrisani!');
                     setCurrentId(null);
                    } else {
                        toast('Došlo je do greške! Brisanje poslodavca nije uspjelo.');
                     setCurrentId(null);
                    }
                    console.log('err', err)
                });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            dispatch({type: 'openTable', payload: true});
            getEmployersForAdmin(value)
        }
        if(name === 'organizer' && !value){
            dispatch({type: 'openTable', payload: true});
            getEmployersAll()
        }
    }

    useEffect(() => {
        if (activeRole === "ROLE_ORGANIZER") {
            getEmployers()
        }
        else if(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") {
            getOrganizers();
            getEmployersAll();
        }
        // activeRole === "ROLE_ORGANIZER" ? getEmployers() : getOrganizers();
        // getEmployers();
        axios.get('/municipality/all',).then(
            (res)=>{
                let municipalityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'municipalities', payload: municipalityOptions});

            }).catch((err)=>{
            console.log('err', err)
        });
    },[activeRole]);

    function getEmployers(){
        axios.get('/employer/by-organizer',).then(
            (res) => {
                let employers = res.data.map(el => ({
                    ...el,
                    residencePlace: el.residence.place.name,
                    nameEmployer: el.name
                }))
                // let employers = res.data;
                dispatch({type: 'employers', payload: employers});
                dispatch({type: 'data', payload: employers});
                let employersData = res.data.map(el => ([
                    { value: el.name },
                    { value: el.residence.place.name },
                    { value: el.residence.address },
                    { value: el.email},
                    { value: el.phone},
                ]));
                dispatch({type: 'employersData', payload: employersData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getEmployersAll(){
        axios.get('/employer/all',).then(
            (res) => {
                let employers = res.data.map(el => ({
                    ...el,
                    residencePlace: el.residence.place.name,
                    nameEmployer: el.name
                }))
                // let employers = res.data;
                dispatch({type: 'employers', payload: employers});
                dispatch({type: 'data', payload: employers});
                let employersData = res.data.map(el => ([
                    { value: el.organizer.name },
                    { value: el.name },
                    { value: el.residence.place.name },
                    { value: el.residence.address },
                    { value: el.email},
                    { value: el.phone},
                ]));
                dispatch({type: 'employersData', payload: employersData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getEmployersForAdmin(id){
        setCurrentId(id);
        setCurrentIdOrganizer(id);
        dispatch({type: 'loader', payload: true});
        dispatch({type: 'openTable', payload: true});
        axios.get(`/employer/by-organizer-id/${id}`,).then(
            (res) => {
                let employers = res.data.map(el => ({
                    ...el,
                    residencePlace: el.residence.place.name,
                    nameEmployer: el.name
                }))
                // let employers = res.data;
                dispatch({type: 'employers', payload: employers});
                dispatch({type: 'data', payload: employers});
                let employersData = res.data.map(el => ([
                    { value: el.organizer.name },
                    { value: el.name },
                    { value: el.residence.place.name },
                    { value: el.residence.address },
                    { value: el.email},
                    { value: el.phone},
                ]));
                dispatch({type: 'employersData', payload: employersData});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Spisak poslodavaca </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            {(!showEditForm && activeRole !== "ROLE_MINISTRY") && <Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Kreiraj poslodavca
                                </Button>
                            </Menu.Item>}
                             <Menu.Item>
                                <ExcelFile filename="Spisak poslodavaca" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={multiDataSet} name="Poslodavci" />
                                </ExcelFile>
                            </Menu.Item>
                            <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                        </Menu.Menu>

                    </Menu>

                </Segment>

                {showAddForm &&
                <Segment>
                    <EmployerForm add toggleEditForm={toggleAddForm} getEmployer={getEmployers} getEmployersForAdmin={getEmployersForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer}/>
                </Segment>}

                {showEditForm &&
                <Segment>
                    <EmployerForm edit toggleEditForm={toggleEditForm} getEmployer={getEmployers} getEmployersForAdmin={getEmployersForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer} employer={state.employer} municipalities={state.municipalities}/>
                </Segment>}

                {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Segment><Form size={'small'}>
                    <Form.Group widths="3">
                        <Form.Field>
                            <label>Organizator</label>
                            <Form.Dropdown
                                placeholder="Odaberite organizatora"
                                value={state.organizer}
                                onChange={handleInputChange}
                                name="organizer"
                                options={state.organizers}
                                search
                                fluid
                                selection
                                clearable
                            />
                        </Form.Field>
                    </Form.Group>
                </Form></Segment>}

                {/*{((activeRole === "ROLE_ADMIN" && state.openTable) || (activeRole === "ROLE_MINISTRY" && state.openTable) || activeRole === "ROLE_ORGANIZER") &&*/}
                <Segment>
                    {state.loader ?
                        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                        :
                        currentPosts.length ?
                            <Table celled size={'small'}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={activeRole !== "ROLE_ORGANIZER" ? 11 : 9} >
                                            <div style={{ paddingLeft: '0px' }}>
                                                <SearchTableData data={state.data} getResults={getResults} filterItem={['nameEmployer', 'residencePlace']} />
                                            </div>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>

                                        <Table.HeaderCell>R. br.</Table.HeaderCell>
                                        {activeRole !== "ROLE_ORGANIZER"  &&<Table.HeaderCell colSpan={2} >Organizator</Table.HeaderCell>}
                                        <Table.HeaderCell colSpan={2} >Poslodavac</Table.HeaderCell>
                                        <Table.HeaderCell >Grad</Table.HeaderCell>
                                        <Table.HeaderCell >Adresa</Table.HeaderCell>
                                        <Table.HeaderCell >E-mail</Table.HeaderCell>
                                        <Table.HeaderCell >Telefon</Table.HeaderCell>
                                        {activeRole !== "ROLE_MINISTRY" && <Table.HeaderCell width={1} />}
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        currentPosts.map((el, index) =>
                                        // state.employers.map((el, index)=>
                                            (<Table.Row key={el.id}>
                                                <Table.Cell>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                                {activeRole !== "ROLE_ORGANIZER"  &&<Table.Cell colSpan={2}>{el.organizer.name}</Table.Cell>}
                                                <Table.Cell colSpan={2}>{el.name}</Table.Cell>
                                                <Table.Cell>{el.residence.place.municipality && el.residence.place.municipality.canton ? el.residence.place.name + " | " + el.residence.place.municipality.canton.name + " | " + el.residence.place.municipality.name: el.residence.place.municipality ? el.residence.place.name + " | " + el.residence.place.municipality.name: el.residence.place.name}</Table.Cell>
                                                <Table.Cell>{el.residence.address + " | " + el.residence.zipCode}</Table.Cell>
                                                <Table.Cell>{el.email}</Table.Cell>
                                                <Table.Cell>{el.phone}</Table.Cell>
                                                {activeRole !== "ROLE_MINISTRY" &&<Table.Cell width={1} verticalAlign="middle" textAlign="center">
                                                    <Popup size={"small"} content='Izmijeni poslodavca' trigger={
                                                        <Button icon size={"mini"} basic color={"grey"} onClick={() => {setShowEditForm(true); dispatch({type: 'employer', payload: el})}}>
                                                            <Icon name='pencil' color={"teal"} />
                                                        </Button>} />
                                                    <Popup size={"small"} content='Obriši poslodavca' trigger={
                                                        <Button icon size={"mini"} basic color={"grey"} disabled={state.currentId && el.id !== state.currentId } onClick={() => {deleteEmployer(el.id)}}>
                                                            <Icon name='trash alternate outline' color={"red"} />
                                                        </Button>} />
                                                </Table.Cell>}
                                            </Table.Row>)
                                        )
                                    }


                                </Table.Body>

                                <Table.Footer>
                                    {/*<Table.Row>*/}
                                    {/*    <Table.HeaderCell colSpan='11' textAlign='center'>*/}
                                    {/*        <Menu  pagination>*/}
                                    {/*            <Menu.Item as='a' icon>*/}
                                    {/*                <Icon name='chevron left' />*/}
                                    {/*            </Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>1</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>2</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>3</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>4</Menu.Item>*/}
                                    {/*            <Menu.Item as='a' icon>*/}
                                    {/*                <Icon name='chevron right' />*/}
                                    {/*            </Menu.Item>*/}
                                    {/*        </Menu>*/}
                                    {/*    </Table.HeaderCell>*/}
                                    {/*</Table.Row>*/}
                                </Table.Footer>
                            </Table> : <div>Nema unesenih podataka o poslodavcima.</div>}
                    {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                </Segment>
            </Segment.Group>
            <ToastContainer  />
        </div>
    );
};

export default Employers;
