import React, {useContext, useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Header, Icon, Input, SegmentGroup} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {DateTimePicker} from "react-widgets";
import axios from "axios";
import {toast} from "react-toastify";
import {UserRoleContext} from "../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'studyProgramName':
            return {...state, studyProgramName: action.payload};
        case 'duration':
            return {...state, duration: action.payload};
        case 'documentNumber':
            return {...state, documentNumber: action.payload};
        case 'documentDate':
            return {...state, documentDate: action.payload};
        case 'studyType':
            return {...state, studyType: action.payload};
        case 'endDate':
            return {...state, endDate: action.payload};
        case 'startDate':
            return {...state, startDate: action.payload};
        case 'educationLevel':
            return {...state, educationLevel: action.payload};
        case 'educationLevelOptions':
            return {...state, educationLevelOptions: action.payload};
        case 'supplementaryEducationType':
            return {...state, supplementaryEducationType: action.payload};
        case 'supplementaryEducationTypeOptions':
            return {...state, supplementaryEducationTypeOptions: action.payload};
        case 'occupation':
            return {...state, occupation: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizerOptions':
            return {...state, organizerOptions: action.payload};
        case 'organizerOccupationOptions':
            return {...state, organizerOccupationOptions: action.payload};
        case 'durationHours':
            return {...state, durationHours: action.payload};
        case 'gradeDurationMonths':
            return {...state, gradeDurationMonths: action.payload};
        case 'profession':
            return {...state, profession: action.payload};
        case 'durationMonths':
            return {...state, durationMonths: action.payload};
        case 'comment':
            return {...state, comment: action.payload};
        default:
            return state;

    }
};
const StudyProgramForm = ({toggleEditForm, add, getStudyPrograms, edit, studyProgram, getStudyProgramsForAdmin, dontRefreshList, getStudyProgram}) => {
    const [studyProgramNameErr, setStudyProgramNameErr] = useState(false);
    const [documentNumberErr, setDocumentNumberErr] = useState(false);
    const [documentDateErr, setDocumentDateErr] = useState(false);
    const [durationErr, setDurationErr] = useState(false);
    const [occupationErr, setOccupationErr] = useState(false);
    const [educationLevelErr, setEducationLevelErr] = useState(false);
    const [supplementaryEducationTypeErr, setsupplementaryEducationTypeErr] = useState(false);
    const [endDateErr, setEndDateErr] = useState(false);
    const [gradeDurationMonthsErr, setGradeDurationMonthsErr] = useState(false);
    const [durationMonthsErr, setDurationMonthsErr] = useState(false);
    const [durationHoursErr, setDurationHoursErr] = useState(false);
    const [startDateErr, setStartDateErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        studyProgramName: studyProgram && studyProgram.name ? studyProgram.name : '',
        documentNumber: studyProgram && studyProgram.documentNumber ? studyProgram.documentNumber : '',
        documentDate: studyProgram && studyProgram.documentDate ? new Date(studyProgram.documentDate):  new Date(),
        occupation: studyProgram && studyProgram.organizerOccupation ? studyProgram.organizerOccupation : '',
        endDate: studyProgram && studyProgram.endDate ? new Date(studyProgram.endDate):  null,
        startDate: studyProgram && studyProgram.startDate ? new Date(studyProgram.startDate):  new Date(),
        educationLevel: studyProgram && studyProgram.educationLevel ? studyProgram.educationLevel : '',
        educationLevelOptions: [],
        organizer: studyProgram && studyProgram.organizerOccupation.organizer ? studyProgram.organizerOccupation.organizer.id : '',
        organizerOptions: [],
        organizerOccupationOptions: [],
        profession: '',
        duration: studyProgram && studyProgram.duration ? studyProgram.duration : null, // staviti null umjesto 0 kad se doda na formu :)
        durationMonths: studyProgram && studyProgram.durationMonths ? studyProgram.durationMonths : null,
        gradeDurationMonths: studyProgram && studyProgram.gradeDurationMonths ? studyProgram.gradeDurationMonths : null,
        durationHours: studyProgram && studyProgram.durationHours ? studyProgram.durationHours : null,
        supplementaryEducationType: studyProgram && studyProgram.supplementaryEducationType ? studyProgram.supplementaryEducationType : '',
        supplementaryEducationTypeOptions: [],
        comment: studyProgram && studyProgram.comment ? studyProgram.comment : ''
    });

    useEffect(() => {
        if ((!add && activeRole === "ROLE_ADMIN") || (!add && activeRole === "ROLE_MINISTRY")) { getOrganizer(); getOrganizerOccupationForAdmin(state.organizer);}
        if (activeRole === "ROLE_ORGANIZER") {getOrganizerOccupation();}
        if(activeRole !== "ROLE_ORGANIZER") getOrganizer();
        getEducationLevels();
        supplementaryEducationType();
    },[activeRole]);

    function getOrganizerOccupationForAdmin(id){
        axios.get(`/organizer-occupation/by-organizer-id/${id}`,).then(
            (res) => {
                let organizerOccupationOptions = res.data.map((el)=>({key: el.id, text: el.occupation.profession.name+ ' | ' + el.occupation.nameBosnian, value: el}));
                dispatch({type: 'organizerOccupationOptions', payload: organizerOccupationOptions});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOrganizerOccupation(){
        axios.get('/organizer-occupation/by-organizer',).then(
            (res) => {
                let organizerOccupationOptions = res.data.map((el)=>({key: el.id, text: el.occupation.profession.name + ' | ' + el.occupation.nameBosnian, value: el}));
                dispatch({type: 'organizerOccupationOptions', payload: organizerOccupationOptions});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getEducationLevels(){
        axios.get('/education-level/all',).then(
            (res) => {
                let educationLevelOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type: 'educationLevelOptions', payload: educationLevelOptions});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOrganizer(){
        axios.get('/organizer/all',).then(
            (res) => {
                let organizerOptions = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizerOptions', payload: organizerOptions});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function supplementaryEducationType(){
        axios.get('/supplementary-education-type/all',).then(
            (res) => {
                let supplementaryEducationTypeOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el, description: el.formal? 'formalno': 'neformalno'}));
                dispatch({type: 'supplementaryEducationTypeOptions', payload: supplementaryEducationTypeOptions});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function addStudyProgram() {
        if (!state.studyProgramName || !state.documentNumber || !state.documentDate || !state.supplementaryEducationType
            || !state.occupation || !state.educationLevel || !state.startDate  || !state.durationHours || (state.supplementaryEducationType && state.supplementaryEducationType.formal && state.supplementaryEducationType.key !== 'primary education' && !state.gradeDurationMonths)  || (state.supplementaryEducationType && state.supplementaryEducationType.formal && !state.duration) || (state.supplementaryEducationType && !state.supplementaryEducationType.formal && !state.durationMonths) ) {
            toast('Niste unijeli sve podatke.');
            if (!state.studyProgramName) {setStudyProgramNameErr(true);} else {setStudyProgramNameErr(false);}
            if (!state.documentNumber) {setDocumentNumberErr(true);} else {setDocumentNumberErr(false);}
            if (!state.documentDate) {setDocumentDateErr(true);} else {setDocumentDateErr(false);}
            if (!state.occupation) {setOccupationErr(true);} else {setOccupationErr(false);}
            if (!state.educationLevel) {setEducationLevelErr(true);} else {setEducationLevelErr(false);}
            if (!state.startDate) {setStartDateErr(true);} else {setStartDateErr(false);}
            // if (!state.endDate) {setEndDateErr(true);} else {setEndDateErr(false);}
            if (!state.durationHours) {setDurationHoursErr(true);} else {setDurationHoursErr(false);}
            if (!state.supplementaryEducationType) {setsupplementaryEducationTypeErr(true);} else {setsupplementaryEducationTypeErr(false);}
            if(state.supplementaryEducationType.formal){
                if(state.supplementaryEducationType.key !== 'primary education'){
                    if (!state.duration) {setDurationErr(true);} else {setDurationErr(false);}
                    if (!state.gradeDurationMonths) {setGradeDurationMonthsErr(true);} else {setGradeDurationMonthsErr(false);}
                }else{
                    if (!state.duration) {setDurationErr(true);} else {setDurationErr(false);}
                }

            }else{
                if (!state.durationMonths) {setDurationMonthsErr(true);} else {setDurationMonthsErr(false);}
            }


        } else {
            let obj = {
                created: new Date(),
                duration: state.duration,
                gradeDurationMonths: state.gradeDurationMonths,
                durationHours: state.durationHours,
                durationMonths: state.durationMonths,
                documentDate: state.documentDate,
                documentNumber: state.documentNumber,
                educationLevel: {id: state.educationLevel.id},
                endDate: state.endDate,
                name: state.studyProgramName,
                startDate: state.startDate,
                organizerOccupation: {
                    id: state.occupation.id,
                },
                supplementaryEducationType: {id: state.supplementaryEducationType.id},
                comment: state.comment
            };
            axios.post('/study-program/save', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste dodali nastavni program.');
                    (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") ? getStudyProgramsForAdmin(state.organizer) : getStudyPrograms();
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function editStudyProgram() {
        if (!state.studyProgramName || !state.documentNumber || !state.documentDate || !state.supplementaryEducationType
            || !state.occupation || !state.educationLevel || !state.startDate  || !state.durationHours || (state.supplementaryEducationType && state.supplementaryEducationType.formal && state.supplementaryEducationType.key !== 'primary education' && !state.gradeDurationMonths) ||   (state.supplementaryEducationType && state.supplementaryEducationType.formal && !state.duration) || (state.supplementaryEducationType && !state.supplementaryEducationType.formal && !state.durationMonths)) {
            toast('Niste unijeli sve podatke.');
            if (!state.studyProgramName) {setStudyProgramNameErr(true);} else {setStudyProgramNameErr(false);}
            if (!state.documentNumber) {setDocumentNumberErr(true);} else {setDocumentNumberErr(false);}
            if (!state.documentDate) {setDocumentDateErr(true);} else {setDocumentDateErr(false);}
            if (!state.occupation) {setOccupationErr(true);} else {setOccupationErr(false);}
            if (!state.educationLevel) {setEducationLevelErr(true);} else {setEducationLevelErr(false);}
            if (!state.startDate) {setStartDateErr(true);} else {setStartDateErr(false);}
            // if (!state.endDate) {setEndDateErr(true);} else {setEndDateErr(false);}
            if (!state.durationHours) {setDurationHoursErr(true);} else {setDurationHoursErr(false);}
            if (!state.supplementaryEducationType) {setsupplementaryEducationTypeErr(true);} else {setsupplementaryEducationTypeErr(false);}

            if(state.supplementaryEducationType.formal){
                if(state.supplementaryEducationType.key !== 'primary education'){
                    if (!state.duration) {setDurationErr(true);} else {setDurationErr(false);}
                    if (!state.gradeDurationMonths) {setGradeDurationMonthsErr(true);} else {setGradeDurationMonthsErr(false);}
                }else{
                    if (!state.duration) {setDurationErr(true);} else {setDurationErr(false);}
                }

            }else{
                if (!state.durationMonths) {setDurationMonthsErr(true);} else {setDurationMonthsErr(false);}
            }

        } else {
            let obj = {
                id: studyProgram.id,
                optLock: studyProgram.optLock,
                duration: state.duration,
                gradeDurationMonths: state.gradeDurationMonths,
                durationHours: state.durationHours,
                durationMonths: state.durationMonths,
                documentDate: state.documentDate,
                documentNumber: state.documentNumber,
                educationLevel: {id: state.educationLevel.id},
                endDate: state.endDate,
                name: state.studyProgramName,
                startDate: state.startDate,
                organizerOccupation: {
                    id: state.occupation.id,
                },
                supplementaryEducationType: {id: state.supplementaryEducationType.id},
                comment: state.comment
            };
            axios.put('/study-program/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste izmijenili nastavni program.');
                    if(!dontRefreshList){
                        (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") ? getStudyProgramsForAdmin(state.organizer) : getStudyPrograms();
                    }else{
                        getStudyProgram(studyProgram.id)
                    }

                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function handleInputChange(event, { name, value }) {
        if(name === 'organizer' && value){
            getOrganizerOccupationForAdmin(value);
        }
        if(name === 'occupation' && value){
            dispatch({type: 'profession', payload: value.occupation.profession});
        }

        dispatch({type: name, payload: value});
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {add?'Dodavanje nastavnog programa':'Izmjena podataka'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>

                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths='equal'>
                            <Form.Field
                                required
                                error={studyProgramNameErr}
                                label="Naziv nastavnog programa"
                                control={Input}
                                name="studyProgramName"
                                value={state.studyProgramName}
                                onChange={handleInputChange}
                                placeholder="Unesite naziv"
                            />
                            <Form.Field
                                label="Napomena"
                                control={Input}
                                name="comment"
                                value={state.comment}
                                onChange={handleInputChange}
                                placeholder="Unesite napomenu"
                            />
                            <Form.Field
                                required
                                error={documentNumberErr}
                                label="Broj rješenja"
                                control={Input}
                                name="documentNumber"
                                value={state.documentNumber}
                                onChange={handleInputChange}
                                placeholder="Unesite broj"
                            />
                            <Form.Field
                                required
                                error={documentDateErr}
                                control={DateTimePicker}
                                label="Datum rješenja"
                                format="D. M. YYYY."
                                name="documentDate"
                                value={state.documentDate}
                                time={false}
                                onChange={(e) => handleDateChange('documentDate', e)}
                                style={{ borderWidth: '0px' }}
                                placeholder="Unesite datum"
                            />
                            {activeRole !== "ROLE_ORGANIZER" &&<Form.Field required>
                                <label>Organizator</label>
                                <Form.Dropdown
                                    // error={organizerErr}
                                    disabled={(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && !add}
                                    placeholder="Odaberite organizatora"
                                    value={state.organizer}
                                    onChange={handleInputChange}
                                    name="organizer"
                                    options={state.organizerOptions}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>}

                        </Form.Group>
                        <Form.Group widths={'equal'}>

                            <Form.Field required>
                                <label>Vrsta obrazovanja</label>
                                <Form.Dropdown
                                    required
                                    error={supplementaryEducationTypeErr}
                                    clearable
                                    placeholder="Odaberite vrstu"
                                    value={state.supplementaryEducationType}
                                    onChange={handleInputChange}
                                    name="supplementaryEducationType"
                                    options={state.supplementaryEducationTypeOptions}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>
                            <Form.Field
                                required
                                error={durationHoursErr}
                                type="number"
                                label="Ukupan broj nastavnih sati"
                                control={Input}
                                name="durationHours"
                                value={state.durationHours}
                                onChange={handleInputChange}
                                placeholder="Unesite trajanje programa"
                            />
                            {state.supplementaryEducationType && !state.supplementaryEducationType.formal ? <Form.Field
                                required
                                error={durationMonthsErr}
                                type="number"
                                label="Minimalni broj mjeseci za realizaciju programa"
                                control={Input}
                                name="durationMonths"
                                value={state.durationMonths}
                                onChange={handleInputChange}
                                placeholder="Unesite trajanje programa"
                            />:
                                <Form.Field
                                    required
                                    error={durationErr}
                                    type="number"
                                    label="Broj razreda (trajanje nastavnog programa)"
                                    control={Input}
                                    name="duration"
                                    disabled={!state.supplementaryEducationType.formal}
                                    value={state.duration}
                                    onChange={handleInputChange}
                                    placeholder="Unesite trajanje programa"
                                />
                            }


                            <Form.Field
                                required={state.supplementaryEducationType.key !== 'primary education'}
                                error={state.supplementaryEducationType.key !== 'primary education' ? gradeDurationMonthsErr : false}
                                type="number"
                                label="Trajanje jednog razreda u mjesecima"
                                control={Input}
                                name="gradeDurationMonths"
                                disabled={!state.supplementaryEducationType.formal}
                                value={state.gradeDurationMonths}
                                onChange={handleInputChange}
                                placeholder="Unesite broj mjeseci"
                            />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field required>
                                <label>Zanimanje</label>
                                <Form.Dropdown
                                    // disabled={!state.profession}
                                    error={occupationErr}
                                    clearable
                                    placeholder="Odaberite zanimanje"
                                    value={state.occupation}
                                    onChange={handleInputChange}
                                    name="occupation"
                                    options={state.organizerOccupationOptions}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>Stečeni stepen stručne spreme</label>
                                <Form.Dropdown
                                    clearable
                                    error={educationLevelErr}
                                    placeholder="Odaberite stepen"
                                    value={state.educationLevel}
                                    onChange={handleInputChange}
                                    name="educationLevel"
                                    options={state.educationLevelOptions}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>
                            <Form.Field
                                required
                                error={startDateErr}
                                label="Datum početka izvođenja programa"
                                control={DateTimePicker}
                                name="startDate"
                                time={false}
                                format="D. M. YYYY."
                                value={state.startDate}
                                onChange={(e) => handleDateChange('startDate', e)}
                                placeholder="Unesite datum"
                                style={{ borderWidth: '0px' }}
                            />
                            <Form.Field
                                error={endDateErr}
                                label="Datum kraja izvođenja programa"
                                control={DateTimePicker}
                                name="endDate"
                                time={false}
                                format="D. M. YYYY."
                                value={state.endDate}
                                onChange={(e) => handleDateChange('endDate', e)}
                                placeholder="Unesite datum"
                                style={{ borderWidth: '0px' }}
                            />
                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addStudyProgram : editStudyProgram}>{add ? 'Kreiraj' : 'Sačuvaj'}</Button>

                    </Form>
                </Segment>
            </SegmentGroup>
        </div>
    );
};

export default StudyProgramForm;
