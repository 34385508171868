import React, {useContext, useEffect, useReducer} from 'react';
import {Form, Header, Icon, SegmentGroup, Select} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import axios from "axios";
import {toast} from "react-toastify";
import {UserRoleContext} from "../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'professions':
            return {...state, professions: action.payload};
        case 'profession':
            return {...state, profession: action.payload, enableOccupation: !!action.payload};
        case 'occupation':
            return {...state, occupation: action.payload};
        case 'occupations':
            return {...state, occupations: action.payload};
        default:
            return state;

    }
};

const OccupationForm = ({organizerId, add, toggleEditForm, getOccupationForOrganizer}) => {
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        occupations: [],
        occupation: null,
        professions:[],
        profession: null,
        enableOccupation: false
    });

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'profession' && value){
            getOccupationsByProfessionId(value.id);
        }
    }

    useEffect(() => {
        getProfessions();
    },[]);

    function getProfessions(){

        axios.get('/profession/all',).then(
            (res) => {
                let professions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type: 'professions', payload: professions});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOccupationsByProfessionId(id){
        axios.get(`/occupation/by-profession-id/${id}`,).then(
            (res) => {
                let occupations = res.data.map((el)=>({key: el.id, text: el.nameBosnian, value: el}));
                dispatch({type: 'occupations', payload: occupations});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function addOccupationForAdmin(){
        let obj = {
            organizer: {id: organizerId},
            occupation: {id: state.occupation.id}
        };
        axios.post('/organizer-occupation/save/for-admin',{...obj}).then(
            (res) => {
                toggleEditForm();
                getOccupationForOrganizer();
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function addOccupation(){
        let obj = {
            organizer: null,
            occupation: {id: state.occupation.id}
        };
        axios.post('/organizer-occupation/save',{...obj}).then(
            (res) => {
                toggleEditForm();
                getOccupationForOrganizer();
            }).catch((err) => {
            console.log('err', err)
        });
    }

    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {add?'Dodavanje novog zanimanja':'Izmjena podataka'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            {/*placeholder*/}
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths={'equal'}>
                                <Form.Field
                                    required
                                    control={Select}
                                    clearable
                                    options={state.professions}
                                    placeholder="Struka"
                                    label="Struka"
                                    onChange={handleInputChange}
                                    name="profession"
                                    selectOnNavigation={false}
                                    openOnFocus={false}
                                    value={state.profession}
                                    search

                                />

                                <Form.Field
                                    disabled={!state.enableOccupation}
                                    required
                                    control={Select}
                                    clearable
                                    options={state.occupations}
                                    placeholder="Zanimanje"
                                    label="Zanimanje"
                                    onChange={handleInputChange}
                                    name="occupation"
                                    selectOnNavigation={false}
                                    openOnFocus={false}
                                    value={state.occupation}
                                    search

                                />
                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={activeRole === 'ROLE_ORGANIZER' ? addOccupation : addOccupationForAdmin}>{add ? 'Dodaj' : 'Sačuvaj'}</Button>
                    </Form>
                </Segment>
            </SegmentGroup>


        </div>
    );
};

export default OccupationForm;
