import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Form, Header, Icon, Input, SegmentGroup, Select} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {UserRoleContext} from "../../../Main";
import axios from "axios";
import {toast} from "react-toastify";

const registryBookDropdown = [
    {
        key: 1,
        value: 1,
        text: 'formalno',
    },
    {
        key: 2,
        value: 2,
        text: 'neformalno'
    }
];

const reducer = (state, action)=> {
    switch (action.type) {
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'registryBookName':
            return {...state, registryBookName: action.payload};
        case 'type':
            return {...state, type: action.payload};
        case 'comment':
            return {...state, comment: action.payload};
        case 'maxNumberOfFields':
            return {...state, maxNumberOfFields: action.payload};
        default:
            return state;

    }
}

const RegistryBookForm = ({ add, toggleEditForm, getRegistryBook, registryBook, getRegistryBooksForAdmin, setCurrentIdOrganizer}) => {
    const [registryBookNameErr, setRegistryBookNameErr] = useState(false);
    const [typeErr, setTypeErr] = useState(false);
    const [organizerErr, setOrganizerErr] = useState(false);
    const [maxNumberOfFieldsErr, setMaxNumberOfFieldsErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        organizers: [],
        organizer: registryBook && registryBook.organizer.id ? registryBook.organizer.id : '',
        registryBookName: registryBook && registryBook.name ? registryBook.name : '',
        type: (registryBook && registryBook.formal === true ) ? 1 : (registryBook && registryBook.formal === false) ? 2 : null,
        comment: registryBook && registryBook.comment ? registryBook.comment : '',
        maxNumberOfFields: registryBook && registryBook.maxNumberOfFields ? registryBook.maxNumberOfFields : null

    });

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function getOrganizers(){
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    useEffect(() => {
        // if (!add && activeRole === "ROLE_ADMIN") { getOrganizers(); getStudyProgramsByOrganizerId(state.organizer);}
        // if(activeRole === "ROLE_ORGANIZER") {getStudyPrograms()}
        if(activeRole !== "ROLE_ORGANIZER") getOrganizers()
    },[add, activeRole]);

    function editRegistryBook(){
        if (!state.registryBookName || !state.type || !state.maxNumberOfFields) {
            toast('Niste unijeli sve podatke.');
            if (!state.registryBookName) { setRegistryBookNameErr(true); } else { setRegistryBookNameErr(false); }
            if (!state.type) { setTypeErr(true); } else { setTypeErr(false); }
            if (!state.maxNumberOfFields) { setMaxNumberOfFieldsErr(true); } else { setMaxNumberOfFieldsErr(false); }
        } else {
            let obj = {
                id: registryBook.id,
                optLock: registryBook.optLock,
                name: state.registryBookName,
                maxNumberOfFields: state.maxNumberOfFields,
                formal: state.type === 1 ? true : false,
                comment: state.comment,
                organizer: activeRole === "ROLE_ADMIN" ? {id: state.organizer} : null,
            };
            axios.put('/registry-book/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste izmijenili matičnu knjigu.');
                    if (activeRole === "ROLE_ADMIN") {getRegistryBooksForAdmin(state.organizer); setCurrentIdOrganizer(state.organizer)}
                    else {getRegistryBook();}
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function addRegistryBook(){
        if (!state.registryBookName || !state.type || !state.maxNumberOfFields || (activeRole === "ROLE_ADMIN" && !state.organizer)) {
            toast('Niste unijeli sve podatke.');
            if (activeRole === "ROLE_ADMIN" && !state.organizer) { setOrganizerErr(true); } else { setOrganizerErr(false); }
            if (!state.registryBookName) { setRegistryBookNameErr(true); } else { setRegistryBookNameErr(false); }
            if (!state.type) { setTypeErr(true); } else { setTypeErr(false); }
            if (!state.maxNumberOfFields) { setMaxNumberOfFieldsErr(true); } else { setMaxNumberOfFieldsErr(false); }
        } else {
            if (activeRole === "ROLE_ORGANIZER") {
                let obj = {
                    createdDate: new Date(),
                    name: state.registryBookName,
                    maxNumberOfFields: state.maxNumberOfFields,
                    formal: state.type === 1 ? true : false,
                    comment: state.comment
                };
                axios.post('/registry-book/save/for-organizer', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste dodali matičnu knjigu.');
                        getRegistryBook();
                    }).catch((err) => {
                    console.log('err', err)
                });
            }
            else{
                let obj = {
                    createdDate: new Date(),
                    name: state.registryBookName,
                    maxNumberOfFields: state.maxNumberOfFields,
                    formal: state.type === 1 ? true : false,
                    comment: state.comment,
                    organizer: {id: state.organizer},
                };
                axios.post('/registry-book/save', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste dodali matičnu knjigu.');
                        getRegistryBooksForAdmin(state.organizer);
                        setCurrentIdOrganizer(state.organizer);
                    }).catch((err) => {
                    console.log('err', err)
                });
            }
        }
    }

    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {add?'Dodavanje nove matične knjige':'Izmjena podataka'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths={'equal'}>
                            {activeRole === "ROLE_ADMIN" &&<Form.Field required>
                                <label>Organizator</label>
                                <Form.Dropdown
                                    required
                                    clearable
                                    disabled={activeRole === "ROLE_ADMIN" && !add}
                                    placeholder="Odaberite organizatora"
                                    value={state.organizer}
                                    onChange={handleInputChange}
                                    name="organizer"
                                    options={state.organizers}
                                    error={organizerErr}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>}

                            <Form.Field
                                required
                                label="Naziv matične knjige"
                                control={Input}
                                name="registryBookName"
                                value={state.registryBookName}
                                onChange={handleInputChange}
                                placeholder="Unesite naziv matične knjige"
                                error={registryBookNameErr}
                            />

                            <Form.Field
                                required
                                control={Select}
                                clearable
                                options={registryBookDropdown}
                                placeholder="Odaberite opciju"
                                label="Tip obrazovanja"
                                onChange={handleInputChange}
                                name="type"
                                value={state.type}
                                search
                                fluid
                                error={typeErr}

                            />

                            <Form.Field
                                required
                                label="Broj mjesta u matičnoj knjizi"
                                control={Input}
                                name="maxNumberOfFields"
                                type="number"
                                value={state.maxNumberOfFields}
                                onChange={handleInputChange}
                                placeholder="Unesite broj"
                                error={maxNumberOfFieldsErr}
                            />

                            <Form.Field
                                label="Napomena"
                                control={Input}
                                name="comment"
                                value={state.comment}
                                onChange={handleInputChange}
                                placeholder="Unesite napomenu"
                            />

                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ?addRegistryBook:editRegistryBook}>{add ? 'Kreiraj' : 'Sačuvaj'}</Button>
                    </Form>
                </Segment>
            </SegmentGroup>


        </div>
    );
};

export default RegistryBookForm;
