import React from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";

const PlaceInfo = ({residence, placeOfBirth}) => {

    return (
        placeOfBirth ?
                <Table celled size={"small"}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell> <Table.HeaderCell>Država</Table.HeaderCell></Table.Cell>
                            <Table.Cell>{placeOfBirth?placeOfBirth.country.name:''}</Table.Cell>
                        </Table.Row>
                        {placeOfBirth && placeOfBirth.municipality && placeOfBirth.municipality.entity && <Table.Row>
                            <Table.Cell> <Table.HeaderCell>Entitet</Table.HeaderCell></Table.Cell>
                            <Table.Cell>{placeOfBirth.municipality.entity.name ? placeOfBirth.municipality.entity.name:''}</Table.Cell>
                        </Table.Row>}
                        {placeOfBirth && placeOfBirth.municipality && placeOfBirth.municipality.canton && <Table.Row>
                            <Table.Cell> <Table.HeaderCell>Kanton</Table.HeaderCell></Table.Cell>
                            <Table.Cell>{placeOfBirth?placeOfBirth.municipality.canton.name:''}</Table.Cell>
                        </Table.Row>}
                        {placeOfBirth && placeOfBirth.municipality && <Table.Row>
                            <Table.Cell> <Table.HeaderCell>Općina</Table.HeaderCell></Table.Cell>
                            <Table.Cell>{placeOfBirth?placeOfBirth.municipality.name:''}</Table.Cell>
                        </Table.Row>}
                        <Table.Row>
                            <Table.Cell> <Table.HeaderCell>Mjesto</Table.HeaderCell></Table.Cell>
                            <Table.Cell>{placeOfBirth?placeOfBirth.name:''}</Table.Cell>
                        </Table.Row>
                        {/*<Table.Row>*/}
                        {/*    <Table.Cell> <Table.HeaderCell>Adresa</Table.HeaderCell></Table.Cell>*/}
                        {/*    <Table.Cell>{residence?residence.address:''}</Table.Cell>*/}
                        {/*</Table.Row>*/}
                    </Table.Body>
                </Table>
                :<Table celled size={"small"}>
            <Table.Body>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Država</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{residence?residence.place.country.name:''}</Table.Cell>
                </Table.Row>
                {residence && residence.place.municipality && residence.place.municipality.entity && <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Entitet</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{residence?residence.place.municipality.entity.name:''}</Table.Cell>
                </Table.Row>}
                {residence && residence.place.municipality && residence.place.municipality.canton && <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Kanton</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{residence?residence.place.municipality.canton.name:''}</Table.Cell>
                </Table.Row>}
                {residence && residence.place.municipality && <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Općina</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{residence?residence.place.municipality.name:''}</Table.Cell>
                </Table.Row>}
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Mjesto</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{residence?residence.place.name:''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Adresa</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{residence?residence.address:''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Poštanski broj</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{residence?residence.zipCode:''}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

export default PlaceInfo;
