import React, {useContext, useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Button, Form, Header, Icon, Loader, Menu} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import axios from "axios";
import RequestForm from "./RequestForm";
import RequestEditAndDetails from "./RequestEditAndDetails";
import moment from "moment";
import {UserRoleContext} from "../../Main";
import {toast, ToastContainer} from "react-toastify";
import SearchTableData from "../Utilities/SearchTableData";
import PaginationFront from "../Utilities/PaginationFront";
import ReactExport from 'react-data-export';

const reducer = (state, action)=>{
    switch (action.type) {
        case 'requests':
            return {...state, requests: action.payload, loader:  false };
        case 'request':
            return {...state, request: action.payload, loader:  false };
        case 'requestsData':
            return {...state, requestsData: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'currentIdOrganizer':
            return {...state, currentIdOrganizer: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;
    }
};
const EditRequest = () => {
    const {activeRole} = useContext(UserRoleContext);
    const [activePage, setActivePage] = useState(1);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [state, dispatch] = useReducer(reducer,{
        requests:[],
        requestsData: [],
        loader: true,
        organizer: '',
        organizers: [],
        currentId: null,
        currentIdOrganizer: null,
        data:[],
        results:[],
        postsPerPage: 20,
        currentPosts: [],
    } );
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditAndDetailsForm, setShowEditAndDetailsForm] = useState(false);

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    const multiDataSet = [
        {
            columns: [
                { title: 'Organizator', width: { wpx: 400 } },
                { title: 'Datum zahtjeva', width: { wpx: 150 } },
                { title: 'Vrsta', width: { wpx: 200 } },
                { title: 'Sadržaj', width: { wpx: 400 } },
                { title: 'Datum obrade zahtjeva', width: { wpx: 300 } },
                { title: 'Odgovor', width: { wpx: 300 } },
                { title: 'Status', width: { wpx: 300 } },
            ],
            data: state.requestsData,
        }
    ];

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    let toggleEditAndDetailsForm = () =>{

        setShowEditAndDetailsForm(!showEditAndDetailsForm)
    };

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    useEffect(() => {
        if (activeRole === "ROLE_ORGANIZER") {getRequests();}
        else if (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY")  {getOrganizers(); getRequestsAll()}
    },[activeRole]);

    function getRequests(){
        dispatch({type: 'loader', payload: true});
        axios.get('/edit-request/by-organizer',).then(
            (res) => {
                let requests = res.data.map(el => ({
                    ...el,
                    editRequestTypeName:el.editRequestType.name,
                    dateCreatedFilter: moment(el.created).format('D. M. YYYY.'),
                    dateDoneFilter: moment(el.doneDate).format('D. M. YYYY.')
                }))
                // let requests = res.data;
                dispatch({type: 'requests', payload: requests});
                dispatch({type: 'data', payload: requests});
                let requestsData = res.data.map(el => ([
                    { value: el.organizer.name},
                    { value: moment(el.created).format('D. M. YYYY.')},
                    { value: el.editRequestType.name},
                    { value: el.request},
                    { value: el.doneDate ? moment(el.doneDate).format('D. M. YYYY.') : '' },
                    { value: el.response ? el.response : ''},
                    { value: (el.doneDate || el.response || el.done) ? 'obrađen' : 'nije obrađen'},
                ]));
                dispatch({type: 'requestsData', payload: requestsData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getRequestsAll(){
        dispatch({type: 'loader', payload: true});
        axios.get('/edit-request/all',).then(
            (res) => {
                let requests = res.data.map(el => ({
                    ...el,
                    editRequestTypeName:el.editRequestType.name,
                    dateCreatedFilter: moment(el.created).format('D. M. YYYY.'),
                    dateDoneFilter: moment(el.doneDate).format('D. M. YYYY.')
                }))
                // let requests = res.data;
                dispatch({type: 'requests', payload: requests});
                dispatch({type: 'data', payload: requests});
                let requestsData = res.data.map(el => ([
                    { value: el.organizer.name},
                    { value: moment(el.created).format('D. M. YYYY.')},
                    { value: el.editRequestType.name},
                    { value: el.request},
                    { value: el.doneDate ? moment(el.doneDate).format('D. M. YYYY.') : '' },
                    { value: el.response ? el.response : ''},
                    { value: (el.doneDate || el.response || el.done) ? 'obrađen' : 'nije obrađen'},
                ]));
                dispatch({type: 'requestsData', payload: requestsData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getRequestsForAdmin(id){
        setCurrentId(id);
        setCurrentIdOrganizer(id);
        dispatch({type: 'loader', payload: true});
        axios.get(`/edit-request/by-organizer-id/${id}`,).then(
            (res) => {
                let requests = res.data.map(el => ({
                    ...el,
                    editRequestTypeName:el.editRequestType.name,
                    dateCreatedFilter: moment(el.created).format('D. M. YYYY.'),
                    dateDoneFilter: moment(el.doneDate).format('D. M. YYYY.')
                }))
                // let requests = res.data;
                dispatch({type: 'requests', payload: requests});
                dispatch({type: 'data', payload: requests});
                let requestsData = res.data.map(el => ([
                    { value: el.organizer.name},
                    { value: moment(el.created).format('D. M. YYYY.')},
                    { value: el.editRequestType.name},
                    { value: el.request},
                    { value: el.doneDate ? moment(el.doneDate).format('D. M. YYYY.') : '' },
                    { value: el.response ? el.response : ''},
                    { value: (el.doneDate || el.response || el.done) ? 'obrađen' : 'nije obrađen'},
                ]));
                dispatch({type: 'requestsData', payload: requestsData});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function deleteRequest(id){
        setCurrentId(id);
        axios.delete(`/edit-request/delete/${id}`,).then(
            (res) => {
                getRequests();
                toast('Uspješno ste izbrisali zahtjev!');
                setCurrentId(null);
            }).catch((err) => {
                toast('Došlo je do greške! Brisanje zahtjeva nije uspjelo.')
                setCurrentId(null);
        });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            getRequestsForAdmin(value)
        }
        else {
            getRequestsAll()
        }
    }

    function getRequestById(id) {

            axios.get(`/edit-request/${id}`,).then(
                (res) => {
                    let request = res.data;
                    dispatch({type: 'request', payload: request});
                    toggleEditAndDetailsForm();
                }).catch((err) => {
                console.log('err', err)
            });

    }

    return (
        <Segment.Group>
            <Segment className={'customPadding'} clearing color={"teal"}>

                <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                    <Menu.Item >
                        <Header as={'h3'} floated={"left"}> Zahtjevi za promjenu podataka </Header>
                    </Menu.Item>
                    <Menu.Menu position={'right'}>
                        <Menu.Item>

                                {activeRole === 'ROLE_ORGANIZER' && <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Kreiraj zahtjev
                                </Button>}

                        </Menu.Item>
                        {((activeRole === "ROLE_ORGANIZER") || ((activeRole === "ROLE_MINISTRY" || activeRole === "ROLE_ADMIN"))) && <Menu.Item>
                            <ExcelFile filename="Spisak zahtjeva za promjenu podataka" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                <Icon name='file excel outline' color={"green"}/>
                                Preuzmi spisak
                            </Button>}>
                                <ExcelSheet dataSet={multiDataSet} name="Zahtjevi za promjenu podataka" />
                            </ExcelFile>
                        </Menu.Item>}
                        <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                        </Menu.Item>
                    </Menu.Menu>

                </Menu>

            </Segment>
            {showAddForm &&
                <Segment>
                    {/*send add or edit as props*/}
                    <RequestForm add toggleEditForm={toggleAddForm} getRequests={getRequests}/>
                </Segment>
            }
            {showEditAndDetailsForm &&
                <Segment>
                    {/*send add or edit as props*/}
                    <RequestEditAndDetails toggleEditForm={toggleEditAndDetailsForm} getRequests={getRequestsAll} request={state.request}/>
                </Segment>
            }

            {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Segment><Form size={'small'}>
                <Form.Group widths="3">
                    <Form.Field>
                        <label>Organizator</label>
                        <Form.Dropdown
                            placeholder="Odaberite organizatora"
                            value={state.organizer}
                            onChange={handleInputChange}
                            name="organizer"
                            options={state.organizers}
                            search
                            clearable
                            fluid
                            selection
                        />
                    </Form.Field>
                </Form.Group>
            </Form></Segment>}
            <Segment clearing>
                {state.loader ?
                    <div style={{paddingTop: '100px', paddingBottom: '100px'}}><Loader active inline="centered"/></div>
                    :
                    currentPosts.length !== 0 ? <Table celled size={'small'}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={9} >
                                        <div style={{ paddingLeft: '0px' }}>
                                            <SearchTableData data={state.data} getResults={getResults} filterItem={['editRequestTypeName', 'request', 'response', 'dateCreatedFilter', 'dateDoneFilter']} />
                                        </div>
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>

                                    <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                                    <Table.HeaderCell>Organizator</Table.HeaderCell>
                                    <Table.HeaderCell>Datum zahtjeva</Table.HeaderCell>
                                    <Table.HeaderCell>Vrsta</Table.HeaderCell>
                                    <Table.HeaderCell width={4}>Sadržaj</Table.HeaderCell>
                                    <Table.HeaderCell>Datum obrade zahtjeva</Table.HeaderCell>
                                    <Table.HeaderCell width={4}>Odgovor</Table.HeaderCell>
                                    {/*<Table.HeaderCell>Organizator</Table.HeaderCell>*/}
                                    <Table.HeaderCell/>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    // getDataToRender().map((el, index) =>
                                        currentPosts.map((el, index) =>
                                    // state.requests.map((el, index) =>
                                        (<Table.Row key={el.id} positive={el.done || el.doneDate || el.response}>
                                            <Table.Cell width={1}>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                            <Table.Cell>{el.organizer.name}</Table.Cell>
                                            <Table.Cell>{moment(el.created).format('D. M. YYYY.')}</Table.Cell>
                                            <Table.Cell>{el.editRequestType.name}</Table.Cell>
                                            <Table.Cell width={4}>{el.request}</Table.Cell>
                                            <Table.Cell>{el.doneDate ? moment(el.doneDate).format('D. M. YYYY.') : ''}</Table.Cell>
                                            <Table.Cell width={4}>{el.response}</Table.Cell>
                                            <Table.Cell width={1} verticalAlign="middle" textAlign="center">
                                                {activeRole === 'ROLE_ORGANIZER' ?
                                                    (el.doneDate || el.response || el.done) ?
                                                        <Popup size={"small"} content='Obriši zahtjev' trigger={

                                                            <Icon name='check' color={"green"}/>
                                                        }
                                                        /> : <Popup size={"small"} content='Obriši zahtjev' trigger={
                                                            <Button icon size={"mini"} basic color={"grey"}
                                                                    disabled={state.currentId && el.id !== state.currentId}
                                                                    onClick={() => deleteRequest(el.id)}>
                                                                <Icon name='trash alternate outline' color={"red"}/>
                                                            </Button>}
                                                        /> :
                                                    <Popup size={"small"} content={'Otvori zahtjev'}
                                                           trigger={<Button icon size={"mini"} basic color={"grey"}
                                                                            onClick={() => {
                                                                                getRequestById(el.id);
                                                                            }}>
                                                               <Icon name='inbox' color={'teal'}/>
                                                           </Button>}/>
                                                }

                                            </Table.Cell>
                                        </Table.Row>)
                                    )
                                }
                            </Table.Body>
                        </Table> : <p>Nema kreiranih zahtjeva.</p>
                }
                {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
            </Segment>
            <ToastContainer/>
        </Segment.Group>
    );
};

export default EditRequest;
