import React, {createContext, useEffect, useState} from 'react';
import App from "./App";
import axios from "axios";
import UserService from "./service/UserService";

export const UserRoleContext = createContext('Default');

const Main = () => {
    const [userRoles, setUserRoles] = useState([]);
    const [activeRole, setActiveRole] = useState([]);
    const [activeRoleId, setActiveRoleId] = useState([]);
    const [activeRoleObj, setActiveRoleObj] = useState([]);
    let username = UserService.getUsername();

    function getRolesByUsername(username){

        axios.get(`/user-role/by-username/${username}/true`,).then(
            (res)=>{
                 setUserRoles(res.data);
            }).catch((err)=>{
                setUserRoles('palo');
                console.log('err', err)
            });
    }

    let getActiveRole = ()=>{
        axios.get('/user-role/current').then(
            (res)=>{
                setActiveRoleObj(res.data);
                setActiveRole(res.data.role.key);
                setActiveRoleId(res.data.id);
        });

    };

    useEffect(() => {
        getRolesByUsername(username);
        getActiveRole();
    },[username]);

    return (
            <UserRoleContext.Provider value = {{userRoles, setUserRoles, activeRole, getActiveRole, activeRoleId, activeRoleObj}}>
                    <App />
            </UserRoleContext.Provider>
    );
};

export default Main;
