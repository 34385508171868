import React, {useReducer} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Form, Header, Icon, Input, SegmentGroup} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {DateTimePicker} from "react-widgets";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'requestType':
            return {...state, requestType: action.payload};
        case 'requestDate':
            return {...state, requestDate: action.payload};
        case 'documentDate':
            return {...state, documentDate: action.payload};
        case 'done':
            return {...state, done: action.payload};
        case 'request':
            return {...state, request: action.payload};
        case 'response':
            return {...state, response: action.payload};
        case 'paidPrice':
            return {...state, paidPrice: action.payload};
        default:
            return state;

    }
};
const AndragogyDocumentationForm = ({add, edit, toggleEditForm}) => {
    const [state, dispatch] = useReducer(reducer,{
        requestType: null,
        requestDate: new Date(),
        done: null,
        request: '',
        response: '',
        paidPrice: null,
        documentDate: add? null: new Date()
    });
    function addRequest(){}
    function editRequest(){}
    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    return (
        <SegmentGroup>
            <Segment>
                <Header
                    as={'h4'}> {add ? 'Kreiranje zaduženja' : 'Izmjena podataka'}
                    <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                </Header>
            </Segment>
            <Segment clearing>
                <Form size={'small'} >
                    <Form.Group widths={'equal'} >
                        <Form.Field>
                            <label>Organizator</label>
                            <Form.Dropdown
                                placeholder="Odaberite organizatora"
                                value={state.organizer}
                                onChange={handleInputChange}
                                name="organizer"
                                options={state.organizers}
                                search
                                fluid
                                selection
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label>Godina</label>
                            <Form.Dropdown
                                clearable
                                placeholder="Odaberite vrstu"
                                //value={language}
                                onChange={handleInputChange}
                                name="requestType"
                                //options={languages.filter((el)=>(el.key === 'bs' || el.key === 'sr' || el.key === 'hr'))}
                                search
                                fluid
                                selection
                            />
                        </Form.Field>
                        <Form.Field
                            required
                            disabled
                            //error={documentDateErr}
                            control={DateTimePicker}
                            label="Datum zaduženja"
                            format="D. M. YYYY."
                            placeholder="Unesite datum"
                            name="requestDate"
                            value={state.requestDate}
                            time={false}
                            onChange={(e) => handleDateChange('requestDate', e)}
                            style={{ borderWidth: '0px' }}
                            //error={openFromDateErr}
                        />





                    </Form.Group>
                    <Form.Group widths={'equal'} >
                        <Form.Field required>
                            <label>Broj</label>
                            <Form.Dropdown
                                clearable
                                placeholder="Odaberite opciju"
                                value={state.done}
                                onChange={handleInputChange}
                                name="done"
                                //options={languages.filter((el)=>(el.key === 'bs' || el.key === 'sr' || el.key === 'hr'))}
                                search
                                fluid
                                selection
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Field required>
                                <label>Broj</label>
                                <Form.Dropdown
                                    clearable
                                    placeholder="Odaberite opciju"
                                    value={state.done}
                                    onChange={handleInputChange}
                                    name="done"
                                    //options={languages.filter((el)=>(el.key === 'bs' || el.key === 'sr' || el.key === 'hr'))}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>
                        </Form.Field>
                        <Form.Field
                            required
                            label="Uplaćeno"
                            control={Input}
                            name="JIB"
                            type="number"
                            value={state.paidPrice}
                            onChange={handleInputChange}
                            placeholder="Unesite broj"
                        />
                    </Form.Group>

                    <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addRequest : editRequest}>{add ? 'Pošalji' : 'Pošalji'}</Button>
                </Form>
            </Segment>

        </SegmentGroup>
    );
};

export default AndragogyDocumentationForm;
