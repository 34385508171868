import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Form, Header, Icon, Input, SegmentGroup} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import axios from "axios";
import { toast } from 'react-toastify';
import PlaceForm from "../Utilities/PlaceForm";
import {UserRoleContext} from "../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'employer':
            return {...state, employer: action.payload};
        case 'email':
            return {...state, email: action.payload};
        case 'phone':
            return {...state, phone: action.payload};
        case 'countries':
            return {...state, countries: action.payload};
        case 'entities':
            return {...state, entities: action.payload };
        case 'cantons':
            return {...state, cantons: action.payload };
        case 'municipalities':
            return {...state, municipalities: action.payload };
        case 'placeOfResidence':
            return {...state, placeOfResidence: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        default:
            return state;

    }
}

const EmployerForm = ({ add, edit, toggleEditForm, getEmployer, employer, municipalities, getEmployersForAdmin, setCurrentIdOrganizer}) => {
    const [employerErr, setEmployerErr] = useState(false);
    const [phoneErr, setPhoneErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [countryErr, setCountryErr] = useState(false);
    const [placeErr, setPlaceErr] = useState(false);
    const [addressErr, setAddressErr] = useState(false);
    const [zipErr, setZipErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        employer: employer && employer.name ? employer.name : '',
        email: employer && employer.email ? employer.email : '',
        phone: employer && employer.phone ? employer.phone : '',
        organizers: [],
        organizer: employer && employer.organizer.id ? employer.organizer.id : '',

        filteredMunicipalities: [],
        countries:[],
        entities: [],
        cantons: [],
        municipalities: [],
        //fields
        country: edit ? employer.residence.place.country : '',
        entity: edit &&  !!employer.residence.place.municipality && !!employer.residence.place.municipality.entity ? employer.residence.place.municipality.entity : '',
        canton: edit ?  !!employer.residence.place.municipality && !!employer.residence.place.municipality.canton && employer.residence.place.municipality.canton :'',
        municipality: edit && !!employer.residence.place.municipality ?  employer.residence.place.municipality : '',
        place: edit ?  !!employer.residence.place && employer.residence.place.name : '',
        address: edit ?  !!employer.residence && employer.residence.address : '',
        zip: edit ?  !!employer.residence && employer.residence.zipCode : '',
        // disable properties
        enableEntity: edit ? !!employer.residence.place.municipality && !!employer.residence.place.municipality.entity: false,
        enableCanton: edit ? !!employer.residence.place.municipality && !!employer.residence.place.municipality.canton : false,
        enableMunicipality: edit ? employer.residence.place.municipality !== null : false,
    });

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function handlePlaceChange(placeName, obj) {
        dispatch({type: placeName, payload: obj});
    }

    function getOrganizers(){
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    useEffect(() => {
        if (!add && activeRole === "ROLE_ADMIN") { getOrganizers(); getEmployersForAdmin(state.organizer);}
        if (activeRole === "ROLE_ADMIN") {getOrganizers()}
        else {getEmployer()}

        axios.get('/country/all',).then(
            (res)=>{
                let countriesOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'countries', payload: countriesOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/entity/all',).then(
            (res)=>{
                let entityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'entities', payload: entityOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/canton/all',).then(
            (res)=>{
                let cantonOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'cantons', payload: cantonOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/municipality/all',).then(
            (res)=>{
                let municipalityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'municipalities', payload: municipalityOptions});

            }).catch((err)=>{
            console.log('err', err)
        });

        if(edit){
            dispatch({type:'country', payload: employer.residence.place.country});
            if( !!employer.residence.place.municipality && !!employer.residence.place.municipality.entity ){
                dispatch({type:'entity', payload: employer.residence.place.municipality.entity});
                // dispatch({type:'municipalities', payload:  student.student.person.residence.place.municipality.entity.key !== 'fbih' ? municipalities.filter((el)=>(el.value.entity && el.value.entity.key  === student.student.person.residence.place.municipality.entity.key)) : []});
            }
            if( !!employer.residence.place.municipality && !!employer.residence.place.municipality.canton){
                dispatch({type:'canton', payload: employer.residence.place.municipality.canton});
                // dispatch({type:'municipalities', payload: municipalities.filter((el)=>(el.value.canton && el.value.canton.key  === student.student.person.residence.place.municipality.canton.key))});
                dispatch({type:'municipality', payload: employer.residence.place.municipality});
            }
        }

    },[activeRole]);


    function editEmployer() {

        if (!state.employer || !state.email || !state.phone || (state.placeOfResidence ? !state.placeOfResidence.country : !state.country)
            || (state.placeOfResidence ? !state.placeOfResidence.place : !state.place)
            || (state.placeOfResidence ? !state.placeOfResidence.address : !state.address)
            || (state.placeOfResidence ? !state.placeOfResidence.zip : !state.zip) ){

            toast('Niste unijeli sve podatke.');

            if (!state.employer) {setEmployerErr(true);} else {setEmployerErr(false);}
            if (!state.email) {setEmailErr(true);} else {setEmailErr(false);}
            if (!state.phone) {setPhoneErr(true);} else {setPhoneErr(false);}
            if (state.placeOfResidence ? !state.placeOfResidence.country : !state.country) {setCountryErr(true);} else {setCountryErr(false);}
            if (state.placeOfResidence ? !state.placeOfResidence.place : !state.place) {setPlaceErr(true);} else {setPlaceErr(false);}
            if (state.placeOfResidence ? !state.placeOfResidence.address : !state.address) {setAddressErr(true);} else {setAddressErr(false);}
            if (state.placeOfResidence ? !state.placeOfResidence.zip : !state.zip) {setZipErr(true);} else {setZipErr(false);}
        } else {

            let obj = {
                id: employer.id,
                optLock: employer.optLock,
                // created: new Date(),
                name: state.employer,
                organizer: activeRole === "ROLE_ADMIN" ? {id: state.organizer} : null,
                residence: {
                    address: state.placeOfResidence ? state.placeOfResidence.address : state.address,
                    zipCode: state.placeOfResidence ? state.placeOfResidence.zip: state.zip,
                    place: {
                        name: state.placeOfResidence ? state.placeOfResidence.place : state.place,
                        country: {
                            id: state.placeOfResidence ? state.placeOfResidence.country.id : state.country.id
                        },
                        municipality: (state.placeOfResidence ? state.placeOfResidence.country.key === 'bih' : state.country.key==='bih') ? {
                            id: state.placeOfResidence ? state.placeOfResidence.municipality.id : state.municipality.id,
                            canton: {id: state.placeOfResidence ? state.placeOfResidence.canton.id : state.canton.id},
                            entity: {id: state.placeOfResidence ? state.placeOfResidence.entity.id : state.entity.id}
                        } : null
                    }
                },
                email: state.email,
                phone: state.phone
            };
            axios.put('/employer/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste izmijenili poslodavca.');
                    if (activeRole === "ROLE_ADMIN")  {getEmployersForAdmin(state.organizer); setCurrentIdOrganizer(state.organizer)}
                    else {getEmployer();}
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function addEmployer(){
        if (!state.employer || !state.email || !state.phone || !state.placeOfResidence.country
            || !state.placeOfResidence.place || !state.placeOfResidence.address || !state.placeOfResidence.zip) {
            toast('Niste unijeli sve podatke.');
            if (!state.employer) { setEmployerErr(true); } else { setEmployerErr(false); }
            if (!state.email) { setEmailErr(true); } else { setEmailErr(false); }
            if (!state.phone) { setPhoneErr(true); } else { setPhoneErr(false); }
            if (!state.placeOfResidence.country) { setCountryErr(true); } else { setCountryErr(false); }
            if (!state.placeOfResidence.place) { setPlaceErr(true); } else { setPlaceErr(false); }
            if (!state.placeOfResidence.address) { setAddressErr(true); } else { setAddressErr(false); }
            if (!state.placeOfResidence.zip) { setZipErr(true); } else { setZipErr(false); }
        } else {
            if (activeRole === "ROLE_ORGANIZER") {
            let obj = {
                created: new Date(),
                name: state.employer,
                organizer: null,
                residence:{
                    address: state.placeOfResidence.address,
                    zipCode: state.placeOfResidence.zip,
                    place: {
                        name: state.placeOfResidence.place,
                        country: {
                            id: state.placeOfResidence.country.id
                        },
                        municipality: state.placeOfResidence.country.key === 'bih'?{
                            id: state.placeOfResidence.municipality.id,
                            canton: { id: state.placeOfResidence.canton.id },
                            entity: { id: state.placeOfResidence.entity.id }
                        }: null
                    }
                },
                email: state.email,
                phone: state.phone
            };
            axios.post('/employer/save', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste dodali poslodavca.');
                    getEmployer();
                }).catch((err) => {
                console.log('err', err)
            });
        }
            else{
                let obj = {
                    created: new Date(),
                    name: state.employer,
                    organizer: {id: state.organizer},
                    residence:{
                        address: state.placeOfResidence.address,
                        zipCode: state.placeOfResidence.zip,
                        place: {
                            name: state.placeOfResidence.place,
                            country: {
                                id: state.placeOfResidence.country.id
                            },
                            municipality: state.placeOfResidence.country.key === 'bih'?{
                                id: state.placeOfResidence.municipality.id,
                                canton: { id: state.placeOfResidence.canton.id },
                                entity: { id: state.placeOfResidence.entity.id }
                            }: null
                        }
                    },
                    email: state.email,
                    phone: state.phone
                };
                axios.post('/employer/save/for-admin', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste dodali poslodavca.');
                        getEmployersForAdmin(state.organizer);
                        setCurrentIdOrganizer(state.organizer);
                        // getEmployer();
                    }).catch((err) => {
                    console.log('err', err)
                });
            }
        }
    }
    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {add?'Dodavanje novog poslodavca':'Izmjena poslodavca'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths={'equal'}>
                            {activeRole === "ROLE_ADMIN" &&<Form.Field required>
                                <label>Organizator</label>
                                <Form.Dropdown
                                    clearable
                                    disabled={activeRole === "ROLE_ADMIN" && !add}
                                    placeholder="Odaberite organizatora"
                                    value={state.organizer}
                                    onChange={handleInputChange}
                                    name="organizer"
                                    options={state.organizers}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>}
                                    <Form.Field
                                        required
                                        label="Poslodavac"
                                        control={Input}
                                        name="employer"
                                        value={state.employer}
                                        onChange={handleInputChange}
                                        placeholder="Unesite poslodavca"
                                        error={employerErr}
                                    />

                                    <Form.Field
                                        required
                                        label="E-mail"
                                        control={Input}
                                        name="email"
                                        value={state.email}
                                        onChange={handleInputChange}
                                        placeholder="Unesite e-mail"
                                        error={emailErr}
                                    />

                                    <Form.Field
                                        required
                                        label="Telefon"
                                        control={Input}
                                        name="phone"
                                        value={state.phone}
                                        onChange={handleInputChange}
                                        placeholder="Unesite telefon"
                                        error={phoneErr}
                                    />

                        </Form.Group>
                        <PlaceForm
                            size={'small'}
                            threeRowsThreeFields
                            handlePlaceChange={handlePlaceChange}
                            formName={'placeOfResidence'}
                            countries={state.countries}
                            entities={state.entities}
                            cantons={state.cantons}
                            municipalities={state.municipalities}
                            country = {state.country}
                            entity = {state.entity}
                            enableEntity = {state.enableEntity}
                            enableCanton = {state.enableCanton}
                            canton = {state.canton}
                            enableMunicipality = {state.enableMunicipality}
                            municipality = {state.municipality}
                            place = {state.place}
                            address = {state.address}
                            zip = {state.zip}

                            countryErr = {countryErr}
                            placeErr = {placeErr}
                            addressErr = {addressErr}
                            zipErr = {zipErr}

                        />
                        <br/>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addEmployer: editEmployer}>{add ? 'Dodaj' : 'Sačuvaj'}</Button>
                    </Form>
                    </Segment>
            </SegmentGroup>


        </div>
    );
};

export default EmployerForm;
