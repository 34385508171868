import React from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {Button, Dropdown, Form, Header, Input, Segment} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import moment from "moment";
import {DateTimePicker} from "react-widgets";

const MarksOnRegistration = ({applicationDate, courses, addMarks, toggleMarkForm, handleInputChange, handleDateChange, getRegisteredCourses, marks, committees}) => {
    return (

            <div>
                <Table celled size={"small"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Header as={'h4'} floated={"left"}>
                                    Predmeti
                                    <span>
                                                    <Icon/></span>
                                    <Header.Subheader>
                                        <i>Datum prijave: {moment(applicationDate).format('D. M. YYYY.')}</i>
                                    </Header.Subheader>
                                </Header>
                            </Table.HeaderCell>
                            <Table.HeaderCell width={3}>Datum ocjene</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Ocjena</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Komisija</Table.HeaderCell>
                        </Table.Row>

                    </Table.Header>
                    <Table.Body>
                        {courses.map((el, index)=>(
                            <Table.Row key={index}>
                                <Table.Cell >

                                    {/*<Checkbox*/}
                                    {/*    label={el.course.nameBosnian}*/}
                                    {/*    onChange={()=>selectCourse(el)}*/}
                                    {/*    checked={selectedCourses.length !== 0 ?( selectedCourses.filter((e)=>(e === el)).length !== 0): false}*/}
                                    {/*/>*/}

                                    {el.courseStudyProgram.course.nameBosnian}

                                </Table.Cell>
                                <Table.Cell >

                                    <Form.Field
                                        required
                                        control={DateTimePicker}
                                        format="D. M. YYYY."
                                        placeholder="Unesite datum"
                                        value={el.markDate? new Date(el.markDate) : null}
                                        time={false}
                                        onChange={(e) => handleDateChange('markDate', e, el)}
                                        style={{ borderWidth: '0px' }}
                                    />

                                </Table.Cell>


                                <Table.Cell >
                                    <Form size={'mini'} >
                                        <Form.Group widths={3}>
                                            <Form.Field inline>
                                                <label >Pismeni dio:</label>
                                            </Form.Field>
                                            <Form.Field inline >
                                                <Dropdown
                                                    clearable
                                                    placeholder="Ocjena"
                                                    value={el.writtenExamMark?el.writtenExamMark.id:null}
                                                    el={el}
                                                    onChange={handleInputChange}
                                                    name="writtenExamMark"
                                                    options={marks}
                                                    search
                                                    // style={{float: 'right'}}
                                                    selection
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Group widths={3}>
                                        <Form.Field inline>
                                            <label>Usmeni dio:</label>
                                        </Form.Field>
                                            <Form.Field inline>

                                                <Dropdown
                                                    clearable
                                                    placeholder="Ocjena"
                                                    value={el.verbalExamMark?el.verbalExamMark.id:null}
                                                    el={el}
                                                    onChange={handleInputChange}
                                                    name="verbalExamMark"
                                                    options={marks}
                                                    search
                                                    // style={{float: 'right'}}
                                                    selection
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Group widths={3}>
                                        <Form.Field inline>
                                            <label>Zaključna ocjena:</label>

                                        </Form.Field>
                                            <Form.Field inline>

                                                <Dropdown
                                                    clearable
                                                    placeholder="Ocjena"
                                                    value={el.finalMark?el.finalMark.id:null}
                                                    onChange={handleInputChange}
                                                    options={marks}
                                                    name="finalMark"
                                                    search
                                                    el={el}
                                                    // style={{float: 'right'}}
                                                    selection
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Form>


                                </Table.Cell>
                                <Table.Cell >
                                    <Form size={'mini'} >
                                        <Form.Group widths={3}>
                                            <Form.Field inline>
                                                <label >Naziv komisije:</label>
                                            </Form.Field>
                                            <Form.Field inline >
                                                <Dropdown
                                                    clearable
                                                    placeholder="Naziv komisije"
                                                    value={el.committee?el.committee:null}
                                                    el={el}
                                                    onChange={handleInputChange}
                                                    name="committee"
                                                    options={committees}
                                                    search
                                                    // style={{float: 'right'}}
                                                    selection
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Group widths={3}>
                                            <Form.Field inline>
                                                <label>Predsjednik:</label>
                                            </Form.Field>
                                            <Form.Field
                                                disabled
                                                control={Input}
                                                value={el.committee?el.committee.firstMember.person.firstName + ' ' + el.committee.firstMember.person.lastName :''}
                                                placeholder="Predsjednik"
                                            />

                                        </Form.Group>
                                        <Form.Group widths={3}>
                                            <Form.Field inline>
                                                <label>Ispitivač:</label>
                                            </Form.Field>
                                            <Form.Field
                                                disabled
                                                control={Input}
                                                value={el.committee?el.committee.secondMember.person.firstName + ' ' + el.committee.secondMember.person.lastName :''}
                                                placeholder="Ispitivač"
                                            />

                                        </Form.Group>
                                        <Form.Group widths={3}>
                                            <Form.Field inline>
                                                <label>Član:</label>

                                            </Form.Field>
                                            <Form.Field
                                                disabled
                                                control={Input}
                                                value={el.committee?el.committee.thirdMember.person.firstName+ ' ' + el.committee.thirdMember.person.lastName:''}
                                                placeholder="Član"
                                            />






                                        </Form.Group>
                                    </Form>


                                </Table.Cell>
                            </Table.Row>))
                        }
                        <Table.Row >
                            {/*<Table.Cell colSpan={2}>*/}
                            {/*    <Button disabled floated={"right"} basic size={"mini"} color={"red"} >{'Ukloni ispite sa prijave'}</Button>*/}
                            {/*</Table.Cell>*/}
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Button floated={"right"} basic size={"mini"} color={"black"} onClick={toggleMarkForm} >{'Odustani'}</Button>
                <Button floated={"right"} basic size={"mini"} color={"teal"} onClick={addMarks} >{'Sačuvaj podatke'}</Button>
            </div>

    );
};

export default MarksOnRegistration;
