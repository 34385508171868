import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Form, Input, Loader} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {DateTimePicker} from "react-widgets";
import moment from "moment";
import {UserRoleContext} from "../../../Main";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {extractFileNameFromHeaders} from "../../Utilities/Util";
import download from "downloadjs";
const reducer = (state, action)=> {
    switch (action.type) {
        case 'graduationExamFileNumber':
            return {...state, graduationExamFileNumber: action.payload};
        case 'graduationExamFileDate':
            return {...state, graduationExamFileDate: action.payload};
        case 'serialNumber':
            return {...state, serialNumber: action.payload};
        case 'graduationExamStartDate':
            return {...state, graduationExamStartDate: action.payload};
        case 'graduationExamEndDate':
            return {...state, graduationExamEndDate: action.payload};
        case 'openForm':
            return {...state, openForm: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'serialNumbers':
            return {...state, serialNumbers: action.payload};
        default:
            return state;

    }
};
const GraduationExam = ({graduationExam, getGraduationInfo}) => {
    const duration = parseInt(localStorage.getItem('duration'));
    const sspId = parseInt(localStorage.getItem('sspId'))
    const formType = localStorage.getItem('formType');
    const organizerId = localStorage.getItem('organizerId');
    const [graduationExamFileNumberErr, setGraduationExamFileNumberErr] = useState(false);
    const [graduationExamFileDateErr, setGraduationExamFileDateErr] = useState(false);
    const [serialNumberErr, setSerialNumberErr] = useState(false);
    const [graduationExamStartDateErr, setGraduationExamStartDateErr] = useState(false);
    const [graduationExamEndDateErr, setGraduationExamEndDateErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        openForm: false,
        loader: false,
        serialNumbers:[]
    });

    useEffect(() => {
        if (graduationExam){

            dispatch({type: 'serialNumber', payload: graduationExam.serialNumber});
            dispatch({type: 'graduationExamFileNumber', payload: graduationExam.graduationExamFileNumber});
            dispatch({type: 'graduationExamFileDate', payload:graduationExam.graduationExamFileDate? new Date(graduationExam.graduationExamFileDate):null});
            dispatch({type: 'graduationExamStartDate', payload:graduationExam.graduationExamStartDate? new Date(graduationExam.graduationExamStartDate):null});
            dispatch({type: 'graduationExamEndDate', payload:graduationExam.graduationExamEndDate? new Date(graduationExam.graduationExamEndDate):null});
            if(!graduationExam.graduationExamFileNumber){ openForm()}
            getAllSerialNumbers()
            // if (formType === "secondary education" || formType === "secondary education - upskilling" || formType === "secondary education - reskilling")
            // {getSerialNumbers(2)}
            // else if (formType === "primary education")
            // {getSerialNumbers(1)}
            // else if (formType === "training" || formType === "qualification") {getSerialNumbers(3)}
            // else{getSerialNumbers(0)}
        }

    },[graduationExam]);
    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    function getAllSerialNumbers(){
        if(activeRole === "ROLE_ORGANIZER")
        {
            if (formType === "secondary education" || formType === "secondary education - upskilling" || formType === "secondary education - reskilling")
            {getSerialNumbers(2)}
            else if (formType === "primary education")
            {getSerialNumbers(1)}
            else if (formType === "training" || formType === "qualification") {getSerialNumbers(3)}
            else{getSerialNumbers(0)}
        }
        else{
            if (formType === "secondary education" || formType === "secondary education - upskilling" || formType === "secondary education - reskilling")
            {getSerialNumbersAdmin(2)}
            else if (formType === "primary education")
            {getSerialNumbersAdmin(1)}
            else if (formType === "training" || formType === "qualification") {getSerialNumbersAdmin(3)}
            else{getSerialNumbersAdmin(0)}
        }
    }

    function getSerialNumbers(formTypeId){
        axios.get(`form/unused-serial-numbers/by-form-type-and-organizer/${formTypeId}`,).then(
            (res) => {
                let serialNumbers = res.data.map((el)=>({key: el, text: el, value: el}));
                dispatch({type: 'serialNumbers', payload: serialNumbers});
            }).catch((err) => {
            if (err.response.data.message === 'Forms with passed type id not found!') {
                toast('Ne postoje obrasci za ovaj tip obrazovanja!');
            }
            // else {
            //     toast('Došlo je do greške')
            // }
            console.log('err', err)
        });
    }

    function getSerialNumbersAdmin(formTypeId){
        axios.get(`form/unused-serial-numbers/by-form-type-and-organizer/${formTypeId}/${organizerId}`,).then(
            (res) => {
                let serialNumbers = res.data.map((el)=>({key: el, text: el, value: el}));
                dispatch({type: 'serialNumbers', payload: serialNumbers});
            }).catch((err) => {
            // if (err.response.data.message === 'Forms with passed type id not found!') {
            //     toast('Ne postoje obrasci za ovaj tip obrazovanja!');
            // }
            // else {
            //     toast('Došlo je do greške')
            // }
            console.log('err', err)
        });
    }

    function openForm(){

        getMarks();

        dispatch({type: 'serialNumber', payload: graduationExam.serialNumber});
        dispatch({type: 'graduationExamFileNumber', payload: graduationExam.graduationExamFileNumber});
        dispatch({type: 'graduationExamFileDate', payload:graduationExam.graduationExamFileDate? new Date(graduationExam.graduationExamFileDate):null});
        dispatch({type: 'graduationExamStartDate', payload:graduationExam.graduationExamStartDate? new Date(graduationExam.graduationExamStartDate):null});
        dispatch({type: 'graduationExamEndDate', payload:graduationExam.graduationExamEndDate? new Date(graduationExam.graduationExamEndDate):null});

        if(state.serialNumber || graduationExam.serialNumber) dispatch({type: 'serialNumbers', payload: [...state.serialNumbers, {key: state.serialNumber, text: state.serialNumber, value: state.serialNumber}]});

        dispatch({type: 'openForm', payload: true});

    }

    function updateGraduationInfo() {
        if (!state.graduationExamFileNumber || !state.graduationExamFileDate || !state.serialNumber || !state.graduationExamStartDate || !state.graduationExamEndDate) {
            toast('Niste unijeli sve podatke.');
            if (!state.graduationExamFileNumber) { setGraduationExamFileNumberErr(true); } else { setGraduationExamFileNumberErr(false); }
            if (!state.graduationExamFileDate) { setGraduationExamFileDateErr(true); } else { setGraduationExamFileDateErr(false); }
            if (!state.serialNumber) { setSerialNumberErr(true); } else { setSerialNumberErr(false); }
            if (!state.graduationExamStartDate) { setGraduationExamStartDateErr(true); } else { setGraduationExamStartDateErr(false); }
            if (!state.graduationExamEndDate) { setGraduationExamEndDateErr(true); } else { setGraduationExamEndDateErr(false); }
        } else {
            let obj = {
                ...graduationExam,
                serialNumber: state.serialNumber,
                graduationExamFileNumber: state.graduationExamFileNumber,
                graduationExamFileDate: state.graduationExamFileDate,
                graduationExamStartDate: state.graduationExamStartDate,
                graduationExamEndDate: state.graduationExamEndDate,

            };

            axios.put('/graduation-exam/edit', {...obj}).then(
                (res) => {
                    getGraduationInfo();
                    getAllSerialNumbers();
                    toast('Uspješno ste izmijenili podatke.');
                    dispatch({type: 'openForm', payload: false});
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function getMarks(){
        axios.get('/mark/all',).then(
            (res) => {
                let marks = res.data.map((el)=>({key: el.id, text: el.number, description:  el.name, value: el})).reverse();
                dispatch({type: 'marks', payload: marks});
            }).catch((err) => {
            console.log('err', err)
        });
    }
    function downloadGraduationExamCertificate(){
        axios({
            method: 'post',
            url: `/document/graduation-exam-certificate/${sspId}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });
    }
    return (
        <div>

            {state.loader?<div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                :<Table celled size={"small"}>
                    <Table.Header>
                        <Table.HeaderCell colSpan={(activeRole === "ROLE_MINISTRY") || state.openForm?2:1}> {duration === 3 ? 'Završni ispit' : 'Maturski ispit'}</Table.HeaderCell>
                        {!(activeRole === "ROLE_MINISTRY") && !state.openForm && <Table.HeaderCell textAlign="center" >
                            <Popup size={"small"}
                                   content='Izmijeni'
                                   trigger={
                                       <Button
                                           icon
                                           size={"tiny"}
                                           basic
                                           compact
                                           disabled={activeRole !== "ROLE_ADMIN" && graduationExam.serialNumber}
                                           floated={"right"}
                                           onClick={openForm}
                                       >
                                           <Icon name='pencil' color={"teal"} />
                                       </Button>
                                   }
                            />
                        </Table.HeaderCell>}

                    </Table.Header>
                    <Table.Body>
                        <Table.Row width={8}>
                            <Table.Cell> <Table.HeaderCell>
                                <Form>
                                    <Form.Field required>
                                        <label>Djelovodni broj</label>
                                    </Form.Field>
                                </Form>
                            </Table.HeaderCell></Table.Cell>
                            {/*<Table.Cell> <Table.HeaderCell> Djelovodni broj </Table.HeaderCell></Table.Cell>*/}


                            {state.openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                    required
                                    control={Input}
                                    placeholder="Unesite broj"
                                    value={state.graduationExamFileNumber}
                                    name="graduationExamFileNumber"
                                    onChange={handleInputChange}
                                    error={graduationExamFileNumberErr}
                                />
                                </Form></Table.Cell>:
                                <Table.Cell >{state.graduationExamFileNumber && state.graduationExamFileNumber}</Table.Cell>
                            }
                        </Table.Row>
                        <Table.Row >
                            <Table.Cell><Table.HeaderCell>
                                <Form>
                                    <Form.Field required>
                                        <label>Datum izdavanja dokumenata</label>
                                    </Form.Field>
                                </Form>
                            </Table.HeaderCell></Table.Cell>
                            {/*<Table.Cell><Table.HeaderCell> Datum izdavanja dokumenta </Table.HeaderCell></Table.Cell>*/}


                            {state.openForm ? <Table.Cell >
                                <Form size={'tiny'}>
                                    <Form.Field
                                        required
                                        error={graduationExamFileDateErr}
                                        control={DateTimePicker}
                                        format="D. M. YYYY."
                                        placeholder="Unesite datum"
                                        name="graduationExamFileDate"
                                        value={state.graduationExamFileDate}
                                        time={false}
                                        onChange={(e) => handleDateChange('graduationExamFileDate', e)}
                                        style={{ borderWidth: '0px' }}
                                    />
                                </Form>
                            </Table.Cell>: <Table.Cell>{state.graduationExamFileDate && moment(state.graduationExamFileDate).format('D. M. YYYY.')}</Table.Cell>}
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell> <Table.HeaderCell>
                                <Form>
                                    <Form.Field required>
                                        <label>Serijski broj</label>
                                    </Form.Field>
                                </Form>
                            </Table.HeaderCell></Table.Cell>
                            {/*<Table.Cell> <Table.HeaderCell> Serijski broj </Table.HeaderCell></Table.Cell>*/}


                            {state.openForm ? <Table.Cell ><Form size={'tiny'}>
                                {!state.serialNumber  || activeRole !== "ROLE_ORGANIZER" ? <Form.Field required>
                                        <Form.Dropdown
                                            error={serialNumberErr}
                                            clearable
                                            placeholder="Odaberite broj"
                                            value={state.serialNumber}
                                            onChange={handleInputChange}
                                            name="serialNumber"
                                            options={state.serialNumbers}
                                            search
                                            fluid
                                            selection
                                        />
                                    </Form.Field> : <Form.Field>{state.serialNumber}</Form.Field>}
                                {/*    <Form.Field*/}
                                {/*    required*/}
                                {/*    control={Input}*/}
                                {/*    placeholder="Unesite broj"*/}
                                {/*    value={state.serialNumber}*/}
                                {/*    name="serialNumber"*/}
                                {/*    onChange={handleInputChange}*/}
                                {/*    error={serialNumberErr}*/}
                                {/*/>*/}
                                </Form></Table.Cell>:
                                <Table.Cell >{state.serialNumber && state.serialNumber}</Table.Cell>
                            }
                        </Table.Row>
                        <Table.Row width={8}>
                            <Table.Cell><Table.HeaderCell>
                                <Form>
                                    <Form.Field required>
                                        <label>Završni ispit je trajao od - do</label>
                                    </Form.Field>
                                </Form>
                            </Table.HeaderCell></Table.Cell>
                            {/*<Table.Cell><Table.HeaderCell> Završni ispit je trajao od - do </Table.HeaderCell></Table.Cell>*/}


                            {state.openForm ? <Table.Cell width={8}>
                                    <Form size={'tiny'}>
                                        <Form.Group widths={'equal'} >
                                            <Form.Field
                                                required
                                                control={DateTimePicker}
                                                format="D. M. YYYY."
                                                placeholder="Unesite datum"
                                                name="graduationExamStartDate"
                                                value={state.graduationExamStartDate}
                                                time={false}
                                                onChange={(e) => handleDateChange('graduationExamStartDate', e)}
                                                style={{ borderWidth: '0px' }}
                                                 error={graduationExamStartDateErr}
                                            />
                                                <Form.Field
                                                    required
                                                    control={DateTimePicker}
                                                    format="D. M. YYYY."
                                                    placeholder="Unesite datum"
                                                    name="graduationExamEndDate"
                                                    value={state.graduationExamEndDate}
                                                    time={false}
                                                    onChange={(e) => handleDateChange('graduationExamEndDate', e)}
                                                    style={{ borderWidth: '0px' }}
                                                     error={graduationExamEndDateErr}
                                                />
                                        </Form.Group>
                                    </Form>
                                </Table.Cell>:
                                <Table.Cell width={8}>{state.graduationExamStartDate && moment(state.graduationExamStartDate).format('D. M. YYYY.')+ ' - '}  {state.graduationExamEndDate && moment(state.graduationExamEndDate).format('D. M. YYYY.')}</Table.Cell>
                            }
                        </Table.Row>

                        {state.openForm ? <Table.Row >
                            <Table.Cell colSpan={state.openForm ? 3: 2} textAlign="right">
                                <Button size={"mini"} basic color={"teal"} onClick={updateGraduationInfo} >Sačuvaj</Button>
                                <Button size={"mini"} basic color={"black"} onClick={()=>{ dispatch({type: 'openForm', payload: false})}} >Odustani</Button>
                            </Table.Cell>

                        </Table.Row>: <Table.Row >
                            <Table.Cell colSpan={state.openForm ? 3: 2} textAlign="right">
                                <Button disabled={activeRole === "ROLE_MINISTRY"} icon labelPosition='left' size="mini" basic color="black" onClick={downloadGraduationExamCertificate} >
                                    <Icon name='download' color={"teal"} />
                                    {duration === 3 ? 'Preuzmi svjedodžbu':'Preuzmi svjedodžbu' }
                                </Button>

                            </Table.Cell>

                        </Table.Row>}

                    </Table.Body>

                </Table>}
            <ToastContainer  />
        </div>
    );
};

export default GraduationExam;
