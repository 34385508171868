import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Button, Form, Header, Icon, Loader, Menu, Segment} from "semantic-ui-react";
import {toast, ToastContainer} from "react-toastify";
import {UserRoleContext} from "../../../Main";
import axios from "axios";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import EducationalGroupHeadForm from "./EducationalGroupHeadForm";
import SearchTableData from "../../Utilities/SearchTableData";
import PaginationFront from "../../Utilities/PaginationFront";
import ReactExport from 'react-data-export';
import moment from "moment";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'loader':
            return {...state, loader: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'educationalGroupHeads':
            return {...state, educationalGroupHeads: action.payload};
        case 'educationalGroupHead':
            return {...state, educationalGroupHead: action.payload};
        case 'educationalGroupHeadsData':
            return {...state, educationalGroupHeadsData: action.payload};
        case 'openTable':
            return {...state, openTable: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'currentIdOrganizer':
            return {...state, currentIdOrganizer: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;

    }
}

const EducationalGroupHead = () => {
    const {activeRole} = useContext(UserRoleContext);
    const [activePage, setActivePage] = useState(1);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [state, dispatch] = useReducer(reducer,{
        loader: false,
        organizer: '',
        organizers: [],
        educationalGroupHeads: [],
        educationalGroupHeadsData: [],
        educationalGroupHead: '',
        openTable: false,
        currentId: null,
        currentIdOrganizer: null,
        data:[],
        results:[],
        postsPerPage: 20,
        currentPosts: [],
    });

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    const columns = [
        { title: 'Ime', width: { wpx: 200 } },
        { title: 'Prezime', width: { wpx: 200 } },
        { title: 'Nastavnik/ica', width: { wpx: 150 } },
    ];

    if (activeRole !== "ROLE_ORGANIZER") {
        columns.unshift({ title: 'Organizator', width: { wpx: 350 } });
    }

    const multiDataSet = [
        {
            columns: columns,
            data: state.educationalGroupHeadsData,
        }
    ];

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    useEffect(() => {
        if (activeRole === "ROLE_ORGANIZER") {
            getEducationalGroupHeads()
        }
        else if(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") {
            getOrganizers();
            getEducationalGroupHeadsAll();
        }
        // if (activeRole === "ROLE_ORGANIZER") getEducationalGroupHeads();
        // if (activeRole == "ROLE_ADMIN" || activeRole == "ROLE_MINISTRY") getOrganizers();
    },[activeRole]);

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            dispatch({type: 'openTable', payload: true});
            getEducationalGroupHeadsForAdmin(value)
        }
        if(name === 'organizer' && !value){
            dispatch({type: 'openTable', payload: true});
            getEducationalGroupHeadsAll()
        }
    }

    function getEducationalGroupHeadsForAdmin(id){
        setCurrentId(id);
        setCurrentIdOrganizer(id);
        dispatch({type: 'loader', payload: true});
        dispatch({type: 'openTable', payload: true});
        axios.get(`/educational-group-head/by-organizer-id/${id}`,).then(
            (res) => {
                let educationalGroupHeads = res.data;
                dispatch({type: 'educationalGroupHeads', payload: educationalGroupHeads});
                dispatch({type: 'data', payload: educationalGroupHeads});
                let educationalGroupHeadsData = res.data.map(el => ([
                    { value: el.organizer.name},
                    { value: el.firstName },
                    { value: el.lastName },
                    { value: el.teacher ? 'DA' : 'NE' },
                ]));
                dispatch({type: 'educationalGroupHeadsData', payload: educationalGroupHeadsData});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getEducationalGroupHeads(){
        dispatch({type: 'loader', payload: true});
        axios.get('/educational-group-head/by-organizer',).then(
            (res) => {
                let educationalGroupHeads = res.data;
                dispatch({type: 'educationalGroupHeads', payload: educationalGroupHeads});
                dispatch({type: 'data', payload: educationalGroupHeads});
                let educationalGroupHeadsData = res.data.map(el => ([
                    { value: el.firstName },
                    { value: el.lastName },
                    { value: el.teacher ? 'DA' : 'NE' },
                ]));
                dispatch({type: 'educationalGroupHeadsData', payload: educationalGroupHeadsData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getEducationalGroupHeadsAll(){
        dispatch({type: 'loader', payload: true});
        axios.get('/educational-group-head/all',).then(
            (res) => {
                let educationalGroupHeads = res.data;
                dispatch({type: 'educationalGroupHeads', payload: educationalGroupHeads});
                dispatch({type: 'data', payload: educationalGroupHeads});
                let educationalGroupHeadsData = res.data.map(el => ([
                    { value: el.organizer.name},
                    { value: el.firstName },
                    { value: el.lastName },
                    { value: el.teacher ? 'DA' : 'NE' },
                ]));
                dispatch({type: 'educationalGroupHeadsData', payload: educationalGroupHeadsData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function deleteEducationalGroupHead(id){
        setCurrentId(id);
        // dispatch({type: 'loader', payload: true});
        axios.delete(`/educational-group-head/delete/${id}`,).then(
            (res) => {
                activeRole === "ROLE_ORGANIZER" && getEducationalGroupHeads();
                activeRole === "ROLE_ADMIN" && getEducationalGroupHeadsForAdmin(state.organizer);
                toast('Uspješno ste izbrisali voditelja obrazovne grupe!');
                // dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            if (err.response.data.message === 'This educational group head cannot be deleted because of existing relations to student study program grade!') {
                toast('Odabrani voditelj obrazovne grupe je povezan sa studentima!');
            } else {
                toast('Došlo je do greške! Brisanje voditelja obrazovne grupe nije uspjelo.');
            }
            setCurrentId(null);
            console.log('err', err)
        });
    }

    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Spisak voditelja obrazovne grupe </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            {(!showEditForm && activeRole !== "ROLE_MINISTRY") &&  <Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Dodaj voditelja obrazovne grupe
                                </Button>
                            </Menu.Item>}
                            <Menu.Item>
                                <ExcelFile filename="Spisak voditelja obrazovne grupe" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={multiDataSet} name="Voditelji obrazovne grupe" />
                                </ExcelFile>
                            </Menu.Item>
                            <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                        </Menu.Menu>

                    </Menu>

                </Segment>

                {showAddForm &&
                <Segment>
                    <EducationalGroupHeadForm add toggleEditForm={toggleAddForm} getEducationalGroupHeads={getEducationalGroupHeads} getEducationalGroupHeadsForAdmin={getEducationalGroupHeadsForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer}/>
                </Segment>}

                {showEditForm &&
                <Segment>
                    <EducationalGroupHeadForm edit toggleEditForm={toggleEditForm} getEducationalGroupHeads={getEducationalGroupHeads} educationalGroupHead={state.educationalGroupHead} getEducationalGroupHeadsForAdmin={getEducationalGroupHeadsForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer}/>
                </Segment>}

                {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Segment><Form size={'small'}>
                    <Form.Group widths="3">
                        <Form.Field>
                            <label>Organizator</label>
                            <Form.Dropdown
                                placeholder="Odaberite organizatora"
                                value={state.organizer}
                                onChange={handleInputChange}
                                name="organizer"
                                options={state.organizers}
                                search
                                fluid
                                selection
                                clearable
                            />
                        </Form.Field>
                    </Form.Group>
                </Form></Segment>}
                {/*{((activeRole === "ROLE_ADMIN" && state.openTable) || (activeRole === "ROLE_MINISTRY" && state.openTable) || activeRole === "ROLE_ORGANIZER") &&*/}
                <Segment>
                    {state.loader ?
                        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                        :
                        currentPosts.length ?
                            <Table celled size={"small"}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={activeRole !== "ROLE_ORGANIZER" ? 11 : 9} >
                                            <div style={{ paddingLeft: '0px' }}>
                                                <SearchTableData data={state.data} getResults={getResults} filterItem={['lastName','firstName']} />
                                            </div>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>

                                        <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                                        {activeRole !== "ROLE_ORGANIZER"  &&<Table.HeaderCell colSpan={2} >Organizator</Table.HeaderCell>}
                                        <Table.HeaderCell >
                                            <Header size={"small"}>
                                                Prezime i ime
                                                <span><Icon/></span>
                                                <Header.Subheader>
                                                    <i>Voditelj obrazovne grupe</i>
                                                </Header.Subheader>
                                            </Header>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={2}>
                                            <Header size={"small"}>
                                                Nastavnik/ica
                                                <Header.Subheader>
                                                    <i>DA/NE</i>
                                                </Header.Subheader>
                                            </Header>
                                        </Table.HeaderCell>
                                        {activeRole !== "ROLE_MINISTRY" &&<Table.HeaderCell width={1} />}
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        currentPosts.map((el, index) =>
                                        // state.educationalGroupHeads.map((el, index)=>
                                            (<Table.Row key={el.id}>
                                                <Table.Cell width={1}>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                                {activeRole !== "ROLE_ORGANIZER"  &&<Table.Cell colSpan={2}>{el.organizer.name}</Table.Cell>}
                                                <Table.Cell>{el.lastName} {el.firstName}</Table.Cell>
                                                <Table.Cell width={2}>{el.teacher ? 'DA' : 'NE'}</Table.Cell>
                                                {activeRole !== "ROLE_MINISTRY" && <Table.Cell width={1} verticalAlign="middle" textAlign="center">
                                                    <Popup size={"small"} content='Izmijeni voditelja' trigger={
                                                        <Button icon size={"mini"} basic color={"grey"} disabled={el.teacher} onClick={() => {setShowEditForm(true); dispatch({type: 'educationalGroupHead', payload: el})}}>
                                                            <Icon name='pencil' color={"teal"} />
                                                        </Button>} />
                                                    <Popup size={"small"} content='Obriši voditelja' trigger={
                                                        <Button icon size={"mini"} basic color={"grey"} disabled={state.currentId && el.id !== state.currentId } onClick={() => {deleteEducationalGroupHead(el.id)}}>
                                                            <Icon name='trash alternate outline' color={"red"} />
                                                        </Button>} />
                                                </Table.Cell>}
                                            </Table.Row>)
                                        )
                                    }


                                </Table.Body>

                                <Table.Footer>
                                    {/*<Table.Row>*/}
                                    {/*    <Table.HeaderCell colSpan='11' textAlign='center'>*/}
                                    {/*        <Menu  pagination>*/}
                                    {/*            <Menu.Item as='a' icon>*/}
                                    {/*                <Icon name='chevron left' />*/}
                                    {/*            </Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>1</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>2</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>3</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>4</Menu.Item>*/}
                                    {/*            <Menu.Item as='a' icon>*/}
                                    {/*                <Icon name='chevron right' />*/}
                                    {/*            </Menu.Item>*/}
                                    {/*        </Menu>*/}
                                    {/*    </Table.HeaderCell>*/}
                                    {/*</Table.Row>*/}
                                </Table.Footer>
                            </Table> : <div>Nema unesenih podataka o voditeljima obrazovne grupe.</div>}
                    {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                </Segment>
            </Segment.Group>
            <ToastContainer  />
        </div>
    );
};

export default EducationalGroupHead;
