import React, {useEffect, useReducer, useState} from 'react';
import {GridColumn, Header, Icon, Input, SegmentGroup} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import PlaceForm from "../Utilities/PlaceForm";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {DateTimePicker} from "react-widgets";

const reducer = (state, action)=>{
    switch (action.type){
        case 'countries':
            return {...state, countries: action.payload};
        case 'entities':
            return {...state, entities: action.payload };
        case 'cantons':
            return {...state, cantons: action.payload };
        case 'municipalities':
            return {...state, municipalities: action.payload };
        // case 'country':
        //     return {...state,
        //         country: action.payload,
        //         enableEntity: action.payload.key === 'bih',
        //         entity: action.payload ? state.entity: '',
        //         canton: action.payload ? state.canton: '',
        //         municipality: action.payload ? state.municipality: '',
        //         enableCanton: action.payload ? state.enableCanton: false,
        //         enableMunicipality: action.payload ? state.enableMunicipality: false,
        //     };
        // case 'entity':
        //     return {...state,
        //         entity: action.payload,
        //         enableCanton: action.payload? action.payload.key === 'fbih': false ,
        //         canton: action.payload && action.payload.key === 'fbih' ? state.canton: '',
        //         enableMunicipality: action.payload ? action.payload.key !== 'fbih': false,
        //         //municipality: action.payload ? state.municipality: '',
        //         filteredMunicipalities: action.payload.key !== 'fbih' ? state.municipalities.filter((el)=>(el.value.entity && el.value.entity.key  === action.payload.key)) : []
        //     };
        // case 'canton':
        //     return {...state,
        //         canton: action.payload,
        //         enableMunicipality: !!action.payload,
        //         municipality: '',
        //         filteredMunicipalities: state.municipalities.filter((el)=>(el.value.canton && el.value.canton.key  === action.payload.key))
        //     };
        // case 'municipality':
        //     return {...state, municipality: action.payload };
        // case 'place':
        //     return {...state, place: action.payload};
        // case 'address':
        //     return {...state, address: action.payload };
        // case 'zip':
        //     return {...state, zip: action.payload };
        case 'placeOfResidence':
            return {...state, placeOfResidence: action.payload};
        default:
            return state;
    }
};


const OrganizerPlaceForm = ({toggleEditForm, add, edit, getOrganizer, organizer, municipalities}) => {
    const [countryErr, setCountryErr] = useState(false);
    const [placeErr, setPlaceErr] = useState(false);
    const [addressErr, setAddressErr] = useState(false);
    const [zipErr, setZipErr] = useState(false);

    const [state, dispatch] = useReducer(reducer,{
// options
        name: edit? organizer.name:'',
        firstName: edit? organizer.firstName :'',
        lastName: edit ? organizer.lastName : '',
        courtRegistrationInputNumber: edit ? organizer.courtRegisterInputNumber : '',
        courtRegistrationInputDate: edit ? new Date(organizer.courtRegisterInputDate) : null,
        organizerType: edit ? organizer.organizerType : '',
        organizerTypeOptions: [],
        JIB: edit ? organizer.jib : '',
        PIB: edit ? organizer.pib : '',
        email: edit ? organizer.email : '',
        documentNumber: '',
        documentDate: '',
        phone: edit ? organizer.phone : '',
        website: '',
        latitude: edit ? organizer.latitude : null,
        longitude: edit ? organizer.longitude : null,
        // options
        countries:[],
        entities: [],
        cantons: [],
        municipalities: [],

        country: edit ? organizer.residence.place.country : '',
        entity: edit &&  !!organizer.residence.place.municipality && !!organizer.residence.place.municipality.entity ? organizer.residence.place.municipality.entity : '',
        canton: edit ?  !!organizer.residence.place.municipality && !!organizer.residence.place.municipality.canton && organizer.residence.place.municipality.canton :'',
        municipality: edit && !!organizer.residence.place.municipality ?  organizer.residence.place.municipality : 'gg',
        place: edit ?  !!organizer.residence.place && organizer.residence.place.name : '',
        address: edit ?  !!organizer.residence && organizer.residence.address : '',
        zip: edit ?  !!organizer.residence && organizer.residence.zipCode : '',
        // disable properties
        enableEntity: edit ? !!organizer.residence.place.municipality && !!organizer.residence.place.municipality.entity: false,
        enableCanton: edit ? !!organizer.residence.place.municipality && !!organizer.residence.place.municipality.canton : false,
        enableMunicipality: edit ? !!organizer.residence.place.municipality: false

    } );

    useEffect(() => {
        axios.get('/country/all',).then(
            (res)=>{
                let countriesOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'countries', payload: countriesOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/entity/all',).then(
            (res)=>{
                let entityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'entities', payload: entityOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/canton/all',).then(
            (res)=>{
                let cantonOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'cantons', payload: cantonOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/municipality/all',).then(
            (res)=>{
                let municipalityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'municipalities', payload: municipalityOptions});
               // dispatch({type:'filteredMunicipalities', payload: municipalityOptions});

            }).catch((err)=>{
            console.log('err', err)
        });

        if(edit){
            dispatch({type:'country', payload: organizer.residence.place.country});
            if( !!organizer.residence.place.municipality && !!organizer.residence.place.municipality.entity ){
                dispatch({type:'entity', payload: organizer.residence.place.municipality.entity});
                dispatch({type:'municipalities', payload:  organizer.residence.place.municipality.entity.key !== 'fbih' ? municipalities.filter((el)=>(el.value.entity && el.value.entity.key  === organizer.residence.place.municipality.entity.key)) : []});
            }
            if( !!organizer.residence.place.municipality && !!organizer.residence.place.municipality.canton){
                dispatch({type:'canton', payload: organizer.residence.place.municipality.canton});
                dispatch({type:'municipalities', payload: municipalities.filter((el)=>(el.value.canton && el.value.canton.key  === organizer.residence.place.municipality.canton.key))});
                dispatch({type:'municipality', payload: organizer.residence.place.municipality});
            }
        }
    }, []);


    function formValidation(){
        if (!state.placeOfResidence.country || !state.placeOfResidence.place || !state.placeOfResidence.address || !state.placeOfResidence.zip) {
            toast("Niste unijeli sve podatke!");
            if (!state.placeOfResidence.country) { setCountryErr(true); } else { setCountryErr(false); }
            if (!state.placeOfResidence.place) { setPlaceErr(true); } else { setPlaceErr(false); }
            if (!state.placeOfResidence.address) { setAddressErr(true); } else { setAddressErr(false); }
            if (!state.placeOfResidence.zip) { setZipErr(true); } else { setZipErr(false); }
            return false;
        }
        return true;
    }

    function editOrganizer(){
        if (formValidation()){
            let obj = {
                id: organizer.id,
                optLock: organizer.optLock,
                name: state.name,
                courtRegisterInputDate: state.courtRegistrationInputDate,
                courtRegisterInputNumber: state.courtRegistrationInputNumber,
                organizerType: { id: state.organizerType.id },
                jib: state.JIB,
                pib: state.PIB,
                email: state.email,
                phone: state.phone,
                web: state.website,
                residence:{
                    address: state.placeOfResidence.address,
                    zipCode: state.placeOfResidence.zip,
                    place: {
                        name: state.placeOfResidence.place,
                        country: {
                            id: state.placeOfResidence.country.id
                        },
                        municipality: state.placeOfResidence.country.key === 'bih'?{
                            id: state.placeOfResidence.municipality.id,
                            canton: { id: state.placeOfResidence.canton.id },
                            entity: { id: state.placeOfResidence.entity.id }
                        }: null
                    }
                },
                latitude: state.latitude,
                longitude: state.longitude

            };

            axios.put('/organizer/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste izmijenili mjesto.');
                    getOrganizer();
                }).catch((err) => {
                console.log('err', err)
            });
        }


    }

    function handlePlaceChange(placeName, obj) {
        dispatch({type: placeName, payload: obj});
    }

    return (
        <div >
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {'Izmjena podataka'}
                       <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                           {/*placeholder*/}
                           <Icon/>
                       </span>
                    </Header>
                </Segment>

                <Segment clearing>


                                    <PlaceForm
                                        oneRowTwoFields
                                        handlePlaceChange={handlePlaceChange}
                                        formName={'placeOfResidence'}
                                        countries={state.countries}
                                        entities={state.entities}
                                        cantons={state.cantons}
                                        municipalities={state.municipalities}
                                        country = {state.country}
                                        entity = {state.entity}
                                        enableEntity = {state.enableEntity}
                                        enableCanton = {state.enableCanton}
                                        canton = {state.canton}
                                        enableMunicipality = {state.enableMunicipality}
                                        municipality = {state.municipality}
                                        place = {state.place}
                                        address = {state.address}
                                        zip = {state.zip}

                                        countryErr = {countryErr}
                                        placeErr = {placeErr}
                                        addressErr = {addressErr}
                                        zipErr = {zipErr}
                                    />

                                    {/*<PlaceForm*/}
                                    {/*    size={'small'}*/}
                                    {/*    handleInputChange = {handleInputChange}*/}
                                    {/*    countries = {state.countries}*/}
                                    {/*    country = {state.country}*/}
                                    {/*    enableEntity = {state.enableEntity}*/}
                                    {/*    entities = {state.entities}*/}
                                    {/*    entity = {state.entity}*/}
                                    {/*    enableCanton = {state.enableCanton}*/}
                                    {/*    canton = {state.canton}*/}
                                    {/*    cantons = {state.cantons}*/}
                                    {/*    enableMunicipality = {state.enableMunicipality}*/}
                                    {/*    filteredMunicipalities = {state.filteredMunicipalities}*/}
                                    {/*    municipality = {state.municipality}*/}
                                    {/*    place = {state.place}*/}
                                    {/*    address = {state.address}*/}
                                    {/*    zip = {state.zip}*/}

                                    {/*    countryErr = {countryErr}*/}
                                    {/*    placeErr = {placeErr}*/}
                                    {/*    addressErr = {addressErr}*/}
                                    {/*    zipErr = {zipErr}*/}

                                    {/*/>*/}

                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={()=>editOrganizer()}>{ 'Sačuvaj'}</Button>
                </Segment>
            </SegmentGroup>
        </div>
    );
};

export default OrganizerPlaceForm;
