import React from 'react';
import {Header, Icon, Input, Segment, Select} from "semantic-ui-react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {DateTimePicker} from "react-widgets";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

const PreviousEducationForm = ({add, edit, teacher, handleInputChange, handleDateChange, institutionName, diplomaNumber, diplomaDate, educationLevel,
                                   title, educationLevels, nextStep, showNextButton, toggleEditForm, grade, grades,
                                   addPreviousEducation, editPreviousEducation, removeButtons, documentTypes, documentType,
                                   institutionNameErr, diplomaNumberErr, diplomaDateErr, educationLevelErr, titleErr, documentTypeErr, gradeErr, gradIsNotMandatory
                               }) => {

    return (<>
            <Segment.Group raised >
                {edit?
                    <Segment >
                    <Header as="h4">
                        Izmjena podataka
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            {/*placeholder*/}
                            <Icon/>
                       </span>
                    </Header>
                </Segment> :<Segment >
                    <Header as="h4">
                        Stečeno zanimanje
                        {!removeButtons && <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            {/*placeholder*/}
                            <Icon/>
                       </span>}
                       {/*treba se prikazivati samo pri dodavanju polaznika tj kad nema dugmica*/}
                       {(removeButtons && !teacher) && <Header.Subheader>
                            <i>Dokument na osnovu kojeg se vrši upis</i>
                        </Header.Subheader>}

                    </Header>
                </Segment>}
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths={'equal'} >
                            <Form.Field
                                required
                                error={institutionNameErr}
                                label="Naziv završene institucije"
                                control={Input}
                                name="institutionName"
                                value={institutionName}
                                onChange={handleInputChange}
                                placeholder="Unesite naziv"
                            />
                            <Form.Field
                                error={diplomaNumberErr}
                                required
                                label="Broj dokumenta"
                                control={Input}
                                name="diplomaNumber"
                                value={diplomaNumber}
                                onChange={handleInputChange}
                                placeholder="Unesite broj"
                            />
                        </Form.Group>
                        <Form.Group widths={'equal'} >
                            <Form.Field
                                required
                                error={diplomaDateErr}
                                control={DateTimePicker}
                                label="Datum dokumenta"
                                format="D. M. YYYY."
                                placeholder="Unesite datum"
                                name="diplomaDate"
                                value={diplomaDate}
                                time={false}
                                onChange={(e) => handleDateChange('diplomaDate', e)}
                                style={{ borderWidth: '0px' }}
                                //error={openFromDateErr}
                            />
                            <Form.Field
                                required
                                error={titleErr}
                                label="Završeno zanimanje"
                                control={Input}
                                name="title"
                                value={title}
                                onChange={handleInputChange}
                                placeholder="Unesite titulu"
                            />
                        </Form.Group>
                        <Form.Group widths={'equal'} >
                            <Form.Field
                                error={documentTypeErr}
                                required
                                control={Select}
                                clearable
                                options={documentTypes}
                                placeholder="Odaberite tip"
                                label="Tip dokumenta"
                                onChange={handleInputChange}
                                name="documentType"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={documentType}
                                search
                            />
                            <Form.Field
                                error={educationLevelErr}
                                required
                                control={Select}
                                clearable
                                options={educationLevels && educationLevels.filter(el => el.value.name !== 'nepoznato')}
                                placeholder="Odaberite stepen"
                                label="Stepen obrazovanja"
                                onChange={handleInputChange}
                                name="educationLevel"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={educationLevel}
                                search
                            />
                            {/*razred treba samo ako se unosi kroz unos polaznika*/}
                           {(removeButtons && !teacher) && <Form.Field
                                required={!gradIsNotMandatory}
                                error={!gradIsNotMandatory && gradeErr}
                                control={Select}
                                clearable
                                options={grades}
                                placeholder="Odaberite razred"
                                label="Završeni razred u zanimanju"
                                onChange={handleInputChange}
                                name="grade"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={grade}
                                search
                            />}


                        </Form.Group>

                    </Form>
                    {((!removeButtons && !showNextButton) || teacher) && <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addPreviousEducation : editPreviousEducation} >{add ? 'Dodaj':'Sačuvaj'}</Button>}
                </Segment>

            </Segment.Group>
        {!removeButtons && showNextButton && <Button floated={"right"} basic size={"small"} color={"teal"} onClick={()=>nextStep(2)} >Nastavi</Button>}
</>
    );
};

export default PreviousEducationForm;
