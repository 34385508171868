import React from 'react';
import {Divider, Header} from "semantic-ui-react";

const SecondaryFooter = () => {
    return (
        <div className={'footer-secondary bottom-fixed'}>
            <Divider horizontal>
                <Header as='h5' color={"teal"}>
                    <a  href="https://iskoom.ba/" color={'teal'}> ISKOOM</a> &copy; 2024
                </Header>
            </Divider>

        </div>
    );
};

export default SecondaryFooter;
