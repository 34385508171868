import React from 'react';
import RequestForm from "./RequestForm";

const RequestEditAndDetails = ({toggleEditForm, getRequests, request}) => {
    return (

            <RequestForm edit toggleEditForm={toggleEditForm} getRequests={getRequests} request={request}/>

    );
};

export default RequestEditAndDetails;
