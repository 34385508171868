import React from 'react';
import {Divider, GridColumn, Header, Icon} from "semantic-ui-react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";

const Footer = () => {
    return (

        <footer className="footer removePadding">

            <Grid columns={3} stackable className='removePadding'>

                <GridColumn className='removePadding'/>



                <GridColumn verticalAlign={'middle'} className='removePadding' textAlign={"center"}>
                    <Divider horizontal>
                        <Header as='h5'>
                            Podržano od
                        </Header>
                    </Divider>
                    <a href={'https://www.dvv-international.ba/'} target={'_blank'}>
                        <Image size={'small'} src={'../../../img/VHS.gif'}  verticalAlign='middle' />
                    </a>
                    <a href={'https://www.bmz.de/de/'} target={'_blank'}>
                        <Image size={'small'} src={'../../../img/bmz.png'}  verticalAlign='middle' />
                    </a>
                </GridColumn>



                <GridColumn className='removePadding'/>
                    {/*<Divider horizontal>*/}
                    {/*    <Header as='h5'>*/}
                    {/*        Adresa*/}
                    {/*    </Header>*/}
                    {/*</Divider>*/}
                    {/*<div className={'float-left'}>*/}
                    {/*<Header as='h5'>*/}
                    {/*        <Icon name='map marker alternate' />*/}
                    {/*        Reisa Džemaludina Čauševića 1 71000 Sarajevo, B&H*/}
                    {/*</Header>*/}

                    {/*</div>*/}


            </Grid>
        </footer>


    );
};

export default Footer;
