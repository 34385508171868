import React from 'react';
import axios from "axios";


const dropdownMapper = res => res.data.map(el => ({ value: el, key: el.id, text: el.name }));

const getAllOrganizers = () => axios.get('organizer/all').then(dropdownMapper);

const Requests = {
   getAllOrganizers
};


export default Requests;
