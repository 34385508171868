import React, {useState} from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {Form, Input} from "semantic-ui-react";

const OriginPlaceOfEmployment = ({ person, edit, originPlaceOfEmployment, numberOfHoursInOriginPlaceOfEmployment, handleInputChange, originPlaceOfEmploymentErr, numberOfHoursInOriginPlaceOfEmploymentErr}) => {

    return (
        <>
            <Table.Row>
                <Table.Cell> <Table.HeaderCell>Matično zaposlenje </Table.HeaderCell></Table.Cell>

                {edit ? <Table.Cell >
                        <Form size={'tiny'}>
                            <Form.Group widths={'equal'}>
                                <Form.Field
                                    // required
                                    control={Input}
                                    placeholder="Unesite matično zaspolenje"
                                    value={originPlaceOfEmployment}
                                    name="originPlaceOfEmployment"
                                    onChange={handleInputChange}
                                    error={originPlaceOfEmploymentErr}
                                />
                            </Form.Group>
                        </Form>
                    </Table.Cell>:
                    <Table.Cell>{person ? person.originPlaceOfEmployment : ''}</Table.Cell>
                }
            </Table.Row>
            <Table.Row>
                <Table.Cell> <Table.HeaderCell>Broj sati sedmično (matično zaposlenje)</Table.HeaderCell></Table.Cell>

                {edit ? <Table.Cell >
                        <Form size={'tiny'}>
                            <Form.Group widths={'equal'}>
                                <Form.Field
                                    // required
                                    control={Input}
                                    placeholder="Unesite broj sati sedmično"
                                    value={numberOfHoursInOriginPlaceOfEmployment}
                                    name="numberOfHoursInOriginPlaceOfEmployment"
                                    onChange={handleInputChange}
                                    error={numberOfHoursInOriginPlaceOfEmploymentErr}
                                />
                            </Form.Group>
                        </Form>
                    </Table.Cell>:
                    <Table.Cell>{person ? person.numberOfHoursInOriginPlaceOfEmployment : ''}</Table.Cell>
                }
            </Table.Row>
        </>
    );
};

export default OriginPlaceOfEmployment;
