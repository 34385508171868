import React, {useEffect, useReducer, useState} from 'react';
import {GridColumn, Header, Icon, Input, SegmentGroup} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import PlaceForm from "../Utilities/PlaceForm";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {DateTimePicker} from "react-widgets";

const reducer = (state, action)=>{
    switch (action.type){
        case 'name':
            return {...state, name: action.payload};
        case 'firstName':
            return {...state, firstName: action.payload };
        case 'lastName':
            return {...state, lastName: action.payload };
        case 'courtRegistrationInputNumber':
            return {...state, courtRegistrationInputNumber: action.payload };
        case 'courtRegistrationInputDate':
            return {...state, courtRegistrationInputDate: action.payload };
        case 'ordinalNumberInRegisterOfOrganizers':
            return {...state, ordinalNumberInRegisterOfOrganizers: action.payload };
        case 'documentNumberInRegisterOfOrganizers':
            return {...state, documentNumberInRegisterOfOrganizers: action.payload };
        case 'documentDateInRegisterOfOrganizers':
            return {...state, documentDateInRegisterOfOrganizers: action.payload };
        case 'organizerType':
            return {...state, organizerType: action.payload };
        case 'organizerTypeOptions':
            return {...state, organizerTypeOptions: action.payload };
        case 'JIB':
            return {...state, JIB: action.payload};
        case 'PIB':
            return {...state, PIB: action.payload };
        case 'email':
            return {...state, email: action.payload };
        case 'documentNumber':
            return {...state, documentNumber: action.payload };
        case 'phone':
            return {...state, phone: action.payload };
        case 'website':
            return {...state, website: action.payload };
        case 'latitude':
            return {...state, latitude: action.payload };
        case 'longitude':
            return {...state, longitude: action.payload };
        case 'countries':
            return {...state, countries: action.payload};
        case 'entities':
            return {...state, entities: action.payload };
        case 'cantons':
            return {...state, cantons: action.payload };
        case 'municipalities':
            return {...state, municipalities: action.payload };
        case 'filteredMunicipalities':
            return {...state, filteredMunicipalities: action.payload };
        // case 'country':
        //     return {...state,
        //         country: action.payload,
        //         enableEntity: action.payload.key === 'bih',
        //         entity: action.payload ? state.entity: '',
        //         canton: action.payload ? state.canton: '',
        //         municipality: action.payload ? state.municipality: '',
        //         enableCanton: action.payload ? state.enableCanton: false,
        //         enableMunicipality: action.payload ? state.enableMunicipality: false,
        //     };
        // case 'entity':
        //     return {...state,
        //         entity: action.payload,
        //         enableCanton: action.payload? action.payload.key === 'fbih': false ,
        //         canton: action.payload && action.payload.key === 'fbih' ? state.canton: '',
        //         enableMunicipality: action.payload ? action.payload.key !== 'fbih': false,
        //         //municipality: action.payload ? state.municipality: '',
        //         filteredMunicipalities: action.payload.key !== 'fbih' ? state.municipalities.filter((el)=>(el.value.entity && el.value.entity.key  === action.payload.key)) : []
        //     };
        // case 'canton':
        //     return {...state,
        //         canton: action.payload,
        //         enableMunicipality: !!action.payload,
        //         municipality: '',
        //         filteredMunicipalities: state.municipalities.filter((el)=>(el.value.canton && el.value.canton.key  === action.payload.key))
        //     };
        // case 'municipality':
        //     return {...state, municipality: action.payload };
        // case 'place':
        //     return {...state, place: action.payload};
        // case 'address':
        //     return {...state, address: action.payload };
        // case 'zip':
        //     return {...state, zip: action.payload };
        case 'placeOfBirth':
            return {...state, placeOfBirth: action.payload};
        default:
            return state;
    }
};


const OrganizerForm = ({toggleEditForm, add, edit, getOrganizer, organizer, municipalities}) => {
    const [nameErr, setNameErr] = useState(false);
    const [organizerTypeErr, setOrganizerTypeErr] = useState(false);
    const [ordinalNumberInRegisterOfOrganizersErr, setordinalNumberInRegisterOfOrganizersErr] = useState(false);
    const [documentNumberInRegisterOfOrganizersErr, setDocumentNumberInRegisterOfOrganizersErr] = useState(false);
    const [documentDateInRegisterOfOrganizersErr, setDocumentDateInRegisterOfOrganizersErr] = useState(false);
    const [JIBErr, setJIBErr] = useState(false);
    // const [PIBErr, setPIBErr] = useState(false);
    const [courtRegistrationInputNumberErr, setCourtRegistrationInputNumberErr] = useState(false);
    const [courtRegistrationInputDateErr, setCourtRegistrationInputDateErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [websiteErr, setWebsiteErr] = useState(false);
    const [phoneErr, setPhoneErr] = useState(false);
    const [countryErr, setCountryErr] = useState(false);
    const [placeErr, setPlaceErr] = useState(false);
    const [addressErr, setAddressErr] = useState(false);
    const [zipErr, setZipErr] = useState(false);

    const [state, dispatch] = useReducer(reducer,{
        // options
        name: edit? organizer.name:'',
        firstName: edit? organizer.firstName :'',
        lastName: edit ? organizer.lastName : '',
        courtRegistrationInputNumber: edit ? organizer.courtRegisterInputNumber : '',
        courtRegistrationInputDate: edit ? new Date(organizer.courtRegisterInputDate) : null,
        organizerType: edit ? organizer.organizerType : '',
        organizerTypeOptions: [],
        ordinalNumberInRegisterOfOrganizers: edit? organizer.ordinalNumberInRegisterOfOrganizers: null,
        documentNumberInRegisterOfOrganizers: edit? organizer.documentNumberInRegisterOfOrganizers: null,
        documentDateInRegisterOfOrganizers: edit? new Date(organizer.documentDateInRegisterOfOrganizers): null,
        JIB: edit ? organizer.jib : '',
        PIB: edit ? organizer.pib : '',
        email: edit ? organizer.email : '',
        documentNumber: '',
        documentDate: '',
        phone: edit ? organizer.phone : '',
        website: edit ? organizer.web: null,
        latitude: edit ? organizer.latitude : null,
        longitude: edit ? organizer.longitude : null,

        // options
        filteredMunicipalities: [],
        countries:[],
        entities: [],
        cantons: [],
        municipalities: [],
        // fields
        // country: edit ? organizer.residence.place.country : '',
        // entity: edit &&  !!organizer.residence.place.municipality && !!organizer.residence.place.municipality.entity ? organizer.residence.place.municipality.entity : '',
        // canton: edit ?  !!organizer.residence.place.municipality && !!organizer.residence.place.municipality.canton && organizer.residence.place.municipality.canton :'',
        // municipality: edit && !!organizer.residence.place.municipality ?  organizer.residence.place.municipality : 'gg',
        // place: edit ?  !!organizer.residence.place && organizer.residence.place.name : '',
        // address: edit ?  !!organizer.residence && organizer.residence.address : '',
        // zip: edit ?  !!organizer.residence && organizer.residence.zipCode : '',
        // // disable properties
        // enableEntity: edit ? !!organizer.residence.place.municipality && !!organizer.residence.place.municipality.entity: false,
        // enableCanton: edit ? !!organizer.residence.place.municipality && !!organizer.residence.place.municipality.canton : false,
        // enableMunicipality: edit ? !!organizer.residence.place.municipality: false

    } );

    useEffect(() => {
        axios.get('/organizer-type/all',).then(
            (res)=>{
                let organizerTypeOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'organizerTypeOptions', payload: organizerTypeOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/country/all',).then(
            (res)=>{
                let countriesOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'countries', payload: countriesOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/entity/all',).then(
            (res)=>{
                let entityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'entities', payload: entityOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/canton/all',).then(
            (res)=>{
                let cantonOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'cantons', payload: cantonOptions});
            }).catch((err)=>{
            console.log('err', err)
        });
        axios.get('/municipality/all',).then(
            (res)=>{
                let municipalityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'municipalities', payload: municipalityOptions});
               // dispatch({type:'filteredMunicipalities', payload: municipalityOptions});

            }).catch((err)=>{
            console.log('err', err)
        });

        if(edit){
            dispatch({type:'country', payload: organizer.residence.place.country});
            if( !!organizer.residence.place.municipality && !!organizer.residence.place.municipality.entity ){
                dispatch({type:'entity', payload: organizer.residence.place.municipality.entity});
                dispatch({type:'filteredMunicipalities', payload:  organizer.residence.place.municipality.entity.key !== 'fbih' ? municipalities.filter((el)=>(el.value.entity && el.value.entity.key  === organizer.residence.place.municipality.entity.key)) : []});
            }
            if( !!organizer.residence.place.municipality && !!organizer.residence.place.municipality.canton){
                dispatch({type:'canton', payload: organizer.residence.place.municipality.canton});
                dispatch({type:'filteredMunicipalities', payload: municipalities.filter((el)=>(el.value.canton && el.value.canton.key  === organizer.residence.place.municipality.canton.key))});
                dispatch({type:'municipality', payload: organizer.residence.place.municipality});
            }
        }
    }, []);



    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value})
    }



    function addOrganizer(){
        if (formValidation()){
        let obj = {
            name: state.name,
            created: new Date(),
            courtRegisterInputDate: new Date(state.courtRegistrationInputDate),
            courtRegisterInputNumber: state.courtRegistrationInputNumber,
            organizerType: { id: state.organizerType.id },
            jib: state.JIB,
            pib: state.PIB,
            ordinalNumberInRegisterOfOrganizers: state.ordinalNumberInRegisterOfOrganizers,
            documentNumberInRegisterOfOrganizers: state.documentNumberInRegisterOfOrganizers,
            documentDateInRegisterOfOrganizers: state.documentDateInRegisterOfOrganizers,
            email: state.email,
            phone: state.phone,
            web: state.website,
            residence:{
                address: state.placeOfBirth.address,
                zipCode: state.placeOfBirth.zip,
                place: {
                    name: state.placeOfBirth.place,
                    country: {
                        id: state.placeOfBirth.country.id
                    },
                    municipality: state.placeOfBirth.country.key === 'bih'?{
                        id: state.placeOfBirth.municipality.id,
                        canton: { id: state.placeOfBirth.canton.id },
                        entity: { id: state.placeOfBirth.entity.id }
                    }: null
                }
            },
            latitude: state.latitude,
            longitude: state.longitude

        };

        axios.post('/organizer/save', {...obj}).then(
            (res) => {
                toggleEditForm();
                toast('Uspješno ste dodali organizatora.');
                getOrganizer();
            }).catch((err) => {
            console.log('err', err)
        });
        }
    }

    function formValidation(){
        if (!state.name || !state.organizerType || !state.JIB || !state.courtRegistrationInputNumber
            || !state.courtRegistrationInputDate || !state.email || !state.website || !state.phone || !state.ordinalNumberInRegisterOfOrganizers || !state.documentNumberInRegisterOfOrganizers || !state.documentDateInRegisterOfOrganizers
          //  || !state.country || !state.place || !state.address || !state.zip
        ) {
            toast("Niste unijeli sve podatke!");
            if (!state.name) { setNameErr(true); } else { setNameErr(false); }
            if (!state.organizerType) { setOrganizerTypeErr(true); } else { setOrganizerTypeErr(false); }
            if (!state.JIB) { setJIBErr(true); } else { setJIBErr(false); }
            // if (!state.PIB) { setPIBErr(true); } else { setPIBErr(false); }
            if (!state.documentNumberInRegisterOfOrganizers) { setDocumentNumberInRegisterOfOrganizersErr(true); } else { setDocumentNumberInRegisterOfOrganizersErr(false); }
            if (!state.documentDateInRegisterOfOrganizers) { setDocumentDateInRegisterOfOrganizersErr(true); } else { setDocumentDateInRegisterOfOrganizersErr(false); }
            if (!state.ordinalNumberInRegisterOfOrganizers) { setordinalNumberInRegisterOfOrganizersErr(true); } else { setordinalNumberInRegisterOfOrganizersErr(false); }
            if (!state.courtRegistrationInputNumber) { setCourtRegistrationInputNumberErr(true); } else { setCourtRegistrationInputNumberErr(false); }
            if (!state.courtRegistrationInputDate) { setCourtRegistrationInputDateErr(true); } else { setCourtRegistrationInputDateErr(false); }
            if (!state.website) { setWebsiteErr(true); } else { setWebsiteErr(false); }
            if (!state.email) { setEmailErr(true); } else { setEmailErr(false); }
            if (!state.phone) { setPhoneErr(true); } else { setPhoneErr(false); }
            // if (!state.country) { setCountryErr(true); } else { setCountryErr(false); }
            // if (!state.place) { setPlaceErr(true); } else { setPlaceErr(false); }
            // if (!state.address) { setAddressErr(true); } else { setAddressErr(false); }
            // if (!state.zip) { setZipErr(true); } else { setZipErr(false); }
            return false;
        }
        return true;
    }

    function editOrganizer(){
        if (formValidation()){
            let obj = {
                id: organizer.id,
                optLock: organizer.optLock,
                name: state.name,
                courtRegisterInputDate: state.courtRegistrationInputDate,
                courtRegisterInputNumber: state.courtRegistrationInputNumber,
                organizerType: { id: state.organizerType.id },
                jib: state.JIB,
                pib: state.PIB,
                documentNumberInRegisterOfOrganizers: state.documentNumberInRegisterOfOrganizers,
                documentDateInRegisterOfOrganizers: state.documentDateInRegisterOfOrganizers,
                ordinalNumberInRegisterOfOrganizers: state.ordinalNumberInRegisterOfOrganizers,
                email: state.email,
                phone: state.phone,
                web: state.website,
                residence:{
                    address: state.placeOfBirth.address,
                    zipCode: state.placeOfBirth.zip,
                    place: {
                        name: state.placeOfBirth.place,
                        country: {
                            id: state.placeOfBirth.country.id
                        },
                        municipality: state.placeOfBirth.country.key === 'bih'?{
                            id: state.placeOfBirth.municipality.id,
                            canton: { id: state.placeOfBirth.canton.id },
                            entity: { id: state.placeOfBirth.entity.id }
                        }: null
                    }
                },
                latitude: state.latitude,
                longitude: state.longitude

            };

            axios.put('/organizer/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste izmijenili organizatora.');
                    getOrganizer();
                }).catch((err) => {
                console.log('err', err)
            });
        }


    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    function handlePlaceChange(placeName, obj) {
        dispatch({type: placeName, payload: obj});
    }

    return (
        <div >
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {add?'Kreiranje organizatora':'Izmjena podataka'}
                       <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                           {/*placeholder*/}
                           <Icon/>
                       </span>
                    </Header>
                </Segment>

                <Segment clearing>
                        <Grid columns={2} divided stackable>
                                <GridColumn>
                                    <Form size={'small'} >
                                        <Form.Group widths={'equal'} >

                                            <Form.Field
                                                required
                                                // type={'number'}
                                                error={documentNumberInRegisterOfOrganizersErr}
                                                label="Broj rješenja upisa u registar obrazovanja odraslih"
                                                control={Input}
                                                name="documentNumberInRegisterOfOrganizers"
                                                value={state.documentNumberInRegisterOfOrganizers}
                                                onChange={handleInputChange}
                                                placeholder="Unesite broj"
                                            />
                                            <Form.Field
                                                required
                                                error={documentDateInRegisterOfOrganizersErr}
                                                control={DateTimePicker}
                                                label="Datum rješenja upisa u registar obrazovanja odraslih"
                                                format="D. M. YYYY."
                                                placeholder="Unesite datum"
                                                name="documentDateInRegisterOfOrganizers"
                                                value={state.documentDateInRegisterOfOrganizers}
                                                time={false}
                                                onChange={(e) => handleDateChange('documentDateInRegisterOfOrganizers', e)}
                                                style={{ borderWidth: '0px' }}
                                                //error={openFromDateErr}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={'equal'} >

                                            <Form.Field
                                                required
                                                type={'number'}
                                                error={ordinalNumberInRegisterOfOrganizersErr}
                                                label="Redni broj upisa u registar obrazovanja odraslih"
                                                control={Input}
                                                name="ordinalNumberInRegisterOfOrganizers"
                                                value={state.ordinalNumberInRegisterOfOrganizers}
                                                onChange={handleInputChange}
                                                placeholder="Unesite broj"
                                            />
                                            <Form.Field
                                                required
                                                error={nameErr}
                                                label="Naziv"
                                                control={Input}
                                                name="name"
                                                value={state.name}
                                                onChange={handleInputChange}
                                                placeholder="Unesite naziv organizatora"
                                            />
                                        </Form.Group>

                                        <Form.Group widths={'equal'}>
                                    <Form.Field
                                        required
                                        error={courtRegistrationInputNumberErr}
                                        label="Broj upisa u sudski registar"
                                        control={Input}
                                        name="courtRegistrationInputNumber"
                                        value={state.courtRegistrationInputNumber}
                                        onChange={handleInputChange}
                                        placeholder="Unesite broj"
                                    />
                                        <Form.Field
                                            required
                                            error={courtRegistrationInputDateErr}
                                            control={DateTimePicker}
                                            label="Datum upisa u sudski registar"
                                            format="D. M. YYYY."
                                            placeholder="Unesite datum"
                                            name="courtRegistrationInputDate"
                                            value={state.courtRegistrationInputDate}
                                            time={false}
                                            onChange={(e) => handleDateChange('courtRegistrationInputDate', e)}
                                            style={{ borderWidth: '0px' }}
                                            //error={openFromDateErr}
                                        />
                                        </Form.Group>
                                        <Form.Group widths={'equal'}>
                                            <Form.Field
                                                required
                                                error={JIBErr}
                                                label="JIB"
                                                control={Input}
                                                name="JIB"
                                                type="number"
                                                value={state.JIB}
                                                onChange={handleInputChange}
                                                placeholder="Unesite broj"
                                            />
                                            <Form.Field
                                                // required
                                                // error={PIBErr}
                                                label="PIB"
                                                control={Input}
                                                name="PIB"
                                                type="number"
                                                value={state.PIB}
                                                onChange={handleInputChange}
                                                placeholder="Unesite broj"
                                            />
                                        </Form.Group>
                                        <Form.Group widths={'equal'}>
                                            <Form.Field required  error={organizerTypeErr}>
                                                <label>Tip organizatora</label>
                                                <Form.Dropdown
                                                    clearable
                                                    placeholder="Odaberite tip"
                                                    value={state.organizerType}
                                                    onChange={handleInputChange}
                                                    name="organizerType"
                                                    options={state.organizerTypeOptions}
                                                    search
                                                    fluid
                                                    selection
                                                />
                                            </Form.Field>


                                        <Form.Field
                                            required
                                            error={websiteErr}
                                            label="Web stranica"
                                            control={Input}
                                            name="website"
                                            value={state.website}
                                            onChange={handleInputChange}
                                            placeholder="Unesite url"
                                        />
                                        </Form.Group>

                                        <Form.Group widths={'equal'}>
                                        <Form.Field
                                            required
                                            error={emailErr}
                                            label="E-mail"
                                            control={Input}
                                            name="email"
                                            value={state.email}
                                            onChange={handleInputChange}
                                            placeholder="Unesite e-mail"
                                        />
                                        <Form.Field
                                            required
                                            error={phoneErr}
                                            label="Telefon"
                                            control={Input}
                                            name="phone"
                                            type="number"
                                            value={state.phone}
                                            onChange={handleInputChange}
                                            placeholder="Unesite broj"
                                        />

                                        {/*<Form.Field*/}
                                        {/*    label="Web stranica"*/}
                                        {/*    control={Input}*/}
                                        {/*    name="website"*/}
                                        {/*    value={state.website}*/}
                                        {/*    onChange={handleInputChange}*/}
                                        {/*    placeholder="Unesite url"*/}
                                        {/*/>*/}
                                        </Form.Group>
                                        {/*<Form.Group widths={'equal'}>*/}
                                        {/*<Form.Field*/}
                                        {/*    label="Latitude"*/}
                                        {/*    control={Input}*/}
                                        {/*    name="latitude"*/}
                                        {/*    type="number"*/}
                                        {/*    value={state.latitude}*/}
                                        {/*    onChange={handleInputChange}*/}
                                        {/*    placeholder="Unesite broj"*/}
                                        {/*/>*/}
                                        {/*<Form.Field*/}
                                        {/*    label="Longitude"*/}
                                        {/*    control={Input}*/}
                                        {/*    name="longitude"*/}
                                        {/*    type="number"*/}
                                        {/*    value={state.longitude}*/}
                                        {/*    onChange={handleInputChange}*/}
                                        {/*    placeholder="Unesite broj"*/}
                                        {/*/>*/}
                                        {/*</Form.Group>*/}
                                    </Form>
                                </GridColumn>
                                <GridColumn>

                                    <PlaceForm
                                        oneRowTwoFields
                                        handlePlaceChange={handlePlaceChange}
                                        formName={'placeOfBirth'}
                                        countries={state.countries}
                                        entities={state.entities}
                                        cantons={state.cantons}
                                        municipalities={state.municipalities}
                                    />

                                    {/*<PlaceForm*/}
                                    {/*    size={'small'}*/}
                                    {/*    handleInputChange = {handleInputChange}*/}
                                    {/*    countries = {state.countries}*/}
                                    {/*    country = {state.country}*/}
                                    {/*    enableEntity = {state.enableEntity}*/}
                                    {/*    entities = {state.entities}*/}
                                    {/*    entity = {state.entity}*/}
                                    {/*    enableCanton = {state.enableCanton}*/}
                                    {/*    canton = {state.canton}*/}
                                    {/*    cantons = {state.cantons}*/}
                                    {/*    enableMunicipality = {state.enableMunicipality}*/}
                                    {/*    filteredMunicipalities = {state.filteredMunicipalities}*/}
                                    {/*    municipality = {state.municipality}*/}
                                    {/*    place = {state.place}*/}
                                    {/*    address = {state.address}*/}
                                    {/*    zip = {state.zip}*/}

                                    {/*    countryErr = {countryErr}*/}
                                    {/*    placeErr = {placeErr}*/}
                                    {/*    addressErr = {addressErr}*/}
                                    {/*    zipErr = {zipErr}*/}

                                    {/*/>*/}
                                </GridColumn>

                        </Grid>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addOrganizer: editOrganizer}>{add ? 'Dodaj' : 'Sačuvaj'}</Button>
                </Segment>
            </SegmentGroup>
        </div>
    );
};

export default OrganizerForm;
