import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Button, Form, Header, Icon, Loader, Menu, Segment} from "semantic-ui-react";
import {toast, ToastContainer} from "react-toastify";
import {UserRoleContext} from "../../Main";
import axios from "axios";
import {downloadStudentRegister, extractFileNameFromHeaders} from "../Utilities/Util";
import download from "downloadjs";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import moment from "moment";
import SearchTableData from "../Utilities/SearchTableData";
import PaginationFront from "../Utilities/PaginationFront";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'registryBook':
            return {...state, registryBook: action.payload};
        case 'registryBooks':
            return {...state, registryBooks: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'download':
            return {...state, download: action.payload};
        case 'studentsRegister':
            return {...state, studentsRegister: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'openTable':
            return {...state, openTable: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;

    }
}

const StudentsRegister = () => {
    const {activeRole} = useContext(UserRoleContext);
    const [organizerErr, setOrganizerErr] = useState(false);
    const [registryBookErr, setRegistryBookErr] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [state, dispatch] = useReducer(reducer,{
        registryBook: '',
        registryBooks: [],
        loader: false,
        download: false,
        studentsRegister: [],
        currentId: null,
        openTable: false,
        organizer: '',
        organizers: [],
        data:[],
        results:[],
        postsPerPage: 20,
        currentPosts: [],
    });

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'registryBook' && value){
            dispatch({type: 'openTable', payload: true});
            getStudentsRegister(value.id)
        }
        if(name === 'organizer' && value){
            // dispatch({type: 'openTable', payload: true});
            dispatch({type: 'studentsRegister', payload: []});
            dispatch({type: 'data', payload: []});
            dispatch({type: 'registryBook', payload: ''});
            getRegistryBooksForAdmin(value)
        }
    }

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }


    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    useEffect(() => {
        if (activeRole === "ROLE_ORGANIZER") {getRegistryBooks()}
        else{getOrganizers()}
    },[activeRole]);

    function getRegistryBooks(){
        dispatch({type: 'loader', payload: true});
        axios.get('/registry-book/by-organizer',).then(
            (res)=>{
                let registryBooks = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'registryBooks', payload: registryBooks});
                dispatch({type: 'loader', payload: false});
            }).catch((err)=>{
            console.log('err', err)
        });
    }

    function getRegistryBooksForAdmin(id){
        setCurrentId(id);
        setCurrentIdOrganizer(id);
        dispatch({type: 'loader', payload: true});
        // dispatch({type: 'openTable', payload: true});
        axios.get(`/registry-book/by-organizer-id/${id}`,).then(
            (res) => {
                let registryBooks = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type: 'registryBooks', payload: registryBooks});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getStudentsRegister(id){
        setCurrentId(id);
        dispatch({type: 'openTable', payload: true});
        dispatch({type: 'loader', payload: true});
        axios.get(`/student-study-program/by-registry-book-id/${id}`,).then(
            (res)=>{
                // let studentsRegister = res.data
                let studentsRegister = res.data.map(el => ({
                    ...el,
                    personFirstName:el.student.person.firstName,
                    personLastName:el.student.person.lastName,
                    dateFilter: moment(el.startDate).format('D. M. YYYY.')
                }))
                dispatch({type:'studentsRegister', payload: studentsRegister});
                dispatch({type: 'data', payload: studentsRegister});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err)=>{
            console.log('err', err)
        });
    }

    function downloadAll(){
        if (activeRole !== "ROLE_ORGANIZER" && !state.organizer) {
            toast('Niste odabrali organizatora!');
            if (!state.organizerOccupation) { setOrganizerErr(true); } else { setOrganizerErr(false); }

        }
        else {
            setRegistryBookErr(false)
            if (activeRole === "ROLE_ORGANIZER") {downloadStudentRegister()}
            else {downloadStudentRegisterForAdminAll()}
       }
    }

    function downloadRegister(){
        if (!state.registryBook) {
            toast('Niste odabrali matičnu knjigu!');
            if (!state.organizerOccupation) { setRegistryBookErr(true); } else { setRegistryBookErr(false); }

        } else {
            setRegistryBookErr(false)
            if (activeRole === "ROLE_ORGANIZER") {downloadStudentRegisterForOrganizer()}
            else {downloadStudentRegisterForAdmin()}
        }
    }

    function downloadStudentRegisterForAdminAll(){
        axios({
            method: 'post',
            url: `document/register-of-students-form/${state.organizer}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });

    }

    function downloadStudentRegisterForOrganizer(){
        axios({
            method: 'post',
            url: `document/register-of-students-form-by-registry-book/${state.registryBook.id}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });

    }

    function downloadStudentRegisterForAdmin(){
        axios({
            method: 'post',
            url: `document/register-of-students-form-by-registry-book/${state.organizer}/${state.registryBook.id}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });

    }

    return (
        <div>
            <Segment.Group >
                <Segment className={'customPadding'} clearing color={"teal"}>
                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Registar polaznika </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            <Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" floated={"right"} onClick={downloadAll}>
                                    <Icon name='download' color={"teal"}/>
                                    Preuzmi registar svih polaznika
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Segment>

                {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Segment><Form size={'small'}>
                    <Form.Group widths="3">
                        <Form.Field>
                            <label>Organizator</label>
                            <Form.Dropdown
                                placeholder="Odaberite organizatora"
                                value={state.organizer}
                                onChange={handleInputChange}
                                name="organizer"
                                options={state.organizers}
                                error={activeRole !== "ROLE_ORGANIZER" && organizerErr}
                                search
                                fluid
                                selection
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Matična knjiga</label>
                            <Form.Dropdown
                                placeholder="Odaberite matičnu knjigu"
                                value={state.registryBook}
                                onChange={handleInputChange}
                                name="registryBook"
                                options={state.registryBooks}
                                error={registryBookErr}
                                clearable
                                search
                                fluid
                                selection
                            />
                        </Form.Field>
                    </Form.Group>
                </Form></Segment>}

                {(activeRole === "ROLE_ORGANIZER") && <Segment><Form size={'small'}>
                    <Form.Group widths="4">
                        <Form.Field>
                            <label>Matična knjiga</label>
                            <Form.Dropdown
                                placeholder="Odaberite matičnu knjigu"
                                value={state.registryBook}
                                onChange={handleInputChange}
                                name="registryBook"
                                options={state.registryBooks}
                                error={registryBookErr}
                                clearable
                                search
                                fluid
                                selection
                            />
                        </Form.Field>
                            {/*<Form.Button label=" " basic size={"small"} color={"teal"}*/}
                            {/*             onClick={download}>Preuzmi registar polaznika</Form.Button>*/}
                    </Form.Group>
                </Form></Segment>}

                {(state.openTable) &&  <Segment>
                    {state.loader ?
                        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                        :
                        currentPosts.length ?
                            <Table celled size={'small'}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={9} >
                                            <div style={{ paddingLeft: '0px' }}>
                                                <SearchTableData data={state.data} getResults={getResults} filterItem={['comment', 'numberInRegistryBook', 'personFirstName', 'personLastName', 'dateFilter']} />
                                            </div>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={4}>
                                            <Header as={'h5'} floated={"left"}>
                                                Registar
                                                <Header.Subheader>
                                                    <i>Preuzimanje registra za odabranu matičnu knjigu</i>
                                                </Header.Subheader>
                                            </Header>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell colSpan={1} textAlign={"center"}>
                                            <Button icon labelPosition='left' size="mini" basic color="black" floated={"right"} onClick={downloadRegister}>
                                                <Icon name='download' color={"teal"}/>
                                                Preuzmi registar polaznika
                                            </Button>
                                            {/*<Popup size={"small"}*/}
                                            {/*       content='Preuzmi registar polaznika'*/}
                                            {/*       verticalAlign={"middle"}*/}
                                            {/*       trigger={*/}
                                            {/*           <Button*/}
                                            {/*               // floated={"right"}*/}
                                            {/*               icon*/}
                                            {/*               size={"tiny"}*/}
                                            {/*               basic*/}
                                            {/*               compact*/}
                                            {/*               onClick={downloadStudentRegisterForOrganizer}*/}
                                            {/*           >*/}
                                            {/*               <Icon name='download' color={"teal"} />*/}
                                            {/*           </Button>*/}
                                            {/*       }*/}
                                            {/*/>*/}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                                        <Table.HeaderCell >Prezime i ime polaznika/ice</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Redni broj polaznika/ice u matičnoj knjizi</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Datum upisa</Table.HeaderCell>
                                        <Table.HeaderCell>Napomena</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        currentPosts.map((el, index) =>
                                        // state.studentsRegister.map((el, index)=>
                                            (<Table.Row key={el.id}>
                                                <Table.Cell width={1}>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                                <Table.Cell>{el.student.person.lastName} {el.student.person.firstName}</Table.Cell>
                                                <Table.Cell width={3}>{el.numberInRegistryBook}</Table.Cell>
                                                <Table.Cell width={2}>{moment(el.startDate).format('D. M. YYYY.')}</Table.Cell>
                                                <Table.Cell>{el.comment}</Table.Cell>
                                            </Table.Row>)
                                        )
                                    }
                                </Table.Body>

                                <Table.Footer>
                                    {/*<Table.Row>*/}
                                    {/*    <Table.HeaderCell colSpan='11' textAlign='center'>*/}
                                    {/*        <Menu  pagination>*/}
                                    {/*            <Menu.Item as='a' icon>*/}
                                    {/*                <Icon name='chevron left' />*/}
                                    {/*            </Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>1</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>2</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>3</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>4</Menu.Item>*/}
                                    {/*            <Menu.Item as='a' icon>*/}
                                    {/*                <Icon name='chevron right' />*/}
                                    {/*            </Menu.Item>*/}
                                    {/*        </Menu>*/}
                                    {/*    </Table.HeaderCell>*/}
                                    {/*</Table.Row>*/}
                                </Table.Footer>
                            </Table> : <div>Nema podataka.</div>}
                    {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                </Segment>}
            </Segment.Group>
            <ToastContainer />
        </div>
    );
};

export default StudentsRegister;
