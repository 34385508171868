import React from 'react';
import {Header, Input, SegmentGroup} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {DateTimePicker} from "react-widgets";
import {Link} from "react-router-dom";

const StudentForm = ({handleInputChange, handleDateChange, addStudent, edit, fileNumber, numberInRegistryBook, registryBook, registryBooks, fileDate, startDate,
                         studyType, studyTypes, organizer, organizers, occupation, occupations, studyProgram, studyPrograms,
                         generation, language, languages, comment, occupationTypes, occupationType, financingType, workExperience, showOrganizerField,
                         languageErr, fileNumberErr, numberInRegistryBookErr, registryBookErr,  fileDateErr, startDateErr, studyTypeErr, occupationErr,
                         occupationTypeErr, organizerErr, studyProgramErr, commentErr
                     }) => {

    return (
<>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {'Prijava'}
                    </Header>
                </Segment>

                <Segment clearing>
                    <Form size={'small'}>

                            <Form.Group widths='equal'>
                                <Form.Field
                                    required
                                    error={fileNumberErr}
                                    label="Broj prijave"
                                    control={Input}
                                    name="fileNumber"
                                    value={fileNumber}
                                    onChange={handleInputChange}
                                    placeholder="Unesite broj prijave"
                                />
                                <Form.Field
                                    required
                                    error={fileDateErr}
                                    control={DateTimePicker}
                                    label="Datum podnošenja prijave"
                                    format="D. M. YYYY."
                                    placeholder="Unesite datum"
                                    name="fileDate"
                                    value={fileDate}
                                    //15 dana unazad i jedan dan unaprijed
                                    max={new Date().getTime() + (1000 * 60 *60 *24)}
                                    min={new Date().getTime() - 60*(1000 * 60 *60 *24)}
                                    time={false}
                                    onChange={(e) => handleDateChange('fileDate', e)}
                                    style={{ borderWidth: '0px' }}
                                    //error={openFromDateErr}
                                />
                            </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field required>
                                <label>Jezik</label>
                                <Form.Dropdown
                                    error={languageErr}
                                    clearable
                                    placeholder="Odaberite jezik"
                                    value={language}
                                    onChange={handleInputChange}
                                    name="language"
                                    options={languages.filter((el)=>(el.key === 'bs' || el.key === 'sr' || el.key === 'hr'))}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>
                            <Form.Field
                                required
                                error={startDateErr}
                                control={DateTimePicker}
                                label="Početak obrazovnog procesa"
                                format="D. M. YYYY."
                                placeholder="Unesite datum"
                                name="startDate"
                                value={startDate}
                                //7 dana unazad i 7 dana unaprijed
                                max={new Date().getTime() + 7*(1000 * 60 *60 *24)}
                                min={new Date().getTime() - 7*(1000 * 60 *60 *24)}
                                time={false}
                                onChange={(e) => handleDateChange('startDate', e)}
                                style={{ borderWidth: '0px' }}
                                //error={openFromDateErr}
                            />
                        </Form.Group>
                            <Form.Group widths={showOrganizerField?'equal':'2'}>
                                {showOrganizerField && <Form.Field required error={organizerErr}>
                                    <label>Organizator</label>
                                    <Form.Dropdown
                                        clearable
                                        placeholder="Odaberite organizatora"
                                        value={organizer}
                                        onChange={handleInputChange}
                                        name="organizer"
                                        options={organizers}
                                        search
                                        fluid
                                        selection
                                    />
                                </Form.Field>}
                                <Form.Field required>
                                    <label>Vrsta obrazovanja</label>
                                    <Form.Dropdown
                                        error={studyTypeErr}
                                        clearable
                                        placeholder="Odaberite vrstu"
                                        value={studyType}
                                        onChange={handleInputChange}
                                        name="studyType"
                                        options={studyTypes}
                                        search
                                        fluid
                                        selection
                                    />
                                </Form.Field>


                            </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field required>
                                <label>Zanimanje</label>
                                <Form.Dropdown
                                    error={occupationErr}
                                    clearable
                                    placeholder="Odaberite zanimanje"
                                    value={occupation}
                                    onChange={handleInputChange}
                                    name="occupation"
                                    options={occupations}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>Nastavni program</label>
                                <Form.Dropdown
                                    error={studyProgramErr}
                                    clearable
                                    placeholder="Odaberite npp"
                                    value={studyProgram}
                                    onChange={handleInputChange}
                                    name="studyProgram"
                                    options={studyPrograms}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Form.Dropdown
                                required
                                error={registryBookErr}
                                label="Matična knjiga"
                                clearable
                                placeholder="Odaberite matičnu knjigu"
                                value={registryBook}
                                onChange={handleInputChange}
                                name="registryBook"
                                options={registryBooks}
                                search
                                fluid
                                selection
                            />
                            <Form.Field
                                required
                                disabled={!registryBook}
                                error={numberInRegistryBookErr}
                                label="Broj u matičnoj knjizi"
                                control={Input}
                                type="number"
                                name="numberInRegistryBook"
                                value={numberInRegistryBook}
                                onChange={handleInputChange}
                                placeholder="Unesite broj u matičnoj knjizi"
                            />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field required error={occupationTypeErr}>
                                <label>Radni status</label>
                                <Form.Dropdown
                                    clearable
                                    placeholder="Odaberite tip"
                                    value={occupationType}
                                    onChange={handleInputChange}
                                    name="occupationType"
                                    options={occupationTypes}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>

                            <Form.Field
                                error={commentErr}
                                label="Radno iskustvo"
                                control={Input}
                                name="workExperience"
                                value={workExperience}
                                onChange={handleInputChange}
                                placeholder="Unesite iskustvo"
                            />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field
                                error={commentErr}
                                label="Način finansiranja"
                                control={Input}
                                name="financingType"
                                value={financingType}
                                onChange={handleInputChange}
                                placeholder="Unesite način"
                            />

                                <Form.Field
                                    error={commentErr}
                                    label="Napomena"
                                    control={Input}
                                    name="comment"
                                    value={comment}
                                    onChange={handleInputChange}
                                    placeholder="Unesite napomenu"
                                />
                            </Form.Group>


                           </Form>
                </Segment>
            </SegmentGroup>


</>
    );
};

export default StudentForm;
