import React, {useContext, useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Form, Header, Icon, Input, SegmentGroup, Select} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {UserRoleContext} from "../../Main";
import axios from "axios";
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'courseType':
            return {...state, courseType: action.payload};
        case 'grades':
            return {...state, grades: action.payload};
        case 'grade':
            return {...state, grade: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'courses':
            return {...state, courses: action.payload};
        case 'course':
            return {...state, course: action.payload};
        case 'durationHours':
            return {...state, durationHours: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        default:
            return state;

    }
}

const CourseStudyProgramCoursesForm = ({add, toggleEditForm, getCourses, edit, course, duration, formal, addedCourses}) => {
    const {activeRole} = useContext(UserRoleContext);
    const location = useLocation();
    const {organizerId, studyProgramId} = location.state;
    const [courseErr, setCourseErr] = useState(false);
    const [gradeErr, setGradeErr] = useState(false);
    const [state, dispatch] = useReducer(reducer,{
        grades: [],
        grade:  course && course.grade ? course.grade.id : null,
        loader: false,
        durationHours: course ? course.durationHours : null,
        course: course && course.course ? course.course.id : '',
        currentId: null,
        courses: [],
        courseType: true
    });

    const courseTypeOptions = [{key: 1, value: true, text: 'obavezni'}, {key: 2, value: false, text: 'izborni'}]

    useEffect(() => {
        if (activeRole !== "ROLE_ORGANIZER") {getCoursesByOrganizer(organizerId);}
        if (activeRole === "ROLE_ORGANIZER") {getCoursesForOrganizer();}
        // getGrades();
    },[activeRole, organizerId]);

    useEffect(() => {
        getGrades();
    },[duration]);

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }
    function resetForm() {
        dispatch({type: 'durationHours', payload: ''});
        dispatch({type: 'course', payload: null});
        dispatch({type: 'grade', payload: null});
        dispatch({type: 'courseType', payload: true});
    }

    function getCoursesByOrganizer(id){
        setCurrentId(id);
        dispatch({type: 'loader', payload: true});
        axios.get(`/course/by-organizer-id/${id}`,).then(
            (res) => {
                let courses = res.data.map((el)=>({...el, key: el.id, text:el.nameBosnian + ' | ' + el.nameSerbian + ' | ' + el.nameCroatian, value: el.id}));;
                dispatch({type: 'courses', payload: courses});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getCoursesForOrganizer(){
        dispatch({type: 'loader', payload: true});
        axios.get('/course/by-organizer',).then(
            (res) => {
                let courses = res.data.map((el)=>({...el, key: el.id, text:el.nameBosnian + ' | ' + el.nameSerbian + ' | ' + el.nameCroatian, value: el.id}));
                dispatch({type: 'courses', payload: courses});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getGrades(){
        dispatch({type: 'loader', payload: true});
        axios.get('/grade/all',).then(
            (res) => {
                let grades = res.data.sort((a, b) => a.number - b.number).filter((e)=>e.number<=duration).map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'grades', payload: grades});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function editCourse(){
        if (!state.course || (formal && !state.grade)) {
            toast('Niste unijeli sve podatke.');
            if (!state.course) { setCourseErr(true); } else { setCourseErr(false); }
            if (!state.grade) { setGradeErr(true); } else { setGradeErr(false); }
        } else {
            let obj = {
                id: course.id,
                optLock: course.optLock,
                course: {id: state.course},
                studyProgram: {id: studyProgramId},
                archived: 0,
                grade: formal ?  {id: state.grade}: null,
                durationHours: state.durationHours?state.durationHours: null,
                mandatory: state.courseType
            };
            axios.put('/course-study-program/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste izmijenili predmet.');

                    getCourses(studyProgramId);
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function addCourse(){
        if (!state.course || (formal && !state.grade)) {
            toast('Niste unijeli sve podatke.');
            if (!state.course) { setCourseErr(true); } else { setCourseErr(false); }
            if (!state.grade) { setGradeErr(true); } else { setGradeErr(false); }
        } else {
            let obj = {
                created: new Date(),
                course: {id: state.course},
                studyProgram: {id: studyProgramId},
                archived: 0,
                durationHours: state.durationHours?state.durationHours: null,
                grade: formal ?  {id: state.grade}: null,
                mandatory: formal ? state.courseType: null

            };
            axios.post('/course-study-program/save', {...obj}).then(
                (res) => {
                    // toggleEditForm();
                    resetForm();
                    toast('Uspješno ste dodali predmet.');
                    getCourses(studyProgramId);
                }).catch((err) => {
                console.log(err);
                if (err.response.data.message === 'This course is already added for passed study program!') {
                    toast('Odabrani predmet je već dodan.');
                } else {
                    toast('Došlo je do greške! Dodavanje predmeta nije uspjelo.');
                }
            });
        }
    }
    {/*.filter((el)=>(!addedCourses.find((e)=>(el.id === e.course.id))))*/}
    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {add ?'Dodavanje predmeta / modula / ispita na program':'Izmjena podataka'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths={'equal'}>

                            <Form.Field
                                required
                                error={courseErr}
                                control={Select}
                                clearable
                                options={state.courses}
                                placeholder="Odaberite predmet"
                                label="Predmet / modul / ispit (bosanski / srpski / hrvatski)"
                                onChange={handleInputChange}
                                name="course"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={state.course}
                                search
                            />
                            {formal && <Form.Field
                                required
                                error={gradeErr}
                                control={Select}
                                clearable
                                options={state.grades}
                                placeholder="Odaberite razred"
                                label={"Razred"}
                                onChange={handleInputChange}
                                name="grade"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={state.grade}
                                search
                            />
                            }

                            {formal && <Form.Field
                                required
                                control={Select}
                                options={courseTypeOptions}
                                placeholder="Odaberite tip"
                                label={"Tip predmeta"}
                                onChange={handleInputChange}
                                name="courseType"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={state.courseType}
                                search
                            />
                            }

                            <Form.Field
                                label={"Trajanje (sati)"}
                                required={!formal}
                                control={Input}
                                type="number"
                                placeholder="Unesite sate"
                                value={state.durationHours}
                                name="durationHours"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />


                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addCourse : editCourse}>{add ? 'Dodaj' : 'Sačuvaj'}</Button>
                    </Form>
                </Segment>
            </SegmentGroup>
        </div>
    );
};

export default CourseStudyProgramCoursesForm;
