import React, {useEffect, useReducer, useState} from 'react';
import {GridColumn, Header, Icon, Input, SegmentGroup} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import PlaceForm from "../Utilities/PlaceForm";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {DateTimePicker} from "react-widgets";

const reducer = (state, action)=>{
    switch (action.type){
        case 'name':
            return {...state, name: action.payload};
        case 'firstName':
            return {...state, firstName: action.payload };
        case 'lastName':
            return {...state, lastName: action.payload };
        case 'courtRegistrationInputNumber':
            return {...state, courtRegistrationInputNumber: action.payload };
        case 'courtRegistrationInputDate':
            return {...state, courtRegistrationInputDate: action.payload };
        case 'organizerType':
            return {...state, organizerType: action.payload };
        case 'organizerTypeOptions':
            return {...state, organizerTypeOptions: action.payload };
        case 'JIB':
            return {...state, JIB: action.payload};
        case 'PIB':
            return {...state, PIB: action.payload };
        case 'email':
            return {...state, email: action.payload };
        case 'ordinalNumberInRegisterOfOrganizers':
            return {...state, ordinalNumberInRegisterOfOrganizers: action.payload };
        case 'documentNumberInRegisterOfOrganizers':
            return {...state, documentNumberInRegisterOfOrganizers: action.payload };
        case 'documentDateInRegisterOfOrganizers':
            return {...state, documentDateInRegisterOfOrganizers: action.payload };
        case 'documentNumber':
            return {...state, documentNumber: action.payload };
        case 'phone':
            return {...state, phone: action.payload };
        case 'website':
            return {...state, website: action.payload };
        case 'latitude':
            return {...state, latitude: action.payload };
        case 'longitude':
            return {...state, longitude: action.payload };
        default:
            return state;
    }
};


const OrganizerEditForm = ({toggleEditForm, add, edit, getOrganizer, organizer}) => {
    const [nameErr, setNameErr] = useState(false);
    const [organizerTypeErr, setOrganizerTypeErr] = useState(false);
    const [ordinalNumberInRegisterOfOrganizersErr, setOrdinalNumberInRegisterOfOrganizersErr] = useState(false);
    const [documentNumberInRegisterOfOrganizersErr, setDocumentNumberInRegisterOfOrganizersErr] = useState(false);
    const [documentDateInRegisterOfOrganizersErr, setDocumentDateInRegisterOfOrganizersErr] = useState(false);
    const [JIBErr, setJIBErr] = useState(false);
    // const [PIBErr, setPIBErr] = useState(false);
    const [courtRegistrationInputNumberErr, setCourtRegistrationInputNumberErr] = useState(false);
    const [courtRegistrationInputDateErr, setCourtRegistrationInputDateErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [phoneErr, setPhoneErr] = useState(false);

    const [state, dispatch] = useReducer(reducer,{
        // options
        name: edit ? organizer.name:'',
        firstName: edit ? organizer.firstName :'',
        lastName: edit ? organizer.lastName : '',
        courtRegistrationInputNumber: edit ? organizer.courtRegisterInputNumber : '',
        courtRegistrationInputDate: edit ? new Date(organizer.courtRegisterInputDate) : null,
        organizerType: edit ? organizer.organizerType : '',
        organizerTypeOptions: [],
        JIB: edit ? organizer.jib : '',
        PIB: edit ? organizer.pib : '',
        ordinalNumberInRegisterOfOrganizers: edit? organizer.ordinalNumberInRegisterOfOrganizers: null,
        documentNumberInRegisterOfOrganizers: edit? organizer.documentNumberInRegisterOfOrganizers: null,
        documentDateInRegisterOfOrganizers: edit? new Date(organizer.documentDateInRegisterOfOrganizers): null,
        email: edit ? organizer.email : '',
        documentNumber: '',
        documentDate: '',
        phone: edit ? organizer.phone : '',
        website: edit ? organizer.web: null,
        latitude: edit ? organizer.latitude : null,
        longitude: edit ? organizer.longitude : null,

    } );

    useEffect(() => {
        axios.get('/organizer-type/all',).then(
            (res)=>{
                let organizerTypeOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'organizerTypeOptions', payload: organizerTypeOptions});
            }).catch((err)=>{
            console.log('err', err)
        });

    }, []);


    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value})
    }

    function formValidation(){
        if (!state.name || !state.organizerType || !state.JIB || !state.courtRegistrationInputNumber
            || !state.courtRegistrationInputDate || !state.email || !state.phone || !state.ordinalNumberInRegisterOfOrganizers || !state.documentNumberInRegisterOfOrganizers || !state.documentDateInRegisterOfOrganizers) {
            toast("Niste unijeli sve podatke!");
            if (!state.name) { setNameErr(true); } else { setNameErr(false); }
            if (!state.organizerType) { setOrganizerTypeErr(true); } else { setOrganizerTypeErr(false); }
            if (!state.JIB) { setJIBErr(true); } else { setJIBErr(false); }
            // if (!state.PIB) { setPIBErr(true); } else { setPIBErr(false); }
            if (!state.ordinalNumberInRegisterOfOrganizers) { setOrdinalNumberInRegisterOfOrganizersErr(true); } else { setOrdinalNumberInRegisterOfOrganizersErr(false); }
            if (!state.documentNumberInRegisterOfOrganizers) { setDocumentNumberInRegisterOfOrganizersErr(true); } else { setDocumentNumberInRegisterOfOrganizersErr(false); }
            if (!state.documentDateInRegisterOfOrganizers) { setDocumentDateInRegisterOfOrganizersErr(true); } else { setDocumentDateInRegisterOfOrganizersErr(false); }
            if (!state.courtRegistrationInputNumber) { setCourtRegistrationInputNumberErr(true); } else { setCourtRegistrationInputNumberErr(false); }
            if (!state.courtRegistrationInputDate) { setCourtRegistrationInputDateErr(true); } else { setCourtRegistrationInputDateErr(false); }
            if (!state.email) { setEmailErr(true); } else { setEmailErr(false); }
            if (!state.phone) { setPhoneErr(true); } else { setPhoneErr(false); }
            return false;
        }
        return true;
    }

    function editOrganizer(){
        if (formValidation()) {
            let obj = {
                id: organizer.id,
                optLock: organizer.optLock,
                name: state.name,
                courtRegisterInputDate: state.courtRegistrationInputDate,
                courtRegisterInputNumber: state.courtRegistrationInputNumber,
                organizerType: {id: state.organizerType.id},
                jib: state.JIB,
                pib: state.PIB? state.PIB: 0,
                ordinalNumberInRegisterOfOrganizers: state.ordinalNumberInRegisterOfOrganizers,
                documentNumberInRegisterOfOrganizers: state.documentNumberInRegisterOfOrganizers,
                documentDateInRegisterOfOrganizers: state.documentDateInRegisterOfOrganizers,
                email: state.email,
                phone: state.phone,
                web: state.website,
                residence: {
                    address: organizer.residence.address,
                    zipCode: organizer.residence.zipCode,
                    place: {
                        name: organizer.residence.place.name,
                        country: {
                            id: organizer.residence.place.country.id
                        },
                        municipality: organizer.residence.place.municipality ? {
                            id: organizer.residence.place.municipality.id,
                            canton: organizer.residence.place.municipality.canton ? {id: organizer.residence.place.municipality.canton.id} : null,
                            entity: organizer.residence.place.municipality.entity ? {id: organizer.residence.place.municipality.entity.id} : null
                        } : null
                    }
                },
                latitude: state.latitude,
                longitude: state.longitude

            };

            axios.put('/organizer/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste izmijenili organizatora.');
                    getOrganizer();
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    return (
        <div >
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {'Izmjena podataka'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            {/*placeholder*/}
                            <Icon/>
                       </span>
                    </Header>
                </Segment>

                <Segment clearing>
                            <Form size={'small'} >
                                <Form.Group widths={'equal'} >
                                    <Form.Field
                                        required
                                        // type={'number'}
                                        error={documentNumberInRegisterOfOrganizersErr}
                                        label="Broj rješenja upisa u registar obrazovanja odraslih"
                                        control={Input}
                                        name="documentNumberInRegisterOfOrganizers"
                                        value={state.documentNumberInRegisterOfOrganizers}
                                        onChange={handleInputChange}
                                        placeholder="Unesite broj"
                                    />
                                    <Form.Field
                                        required
                                        error={documentDateInRegisterOfOrganizersErr}
                                        control={DateTimePicker}
                                        label="Datum rješenja upisa u registar obrazovanja odraslih"
                                        format="D. M. YYYY."
                                        placeholder="Unesite datum"
                                        name="documentDateInRegisterOfOrganizers"
                                        value={state.documentDateInRegisterOfOrganizers}
                                        time={false}
                                        onChange={(e) => handleDateChange('documentDateInRegisterOfOrganizers', e)}
                                        style={{ borderWidth: '0px' }}
                                        //error={openFromDateErr}
                                    />

                                </Form.Group>
                                <Form.Group widths={'equal'} >
                                    <Form.Field
                                        required
                                        type={'number'}
                                        error={ordinalNumberInRegisterOfOrganizersErr}
                                        label="Redni broj upisa u registar obrazovanja odraslih"
                                        control={Input}
                                        name="ordinalNumberInRegisterOfOrganizers"
                                        value={state.ordinalNumberInRegisterOfOrganizers}
                                        onChange={handleInputChange}
                                        placeholder="Unesite broj"
                                    />
                                    <Form.Field
                                        required
                                        error={nameErr}
                                        label="Naziv"
                                        control={Input}
                                        name="name"
                                        value={state.name}
                                        onChange={handleInputChange}
                                        placeholder="Unesite naziv"
                                    />

                                </Form.Group>
                                <Form.Group widths={'equal'}>
                                    <Form.Field
                                        required
                                        error={JIBErr}
                                        label="JIB"
                                        control={Input}
                                        name="JIB"
                                        type="number"
                                        value={state.JIB}
                                        onChange={handleInputChange}
                                        placeholder="Unesite broj"
                                    />
                                    <Form.Field
                                        // required
                                        // error={PIBErr}
                                        label="PIB"
                                        control={Input}
                                        name="PIB"
                                        type="number"
                                        value={state.PIB}
                                        onChange={handleInputChange}
                                        placeholder="Unesite broj"
                                    />
                                </Form.Group>

                                <Form.Group widths={'equal'}>
                                    <Form.Field
                                        required
                                        error={courtRegistrationInputNumberErr}
                                        label="Broj upisa u sudski registar"
                                        control={Input}
                                        name="courtRegistrationInputNumber"
                                        value={state.courtRegistrationInputNumber}
                                        onChange={handleInputChange}
                                        placeholder="Unesite broj"
                                    />
                                    <Form.Field
                                        required
                                        error={courtRegistrationInputDateErr}
                                        control={DateTimePicker}
                                        label="Datum upisa u sudski registar"
                                        format="D. M. YYYY."
                                        placeholder="Unesite datum"
                                        name="courtRegistrationInputDate"
                                        value={state.courtRegistrationInputDate}
                                        time={false}
                                        onChange={(e) => handleDateChange('courtRegistrationInputDate', e)}
                                        style={{ borderWidth: '0px' }}
                                        //error={openFromDateErr}
                                    />
                                </Form.Group>
                                <Form.Group widths={'equal'}>
                                    <Form.Field
                                        required
                                        error={emailErr}
                                        label="E-mail"
                                        control={Input}
                                        name="email"
                                        value={state.email}
                                        onChange={handleInputChange}
                                        placeholder="Unesite e-mail"
                                    />
                                    <Form.Field
                                        required
                                        error={phoneErr}
                                        label="Telefon"
                                        control={Input}
                                        name="phone"
                                        value={state.phone}
                                        onChange={handleInputChange}
                                        placeholder="Unesite broj"
                                    />

                                    {/*<Form.Field*/}
                                    {/*    label="Web stranica"*/}
                                    {/*    control={Input}*/}
                                    {/*    name="website"*/}
                                    {/*    value={state.website}*/}
                                    {/*    onChange={handleInputChange}*/}
                                    {/*    placeholder="Unesite url"*/}
                                    {/*/>*/}
                                </Form.Group>
                                <Form.Group widths={'equal'}>
                                    <Form.Field required  error={organizerTypeErr}>
                                        <label>Tip organizatora</label>
                                        <Form.Dropdown
                                            clearable
                                            placeholder="Odaberite tip"
                                            value={state.organizerType}
                                            onChange={handleInputChange}
                                            name="organizerType"
                                            options={state.organizerTypeOptions}
                                            search
                                            fluid
                                            selection
                                        />
                                    </Form.Field>

                                    <Form.Field
                                        label="Web stranica"
                                        control={Input}
                                        name="website"
                                        value={state.website}
                                        onChange={handleInputChange}
                                        placeholder="Unesite url"
                                    />
                                </Form.Group>
                                {/*<Form.Group widths={'equal'}>*/}
                                {/*    <Form.Field*/}
                                {/*        label="Latitude"*/}
                                {/*        control={Input}*/}
                                {/*        name="latitude"*/}
                                {/*        type="number"*/}
                                {/*        value={state.latitude}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        placeholder="Unesite broj"*/}
                                {/*    />*/}
                                {/*    <Form.Field*/}
                                {/*        label="Longitude"*/}
                                {/*        control={Input}*/}
                                {/*        name="longitude"*/}
                                {/*        type="number"*/}
                                {/*        value={state.longitude}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        placeholder="Unesite broj"*/}
                                {/*    />*/}
                                {/*</Form.Group>*/}
                            </Form>

                    <Button floated={"right"} basic size={"small"} color={"teal"} onClick={editOrganizer}>Sačuvaj</Button>
                </Segment>
            </SegmentGroup>
        </div>
    );
};

export default OrganizerEditForm;
