import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Button, Form, Header, Icon, Loader, Menu, Segment} from "semantic-ui-react";
import axios from "axios";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import ContestForm from "./ContestForm";
import moment from "moment";
import {toast, ToastContainer} from 'react-toastify';
import {UserRoleContext} from "../../Main";
import SearchTableData from "../Utilities/SearchTableData";
import ReactExport from 'react-data-export';
import PaginationFront from "../Utilities/PaginationFront";


const reducer = (state, action)=> {
    switch (action.type) {
        case 'contests':
            return {...state, contests: action.payload};
        case 'contestsData':
            return {...state, contestsData: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'contest':
            return {...state, contest: action.payload};
        case 'openTable':
            return {...state, openTable: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'currentIdOrganizer':
            return {...state, currentIdOrganizer: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;

    }
}

const Contests = () => {
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [activePage, setActivePage] = useState(1);

    const [state, dispatch] = useReducer(reducer,{
        contests: [],
        contestsData: [],
        currentId: null,
        contest: '',
        organizer: null,
        organizers: [],
        openTable: false,
        loader: false,
        currentIdOrganizer: null,
        data:[],
        results:[],
        postsPerPage: 20,
        currentPosts: [],
    });

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    const columns = [
        { title: 'Nastavni program', width: { wpx: 250 } },
        { title: 'Zanimanje', width: { wpx: 250 } },
        { title: 'Datum otvaranja konkursa', width: { wpx: 200 } },
        { title: 'Datum zatvaranja konkursa', width: { wpx: 200 } },
        { title: 'Datum kreiranja konkursa', width: { wpx: 200 } },
        { title: 'Mjesto objavljivanja konkursa', width: { wpx: 300 } },
    ];

    if (activeRole !== "ROLE_ORGANIZER") {
        columns.unshift({ title: 'Organizator', width: { wpx: 250 } });
    }

    const multiDataSet = [
        {
            columns: columns,
            data: state.contestsData,
        }
    ];

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            dispatch({type: 'openTable', payload: true});
            getContestsForAdmin(value)
        }
        if(name === 'organizer' && !value){
            dispatch({type: 'openTable', payload: true});
            getContestsAll()
        }
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function deleteContest(id){
        setCurrentId(id);
        dispatch({type: 'loader', payload: true});
        axios.delete(`/contest/delete/${id}`,).then(
            (res) => {
                activeRole === "ROLE_ORGANIZER" ? getContests() : getContestsForAdmin(state.organizer);
                // localStorage.getItem('activeRole').substr(0,10) === 'ROLE_ADMIN' && getContestsForAdmin(state.organizer);
                // getContests();
                toast('Uspješno ste izbrisali konkurs!');
                setCurrentId(null);
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    useEffect(() => {
        if (activeRole === "ROLE_ORGANIZER") {
            getContests()
        }
        else if (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") {
            getOrganizers();
            getContestsAll();
        }
    },[activeRole]);

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getContests(){
        dispatch({type: 'loader', payload: true});
        axios.get('/contest/by-organizer',).then(
            (res) => {
                let occupations = res.data.map(el => ({
                    ...el,
                    studyProgramName:el.studyProgram.name,
                    dateToFilter: moment(el.openToDate).format('D. M. YYYY.'),
                    dateFromFilter: moment(el.openFromDate).format('D. M. YYYY.'),
                    nameB:el.studyProgram.organizerOccupation.occupation.nameBosnian
                }))
                // let occupations = res.data;
                dispatch({type: 'contests', payload: occupations});
                dispatch({type: 'data', payload: occupations});
                let contestsData = res.data.map(el => ([
                    { value: el.studyProgram.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value: moment(el.openFromDate).format('D. M. YYYY.') },
                    { value: moment(el.openToDate).format('D. M. YYYY.')},
                    { value: moment(el.created).format('D. M. YYYY.') },
                    { value: el.placeOfPublication },
                ]));
                dispatch({type: 'contestsData', payload: contestsData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getContestsAll(){
        dispatch({type: 'loader', payload: true});
        axios.get('/contest/all',).then(
            (res) => {
                let occupations = res.data.map(el => ({
                    ...el,
                    studyProgramName:el.studyProgram.name,
                    dateToFilter: moment(el.openToDate).format('D. M. YYYY.'),
                    dateFromFilter: moment(el.openFromDate).format('D. M. YYYY.'),
                    nameB:el.studyProgram.organizerOccupation.occupation.nameBosnian
                }))
                // let occupations = res.data;
                dispatch({type: 'contests', payload: occupations});
                dispatch({type: 'data', payload: occupations});
                let contestsData = res.data.map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name },
                    { value: el.studyProgram.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value: moment(el.openFromDate).format('D. M. YYYY.') },
                    { value: moment(el.openToDate).format('D. M. YYYY.')},
                    { value: moment(el.created).format('D. M. YYYY.') },
                    { value: el.placeOfPublication },
                ]));
                dispatch({type: 'contestsData', payload: contestsData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getContestsForAdmin(id){
        setCurrentId(id);
        setCurrentIdOrganizer(id);
        dispatch({type: 'loader', payload: true});
        dispatch({type: 'openTable', payload: true});
        axios.get(`/contest/by-organizer-id/${id}`,).then(
            (res) => {
                let occupations = res.data.map(el => ({
                    ...el,
                    organizerName:el.studyProgram.organizerOccupation.organizer.name,
                    studyProgramName:el.studyProgram.name,
                    dateToFilter: moment(el.openToDate).format('D. M. YYYY.'),
                    dateFromFilter: moment(el.openFromDate).format('D. M. YYYY.'),
                    nameB:el.studyProgram.organizerOccupation.occupation.nameBosnian
                }))
                // let occupations = res.data;
                dispatch({type: 'contests', payload: occupations});
                // dispatch({type: 'loader', payload: false});
                dispatch({type: 'data', payload: occupations});
                let contestsData = res.data.map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name },
                    { value: el.studyProgram.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value: moment(el.openFromDate).format('D. M. YYYY.') },
                    { value: moment(el.openToDate).format('D. M. YYYY.')},
                    { value: moment(el.created).format('D. M. YYYY.') },
                    { value: el.placeOfPublication },
                ]));
                dispatch({type: 'contestsData', payload: contestsData});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Spisak konkursa </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            {(!showEditForm && activeRole !== "ROLE_MINISTRY") &&  <Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Kreiraj konkurs
                                </Button>
                            </Menu.Item>}
                            <Menu.Item>
                                <ExcelFile filename="Spisak konkursa" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={multiDataSet} name="Konkursi" />
                                </ExcelFile>
                            </Menu.Item>
                            <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>

                </Segment>

                {showAddForm &&
                <Segment>
                    <ContestForm add toggleEditForm={toggleAddForm} getContest={getContests} getContestsForAdmin={getContestsForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer}/>
                </Segment>}

                {showEditForm &&
                <Segment>
                    <ContestForm edit toggleEditForm={toggleEditForm} getContest={getContests} contest={state.contest} getContestsForAdmin={getContestsForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer}/>
                </Segment>}

                {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Segment><Form size={'small'}>
                    <Form.Group widths="3">
                        <Form.Field>
                            <label>Organizator</label>
                            <Form.Dropdown
                                placeholder="Odaberite organizatora"
                                value={state.organizer}
                                onChange={handleInputChange}
                                name="organizer"
                                options={state.organizers}
                                search
                                fluid
                                selection
                                clearable
                            />
                        </Form.Field>
                    </Form.Group>
                </Form></Segment>}
                <Segment>
                    {state.loader ?
                        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                        :
                        currentPosts.length ?
                        <Table celled size={'small'}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={9} >
                                        <div style={{ paddingLeft: '0px' }}>
                                            <SearchTableData data={state.data} getResults={getResults} filterItem={['placeOfPublication', 'studyProgramName', 'organizerName', 'dateToFilter', 'dateFromFilter', 'nameB']} />
                                        </div>
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>

                                    <Table.HeaderCell >R. br.</Table.HeaderCell>
                                    {activeRole !== "ROLE_ORGANIZER"  &&<Table.HeaderCell colSpan={2} >Organizator</Table.HeaderCell>}
                                    <Table.HeaderCell >Nastavni program/Zanimanje</Table.HeaderCell>
                                    <Table.HeaderCell >Datum otvaranja konkursa</Table.HeaderCell>
                                    <Table.HeaderCell >Datum zatvaranja konkursa</Table.HeaderCell>
                                    <Table.HeaderCell >Datum kreiranja konkursa</Table.HeaderCell>
                                    <Table.HeaderCell >Mjesto objavljivanja konkursa</Table.HeaderCell>
                                    {activeRole !== "ROLE_MINISTRY" &&<Table.HeaderCell width={1} />}
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    currentPosts.map((el, index) =>
                                    // state.contests.map((el, index)=>
                                        (<Table.Row key={el.id}>
                                            <Table.Cell>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                            {activeRole !== "ROLE_ORGANIZER"  &&<Table.Cell colSpan={2}>{el.studyProgram.organizerOccupation.organizer.name}</Table.Cell>}
                                            <Table.Cell><strong>{el.studyProgram.name}</strong> | {el.studyProgram.organizerOccupation.occupation.nameBosnian}</Table.Cell>
                                            <Table.Cell>{moment(el.openFromDate).format('D. M. YYYY.')}</Table.Cell>
                                            <Table.Cell>{moment(el.openToDate).format('D. M. YYYY.')}</Table.Cell>
                                            <Table.Cell>{moment(el.created).format('D. M. YYYY.')}</Table.Cell>
                                            <Table.Cell>{el.placeOfPublication}</Table.Cell>
                                            {activeRole !== "ROLE_MINISTRY" && <Table.Cell width={1} verticalAlign="middle" textAlign="center">
                                                <Popup size={"small"} content='Izmijeni konkurs' trigger={
                                                    <Button icon size={"mini"} basic color={"grey"} onClick={() => {setShowEditForm(true); dispatch({type: 'contest', payload: el})}}>
                                                        <Icon name='pencil' color={"teal"} />
                                                    </Button>} />
                                                <Popup size={"small"} content='Obriši konkurs' trigger={
                                                    <Button icon size={"mini"} basic color={"grey"} disabled={state.currentId && el.id !== state.currentId } onClick={() => {deleteContest(el.id)}}>
                                                        <Icon name='trash alternate outline' color={"red"} />
                                                    </Button>} />
                                            </Table.Cell>}
                                        </Table.Row>)
                                    )
                                }


                            </Table.Body>

                            <Table.Footer>
                                {/*<Table.Row>*/}
                                {/*    <Table.HeaderCell colSpan='11' textAlign='center'>*/}
                                {/*        <Menu  pagination>*/}
                                {/*            <Menu.Item as='a' icon>*/}
                                {/*                <Icon name='chevron left' />*/}
                                {/*            </Menu.Item>*/}
                                {/*            <Menu.Item as='a'>1</Menu.Item>*/}
                                {/*            <Menu.Item as='a'>2</Menu.Item>*/}
                                {/*            <Menu.Item as='a'>3</Menu.Item>*/}
                                {/*            <Menu.Item as='a'>4</Menu.Item>*/}
                                {/*            <Menu.Item as='a' icon>*/}
                                {/*                <Icon name='chevron right' />*/}
                                {/*            </Menu.Item>*/}
                                {/*        </Menu>*/}
                                {/*    </Table.HeaderCell>*/}
                                {/*</Table.Row>*/}
                            </Table.Footer>
                        </Table> : <div>Nema unesenih podataka o konkursima.</div>}
                    {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                </Segment>
            </Segment.Group>
            <ToastContainer  />
        </div>
    );
};

export default Contests;
