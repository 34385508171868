import React, {useEffect, useReducer} from 'react';
import {Button, Header, Icon, Loader, Menu, Segment} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import moment from "moment";
import {extractFileNameFromHeaders} from "../../Utilities/Util";
import download from "downloadjs";
const reducer = (state, action)=> {
    switch (action.type) {
        case 'marks':
            return {...state, marks: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        default:
            return state;    }
};
const MarksOverview = () => {
    const sspGrade = JSON.parse(localStorage.getItem('sspGrade'));
    const [state, dispatch] = useReducer(reducer,{
        marks:[],
        loader: false,
    });

    function getMarks(){
        dispatch({type: 'loader', payload: true});
        axios.get(`/student-study-program-grade/marks/${sspGrade.id}`,).then(
            (res) => {
                let marks = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'marks', payload: marks});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function downloadGradeDiploma() {
        axios({
            method: 'post',
            url: `/document/high-school-grade-diploma/${sspGrade.id}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });
    }

    useEffect(()=>{
        getMarks()

    },[]);

    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>
                        <Menu.Item ><Header as={'h3'} floated={"left"}>
                            Pregled ocjena za {sspGrade.grade.name} razred
                            <span>
                            <Icon/></span>
                            <Header.Subheader>
                                <i>{localStorage.getItem('name')}</i>
                            </Header.Subheader>
                        </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            <Menu.Item>
                                    <span>
                                        <Button floated={"right"} icon labelPosition='left' size="mini" basic color="black" onClick={downloadGradeDiploma} >
                                            <Icon name='download' color={"teal"}/>
                                            Preuzmi svjedočanstvo
                                        </Button>
                                            <Icon/>
                                    </span>
                            </Menu.Item>
                            <Menu.Item>
                                    <span>
                                        <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                            <Icon name='arrow left'/>
                                        </Button>
                                            <Icon/>
                                    </span>
                            </Menu.Item>

                        </Menu.Menu>
                    </Menu>

                </Segment>

                <Segment>
                    {state.loader ?
                        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                        :
                        state.marks.length ?
                            <Table celled size={'small'}>
                                <Table.Header>
                                    <Table.Row>

                                        <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Predmet</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Datum prijave</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Datum ocjene</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Datum unosa ocjene</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>
                                            <Header size={"small"}>
                                                Ocjena
                                                <Header.Subheader>
                                                    <i>pismeni dio, usmeni dio</i>
                                                </Header.Subheader>
                                            </Header>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            <Header size={"small"}>
                                                Ocjena
                                                <Header.Subheader>
                                                    <i>zaključna</i>
                                                </Header.Subheader>
                                            </Header>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                            <Header size={"small"}>
                                                Komisija
                                                <Header.Subheader>
                                                    <i>predsjednik, ispitivač, član</i>
                                                </Header.Subheader>
                                            </Header>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        state.marks.map((el, index)=>
                                            (<Table.Row key={el.id}>
                                                <Table.Cell width={1}>{index+1}.</Table.Cell>
                                                <Table.Cell width={3}>
                                                        {el.courseStudyProgram.course.nameBosnian}
                                                        <span style={{ float: 'right' }}>{el.recognized &&<Popup size={"small"}
                                                               content='Priznavanje'
                                                               trigger={<span style={{ float: 'right' }}><Icon name='check' color={"green"}/></span>}
                                                        />}</span>
                                                </Table.Cell>
                                                <Table.Cell width={2}>{el.examRegistration? moment(el.examRegistration.applicationDate).format('D. M. YYYY.') : null}</Table.Cell>
                                                <Table.Cell width={2}>{el.markDate? moment(el.markDate).format('D. M. YYYY.') : null}</Table.Cell>
                                                <Table.Cell width={2}>{el.markEntryDate? moment(el.markEntryDate).format('D. M. YYYY.') : null}</Table.Cell>
                                                <Table.Cell width={2}>{el.writtenExamMark?el.writtenExamMark.name + ' (' + el.writtenExamMark.number + ') |': null} {el.verbalExamMark?el.verbalExamMark.name + ' (' + el.verbalExamMark.number + ')':null}</Table.Cell>
                                                <Table.Cell><strong>{el.finalMark?el.finalMark.name + ' (' + el.finalMark.number + ')':null}</strong></Table.Cell>
                                                <Table.Cell width={3}><strong>{el.committee?el.committee.name :null}</strong>{el.committee? ' (' + el.committee.firstMember.person.firstName + ' ' + el.committee.firstMember.person.lastName + ', ' :''}{el.committee?el.committee.secondMember.person.firstName + ' ' + el.committee.secondMember.person.lastName + ', ' :''}{el.committee?el.committee.thirdMember.person.firstName+ ' ' + el.committee.thirdMember.person.lastName + ')':''}</Table.Cell>
                                            </Table.Row>)
                                        )
                                    }
                                </Table.Body>
                                <Table.Footer>
                                </Table.Footer>
                            </Table>
                            : <div>Nema unesenih podataka o ocjenama.</div>}
                </Segment>
            </Segment.Group>
            <ToastContainer  />
        </div>
    );
};

export default MarksOverview;
