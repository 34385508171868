import React from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import moment from "moment";

const AuthPerson = ({authPerson}) => {
    return (
        authPerson?
        <Table celled size={"small"}>
            <Table.Body>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell> Ime</Table.HeaderCell></Table.Cell>

                    <Table.Cell width={10}>{authPerson && authPerson.firstName}</Table.Cell>
                </Table.Row>
                <Table.Row >
                    <Table.Cell><Table.HeaderCell> Prezime</Table.HeaderCell></Table.Cell>


                    <Table.Cell>{authPerson && authPerson.lastName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Datum imenovanja</Table.HeaderCell></Table.Cell>


                    <Table.Cell>{authPerson && moment(authPerson.documentDate).format('D. M. YYYY.')}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Broj odluke</Table.HeaderCell></Table.Cell>

                    <Table.Cell>{authPerson && authPerson.documentNumber}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Datum početka</Table.HeaderCell></Table.Cell>


                    <Table.Cell>{authPerson && moment(authPerson.startDate).format('D. M. YYYY.')}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Datum kraja</Table.HeaderCell></Table.Cell>


                    <Table.Cell>{authPerson && authPerson.endDate && moment(authPerson.endDate).format('D. M. YYYY.')}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>:
            <p>Nema podataka o ovlaštenom licu.</p>


    );
};

export default AuthPerson;
