import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import 'semantic-ui-css/semantic.min.css'
import Main from "./Main";
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';
import 'react-datepicker/dist/react-datepicker.css';
import UserService from "./service/UserService";
import QrScan from "./components/public/QRScan";
import {HashRouter} from "react-router-dom";
import AppRoutes from "./containers/AppRoutes/AppRoutes";
import PublicRoutes from "./containers/AppRoutes/PublicRoutes";

momentLocalizer(moment);


let userOrdinal = window.sessionStorage.getItem('u');
userOrdinal = userOrdinal || window.localStorage.getItem('lastSelectedU');

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL + '/u/' + ( userOrdinal || 0);
//

axios.interceptors.request.use(config => {
    // console.log('url conf: ', config.url)
    // console.log('url sub: ', config.url.substring(0,13) )
    // Check if it's the specific request where you want to remove headers
    if (config.url.substring(0,13) === 'qr-code-info/') {
        console.log('qrconf: ',config.url )
        // Remove all headers
        config.headers = {};
    } else {
        // Set global headers for other requests
        config.headers = {
            ...config.headers,
            ...{
                'Content-Type': 'application/json',
                Accept: 'application/json',
                // 'Access-Control-Allow-Origin': '*',
                // eslint-disable-next-line no-use-before-define
                Authorization: 'BEARER ' + UserService.getToken()
            },
        };
    }
    return config;
});


const renderApp = () => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <HashRouter>
                <Main/>
            </HashRouter>
        </React.StrictMode>
    );
};
const renderPublicApp = () => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <HashRouter>
                <PublicRoutes/>
            </HashRouter>
        </React.StrictMode>
    );
};
UserService.initKeycloak(renderApp, renderPublicApp);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
