import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Form, Header, Icon, Input, SegmentGroup, Select} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import axios from "axios";
import { DateTimePicker } from 'react-widgets';
import { toast } from 'react-toastify';
import {UserRoleContext} from "../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'contractNumber':
            return {...state, contractNumber: action.payload};
        case 'contractDate':
            return {...state, contractDate: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizerOccupations':
            return {...state, organizerOccupations: action.payload};
        case 'organizerOccupation':
            return {...state, organizerOccupation: action.payload};
        case 'employers':
            return {...state, employers: action.payload};
        case 'employer':
            return {...state, employer: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        default:
            return state;

    }
}

const ContractForm = ({ add, toggleEditForm, getContract, contract, getContractsForAdmin, setCurrentIdOrganizer}) => {
    const [organizerOccupationErr, setOrganizerOccupationErr] = useState(false);
    // const [organizerErr, setOrganizerErr] = useState(false);
    const [employerErr, setEmployerErr] = useState(false);
    const [contractNumberErr, setContractNumberErr] = useState(false);
    const [contractDateErr, setContractDateErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        contractDate: contract && contract.contractDate ? new Date(contract.contractDate):  new Date(),
        contractNumber: contract && contract.contractNumber ? contract.contractNumber : '',
        organizers: [],
        organizer: contract && contract.organizer.id ? contract.organizer.id : null,
        organizerOccupations:[],
        organizerOccupation: contract && contract.occupation ? contract.occupation : null,
        employers: [],
        employer: contract && contract.employer.id ? contract.employer.id : null,
        currentId: null,
    });

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            getOrganizerOccupationById(value);
            dispatch({type: 'organizer', payload: value});
        }
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    function getOrganizers(){
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getEmployers(){
        axios.get('/employer/all',).then(
            (res) => {
                let employers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'employers', payload: employers});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function getOrganizerOccupationById(id){

        setCurrentId(id);
        axios.get(`/occupation/by-organizer-id/${id}`,).then(
            (res) => {
                let organizerOccupations = res.data.map((el)=>({key: el.id,
                    content: <div><strong>{el.profession.name}</strong> { ' | ' + el.nameBosnian}</div>,
                    text: el.profession.name + ' | ' + el.nameBosnian,
                    value: el}));
                dispatch({type: 'organizerOccupations', payload: organizerOccupations});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOrganizerOccupation(){
        axios.get('/occupation/by-organizer',).then(
            (res) => {
                let organizerOccupations = res.data.map((el)=>({key: el.id,
                    content: <div><strong>{el.profession.name}</strong> { ' | ' + el.nameBosnian}</div>,
                    text: el.profession.name + ' | ' + el.nameBosnian,
                    value: el}));
                dispatch({type: 'organizerOccupations', payload: organizerOccupations});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    useEffect(() => {
        if (!add && activeRole === "ROLE_ADMIN") { getOrganizers(); getOrganizerOccupationById(state.organizer)}
        // else {getOrganizers(); getOrganizerOccupation()}
        if(activeRole === "ROLE_ORGANIZER") {getOrganizerOccupation()}
        // else {getOrganizers(); getStudyPrograms()}
        if(activeRole !== "ROLE_ORGANIZER") getOrganizers()
        getEmployers();
    },[activeRole]);


    function editContract(){
        if (!state.organizerOccupation || !state.organizer || !state.employer || !state.contractNumber || !state.contractDate) {
            toast('Niste unijeli sve podatke.');
            if (!state.organizerOccupation) { setOrganizerOccupationErr(true); } else { setOrganizerOccupationErr(false); }
            // if (!state.organizer) { setOrganizerErr(true); } else { setOrganizerErr(false); }
            if (!state.employer) { setEmployerErr(true); } else { setEmployerErr(false); }
            if (!state.contractNumber) { setContractNumberErr(true); } else { setContractNumberErr(false); }
            if (!state.contractDate) { setContractDateErr(true); } else { setContractDateErr(false); }
        } else {
            let obj = {
                id: contract.id,
                optLock: contract.optLock,
                occupation: {id: state.organizerOccupation.id},
                organizer: activeRole === "ROLE_ADMIN" ? {id: state.organizer} : null,
                employer: {id: state.employer},
                contractNumber: state.contractNumber,
                contractDate: state.contractDate
            };
            axios.put('/contract/edit', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste izmijenili ugovor.');
                    if (activeRole === "ROLE_ADMIN") {getContractsForAdmin(state.organizer); setCurrentIdOrganizer(state.organizer)}
                    else {getContract();}
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function addContract(){
        if (!state.organizerOccupation || !state.employer || !state.contractNumber || !state.contractDate) {
            toast('Niste unijeli sve podatke.');
            if (!state.organizerOccupation) { setOrganizerOccupationErr(true); } else { setOrganizerOccupationErr(false); }
            if (!state.employer) { setEmployerErr(true); } else { setEmployerErr(false); }
            if (!state.contractNumber) { setContractNumberErr(true); } else { setContractNumberErr(false); }
            if (!state.contractDate) { setContractDateErr(true); } else { setContractDateErr(false); }
        } else {
            if (activeRole === "ROLE_ORGANIZER") {
            let obj = {
                created: new Date(),
                occupation: {id: state.organizerOccupation.id},
                // organizer: {id: state.organizer},
                employer: {id: state.employer},
                contractNumber: state.contractNumber,
                contractDate: state.contractDate
            };
            axios.post('/contract/save', {...obj}).then(
                (res) => {
                    toggleEditForm();
                    toast('Uspješno ste dodali konkurs.');
                    getContract();
                }).catch((err) => {
                console.log('err', err)
            });
        }
            else{
                let obj = {
                    created: new Date(),
                    occupation: {id: state.organizerOccupation.id},
                    organizer: {id: state.organizer},
                    employer: {id: state.employer},
                    contractNumber: state.contractNumber,
                    contractDate: state.contractDate
                };
                axios.post('/contract/save/for-admin', {...obj}).then(
                    (res) => {
                        toggleEditForm();
                        toast('Uspješno ste dodali konkurs.');
                        getContractsForAdmin(state.organizer);
                        setCurrentIdOrganizer(state.organizer);
                    }).catch((err) => {
                    console.log('err', err)
                });
            }
        }
    }
    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {add?'Dodavanje novog ugovora':'Izmjena ugovora'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths={'equal'}>
                            {activeRole === "ROLE_ADMIN" &&<Form.Field required>
                                <label>Organizator</label>
                                <Form.Dropdown
                                    clearable
                                    disabled={activeRole === "ROLE_ADMIN" && !add}
                                    placeholder="Odaberite organizatora"
                                    value={state.organizer}
                                    onChange={handleInputChange}
                                    name="organizer"
                                    options={state.organizers}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>}
                            <Form.Field
                                required
                                control={Select}
                                clearable
                                options={state.organizerOccupations}
                                placeholder="Odaberite zanimanje"
                                label="Zanimanje"
                                onChange={handleInputChange}
                                name="organizerOccupation"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={state.organizerOccupation}
                                search
                                error={organizerOccupationErr}

                            />

                            <Form.Field
                                required
                                control={Select}
                                clearable
                                options={state.employers}
                                placeholder="Odaberite poslodavca"
                                label="Poslodavac"
                                onChange={handleInputChange}
                                name="employer"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={state.employer}
                                search
                                error={employerErr}
                            />

                            <Form.Field
                                required
                                control={DateTimePicker}
                                label="Datum ugovora"
                                format="D. M. YYYY."
                                placeholder="Unesite datum"
                                name="contractDate"
                                value={state.contractDate ? new Date(state.contractDate) : new Date(contract.contractDate)}
                                time={false}
                                onChange={(e) => handleDateChange('contractDate', e)}
                                style={{ borderWidth: '0px' }}
                                error={contractDateErr}
                            />

                            <Form.Field
                                required
                                label="Broj ugovora"
                                control={Input}
                                name="contractNumber"
                                value={state.contractNumber}
                                onChange={handleInputChange}
                                placeholder="Unesite broj ugovora"
                                error={contractNumberErr}
                            />
                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addContract : editContract}>{add ? 'Dodaj' : 'Sačuvaj'}</Button>
                    </Form>
                </Segment>
            </SegmentGroup>


        </div>
    );
};

export default ContractForm;
