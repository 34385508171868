import React, {useContext, useEffect, useReducer, useState} from 'react';
import {useLocation} from "react-router-dom";
import axios from "axios";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {GridColumn, GridRow, Header, Menu, SegmentGroup} from "semantic-ui-react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import StudentInfo from "./StudentInfo";
import PreviousEducation from "../Utilities/PreviousEducation";
import PersonInfo from "../Utilities/PersonInfo";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import PreviousEducationForm from "../Utilities/PreviousEducationForm";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import PlaceInfo from "../Organizers/OrganizerDetails/PlaceInfo";
import {UserRoleContext} from "../../Main";
import StudentPlaceOfResidenceForm from "./StudentPlaceOfResidenceForm";
import {toast, ToastContainer} from "react-toastify";
import StudentForm from "./StudentForm";
import StudentPlaceOfBirthForm from "./StudentPlaceOfBirthForm";
import {extractFileNameFromHeaders} from "../Utilities/Util";
import download from "downloadjs";

const reducer = (state, action)=>{
    switch (action.type) {
        case 'entities':
            return {...state, entities: action.payload };
        case 'cantons':
            return {...state, cantons: action.payload };
        case 'municipalities':
            return {...state, municipalities: action.payload };
        case 'student':
            return {...state, student: action.payload, loader:  false };
        case 'previousEducations':
            return {...state, previousEducations: action.payload};
        case 'citizenship':
            return {...state, citizenship: action.payload};
        // previous education
        case 'institutionName':
            return {...state, institutionName: action.payload };
        case 'diplomaNumber':
            return {...state, diplomaNumber: action.payload };
        case 'diplomaDate':
            return {...state, diplomaDate: action.payload };
        case 'educationLevel':
            return {...state, educationLevel: action.payload};
        case 'documentType':
            return {...state, documentType: action.payload};
        case 'title':
            return {...state, title: action.payload};
        case 'educationLevels':
            return {...state, educationLevels: action.payload};
        case 'documentTypes':
            return {...state, documentTypes: action.payload};
        // osnovni podaci
        case 'firstName':
            return {...state, firstName: action.payload};
        case 'lastName':
            return {...state, lastName: action.payload};
        case 'maidenName':
            return {...state, maidenName: action.payload};
        case 'gender':
            return {...state, gender: action.payload};
        case 'dateOfBirth':
            return {...state, dateOfBirth: action.payload};
        //prijava
        case 'fileNumber':
            return {...state, fileNumber: action.payload};
        case 'registryBook':
            return {...state, registryBook: action.payload};
        case 'registryBooks':
            return {...state, registryBooks: action.payload};
        case 'numberInRegistryBook':
            return {...state, numberInRegistryBook: action.payload};
        case 'numberAvailability':
            return {...state, numberAvailability: action.payload};
        case 'fileDate':
            return {...state, fileDate: action.payload};
        case 'created':
            return {...state, created: action.payload};
        case 'startDate':
            return {...state, startDate: action.payload};
        case 'graduationDateFinal':
            return {...state, graduationDateFinal: action.payload};
        case 'diplomaNumberFinal':
            return {...state, diplomaNumberFinal: action.payload};
        case 'dropoutDate':
            return {...state, dropoutDate: action.payload};
        case 'dropoutReason':
            return {...state, dropoutReason: action.payload};
        default:
            return state;
    }
};

const StudentDetails = () => {
    const {activeRoleObj, activeRole} = useContext(UserRoleContext);
    const personId = localStorage.getItem('personId');
    const id = localStorage.getItem('sspId');
    const [numberInRegistryBookErr, setnumberInRegistryBookErr] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showEditPlaceForm, setShowEditPlaceForm] = useState(false);
    const [showEditPlaceOfResidenceForm, setShowEditPlaceOfResidenceForm] = useState(false);
    const [showStudentInfoForm, setShowStudentInfoForm] = useState(false);
    const [showPreviousEducationForm, setShowPreviousEducationForm] = useState(false);
    const [showAddPreviousEducationForm, setShowAddPreviousEducationForm] = useState(false);
    const gradDate = localStorage.getItem('gradDate');
    const droppedDate = localStorage.getItem('droppedDate');
    const [state, dispatch] = useReducer(reducer,{
        student: undefined,
        previousEducations:[],
        registryBooks:[],
        citizenship: [],
        loader: true,
        numberAvailability: false

    } );

    useEffect(() => {
        if(activeRole === "ROLE_ORGANIZER") {getRegistryBooks()};
        if (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") {getRegistryBooksForAdmin()}
        // getRegistryBooks();
        getStudentById();
        getPreviousEducation();
        getCitizenship();
    },[activeRole]);

    let getLookupsForPreviousEducation = () =>{

        axios.get('/education-level/all',).then(
            (res)=>{
                let educationLevels = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'educationLevels', payload: educationLevels});

            }).catch((err)=>{
            console.log('err', err)
        });

        axios.get('/document-type/all',).then(
            (res)=>{
                let documentTypes = res.data.filter((e)=>(e.graduationDocument === true)).map((el)=>({key: el.key, text: el.name, value: el}));
                // let documentTypes = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'documentTypes', payload: documentTypes});

            }).catch((err)=>{
            console.log('err', err)
        });

    };

    let getRegistryBooks = () =>{

        axios.get('/registry-book/not-full/by-organizer',).then(
            (res)=>{
                let registryBooks = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'registryBooks', payload: registryBooks});

            }).catch((err)=>{
            console.log('err', err)
        });

    };

    function getRegistryBooksForAdmin (){

        axios.get(`/registry-book/not-full/by-organizer-id/${localStorage.getItem('organizerOption')}`,).then(
            (res)=>{
                let registryBooks = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'registryBooks', payload: registryBooks});

            }).catch((err)=>{
            console.log('err', err)
        });

    };

    function  editPersonInfo(){
        let obj = {
            ...state.student.student.person,
            firstName: state.firstName ?state.firstName:state.student.student.person.firstName,
            lastName: state.lastName ?state.lastName : state.student.student.person.lastName,
            maidenName: state.maidenName ?state.maidenName : state.student.student.person.maidenName,
            gender: state.gender ? state.gender : state.student.student.person.gender,
            dateOfBirth: state.dateOfBirth ? state.dateOfBirth : state.student.student.person.dateOfBirth,

        };

        axios.put('/person/edit', {...obj}).then(
            (res) => {
                toast('Podaci su uspješno sačuvani.');
                getStudentById();
                toggleEditForm()
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function  editStudentInfo(){
        if(state.numberInRegistryBook && state.numberAvailability === false)
        {
            setnumberInRegistryBookErr(true);
            toast('Matični broj je već zauzet!');
            return;
        }
        else {
        let obj = {
            ...state.student,
            fileNumber: state.fileNumber?state.fileNumber:state.student?state.student.fileNumber:'',
            registryBook: state.registryBook?state.registryBook:state.student?state.student.registryBook:'',
            numberInRegistryBook: state.numberInRegistryBook?parseInt(state.numberInRegistryBook):state.student?parseInt(state.student.numberInRegistryBook):'',
            fileDate: state.fileDate?state.fileDate:state.student?state.student.fileDate:'',
            graduationDate: activeRole !== "ROLE_ORGANIZER" ? state.graduationDateFinal?state.graduationDateFinal: null : state.graduationDateFinal?state.graduationDateFinal:state.student?state.student.graduationDate:'',
            // graduationDate: state.graduationDateFinal?state.graduationDateFinal:state.student?state.student.graduationDate:'',
            diplomaNumber: state.diplomaNumberFinal?state.diplomaNumberFinal:state.student?state.student.diplomaNumberFinal:'',
            droppedOutDate: state.dropoutDate,
            dropoutReason: state.dropoutReason?state.dropoutReason:null,
            // occupationType: state.occupationType,
        };

        axios.put('/student-study-program/edit', {...obj}).then(
            (res) => {
                toast('Podaci su uspješno sačuvani.');
                getStudentById();
                toggleStudentInfoForm();
            }).catch((err) => {
            console.log('err', err)
        });
        }
    }

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    let toggleStudentInfoForm = () =>{
        setShowStudentInfoForm(!showStudentInfoForm)
    };

    let togglePreviousEducationForm = () =>{
        getLookupsForPreviousEducation();
        setShowPreviousEducationForm(!showPreviousEducationForm)
    };

    let toggleAddPreviousEducationForm = () =>{
        setShowAddPreviousEducationForm(!showAddPreviousEducationForm)
    };

    let toggleEditPlaceForm = () =>{
        setShowEditPlaceForm(!showEditPlaceForm)
    };

    let toggleEditPlaceOfResidenceForm = () =>{
        setShowEditPlaceOfResidenceForm(!showEditPlaceOfResidenceForm)
    };

    function editPreviousEducation(){

        let objPrevEdu = {
            id: state.previousEducations[0].id,
            optLock: state.previousEducations[0].optLock,
            institutionName: state.institutionName,
            documentNumber: state.diplomaNumber,
            documentDate: state.diplomaDate,
            documentType: state.documentType,
            educationLevel: state.educationLevel,
            title: state.title,
            person: {id: personId}
        };
        axios.put('/previous-education/edit', {...objPrevEdu}).then(
            (res) => {
                toast('Podaci su uspješno sačuvani.');
                getPreviousEducation();
                togglePreviousEducationForm()
            }).catch((err) => {
            console.log('err', err)
        });

    }

    function getStudentById() {
        axios.get(`/student-study-program/${id}`,).then(
            (res) => {
                let student = res.data;
                dispatch({type: 'student', payload: student});
                dispatch({type: 'dropoutDate', payload: student.dropoutDate});
                dispatch({type: 'dropoutReason', payload: student.dropoutReason});
                dispatch({type: 'registryBook', payload: student.registryBook});
            }).catch((err) => {
            console.log('err', err)
        });

    }

    function downloadEnrollmentForm(){
        axios({
            method: 'post',
            url: `/document/enrollment-form/${id}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });

    }

    function getCitizenship(){
        axios.get(`/person-citizenship/by-person-id/${personId}`,).then(
            (res) => {
                let citizenship = res.data;
                dispatch({type: 'citizenship', payload: citizenship});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getPreviousEducation() {
        axios.get(`/previous-education/by-person-id/${personId}`,).then(
            (res) => {
                let previousEducations = res.data;
                dispatch({type: 'previousEducations', payload: previousEducations});
                dispatch({type: 'institutionName', payload: previousEducations[0].institutionName});
                dispatch({type: 'diplomaNumber', payload: previousEducations[0].documentNumber});
                dispatch({type: 'diplomaDate', payload: new Date(previousEducations[0].documentDate)});
                dispatch({type: 'educationLevel', payload: previousEducations[0].educationLevel});
                dispatch({type: 'documentType', payload: previousEducations[0].documentType});
                dispatch({type: 'title', payload: previousEducations[0].title});
            }).catch((err) => {
            console.log('err', err)
        });

    }

    function numberAvailabilityCheck(registryBookId, number){
        axios.get(`registry-book/number-availability-check/${registryBookId}/${number}`,).then(
            (res) => {
                let numberAvailability = res.data;
                dispatch({type: 'numberAvailability', payload: numberAvailability});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value})
        if(name === 'numberInRegistryBook' && value)
        {
            numberAvailabilityCheck(state.registryBook.id, value);
            if (value < 1 || value > state.registryBook.maxNumberOfFields )
            {
                setnumberInRegistryBookErr(true)
                toast('Potrebno je unijeti validan matični broj');
            }
            else{
                setnumberInRegistryBookErr(false)
            }
        }

    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    return (
        <div>
            <Segment.Group>
                <Segment className={'customPadding'} clearing color={"teal"}>
                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>
                        <Menu.Item ><Header as={'h3'} floated={"left"}>
                            {localStorage.getItem('name')}
                            <span>
                            <Icon/></span>
                            <Header.Subheader>
                                <i>Ime i prezime polaznika/ice</i>
                            </Header.Subheader>
                        </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            <Menu.Item>
                            <span>
                                <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                    <Icon name='arrow left'/>
                                </Button>
                                <Icon/>
                            </span>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Segment>
                {/*<Segment color='teal'>*/}
                {/*    <Header as={'h3'}>{name}*/}

                {/*        /!* {!showEditForm && <span>*!/*/}
                {/*        /!*    <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>*!/*/}
                {/*        /!*        <Icon name='pencil alternate' color={"teal"}/>*!/*/}
                {/*        /!*    </Button>*!/*/}

                {/*        /!*     /!*placeholder*!/*!/*/}
                {/*        /!*     <Icon/>*!/*/}
                {/*        /!*</span>}*!/*/}
                {/*        <Header.Subheader>*/}
                {/*            <i>Ime i prezime polaznika</i>*/}
                {/*        </Header.Subheader>*/}
                {/*    </Header>*/}

                {/*</Segment>*/}
                <Segment>
                    {/*send add or edit as props*/}
                    {/*{showEditForm && <StudentForm edit toggleEditForm={toggleEditForm} getStudentById={getStudentById} student={state.student} municipalities={state.municipalities}/>}*/}
                    <Grid columns={2}>
                        <GridRow>
                            <GridColumn>
                                <SegmentGroup>
                                    <Segment >
                                        <Header as={'h4'}>
                                            <span><Icon  name='address card outline' color={"black"} /></span>
                                            Osnovni podaci
                                            {activeRole === "ROLE_ORGANIZER" && (gradDate !== 'null' || droppedDate !== 'null') ? '': <span>
                                                    <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm} disabled={activeRole === "ROLE_ORGANIZER" && (gradDate !== 'null' || droppedDate !== 'null')}>
                                                        <Icon name={showEditForm?'close' : 'pencil alternate'} color={showEditForm?'':"teal"}/>
                                                    </Button>
                                                {/*placeholder*/}
                                                <Icon/></span>}
                                        </Header>
                                    </Segment>
                                    <Segment clearing>
                                        <Table celled size={"small"}>
                                            <Table.Body>
                                                <PersonInfo
                                                    student
                                                    edit={showEditForm}
                                                    handleInputChange={handleInputChange}
                                                    handleDateChange={handleDateChange}
                                                    person={state.student?state.student.student.person:null}
                                                    //dozvoljeno za edit
                                                    firstName={state.firstName?state.firstName:state.student?state.student.student.person.firstName:''}
                                                    lastName={state.lastName?state.lastName:state.student?state.student.student.person.lastName:''}
                                                    maidenName={state.maidenName?state.maidenName:state.student?state.student.student.person.maidenName:''}
                                                    gender={state.gender?state.gender:state.student?state.student.student.person.gender:''}
                                                    dateOfBirth={state.dateOfBirth? new Date(state.dateOfBirth):state.student?new Date(state.student.student.person.dateOfBirth):''}
                                                    citizenship={state.citizenship}
                                                />
                                            </Table.Body>
                                        </Table>
                                        {showEditForm && <Button floated={"right"} basic size={"small"} color={"teal"} onClick={()=>editPersonInfo()}>{'Sačuvaj'}</Button>}
                                    </Segment>

                                </SegmentGroup>
                                <SegmentGroup>
                                    <Segment >
                                        <Header as={'h4'}>
                                            <span><Icon  name='address card outline' color={"black"} /></span>
                                            Mjesto rođenja
                                            {!showEditPlaceForm && !(activeRole === "ROLE_ORGANIZER" && (gradDate !== 'null' || droppedDate !== 'null')) ? <span>
                                                       <Popup size={"small"} content={'Izmijeni podatke'} trigger={
                                                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditPlaceForm} >
                                                               <Icon name='pencil alternate' color={"teal"}/>
                                                           </Button>} />

                                                {/*placeholder*/}
                                                <Icon/></span> : ''}
                                        </Header>
                                    </Segment>
                                    {showEditPlaceForm && <Segment>
                                        <StudentPlaceOfBirthForm edit toggleEditForm={toggleEditPlaceForm} getStudent={getStudentById} student={state.student}/>
                                    </Segment>}
                                    <Segment>
                                        <PlaceInfo placeOfBirth={state.student?state.student.student.person.placeOfBirth:null}/>
                                    </Segment>
                                </SegmentGroup>

                                {/*<SegmentGroup>*/}
                                {/*    <Segment >*/}
                                {/*        <Header as={'h4'}>*/}
                                {/*            <span><Icon  name='graduation' color={"black"} /></span>*/}
                                {/*            Prethodno obrazovanje*/}
                                {/*            {!showPreviousEducationForm && <span>*/}
                                {/*                    <Button floated={"right"} icon size={"mini"} basic onClick={togglePreviousEducationForm} disabled={activeRole === 'ROLE_MINISTRY'}>*/}
                                {/*                        <Icon name='pencil alternate' color={"teal"}/>*/}
                                {/*                    </Button>*/}
                                {/*                    /!*<Button floated={"right"} icon size={"mini"} basic onClick={toggleAddPreviousEducationForm}>*!/*/}
                                {/*                    /!*    <Icon name='add' color={"teal"}/>*!/*/}
                                {/*                    /!*</Button>*!/*/}
                                {/*                /!*placeholder*!/*/}
                                {/*                <Icon/></span>}*/}
                                {/*        </Header>*/}
                                {/*    </Segment>*/}
                                {/*    { showPreviousEducationForm && <Segment clearing>*/}
                                {/*        <PreviousEducationForm*/}
                                {/*            institutionName={state.institutionName}*/}
                                {/*            diplomaNumber={state.diplomaNumber}*/}
                                {/*            diplomaDate={state.diplomaDate}*/}
                                {/*            educationLevel={state.educationLevel}*/}
                                {/*            educationLevels={state.educationLevels}*/}
                                {/*            documentType={state.documentType}*/}
                                {/*            documentTypes={state.documentTypes}*/}
                                {/*            title={state.title}*/}
                                {/*            handleInputChange={handleInputChange}*/}
                                {/*            handleDateChange={handleDateChange}*/}
                                {/*            edit*/}
                                {/*            editPreviousEducation={editPreviousEducation}*/}
                                {/*            toggleEditForm={togglePreviousEducationForm}*/}
                                {/*            getPreviousEducation={getPreviousEducation}*/}
                                {/*        />*/}
                                {/*    </Segment>}*/}
                                {/*    { showAddPreviousEducationForm && <Segment>*/}
                                {/*        <PreviousEducationForm add toggleEditForm={toggleAddPreviousEducationForm}  getPreviousEducation={getPreviousEducation} student={state.student}/>*/}
                                {/*    </Segment>}*/}
                                {/*    <Segment>*/}
                                {/*        <PreviousEducation previousEducation={state.previousEducations.length !== 0 ? state.previousEducations[0] : null}  />*/}
                                {/*    </Segment>*/}
                                {/*</SegmentGroup>*/}
                                <SegmentGroup>
                                    <Segment >
                                        <Header as={'h4'}>
                                            <span><Icon  name='address card outline' color={"black"} /></span>
                                            Prebivalište
                                            {!showEditPlaceOfResidenceForm && !(activeRole === "ROLE_ORGANIZER" && (gradDate !== 'null' || droppedDate !== 'null')) ? <span>
                                                       <Popup size={"small"} content={'Izmijeni podatke'} trigger={
                                                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditPlaceOfResidenceForm}>
                                                               <Icon name='pencil alternate' color={"teal"}/>
                                                           </Button>} />

                                                {/*placeholder*/}
                                                <Icon/></span>: ''}
                                        </Header>
                                    </Segment>
                                    {showEditPlaceOfResidenceForm && <Segment>
                                        <StudentPlaceOfResidenceForm edit toggleEditForm={toggleEditPlaceOfResidenceForm} getStudent={getStudentById} student={state.student}/>
                                    </Segment>}
                                    <Segment>
                                        <PlaceInfo residence={state.student?state.student.student.person.residence:null}/>
                                    </Segment>
                                </SegmentGroup>
                            </GridColumn>
                            <GridColumn>

                                <SegmentGroup>
                                    <Segment >
                                        <Header as={'h4'}>
                                            <span><Icon  name='building outline' color={"black"} /></span>
                                            Prijava
                                            <span>

                                                    {activeRole === "ROLE_ORGANIZER" && (gradDate !== 'null' || droppedDate !== 'null') ? '':<Button floated={"right"} icon size={"mini"} basic onClick={toggleStudentInfoForm} >
                                                        <Icon name={showStudentInfoForm?'close' : 'pencil alternate'} color={showStudentInfoForm?'':"teal"}/>
                                                    </Button>}
                                                <Button floated={"right"} icon labelPosition='left' size="mini" basic onClick={downloadEnrollmentForm} disabled={activeRole === 'ROLE_MINISTRY' || activeRole === "ROLE_ADMIN"}>
                                                    <Icon name='file pdf outline' color={"red"} />
                                                         Preuzmi prijavu
                                                </Button>
                                                {/*placeholder*/}
                                                <Icon/></span>
                                        </Header>
                                    </Segment>
                                    <Segment clearing>
                                        <StudentInfo
                                            edit={showStudentInfoForm}
                                            handleInputChange={handleInputChange}
                                            handleDateChange={handleDateChange}
                                            ssp={state.student?state.student:null}
                                            fileNumber={(state.fileNumber)?state.fileNumber:state.student?state.student.fileNumber:''}
                                            numberInRegistryBook={state.numberInRegistryBook?state.numberInRegistryBook:state.student?state.student.numberInRegistryBook:''}
                                            registryBook={(state.registryBook)?state.registryBook:state.student?state.student.registryBook:''}
                                            fileDate={state.fileDate?state.fileDate:state.student? new Date(state.student.fileDate):''}
                                            graduationDateFinal={state.graduationDateFinal?state.graduationDateFinal:(state.student && state.student.graduationDate) ? new Date(state.student.graduationDate):state.student ?state.student.graduationDate: null}
                                            diplomaNumberFinal={(state.diplomaNumberFinal)?state.diplomaNumberFinal:state.student?state.student.diplomaNumber:''}
                                            dropoutDate={state.dropoutDate?state.dropoutDate:(state.student && state.student.droppedOutDate) ? new Date(state.student.droppedOutDate):state.student ?state.student.droppedOutDate: ''}
                                            dropoutReason={state.dropoutReason}
                                            registryBooks={state.registryBooks}
                                            numberInRegistryBookErr={numberInRegistryBookErr}
                                        />
                                        {showStudentInfoForm && <Button floated={"right"} basic size={"small"} color={"teal"} onClick={()=> editStudentInfo()}>{'Sačuvaj'}</Button>}
                                    </Segment>
                                </SegmentGroup>
                                <SegmentGroup>
                                    <Segment >
                                        <Header as={'h4'}>
                                            <span><Icon  name='graduation' color={"black"} /></span>
                                            Prethodno obrazovanje
                                            {!showPreviousEducationForm && !(activeRole === "ROLE_ORGANIZER" && (gradDate !== 'null' || droppedDate !== 'null')) ? <span>
                                                    <Button floated={"right"} icon size={"mini"} basic onClick={togglePreviousEducationForm}>
                                                        <Icon name='pencil alternate' color={"teal"}/>
                                                    </Button>
                                                {/*<Button floated={"right"} icon size={"mini"} basic onClick={toggleAddPreviousEducationForm}>*/}
                                                {/*    <Icon name='add' color={"teal"}/>*/}
                                                {/*</Button>*/}
                                                {/*placeholder*/}
                                                <Icon/></span>: ''}
                                        </Header>
                                    </Segment>
                                    { showPreviousEducationForm && <Segment clearing>
                                        <PreviousEducationForm
                                            institutionName={state.institutionName}
                                            diplomaNumber={state.diplomaNumber}
                                            diplomaDate={state.diplomaDate}
                                            educationLevel={state.educationLevel}
                                            educationLevels={state.educationLevels}
                                            documentType={state.documentType}
                                            documentTypes={state.documentTypes}
                                            title={state.title}
                                            handleInputChange={handleInputChange}
                                            handleDateChange={handleDateChange}
                                            edit
                                            editPreviousEducation={editPreviousEducation}
                                            toggleEditForm={togglePreviousEducationForm}
                                            getPreviousEducation={getPreviousEducation}
                                        />
                                    </Segment>}
                                    { showAddPreviousEducationForm && <Segment>
                                        <PreviousEducationForm add toggleEditForm={toggleAddPreviousEducationForm}  getPreviousEducation={getPreviousEducation} student={state.student}/>
                                    </Segment>}
                                    <Segment>
                                        <PreviousEducation previousEducation={state.previousEducations.length !== 0 ? state.previousEducations[0] : null}  />
                                    </Segment>
                                </SegmentGroup>
                                {/*<SegmentGroup>*/}
                                {/*    <Segment >*/}
                                {/*        <Header as={'h4'}>*/}
                                {/*            <span><Icon  name='address card outline' color={"black"} /></span>*/}
                                {/*            Prebivalište*/}
                                {/*            {!showEditPlaceOfResidenceForm && <span>*/}
                                {/*                       <Popup size={"small"} content={'Izmijeni podatke'} trigger={*/}
                                {/*                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditPlaceOfResidenceForm} disabled={activeRole === 'ROLE_MINISTRY'}>*/}
                                {/*                               <Icon name='pencil alternate' color={"teal"}/>*/}
                                {/*                           </Button>} />*/}

                                {/*                /!*placeholder*!/*/}
                                {/*                <Icon/></span>}*/}
                                {/*        </Header>*/}
                                {/*    </Segment>*/}
                                {/*    {showEditPlaceOfResidenceForm && <Segment>*/}
                                {/*        <StudentPlaceOfResidenceForm edit toggleEditForm={toggleEditPlaceOfResidenceForm} getStudent={getStudentById} student={state.student}/>*/}
                                {/*    </Segment>}*/}
                                {/*    <Segment>*/}
                                {/*        <PlaceInfo residence={state.student?state.student.student.person.residence:null}/>*/}
                                {/*    </Segment>*/}
                                {/*</SegmentGroup>*/}
                            </GridColumn>
                        </GridRow>

                    </Grid>


                </Segment>
            </Segment.Group>
<ToastContainer/>
        </div>
    );
};

export default StudentDetails;
