import React, {useContext, useEffect, useReducer, useState} from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {Form} from "semantic-ui-react";
import axios from "axios";
import {toast} from "react-toastify";
import {UserRoleContext} from "../../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'editMode':
            return {...state, editMode: action.payload};
        case 'courseStudyProgram':
            return {...state, courseStudyProgram: action.payload};
        case 'mark':
            return {...state, mark: action.payload};
        case 'marks':
            return {...state, marks: action.payload};
        case 'recognizedCourses':
            return {...state, recognizedCourses: action.payload};
        case 'recognizedCourse':
            return {...state, recognizedCourse: action.payload};
        case 'courses':
            return {...state, courses: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        default:
            return state;

    }
};
const AddRecognizedCourse = ({studyProgramId, recognitionId, committee, gradeNumber}) => {
    const [openCourseForm, setOpenCourseForm] = useState(false);
    const [courseStudyProgramErr, setCourseStudyProgramErr] = useState(false);
    const [markErr, setMarkErr] = useState(false);
    const gradDate = localStorage.getItem('gradDate');
    const droppedDate = localStorage.getItem('droppedDate');
    const {activeRole} = useContext(UserRoleContext);

    const [state, dispatch] = useReducer(reducer,{
        marks:[],
        mark: null,
        courses: [],
        editMode: false,
        courseStudyProgram: null,
        recognizedCourses: [],
        loader: true
    });

    let toggleOpenCourseForm = () =>{
        setOpenCourseForm(!openCourseForm);
        if (!openCourseForm) {
            getCourses(studyProgramId);
            getMarks();
        }
    };

    function handleInputChange(event, { name, value}) {
        dispatch({type: name, payload: value});

    }

    function resetForm() {
        setMarkErr(false);
        setCourseStudyProgramErr(false);
        dispatch({type: 'mark', payload: null});
        dispatch({type: 'courseStudyProgram', payload: null});
        dispatch({type: 'editMode', payload: false});
    }

    function getMarks(){
        axios.get('/mark/all',).then(
            (res) => {
                let marks = res.data.map((el)=>({key: el.id, text: el.number, description:  el.name, value: el.id})).reverse();
                dispatch({type: 'marks', payload: marks});
            }).catch((err) => {
            console.log('err', err)
        });
    }



    useEffect(()=>{
        getRecognizedCourses(recognitionId);
    }, []);

    function addRecognizedCourse() {
        if (!state.courseStudyProgram) {setCourseStudyProgramErr(true);} else {setCourseStudyProgramErr(false);}
        if (!state.mark) {setMarkErr(true);} else {setMarkErr(false);}

        if(!state.courseStudyProgram || !state.mark){
            toast('Niste unijeli sve podatke!')
        } else{
            let sspId = parseInt(localStorage.getItem('sspId'));
            let obj = {
                recognition: {id: recognitionId},
                courseStudyProgram: {id: state.courseStudyProgram},
                mark: {id: state.mark},
                markEntryDate: new Date(),
                markDate: new Date(),
                studentStudyProgram: {id: sspId},
                committee: committee
            };
            axios.post('/student-study-program-recognition-mark/save', {...obj}).then(
                (res) => {
                    getRecognizedCourses(recognitionId);
                    resetForm();
                    setOpenCourseForm(false);
                    toast('Uspješno ste dodali podatke.');

                }).catch((err) => {
                if (err.response.data.message === 'Mark for passed course study program already exists!') {
                    toast('Odabrani predmet je već priznat.');
                } else {
                    toast('Došlo je do greške! Dodavanje predmeta nije uspjelo.');
                }
            });
        }
    }

    function openEditForm(recognizedCourse){
        toggleOpenCourseForm();
        dispatch({type: 'editMode', payload: true});
        //editovati
        dispatch({type: 'mark', payload: recognizedCourse.mark.id});
        dispatch({type: 'courseStudyProgram', payload: recognizedCourse.courseStudyProgram.id});
        dispatch({type: 'recognizedCourse', payload: recognizedCourse});

    }

    function editRecognizedCourse() {

        if (!state.courseStudyProgram) {setCourseStudyProgramErr(true);} else {setCourseStudyProgramErr(false);}
        if (!state.mark) {setMarkErr(true);} else {setMarkErr(false);}

        if(!state.courseStudyProgram || !state.mark){
            toast('Niste unijeli sve podatke!')
        } else{
            let sspId = parseInt(localStorage.getItem('sspId'));
            let obj = {
                id: state.recognizedCourse.id,
                optLock: state.recognizedCourse.optLock,
                recognition: {id: recognitionId},
                courseStudyProgram: {id: state.courseStudyProgram},
                mark: {id: state.mark},
                markEntryDate: state.recognizedCourse.markEntryDate,
                markDate: state.recognizedCourse.markDate,
                studentStudyProgram: {id: sspId},
                committee: committee

            };
            axios.put('/student-study-program-recognition-mark/edit', {...obj}).then(
                (res) => {
                    getRecognizedCourses(recognitionId);
                    toggleOpenCourseForm();
                    resetForm();
                    toast('Uspješno ste izmijenili podatke.');

                }).catch((err) => {
                console.log('err', err)
            });

        }
    }

    function deleteRecognizedCourse(id){
        setCurrentId(id);
        dispatch({type: 'loader', payload: true});
        axios.delete(`student-study-program-recognition-mark/delete/${id}`,).then(
            (res) => {
               getRecognizedCourses(recognitionId);
                toast('Uspješno ste izbrisali ocjenu!');
                setCurrentId(null);
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function getCourses(id){
        // dispatch({type: 'loader', payload: true});
        dispatch({type: 'openTable', payload: true});
        axios.get(`/course-study-program/by-study-program/${id}`,).then(
            (res) => {
                let courses = res.data.map((el)=>({...el, key: el.id, text: el.course.nameBosnian, description:  el.grade.name, value: el.id})).filter((e)=>(e.grade.number === gradeNumber));
                dispatch({type: 'courses', payload: courses})
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getRecognizedCourses(id){
        dispatch({type: 'loader', payload: true});
        axios.get(`/student-study-program-recognition-mark/by-recognition/ordered/${id}`,).then(
            (res) => {
                let recognizedCourses = res.data.sort((a, b) => a.courseStudyProgram.grade.number - b.courseStudyProgram.grade.number);
                dispatch({type: 'recognizedCourses', payload: recognizedCourses});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    return (
        <div>
            <Table celled size={"small"}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell colSpan={2}> <span><Table.HeaderCell>Priznati predmeti za {gradeNumber}. razred</Table.HeaderCell></span> </Table.Cell>
                        <Table.Cell textAlign="center" >

                            <Popup size={"small"}
                                   content='Dodaj predmet/ocjenu'
                                   trigger={
                                       <Button
                                           // disabled={gradDate !== 'null' || droppedDate !== 'null'}
                                           icon
                                           size={"tiny"}
                                           basic
                                           compact
                                           disabled={openCourseForm || (gradDate !== 'null' && activeRole==="ROLE_ORGANIZER") || (droppedDate !== 'null' && activeRole==="ROLE_ORGANIZER")}
                                           onClick={toggleOpenCourseForm}
                                       >
                                           <Icon name='add' color={"teal"} />
                                       </Button>
                                   }
                            />




                        </Table.Cell>
                    </Table.Row>
                    {(state.recognizedCourses.filter((e)=>(e.courseStudyProgram.grade.number === gradeNumber)).length !== 0 || openCourseForm) ? <><Table.Row>
                        <Table.Cell width={8}> <Table.HeaderCell>Naziv predmeta </Table.HeaderCell></Table.Cell>
                        <Table.Cell width={4}> <Table.HeaderCell>Ocjena</Table.HeaderCell></Table.Cell>
                        <Table.Cell width={4}/>
                    </Table.Row>
                        {openCourseForm && <Table.Row>

                            <Table.Cell width={8}>
                                <Form size={'tiny'}>
                                    <Form.Field required>
                                        <Form.Dropdown
                                            error={courseStudyProgramErr}
                                            clearable
                                            disabled={state.editMode}
                                            placeholder="Odaberite predmet"
                                            value={state.courseStudyProgram}
                                            onChange={handleInputChange}
                                            name="courseStudyProgram"
                                            options={state.courses}
                                            search
                                            fluid
                                            selection
                                        />
                                    </Form.Field>
                                </Form>
                            </Table.Cell>
                            <Table.Cell width={4}>
                                <Form size={'tiny'}>
                                    <Form.Field required>
                                        <Form.Dropdown
                                            error={markErr}
                                            clearable
                                            placeholder="Ocjena"
                                            value={state.mark}
                                            onChange={handleInputChange}
                                            name="mark"
                                            options={state.marks}
                                            search
                                            fluid
                                            selection
                                        />
                                    </Form.Field>
                                </Form>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={4}>
                                <Form size={'tiny'} >
                                    <Form.Field>
                                        <Button
                                            icon
                                            size={"tiny"}
                                            basic
                                            compact
                                            onClick={state.editMode ? editRecognizedCourse : addRecognizedCourse}
                                        >
                                            <Icon name='check' color={"teal"} />
                                           {/*{state.editMode?'Sačuvaj': 'Priznaj'}*/}
                                        </Button>
                                        <Button
                                            icon
                                            size={"tiny"}
                                            basic
                                            compact
                                            onClick={()=>{resetForm(); toggleOpenCourseForm()}}
                                        >
                                            <Icon name='close' color={"black"} />
                                            {/*Odustani*/}
                                        </Button>
                                        {/*<Button size={"tiny"} basic color={'teal'} circular as={Icon} icon={'check'} onClick={addRecognizedGrade}/>*/}
                                        {/*<Button size={"tiny"} basic color={'black'} circular as={Icon} icon={'close'} onClick={toggleOpenGradeForm}/>*/}
                                    </Form.Field>
                                </Form>
                            </Table.Cell>
                        </Table.Row>}
                        {
                            state.recognizedCourses.filter((e)=>(e.courseStudyProgram.grade.number === gradeNumber)).map((el, index)=>
                                ( <Table.Row>
                                    <Table.Cell> {el.courseStudyProgram.course.nameBosnian}
                                        {/*{el.courseStudyProgram.grade.name} razred*/}
                                    </Table.Cell>
                                    <Table.Cell>{el.mark.number + ' (' + el.mark.name + ')'}</Table.Cell>

                                    <Table.Cell textAlign="center" width={3}>
                                        <Form size={'tiny'} >
                                            <Form.Field>
                                                <Popup size={"small"}
                                                       content='Izmijeni'
                                                       trigger={
                                                           <Button disabled={(state.currentId && el.id !== state.currentId) || (gradDate !== 'null' && activeRole==="ROLE_ORGANIZER") || (droppedDate !== 'null' && activeRole==="ROLE_ORGANIZER") }
                                                                   icon
                                                                   size={"tiny"}
                                                                   basic
                                                                   color={"gray"}
                                                                   compact
                                                                   onClick={() => {openEditForm(el)}}
                                                           >
                                                               <Icon name='pencil' color={"teal"} />
                                                           </Button>
                                                       }
                                                />
                                                <Popup size={"small"}
                                                       content='Obriši'
                                                       trigger={
                                                           <Button disabled={(state.currentId && el.id !== state.currentId) || (gradDate !== 'null' && activeRole==="ROLE_ORGANIZER") || (droppedDate !== 'null' && activeRole==="ROLE_ORGANIZER")}
                                                                   icon
                                                                   size={"tiny"}
                                                                   basic
                                                                   color={"gray"}
                                                                   compact
                                                                   onClick={() => {deleteRecognizedCourse(el.id)}}
                                                           >
                                                               <Icon name='trash alternate outline' color={"red"} />
                                                           </Button>
                                                       }
                                                />
                                            </Form.Field>
                                        </Form>
                                    </Table.Cell>
                                </Table.Row>)
                            )
                        }
                    </>: <Table.Row>
                        <Table.Cell colSpan={4}> Nema priznatih predmeta. </Table.Cell>
                    </Table.Row>
                    }
                </Table.Body>
            </Table>

        </div>
    );
};

export default AddRecognizedCourse;
