import React, {useReducer} from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import moment from "moment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'editForm':
            return {...state, editForm: action.payload};
        default:
            return state;

    }
};
const GradeInfo = ({sspGrade}) => {
    const [state, dispatch] = useReducer(reducer,{
        editForm: false
    });
    return (
        <div>
            <Table celled size={"small"}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell colSpan={2}> <Table.HeaderCell> <h5>Podaci za {sspGrade?.grade.name} razred </h5> </Table.HeaderCell></Table.Cell>
                    </Table.Row>


                    <Table.Row >
                        <Table.Cell width={8}> <Table.HeaderCell> Datum početka obrazovnog procesa </Table.HeaderCell></Table.Cell>
                        <Table.Cell width={8}> {sspGrade && sspGrade.enrollmentDate && moment(sspGrade.enrollmentDate).format('D. M. YYYY.')}</Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width={8}> <Table.HeaderCell> Datum kraja obrazovnog procesa </Table.HeaderCell></Table.Cell>
                        <Table.Cell width={8}>  {sspGrade && sspGrade.completedDate && moment(sspGrade.completedDate).format('D. M. YYYY.')}</Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width={8}> <Table.HeaderCell> Djelovodni broj svjedočanstva </Table.HeaderCell></Table.Cell>
                        <Table.Cell width={8}>  {sspGrade?.gradeDiplomaNumber}</Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width={8}> <Table.HeaderCell> Datum svjedočanstva </Table.HeaderCell></Table.Cell>
                        <Table.Cell width={8}>  {sspGrade && sspGrade.gradeDiplomaDate && moment(sspGrade.gradeDiplomaDate).format('D. M. YYYY.')} </Table.Cell>
                    </Table.Row>

                    {state.editForm ? <Table.Row >
                            <Table.Cell colSpan={state.editForm ? 3: 2} textAlign="right">
                                <Button size={"mini"} basic color={"teal"} onClick={()=>{dispatch({type: 'editForm', payload: true});}}>Sačuvaj</Button>
                                <Button  size={"mini"} basic color={"black"} onClick={()=>{dispatch({type: 'editForm', payload: false});}}>Odustani</Button>
                            </Table.Cell>

                        </Table.Row>:
                        <Table.Row >
                            <Table.Cell colSpan={state.editForm ? 3: 2} textAlign="right">
                                <Button icon labelPosition='left' size="mini" basic color="black" >
                                    <Icon name='download' color={"teal"} />
                                    Svjedočanstvo
                                </Button>
                            </Table.Cell>
                        </Table.Row>

                    }

                </Table.Body>
            </Table>
        </div>
    );
};

export default GradeInfo;
