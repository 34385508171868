import React, {useContext, useReducer, useState} from 'react';
import {Form, GridColumn, Header, Icon, Input, SegmentGroup, Select} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import axios from "axios";
import {DateTimePicker} from "react-widgets";
import {toast} from "react-toastify";
import {UserRoleContext} from "../../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'firstName':
            return {...state, firstName: action.payload};
        case 'lastName':
            return {...state, lastName: action.payload, enableOccupation: !!action.payload};
        case 'documentNumber':
            return {...state, documentNumber: action.payload};
        case 'documentDate':
            return {...state, documentDate: action.payload};
        case 'startDate':
            return {...state, startDate: action.payload};
        case 'endDate':
            return {...state, endDate: action.payload};
        default:
            return state;

    }
};

const AuthPersonForm = ({add, edit, toggleEditForm, getAuthPersons, organizer, authPerson, getAuthPersonsForOrganizer}) => {
    const {activeRole} = useContext(UserRoleContext);
    const [firstNameErr, setFirstNameErr] = useState(false);
    const [lastNameErr, setLastNameErr] = useState(false);
    const [documentNumberErr, setDocumentNumberErr] = useState(false);
    const [startDateErr, setStartDateErr] = useState(false);
    const [documentDateErr, setDocumentDateErr] = useState(false);
    const [state, dispatch] = useReducer(reducer,{
        firstName: edit ? authPerson.firstName:'',
        lastName: edit ? authPerson.lastName:'',
        documentDate:edit ? new Date(authPerson.documentDate):new Date(),
        startDate:edit ? new Date(authPerson.startDate): new Date(),
        endDate:edit ? authPerson.endDate !== null ?new Date(authPerson.endDate): null : null,
        documentNumber: edit ? authPerson.documentNumber:''
    });

    function addAuthPerson() {
        if (!state.firstName || !state.lastName || !state.documentDate || !state.startDate || !state.documentNumber) {
            toast('Niste unijeli sve podatke.');
            if (!state.firstName) { setFirstNameErr(true); } else { setFirstNameErr(false); }
            if (!state.lastName) { setLastNameErr(true); } else { setLastNameErr(false); }
            if (!state.documentDate) { setDocumentDateErr(true); } else { setDocumentDateErr(false); }
            if (!state.startDate) { setStartDateErr(true); } else { setStartDateErr(false); }
            if (!state.documentNumber) { setDocumentNumberErr(true); } else { setDocumentNumberErr(false); }
        } else {

        let obj = {
            firstName: state.firstName,
            lastName: state.lastName,
            documentDate: state.documentDate,
            documentNumber: state.documentNumber,
            startDate: state.startDate,
            endDate: state.endDate,
            created: new Date(),
            organizer: {id: organizer.id}
        };

        axios.post('organizers-authorized-person/save',{...obj}).then(
            (res) => {
                toast('Uspješno ste dodali podatke za ovlašteno lice.');
                toggleEditForm();
                if (activeRole === "ROLE_ORGANIZER") {
                    getAuthPersonsForOrganizer()
                } else
                {
                    getAuthPersons();
                }
            }).catch((err) => {
            toast('Došlo je do greške! Podaci nisu spremljeni.');
            console.log('err', err)
        });
        }

    }

    function editAuthPerson() {
        if (!state.firstName || !state.lastName || !state.documentDate || !state.startDate || !state.documentNumber) {
            toast('Niste unijeli sve podatke.');
            if (!state.firstName) { setFirstNameErr(true); } else { setFirstNameErr(false); }
            if (!state.lastName) { setLastNameErr(true); } else { setLastNameErr(false); }
            if (!state.documentDate) { setDocumentDateErr(true); } else { setDocumentDateErr(false); }
            if (!state.startDate) { setStartDateErr(true); } else { setStartDateErr(false); }
            if (!state.documentNumber) { setDocumentNumberErr(true); } else { setDocumentNumberErr(false); }
        } else {

        let obj = {
            id: authPerson.id,
            optLock: authPerson.optLock,
            firstName: state.firstName,
            lastName: state.lastName,
            documentDate: state.documentDate,
            documentNumber: state.documentNumber,
            startDate: state.startDate,
            endDate: state.endDate,
            organizer: {id: organizer.id}
        };

        axios.put('organizers-authorized-person/edit',{...obj}).then(
            (res) => {
                toast('Uspješno ste dodali nove podatke za ovlašteno lice.');
                toggleEditForm();
                if (activeRole === "ROLE_ORGANIZER") {
                    getAuthPersonsForOrganizer()
                } else
                {
                    getAuthPersons();
                }

            }).catch((err) => {
            toast('Došlo je do greške! Podaci nisu spremljeni.');
            console.log('err', err)
        });
        }

    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    return (
        <div>

            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {add?'Dodavanje ovlaštenog lica':'Izmjena podataka'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            {/*placeholder*/}
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'} >
                        <Form.Group widths={'equal'} >
                            <Form.Field
                                required
                                error={firstNameErr}
                                label="Ime"
                                control={Input}
                                name="firstName"
                                value={state.firstName}
                                onChange={handleInputChange}
                                placeholder="Unesite ime"
                            />
                            <Form.Field
                                required
                                error={lastNameErr}
                                label="Prezime"
                                control={Input}
                                name="lastName"
                                value={state.lastName}
                                onChange={handleInputChange}
                                placeholder="Unesite prezime"
                            />
                            <Form.Field
                                required
                                error={documentDateErr}
                                control={DateTimePicker}
                                label="Datum imenovanja"
                                format="D. M. YYYY."
                                placeholder="Unesite datum"
                                name="documentDate"
                                value={state.documentDate}
                                time={false}
                                onChange={(e) => handleDateChange('documentDate', e)}
                                style={{ borderWidth: '0px' }}
                                //error={openFromDateErr}
                            />



                        </Form.Group>
                        <Form.Group widths={'equal'} >
                            <Form.Field
                                required
                                error={documentNumberErr}
                                label="Broj odluke"
                                control={Input}
                                name="documentNumber"
                                value={state.documentNumber}
                                onChange={handleInputChange}
                                placeholder="Unesite ime"
                            />
                            <Form.Field
                                required
                                error={startDateErr}
                                control={DateTimePicker}
                                label="Datum početka"
                                format="D. M. YYYY."
                                placeholder="Unesite datum"
                                name="startDate"
                                value={state.startDate}
                                time={false}
                                onChange={(e) => handleDateChange('startDate', e)}
                                style={{ borderWidth: '0px' }}
                                //error={openFromDateErr}
                            />
                            <Form.Field
                                // required
                                // error={documentDateErr}
                                control={DateTimePicker}
                                label="Datum kraja"
                                format="D. M. YYYY."
                                placeholder="Unesite datum"
                                name="endDate"
                                value={state.endDate}
                                time={false}
                                onChange={(e) => handleDateChange('endDate', e)}
                                style={{ borderWidth: '0px' }}
                                //error={openFromDateErr}
                            />



                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addAuthPerson : editAuthPerson}>{add ? 'Dodaj' : 'Sačuvaj'}</Button>
                    </Form>
                </Segment>
            </SegmentGroup>

        </div>
    );
};

export default AuthPersonForm;
