import React from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import moment from "moment";

const GeneralInfo = ({organizer}) => {

    return (
        <Table celled size={"small"}>
            <Table.Body>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Broj rješenja upisa u registar obrazovanja odraslih </Table.HeaderCell></Table.Cell>
                    <Table.Cell width={9}>{organizer?organizer.documentNumberInRegisterOfOrganizers:''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Datum rješenja upisa u registar obrazovanja odraslih </Table.HeaderCell></Table.Cell>
                    <Table.Cell width={9}>{organizer?moment(organizer.documentDateInRegisterOfOrganizers).format('D. M. YYYY.'):''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Redni broj upisa u registar obrazovanja odraslih </Table.HeaderCell></Table.Cell>
                    <Table.Cell width={9}>{organizer?organizer.ordinalNumberInRegisterOfOrganizers:''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Broj upisa u sudski registar</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{organizer?organizer.courtRegisterInputNumber:''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Datum upisa u sudski registar</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{organizer?moment(organizer.courtRegisterInputDate).format('D. M. YYYY.'):''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Naziv </Table.HeaderCell></Table.Cell>
                    <Table.Cell width={9}>{organizer?organizer.name:''}</Table.Cell>
                </Table.Row>
                <Table.Row >
                    <Table.Cell><Table.HeaderCell> Tip </Table.HeaderCell></Table.Cell>
                    <Table.Cell>{organizer?organizer.organizerType.name:''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>JIB</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{organizer?organizer.jib:''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>PIB</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{organizer?organizer.pib?organizer.pib:'':''}</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>E-mail</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{organizer?organizer.email:''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Telefon</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{organizer?organizer.phone:''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Web stranica</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{organizer?organizer.web:''}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

export default GeneralInfo;
