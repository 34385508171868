import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Button, Form, Header, Icon, GridColumn, GridRow} from "semantic-ui-react";
import {toast, ToastContainer} from 'react-toastify';
import {UserRoleContext} from "../../Main";
import axios from "axios";
import moment from "moment";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import SearchTableData from "../Utilities/SearchTableData";
import ReactExport from "react-data-export";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";


const reducer = (state, action)=> {
    switch (action.type) {
        case 'students':
            return {...state, students: action.payload};
        case 'studentsData':
            return {...state, studentsData: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'professions':
            return {...state, professions: action.payload};
        case 'profession':
            return {...state, profession: action.payload};
        case 'occupation':
            return {...state, occupation: action.payload};
        case 'occupations':
            return {...state, occupations: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        default:
            return state;

    }
}

const EnrolledStudents = ({reportId, gradStudents, allStudents, currentStudents, droppedOutStudents, formalEducation, informalEducation, profession, occupation}) => {
    const {activeRole} = useContext(UserRoleContext);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [state, dispatch] = useReducer(reducer,{
        students: [],
        studentsData: [],
        data: [],
        results: [],
        professions: [],
        profession: null,
        occupations: [],
        occupation: null,
        organizer: null,
        organizers: [],
        currentId: null,
    });

    const columns = [
        { title: 'JMBG', width: { wpx: 100 } },
        { title: 'Ime', width: { wpx: 150 } },
        { title: 'Prezime', width: { wpx: 150 } },
        { title: 'Vrsta obrazovanja', width: { wpx: 150 } },
        { title: 'Struka', width: { wpx: 250 } },
        { title: 'Zanimanje', width: { wpx: 250 } },
        { title: 'Početak obrazovnog procesa', width: { wpx: 200 } },
        { title: 'Naziv programa', width: { wpx: 300 } },
    ];

    if (activeRole !== "ROLE_ORGANIZER") {
        columns.unshift({ title: 'Organizator', width: { wpx: 350 } });
    }

    const multiDataSet = [
        {
            columns: columns,
            data: state.studentsData,
        }
    ];

    // const multiDataSet = [
    //     {
    //         columns: [
    //             { title: 'JMBG', width: { wpx: 100 } },
    //             { title: 'Ime', width: { wpx: 150 } },
    //             { title: 'Prezime', width: { wpx: 150 } },
    //             { title: 'Vrsta obrazovanja', width: { wpx: 150 } },
    //             { title: 'Struka', width: { wpx: 250 } },
    //             { title: 'Zanimanje', width: { wpx: 250 } },
    //             { title: 'Početak obrazovnog procesa', width: { wpx: 200 } },
    //             { title: 'Naziv programa', width: { wpx: 300 } },
    //         ],
    //         data: state.studentsData,
    //     }
    // ];

    function getResults(results) {
        dispatch({type: 'results', payload: results});
    }

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }


    useEffect(() => {
        if (activeRole === "ROLE_ORGANIZER" && reportId === '0') {
            getStudents();
        }
        else if (activeRole === "ROLE_ORGANIZER" && reportId === '1') {
            getGradStudents()
        }
        else if (activeRole === "ROLE_ORGANIZER" && reportId === '2'){
            getDroppedOutStudents()
        }
        else if (activeRole === "ROLE_ORGANIZER" && reportId === '3'){
            getCurrentStudents()
        }
        else if (activeRole === "ROLE_ORGANIZER" && reportId === '4') {
            getFormalStudents()
        }
        else if (activeRole === "ROLE_ORGANIZER" && reportId === '5'){
            getInformalStudents()
        }
        else if(activeRole === "ROLE_ORGANIZER" && (reportId === '6' || reportId === '7')){
            getProfessions()
        }
        // else if((activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY" ) && reportId === '0'){
        //     getProfessions()
        // }
        else{
            getOrganizers()
            if((activeRole === "ROLE_ADMIN" || activeRole=== "ROLE_MINISTRY")  && (reportId === '6' || reportId === '7'))
            {
                getProfessions()
            }
            if((activeRole === "ROLE_ADMIN" || activeRole=== "ROLE_MINISTRY")  && (reportId === '0'))
            {
                getStudentsAll()
            }
            if((activeRole === "ROLE_ADMIN" || activeRole=== "ROLE_MINISTRY")  && (reportId === '1'))
            {
                getGradStudentsAll()
            }
            if((activeRole === "ROLE_ADMIN" || activeRole=== "ROLE_MINISTRY")  && (reportId === '2'))
            {
                getDroppedOutStudentsAll()
            }
            if((activeRole === "ROLE_ADMIN" || activeRole=== "ROLE_MINISTRY")  && (reportId === '3'))
            {
                getCurrentStudentsAll()
            }
            if((activeRole === "ROLE_ADMIN" || activeRole=== "ROLE_MINISTRY")  && (reportId === '4'))
            {
                getFormalStudentsAll()
            }
            if((activeRole === "ROLE_ADMIN" || activeRole=== "ROLE_MINISTRY")  && (reportId === '5'))
            {
                getInformalStudentsAll()
            }
        }
    },[activeRole, reportId]);

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'profession' && value && activeRole === "ROLE_ORGANIZER")
        {
            localStorage.setItem('professionId', JSON.stringify(value.id))
            getStudentsWithProfession(value.id)
            if(reportId === '7' && value)
            {
                getOccupationsByProfessionId(value.id)
                dispatch({type: 'occupation', payload: null});
            }
        }
        if( name === 'occupation' && activeRole === "ROLE_ORGANIZER" ) {
            getStudentsWithOccupation(value.id)
        }

        if(name === 'organizer' && reportId === '0'){
            getStudentsForAdmin(value)
        }
        if(name === 'organizer' && !value && reportId === '0'){
            getStudentsAll()
        }
        if(name === 'organizer' && reportId === '1'){
            getGradStudentsForAdmin(value)
        }

        if(name === 'organizer' && !value && reportId === '1'){
            getGradStudentsAll()
        }
        if(name === 'organizer' && reportId === '2'){
            getDroppedOutStudentsForAdmin(value)
        }

        if(name === 'organizer' && !value && reportId === '2'){
            getDroppedOutStudentsAll()
        }

        if(name === 'organizer' && reportId === '3'){
            getCurrentStudentsForAdmin(value)
        }

        if(name === 'organizer' && !value && reportId === '3'){
            getCurrentStudentsAll()
        }
        if(name === 'organizer' && reportId === '4'){
            getFormalStudentsForAdmin(value)
        }

        if(name === 'organizer' && !value && reportId === '4'){
            getFormalStudentsAll()
        }

        if(name === 'organizer' && reportId === '5'){
            getInformalStudentsForAdmin(value)
        }

        if(name === 'organizer' && !value && reportId === '5'){
            getInformalStudentsAll()
        }

        if(name === 'profession' && (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY")){
            console.log('tu', state.organizer, value.id)
            getStudentsWithProfessionForAdmin(state.organizer, value.id)
            if(reportId === '7' && value)
            {
                getOccupationsByProfessionId(value.id)
                dispatch({type: 'occupation', payload: null});
            }
        }

        if( name === 'occupation' && (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") ) {
            getStudentsWithOccupationForAdmin(state.organizer,value.id)
        }
        if(name === 'organizer' && (activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY"))
        {
            dispatch({type: 'profession', payload: null});
            dispatch({type: 'occupation', payload: null});
        }

    }

    function getProfessions(){
        axios.get('/profession/all',).then(
            (res) => {
                let professions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type: 'professions', payload: professions});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOccupationsByProfessionId(id){
        axios.get(`/occupation/by-profession-id/${id}`,).then(
            (res) => {
                let occupations = res.data.map((el)=>({key: el.id, text: el.nameBosnian, value: el}));
                dispatch({type: 'occupations', payload: occupations});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getStudents(){
        axios.get('/student-study-program/by-organizer',).then(
            (res) => {
                let students = res.data.map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });

                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function getStudentsForAdmin(organizerId){
        setCurrentId(organizerId);
        setCurrentIdOrganizer(organizerId);
        console.log('u funkciji', organizerId)
        axios.get(`/student-study-program/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let students = res.data.map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    studyProgramFilter: el.studyProgram.name,
                    startDateFilter: moment(el.startDate).format('D. M. YYYY.')
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name},
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getStudentsAll(){
        axios.get('/student-study-program/all',).then(
            (res) => {
                let students = res.data.map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });

                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name},
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getGradStudents(){
        axios.get('/student-study-program/by-organizer',).then(
            (res) => {
                let students = res.data.filter((e=>(e.graduationDate))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.graduationDate))).map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getGradStudentsForAdmin(organizerId){
        setCurrentId(organizerId);
        setCurrentIdOrganizer(organizerId);
        console.log('u funkciji', organizerId)
        axios.get(`/student-study-program/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let students = res.data.filter((e=>(e.graduationDate))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    studyProgramFilter: el.studyProgram.name,
                    startDateFilter: moment(el.startDate).format('D. M. YYYY.')
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.graduationDate))).map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name},
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getGradStudentsAll(){
        axios.get('/student-study-program/all',).then(
            (res) => {
                let students = res.data.filter((e=>(e.graduationDate))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.graduationDate))).map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name},
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }


    function getDroppedOutStudents(){
        axios.get('/student-study-program/by-organizer',).then(
            (res) => {
                let students = res.data.filter((e=>(e.droppedOutDate))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.droppedOutDate))).map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getDroppedOutStudentsForAdmin(organizerId){
        setCurrentId(organizerId);
        setCurrentIdOrganizer(organizerId);
        console.log('u funkciji', organizerId)
        axios.get(`/student-study-program/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let students = res.data.filter((e=>(e.droppedOutDate))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    studyProgramFilter: el.studyProgram.name,
                    startDateFilter: moment(el.startDate).format('D. M. YYYY.')
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.droppedOutDate))).map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name},
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getDroppedOutStudentsAll(){
        axios.get('/student-study-program/all',).then(
            (res) => {
                let students = res.data.filter((e=>(e.droppedOutDate))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.droppedOutDate))).map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name},
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getCurrentStudents(){
        axios.get('/student-study-program/by-organizer',).then(
            (res) => {
                let students = res.data.filter((e=>(!e.graduationDate && !e.droppedOutDate))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(!e.graduationDate && !e.droppedOutDate))).map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getCurrentStudentsForAdmin(organizerId){
        setCurrentId(organizerId);
        setCurrentIdOrganizer(organizerId);
        console.log('u funkciji', organizerId)
        axios.get(`/student-study-program/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let students = res.data.filter((e=>(!e.droppedOutDate && !e.graduationDate))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    studyProgramFilter: el.studyProgram.name,
                    startDateFilter: moment(el.startDate).format('D. M. YYYY.')
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(!e.droppedOutDate && !e.graduationDate))).map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name},
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getCurrentStudentsAll(){
        axios.get('/student-study-program/all',).then(
            (res) => {
                let students = res.data.filter((e=>(!e.droppedOutDate && !e.graduationDate))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(!e.droppedOutDate && !e.graduationDate))).map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getFormalStudents(){
        axios.get('/student-study-program/by-organizer',).then(
            (res) => {
                let students = res.data.filter((e=>(e.studyProgram.supplementaryEducationType.name === "srednje obrazovanje" || e.studyProgram.supplementaryEducationType.name === "osnovno obrazovanje" || e.studyProgram.supplementaryEducationType.name === "dokvalifikacija" || e.studyProgram.supplementaryEducationType.name === "prekvalifikacija"))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.studyProgram.supplementaryEducationType.name === "srednje obrazovanje" || e.studyProgram.supplementaryEducationType.name === "osnovno obrazovanje" || e.studyProgram.supplementaryEducationType.name === "dokvalifikacija" || e.studyProgram.supplementaryEducationType.name === "prekvalifikacija"))).map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getFormalStudentsForAdmin(organizerId){
        setCurrentId(organizerId);
        setCurrentIdOrganizer(organizerId);
        console.log('u funkciji', organizerId)
        axios.get(`/student-study-program/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let students = res.data.filter((e=>(e.studyProgram.supplementaryEducationType.name === "srednje obrazovanje" || e.studyProgram.supplementaryEducationType.name === "osnovno obrazovanje" || e.studyProgram.supplementaryEducationType.name === "dokvalifikacija" || e.studyProgram.supplementaryEducationType.name === "prekvalifikacija"))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    studyProgramFilter: el.studyProgram.name,
                    startDateFilter: moment(el.startDate).format('D. M. YYYY.')
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.studyProgram.supplementaryEducationType.name === "srednje obrazovanje" || e.studyProgram.supplementaryEducationType.name === "osnovno obrazovanje" || e.studyProgram.supplementaryEducationType.name === "dokvalifikacija" || e.studyProgram.supplementaryEducationType.name === "prekvalifikacija"))).map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name},
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getFormalStudentsAll(){
        axios.get('/student-study-program/all',).then(
            (res) => {
                let students = res.data.filter((e=>(e.studyProgram.supplementaryEducationType.name === "srednje obrazovanje" || e.studyProgram.supplementaryEducationType.name === "osnovno obrazovanje" || e.studyProgram.supplementaryEducationType.name === "dokvalifikacija" || e.studyProgram.supplementaryEducationType.name === "prekvalifikacija"))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.studyProgram.supplementaryEducationType.name === "srednje obrazovanje" || e.studyProgram.supplementaryEducationType.name === "osnovno obrazovanje" || e.studyProgram.supplementaryEducationType.name === "dokvalifikacija" || e.studyProgram.supplementaryEducationType.name === "prekvalifikacija"))).map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getInformalStudents(){
        axios.get('/student-study-program/by-organizer',).then(
            (res) => {
                let students = res.data.filter((e=>(e.studyProgram.supplementaryEducationType.name === "usavršavanje" || e.studyProgram.supplementaryEducationType.name === "osposobljavanje"))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.studyProgram.supplementaryEducationType.name === "usavršavanje" || e.studyProgram.supplementaryEducationType.name === "osposobljavanje"))).map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getInformalStudentsForAdmin(organizerId){
        setCurrentId(organizerId);
        setCurrentIdOrganizer(organizerId);
        console.log('u funkciji', organizerId)
        axios.get(`/student-study-program/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let students = res.data.filter((e=>(e.studyProgram.supplementaryEducationType.name === "usavršavanje" || e.studyProgram.supplementaryEducationType.name === "osposobljavanje"))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    studyProgramFilter: el.studyProgram.name,
                    startDateFilter: moment(el.startDate).format('D. M. YYYY.')
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.studyProgram.supplementaryEducationType.name === "usavršavanje" || e.studyProgram.supplementaryEducationType.name === "osposobljavanje"))).map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name},
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getInformalStudentsAll(){
        axios.get('/student-study-program/all',).then(
            (res) => {
                let students = res.data.filter((e=>(e.studyProgram.supplementaryEducationType.name === "usavršavanje" || e.studyProgram.supplementaryEducationType.name === "osposobljavanje"))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.studyProgram.supplementaryEducationType.name === "usavršavanje" || e.studyProgram.supplementaryEducationType.name === "osposobljavanje"))).map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }


    function getStudentsWithProfession(id){
        axios.get('/student-study-program/by-organizer',).then(
            (res) => {
                let students = res.data.filter((e=>(e.studyProgram.organizerOccupation.occupation.profession.id === id))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.studyProgram.organizerOccupation.occupation.profession.id === id))).map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getStudentsWithProfessionForAdmin(organizerId, id){
        setCurrentId(organizerId);
        setCurrentIdOrganizer(organizerId);
        console.log('u funkciji', organizerId)
        axios.get(`/student-study-program/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let students = res.data.filter((e=>(e.studyProgram.organizerOccupation.occupation.profession.id === id))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    studyProgramFilter: el.studyProgram.name,
                    startDateFilter: moment(el.startDate).format('D. M. YYYY.')
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.studyProgram.organizerOccupation.occupation.profession.id === id))).map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name},
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getStudentsWithOccupation(id){
        axios.get('/student-study-program/by-organizer',).then(
            (res) => {
                let students = res.data.filter((e=>(e.studyProgram.organizerOccupation.occupation.id === id))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    startDateFilter:  moment(el.startDate).format('D. M. YYYY.'),
                    studyProgramFilter: el.studyProgram.name
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.studyProgram.organizerOccupation.occupation.id === id))).map(el => ([
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getStudentsWithOccupationForAdmin(organizerId, id){
        setCurrentId(organizerId);
        setCurrentIdOrganizer(organizerId);
        console.log('u funkciji', organizerId)
        axios.get(`/student-study-program/by-organizer-id/${organizerId}`,).then(
            (res) => {
                let students = res.data.filter((e=>(e.studyProgram.organizerOccupation.occupation.id === id))).map(el => ({
                    ...el,
                    jmbg: el.student.person.jmbg,
                    firstName: el.student.person.firstName,
                    lastName: el.student.person.lastName,
                    supplementaryEducationType:el.studyProgram.supplementaryEducationType.name,
                    occupation: el.studyProgram.organizerOccupation.occupation.profession.name,
                    nameBosnian: el.studyProgram.organizerOccupation.occupation.nameBosnian,
                    studyProgramFilter: el.studyProgram.name,
                    startDateFilter: moment(el.startDate).format('D. M. YYYY.')
                }))
                // let students = res.data;
                students.sort((a, b) => {
                    return new Date(b.startDate) - new Date(a.startDate);
                });
                dispatch({type: 'students', payload: students});
                dispatch({type: 'data', payload: students});
                let studentsData = students.filter((e=>(e.studyProgram.organizerOccupation.occupation.id === id))).map(el => ([
                    { value: el.studyProgram.organizerOccupation.organizer.name},
                    { value: el.student.person.jmbg },
                    { value: el.student.person.firstName },
                    { value: el.student.person.lastName },
                    { value: el.studyProgram.supplementaryEducationType.name },
                    { value: el.studyProgram.organizerOccupation.occupation.profession.name },
                    { value: el.studyProgram.organizerOccupation.occupation.nameBosnian },
                    { value:  moment(el.startDate).format('D. M. YYYY.') },
                    { value: el.studyProgram.name },
                ]));
                dispatch({type: 'studentsData', payload: studentsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }



    return (
        <div>
            <Form size={'small'}>
                <Form.Group widths="3">
                    {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY" ) &&
                    <Form.Field>
                        <label>Organizator</label>
                        <Form.Dropdown
                            placeholder="Odaberite organizatora"
                            value={state.organizer}
                            onChange={handleInputChange}
                            name="organizer"
                            options={state.organizers}
                            search
                            fluid
                            selection
                            clearable
                        />
                    </Form.Field>}
                    {(reportId === '6' || reportId === '7') &&
                        <Form.Field>
                            <label>Struka</label>
                            <Form.Dropdown
                                placeholder="Odaberite struku"
                                value={state.profession}
                                onChange={handleInputChange}
                                name="profession"
                                options={state.professions}
                                search
                                fluid
                                selection
                                clearable
                            />
                        </Form.Field>}
                    {reportId === '7' &&
                    <Form.Field>
                        <label>Zanimanje</label>
                        <Form.Dropdown
                            placeholder="Odaberite zanimanje"
                            value={state.occupation}
                            onChange={handleInputChange}
                            name="occupation"
                            options={state.occupations}
                            search
                            fluid
                            selection
                            clearable
                        />
                    </Form.Field>}
                </Form.Group>
            </Form>
            {((reportId === '6' && !state.profession) || (reportId === '7' && !state.occupation)) ? '' :
                    state.students.length === 0 ? 'Nema polaznika.' : <div> {state.students.length ?<Table celled size={'small'}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={activeRole !== "ROLE_ORGANIZER" ? 10 : 8} style={{ padding: '0px' }}>
                                    <Grid column={2}>
                                        <Grid.Column width={14}>
                                            <SearchTableData data={state.data} getResults={getResults} filterItem={['startDateFilter', 'jmbg', 'firstName', 'lastName', 'supplementaryEducationType', 'occupation', 'nameBosnian', 'studyProgramFilter']} />
                                                {/*<SearchTableData profMozilla data={this.state.students} getResults={this.getResults} filterItem={['organizationalUnitName', 'lastName', 'firstName', 'fatherName', 'index', 'studyProgramSemesterName', 'studyCycleName', 'studyProgramBasicName', 'department', 'studyProgramName', 'studyTypeName', 'studentStatusName']} />*/}
                                            </Grid.Column>
                                        <Grid.Column width={2} textAlign="center">
                                            <ExcelFile filename={allStudents ? "Ukupan broj polaznika" :
                                                gradStudents ? "Polaznici koji su završili školovanje" :
                                                    droppedOutStudents ? "Polaznici koji su odustali od školovanja" :
                                                        currentStudents ? "Polaznici koji su trenutno na školovanju":
                                                            formalEducation ? "Polaznici formalnog obrazovanja" :
                                                                informalEducation ? "Polaznici neformalnog obrazovanja" :
                                            profession ? "Polaznici po strukama" : "Polaznici po zanimanjima"} element={<Popup
                                                trigger={<Button animated="vertical" color='green' basic floated='right' fluid style={{ textAlign: 'center' }}>
                                                    <Button.Content hidden> <Icon name="download" /></Button.Content>
                                                    <Button.Content visible>
                                                        Excel
                                                    </Button.Content>
                                                </Button>}
                                                content="Preuzmi spisak u Excel-u"
                                                hideOnScroll
                                                position="top center"
                                            />}>
                                                <ExcelSheet dataSet={multiDataSet} name="Polaznici" />
                                            </ExcelFile>
                                        </Grid.Column>
                                    </Grid>
                                    {/*<div style={{ paddingLeft: '0px' }}>*/}
                                    {/*    <SearchTableData data={state.data} getResults={getResults} filterItem={['startDateFilter', 'jmbg', 'firstName', 'lastName', 'supplementaryEducationType', 'occupation', 'nameBosnian', 'studyProgramFilter']} />*/}
                                    {/*</div>*/}
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>

                                <Table.HeaderCell >R. br.</Table.HeaderCell>
                                {activeRole !== "ROLE_ORGANIZER"  &&<Table.HeaderCell colSpan={2} >Organizator</Table.HeaderCell>}
                                <Table.HeaderCell>JMBG</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Ime i prezime</Table.HeaderCell>
                                <Table.HeaderCell>Vrsta obrazovanja</Table.HeaderCell>
                                <Table.HeaderCell>Struka / zanimanje</Table.HeaderCell>
                                <Table.HeaderCell>Program</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Početak obrazovnog procesa</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                getDataToRender().map((el, index) =>
                                    // state.students.map((el, index)=> negative={!!el.droppedOutDate} positive={!!el.graduationDate}
                                    (<Table.Row key={el.id} >
                                        <Table.Cell >{(index + 1)}.</Table.Cell>
                                        {activeRole !== "ROLE_ORGANIZER"  &&<Table.Cell colSpan={2}>{el.studyProgram.organizerOccupation.organizer.name}</Table.Cell>}
                                        <Table.Cell>{el.student.person.jmbg}</Table.Cell>
                                        <Table.Cell width={2}>
                                            {el.student.person.firstName} {el.student.person.lastName}
                                            {el.graduationDate && <Popup content="Polaznik je završio." hideOnScroll position="top center" trigger={<span size="tiny" as="a" basic style={{ float: 'right' }}><span><Icon name="lock" color="blue" /></span></span>} />}
                                            {el.droppedOutDate && <Popup content="Polaznik je ispisan." hideOnScroll position="top center" trigger={<span size="tiny" as="a" basic style={{ float: 'right' }}><span><Icon name="sign-out" color="red" /></span></span>} />}
                                        </Table.Cell>
                                        <Table.Cell>{el.studyProgram.supplementaryEducationType.name}</Table.Cell>
                                        <Table.Cell>{el.studyProgram.organizerOccupation.occupation.profession.name} / <strong>{el.studyProgram.organizerOccupation.occupation.nameBosnian}</strong></Table.Cell>
                                        <Table.Cell>{el.studyProgram.name}</Table.Cell>
                                        <Table.Cell width={2}>{moment(el.startDate).format('D. M. YYYY.')}</Table.Cell>
                                    </Table.Row>)
                                )
                            }


                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='14' textAlign='center'>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table> : ''}</div>}

            <ToastContainer  />
        </div>
    );
};

export default EnrolledStudents;
