import React, {useReducer} from 'react';
import {Form, Header, Icon, Input, SegmentGroup} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'documentTypes':
            return {...state, documentTypes: action.payload};
        case 'documentType':
            return {...state, documentType: action.payload};
        case 'quantity':
            return {...state, quantity: action.payload};
        default:
            return state;

    }
};
const DocumentSuppliesForm = ({add, toggleEditForm, getSupplies}) => {

    // const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        supplies: [1],
        documentTypes: [1],
        documentType: null,
        quantity: null,

    });

    function addSupply(){}
    function editSupply(){}

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {add?'Dodavanje zaliha obrasca':'Izmjena podataka'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths={'equal'}>

                            <Form.Field>
                                <label>Vrsta obrasca</label>
                                <Form.Dropdown
                                    placeholder="Odaberite vrstu"
                                    value={state.documentType}
                                    onChange={handleInputChange}
                                    name="documentType"
                                    options={state.documentTypes}
                                    search
                                    fluid
                                    selection
                                />
                            </Form.Field>

                            <Form.Field
                                required
                                //error={JIBErr}
                                label="Količina"
                                control={Input}
                                name="quantity"
                                type="number"
                                value={state.quantity}
                                onChange={handleInputChange}
                                placeholder="Unesite količinu"
                            />

                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={add ? addSupply : editSupply}>{add ? 'Dodaj' : 'Sačuvaj'}</Button>
                    </Form>
                </Segment>
            </SegmentGroup>


        </div>
    );
};

export default DocumentSuppliesForm;
