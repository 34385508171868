import React from 'react';
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {Divider, GridColumn, GridRow, Header, Input, Segment, Select} from "semantic-ui-react";
import {DateTimePicker} from "react-widgets";
import PlaceForm from "../Utilities/PlaceForm";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import StudentPlaceOfResidenceForm from "./StudentPlaceOfResidenceForm";
import PlaceInfo from "../Organizers/OrganizerDetails/PlaceInfo";

const PersonForm = ({student, citizenship, jmbg, oid, dateOfBirth, firstName, lastName, maidenName, gender, nextStep,
                        genderOptions,fatherFirstName, fatherLastName, motherFirstName, motherLastName,placeOfBirthBackObj, placeOfResidenceBackObj,
                        placeOfBirth, placeOfResidence,
                        handleInputChange, handleDateChange, handlePlaceChange, countries, entities, cantons, municipalities,
                        jmbgErr, dateOfBirthErr, citizenshipErr, firstNameErr, lastNameErr, genderErr, motherLastNameErr, motherFirstNameErr,
                        fatherLastNameErr, fatherFirstNameErr, countryErr, placeErr, placeRErr, countryRErr, addressErr, zipErr, disablePerson, disableStudent, showPlaceInfo }) => {
    return (
        <div>
            <Grid columns={3} stackable>
                <GridRow>
                    <GridColumn>
                        <Segment.Group raised >
                            <Segment >
                                <Header as="h4">
                                    Lični podaci
                                </Header>
                            </Segment>
                            <Segment>
                        <Form size={'small'}>
                            <Form.Group widths={'equal'} >
                                <Form.Field

                                    required
                                    error={jmbgErr}
                                    label="JMBG"
                                    control={Input}
                                    name="jmbg"
                                    value={jmbg}
                                    onChange={handleInputChange}
                                    placeholder="Unesite JMBG"
                                />
                                {/*<Form.Field*/}

                                {/*    required*/}
                                {/*    label="OID"*/}
                                {/*    control={Input}*/}
                                {/*    name="oid"*/}
                                {/*    type="number"*/}
                                {/*    value={oid}*/}
                                {/*    onChange={handleInputChange}*/}
                                {/*    placeholder="Unesite OID"*/}
                                {/*/>*/}
                            </Form.Group>
                            <Form.Group widths={'equal'} >
                                <Form.Field
                                    disabled={disablePerson}
                                    required
                                    error={firstNameErr}
                                    label="Ime"
                                    control={Input}
                                    name="firstName"
                                    value={firstName}
                                    onChange={handleInputChange}
                                    placeholder="Unesite ime"
                                />
                                <Form.Field
                                    disabled={disablePerson}
                                    required
                                    error={lastNameErr}
                                    label="Prezime"
                                    control={Input}
                                    name="lastName"
                                    value={lastName}
                                    onChange={handleInputChange}
                                    placeholder="Unesite prezime"
                                />
                            </Form.Group>
                            <Form.Group widths={'equal'} >
                                <Form.Field
                                    disabled={disablePerson}
                                    error={genderErr}
                                    required
                                    control={Select}
                                    clearable
                                    options={genderOptions.filter(el => el.value.name !== 'nepoznato')}
                                    placeholder="Odaberite spol"
                                    label="Spol"
                                    onChange={handleInputChange}
                                    name="gender"
                                    selectOnNavigation={false}
                                    openOnFocus={false}
                                    value={gender}
                                    search
                                />
                                <Form.Field

                                    // error={courtRegistrationInputNumberErr}
                                    disabled={!gender || gender.key !== 'f' || disablePerson}
                                    label="Djevojačko prezime"
                                    control={Input}
                                    name="maidenName"
                                    value={maidenName}
                                    onChange={handleInputChange}
                                    placeholder="Unesite djevojačko prezime"
                                />


                            </Form.Group>
                            <Form.Group widths={'equal'} >

                                <Form.Field
                                    disabled={disablePerson}
                                    required
                                    error={dateOfBirthErr}
                                    control={DateTimePicker}
                                    label="Datum rođenja"
                                    format="D. M. YYYY."
                                    placeholder="Unesite datum"
                                    name="dateOfBirth"
                                    value={dateOfBirth}
                                    time={false}
                                    onChange={(e) => handleDateChange('dateOfBirth', e)}
                                    style={{ borderWidth: '0px' }}
                                    //error={openFromDateErr}
                                />
                                {student && <Form.Field
                                    // disabled={disablePerson}
                                    disabled={disablePerson}
                                    error={citizenshipErr}
                                    required
                                    control={Select}
                                    clearable
                                    options={countries}
                                    placeholder="Odaberite državljanstvo"
                                    label="Državljanstvo"
                                    onChange={handleInputChange}
                                    name="citizenship"
                                    selectOnNavigation={false}
                                    openOnFocus={false}
                                    value={citizenship}
                                    search
                                />}
                            </Form.Group>
                            {student && <>
                                <Form.Group widths={'equal'} >
                                    <Form.Field
                                        disabled={disableStudent}
                                        required
                                        error={fatherFirstNameErr}
                                        label="Ime oca"
                                        control={Input}
                                        name="fatherFirstName"
                                        value={fatherFirstName}
                                        onChange={handleInputChange}
                                        placeholder="Unesite ime"
                                    />
                                    <Form.Field
                                        disabled={disableStudent}
                                        required
                                        error={fatherLastNameErr}
                                        label="Prezime oca"
                                        control={Input}
                                        name="fatherLastName"
                                        value={fatherLastName}
                                        onChange={handleInputChange}
                                        placeholder="Unesite prezime"
                                    />
                                </Form.Group>
                                <Form.Group widths={'equal'} >
                                    <Form.Field
                                        disabled={disableStudent}
                                        required
                                        error={motherFirstNameErr}
                                        label="Ime majke"
                                        control={Input}
                                        name="motherFirstName"
                                        value={motherFirstName}
                                        onChange={handleInputChange}
                                        placeholder="Unesite ime"
                                    />
                                    <Form.Field
                                        disabled={disableStudent}
                                        required
                                        error={motherLastNameErr}
                                        label="Prezime majke"
                                        control={Input}
                                        name="motherLastName"
                                        value={motherLastName}
                                        onChange={handleInputChange}
                                        placeholder="Unesite prezime"
                                    />
                                </Form.Group>


                            </>}
                        </Form>
                        </Segment>
                        </Segment.Group>
                    </GridColumn>
                    <GridColumn>

                        <Segment.Group raised>
                            <Segment >
                                <Header as="h4">
                                    Mjesto rođenja
                                </Header>
                            </Segment>
                            <Segment>
                               {!showPlaceInfo ? <PlaceForm
                                    country={placeOfBirth.country}
                                    place={placeOfBirth.place}

                                    noPlaceNoAddress
                                    handlePlaceChange={handlePlaceChange}
                                    formName={'placeOfBirth'}
                                    countries={countries}
                                    entities={entities}
                                    cantons={cantons}
                                    municipalities={municipalities}

                                    countryErr={countryErr}
                                    placeErr={placeErr}
                                /> :
                                <PlaceInfo placeOfBirth={placeOfBirthBackObj}/>}
                        </Segment>
                        </Segment.Group>
                    </GridColumn>
                    <GridColumn>

                        <Segment.Group raised>
                            <Segment >
                                <Header as="h4">
                                    Prebivalište
                                </Header>
                            </Segment>
                            <Segment>
                        {!showPlaceInfo ? <PlaceForm
                            country={placeOfResidence.country}
                            place={placeOfResidence.place}
                            address={placeOfResidence.address}
                            zip={placeOfResidence.zip}

                            oneRowTwoFields
                            handlePlaceChange={handlePlaceChange}
                            formName={'placeOfResidence'}
                            countries={countries}
                            entities={entities}
                            cantons={cantons}
                            municipalities={municipalities}

                            countryErr={countryRErr}
                            placeErr={placeRErr}
                            addressErr={addressErr}
                            zipErr={zipErr}
                        /> :
                        <PlaceInfo residence={placeOfResidenceBackObj}/>}
                            </Segment>
                        </Segment.Group>
                    </GridColumn>
                </GridRow>
            </Grid>
            {/*<Button floated={"right"} basic size={"small"} color={"teal"} onClick={()=>nextStep(1)} >{user ? 'Sačuvaj' :'Nastavi'}</Button>*/}
            <Button floated={"right"} basic size={"small"} color={"teal"} onClick={()=>nextStep(1)} >{'Nastavi'}</Button>



        </div>
    );
};

export default PersonForm;
