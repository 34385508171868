import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Header, Loader, Menu, SegmentGroup} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {useLocation} from "react-router-dom";
import OccupationForm from "./OccupationForm";
import axios from "axios";
import {UserRoleContext} from "../../Main";
import SearchTableData from "../Utilities/SearchTableData";
import moment from "moment";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import {toast, ToastContainer} from "react-toastify";
import ReactExport from 'react-data-export';
import PaginationFront from "../Utilities/PaginationFront";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'occupations':
            return {...state, occupations: action.payload};
        case 'occupationsData':
            return {...state, occupationsData: action.payload };
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;

    }
};
const OrganizerOccupations = () => {
    const [showAddForm, setShowAddForm] = useState(false);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [activePage, setActivePage] = useState(1);
    const [state, dispatch] = useReducer(reducer,{
        occupations: [],
        occupationsData: [],
        data: [],
        results: [],
        postsPerPage: 20,
        currentPosts: [],
    });
    let location;
    let name, id;
    const {activeRole} = useContext(UserRoleContext);
    if(activeRole === 'ROLE_ADMIN' || activeRole === 'ROLE_MINISTRY'){
        // eslint-disable-next-line react-hooks/rules-of-hooks
        location = useLocation();
        name = location.state.name;
        id = location.state.id;
    }

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    const multiDataSet = [
        {
            columns: [
                { title: 'Struka', width: { wpx: 300 } },
                { title: 'Zanimanje', width: { wpx: 300 } },
            ],
            data: state.occupationsData,
        }
    ];

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }
    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    useEffect(() => {
        if(activeRole === 'ROLE_ADMIN' || activeRole === 'ROLE_MINISTRY') getOccupationForOrganizerByAdmin();
        if(activeRole === 'ROLE_ORGANIZER') getOccupationForOrganizer();

    },[]);

    function getOccupationForOrganizerByAdmin(){
        axios.get(`organizer-occupation/by-organizer-id/${id}`,).then(
            (res) => {
                let occupations = res.data.map(el => ({
                    ...el,
                    professionName: el.occupation.profession.name,
                    occupationNameBosnian: el.occupation.nameBosnian
                }))
                dispatch({type: 'occupations', payload: occupations});
                dispatch({type: 'data', payload: occupations});
                let occupationsData = res.data.map(el => ([
                    { value: el.occupation.profession.name },
                    { value: el.occupation.nameBosnian },
                ]));
                dispatch({type: 'occupationsData', payload: occupationsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOccupationForOrganizer(){
        axios.get('/organizer-occupation/by-organizer',).then(
            (res) => {
                let occupations = res.data.map(el => ({
                    ...el,
                    professionName: el.occupation.profession.name,
                    occupationNameBosnian: el.occupation.nameBosnian
                }))
                dispatch({type: 'occupations', payload: occupations});
                dispatch({type: 'data', payload: occupations});
                let occupationsData = res.data.map(el => ([
                    { value: el.occupation.profession.name },
                    { value: el.occupation.nameBosnian },
                ]));
                dispatch({type: 'occupationsData', payload: occupationsData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function deleteOrganizerOccupation(id){
        setCurrentId(id);
        dispatch({type: 'loader', payload: true});
        axios.delete(`/organizer-occupation/delete/${id}`,).then(
            (res) => {
                activeRole === 'ROLE_ORGANIZER'?getOccupationForOrganizer():getOccupationForOrganizerByAdmin()
                toast('Uspješno ste uklonili zanimanje!');
                setCurrentId(null);
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    return (
        <div>
            <SegmentGroup >
                <Segment className={'customPadding'} clearing color={"teal"}>
                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>
                        <Menu.Item ><Header as={'h3'} floated={"left"}>
                            Spisak registrovanih zanimanja
                            <span>
                            <Icon/></span>
                           {name && <Header.Subheader>
                                <i>Naziv organizatora: {name}</i>
                            </Header.Subheader>}
                        </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            <Menu.Item>
                                {!showAddForm && activeRole !== 'ROLE_MINISTRY' && <span>
                           <Button icon labelPosition='left' size="mini" basic color="black" floated={"right"} onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Dodaj zanimanje
                                </Button>

                                    {/*placeholder*/}
                                    <Icon/>
                       </span>}
                            </Menu.Item>
                            {((activeRole === "ROLE_ORGANIZER") || (state.organizer && (activeRole === "ROLE_MINISTRY" || activeRole === "ROLE_ADMIN"))) && <Menu.Item>
                                <ExcelFile filename="Spisak zanimanja" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={multiDataSet} name="Zanimanja" />
                                </ExcelFile>
                            </Menu.Item>}
                            <Menu.Item>
                            <span>
                                <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                    <Icon name='arrow left'/>
                                </Button>
                                <Icon/>
                            </span>
                            </Menu.Item>

                        </Menu.Menu>
                    </Menu>
                </Segment>
                {showAddForm &&
                <Segment>
                    {/*send add or edit as props*/}
                    <OccupationForm add toggleEditForm={toggleAddForm} getOccupationForOrganizer={activeRole === 'ROLE_ORGANIZER'?getOccupationForOrganizer:getOccupationForOrganizerByAdmin} organizerId={id} />
                </Segment>}
                <Segment>
                    {state.loader ?
                            <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                            : currentPosts.length ?
                    <Table celled size={"small"}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={9} >
                                    <div style={{ paddingLeft: '0px' }}>
                                        <SearchTableData data={state.data} getResults={getResults} filterItem={['professionName', 'occupationNameBosnian']} />
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell width={1}>R. br.</Table.HeaderCell>
                                <Table.HeaderCell width={7}>Struka</Table.HeaderCell>
                                <Table.HeaderCell>Zanimanje</Table.HeaderCell>
                                {activeRole !== 'ROLE_MINISTRY' && <Table.HeaderCell/>}
                                {/*<Table.HeaderCell>Broj aktivnih nastavnih planova</Table.HeaderCell>*/}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                           {
                               currentPosts.map((el, index)=>
                                   (<Table.Row key = {el.id}>
                                           <Table.Cell width={1}>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                           <Table.Cell width={7}>{el.occupation.profession.name}</Table.Cell>
                                           <Table.Cell>{el.occupation.nameBosnian}</Table.Cell>
                                           {activeRole !== 'ROLE_MINISTRY' &&
                                               <Table.Cell width={1} verticalAlign="middle" textAlign="center">
                                                   <Popup size={"small"} content='Obriši zanimanje'
                                                          trigger={<Button icon size={"mini"} basic color={"grey"}
                                                                           disabled={state.currentId && el.id !== state.currentId}
                                                                           onClick={() => {
                                                                               deleteOrganizerOccupation(el.id)
                                                                           }}>
                                                              <Icon name='trash alternate outline' color={"red"}/>
                                                          </Button>}/>
                                               </Table.Cell>}
                                       </Table.Row>
                                   ))
                           }
                        </Table.Body>
                    </Table>: <div>Nema unesenih podataka o zanimanjima.</div>}
                    {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                </Segment>
            </SegmentGroup>
            <ToastContainer/>
        </div>
    );
};

export default OrganizerOccupations;
