import React, {useEffect, useReducer, useState} from 'react';
import {Form, Header, Icon, Input, SegmentGroup, Select} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import axios from "axios";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'professions':
            return {...state, professions: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        default:
            return state;

    }
};
const LkpOccupationForm = ({toggleEditForm, addOccupation, handleInputChange, profession, nameBosnian, nameSerbian, nameCroatian }) => {
    const [nameBosnianErr, setNameBosnianErr] = useState(false);
    const [nameSerbianErr, setNameSerbianErr] = useState(false);
    const [nameCroatianErr, setNameCroatianErr] = useState(false);
    const [state, dispatch] = useReducer(reducer,{
        professions: [],
        loader: false
    });

    function getProfessions(){

        axios.get('/profession/all',).then(
            (res) => {
                let professions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type: 'professions', payload: professions});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    useEffect(() => {
        getProfessions();
    },[]);

    return (
        <div>
            <SegmentGroup raised>
                <Segment >
                    <Header as={'h4'}> {'Dodavanje novog zanimanja'}
                        <span>
                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm}>
                               <Icon name='close'/>
                           </Button>
                            <Icon/>
                       </span>
                    </Header>
                </Segment>
                <Segment clearing>
                    <Form size={'small'}>
                        <Form.Group widths={'equal'}>
                            <Form.Field
                                required
                                control={Select}
                                clearable
                                options={state.professions}
                                placeholder="Struka"
                                label="Struka"
                                onChange={handleInputChange}
                                name="profession"
                                selectOnNavigation={false}
                                openOnFocus={false}
                                value={profession}
                                search

                            />

                            <Form.Field
                                required
                                label="Naziv zanimanja na bosanskom jeziku"
                                control={Input}
                                name="nameBosnian"
                                value={nameBosnian}
                                onChange={handleInputChange}
                                placeholder="Unesite naziv zanimanja na bosanskom jeziku"
                                error={nameBosnianErr}
                            />
                            <Form.Field
                                required
                                label="Naziv zanimanja na srpskom jeziku"
                                control={Input}
                                name="nameSerbian"
                                value={nameSerbian}
                                onChange={handleInputChange}
                                placeholder="Unesite naziv zanimanja na  srpskom jeziku"
                                error={nameSerbianErr}
                            />

                            <Form.Field
                                required
                                label="Naziv zanimanja na hrvatskom jeziku"
                                control={Input}
                                name="nameCroatian"
                                value={nameCroatian}
                                onChange={handleInputChange}
                                placeholder="Unesite naziv zanimanja na hrvatskom jeziku"
                                error={nameCroatianErr}
                            />

                        </Form.Group>
                        <Button floated={"right"} basic size={"small"} color={"teal"} onClick={addOccupation}>{'Dodaj'}</Button>
                    </Form>
                </Segment>
            </SegmentGroup>
        </div>
    );
};

export default LkpOccupationForm;
