import React, {useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Button, Header, Icon, Loader, Menu} from "semantic-ui-react";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import LKPOccupationForm from "./LKPOccupationForm";
import SearchTableData from "../Utilities/SearchTableData";
import ReactExport from 'react-data-export';
import PaginationFront from "../Utilities/PaginationFront";
import moment from "moment";

const reducer = (state, action)=>{
    switch (action.type) {
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'openTable':
            return {...state, openTable: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'occupation':
            return {...state, occupation: action.payload};
        case 'occupations':
            return {...state, occupations: action.payload};
        case 'occupationsData':
            return {...state, occupationsData: action.payload };
        case 'nameBosnian':
            return {...state, nameBosnian: action.payload};
        case 'nameSerbian':
            return {...state, nameSerbian: action.payload};
        case 'nameCroatian':
            return {...state, nameCroatian: action.payload};
        case 'profession':
            return {...state, profession: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;
    }
};


const Occupations = () => {
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [activePage, setActivePage] = useState(1);
    const [state, dispatch] = useReducer(reducer,{
        currentId: null,
        openTable: false,
        loader: false,
        occupations: [],
        occupationsData: [],
        occupation: '',
        data:[],
        results:[],
        postsPerPage: 20,
        currentPosts: [],
    } );

    const [showAddForm, setShowAddForm] = useState(false);

    const multiDataSet = [
        {
            columns: [
                { title: 'Struka', width: { wpx: 300 } },
                { title: 'Naziv zanimanja (B/H/S)', width: { wpx: 700 } },
            ],
            data: state.occupationsData,
        }
    ];

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    useEffect(() => {
       getOccupations();
    },[]);

    function deleteOccupation(id){
        setCurrentId(id);
        dispatch({type: 'loader', payload: true});
        axios.delete(`/occupation/delete/${id}`,).then(
            (res) => {
                getOccupations();
                toast('Uspješno ste izbrisali zanimanje!');
                setCurrentId(null);
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOccupations(){
        dispatch({type: 'loader', payload: true});
        axios.get('/occupation/all',).then(
            (res) => {
                // let occupation = res.data;
                let occupation = res.data.map(el => ({
                    ...el,
                    professionName:el.profession.name,
                }))
                // el.profession.name
                dispatch({type: 'occupations', payload: occupation});
                dispatch({type: 'data', payload: occupation});
                let occupationsData = res.data.map(el => ([
                    { value: el.profession.name },
                    { value: el.nameBosnian + '/' + el.nameCroatian + '/' + el.nameSerbian},
                ]));
                dispatch({type: 'occupationsData', payload: occupationsData});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function addOccupation(){
        let obj = {
            profession: state.profession,
            nameBosnian: state.nameBosnian,
            nameSerbian: state.nameSerbian,
            nameCroatian: state.nameCroatian,
        };
        dispatch({type: 'loader', payload: true});
        axios.post('/occupation/save', obj).then(
            (res) => {
                toast('Uspješno ste dodali zanimanje.')
                toggleAddForm();
                getOccupations();
            }).catch((err) => {
            console.log('err', err)
        });
    }


    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    return (
        <div>
            <Segment.Group >
                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}>Spisak zanimanja
                                <span><Icon/></span>
                            </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            {(!showAddForm) && <Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Dodaj zanimanje
                                </Button>
                            </Menu.Item>}
                            <Menu.Item>
                                <ExcelFile filename="Spisak zanimanja" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={multiDataSet} name="Zanimanja" />
                                </ExcelFile>
                            </Menu.Item>
                            <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                        </Menu.Menu>

                    </Menu>

                </Segment>

                {(showAddForm) &&
                <Segment>
                    <LKPOccupationForm add toggleEditForm={toggleAddForm} getCourses={getOccupations} addOccupation={addOccupation} handleInputChange={handleInputChange}/>
                </Segment>}


                <Segment>
                    {state.loader ?
                        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                        :
                        currentPosts.length ?
                            <Table celled size={'small'}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={9} >
                                            <div style={{ paddingLeft: '0px' }}>
                                                <SearchTableData data={state.data} getResults={getResults} filterItem={['nameBosnian','nameCroatian', 'nameSerbian', 'professionName']} />
                                            </div>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>

                                        <Table.HeaderCell >R. br.</Table.HeaderCell>
                                        <Table.HeaderCell >Struka</Table.HeaderCell>
                                        <Table.HeaderCell>Naziv zanimanja (B / H / S)</Table.HeaderCell>
                                        <Table.HeaderCell />
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        currentPosts.map((el, index) =>
                                        // state.occupations.map((el, index)=>
                                            (<Table.Row>
                                                <Table.Cell width={1}>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                                <Table.Cell width={4}>{el.profession.name}</Table.Cell>
                                                <Table.Cell>{el.nameBosnian} / {el.nameCroatian} / {el.nameSerbian}</Table.Cell>
                                                <Table.Cell width={1} verticalAlign="middle" textAlign="center">
                                                    {/*<Popup size={"small"} content='Izmijeni predmet' trigger={*/}
                                                    {/*    <Button icon size={"mini"} basic color={"grey"}  onClick={() => {setShowEditForm(true); dispatch({type: 'course', payload: el})}}>*/}
                                                    {/*        <Icon name='pencil' color={"teal"} />*/}
                                                    {/*    </Button>} />*/}
                                                    <Popup size={"small"} content='Obriši zanimanje' trigger={ <Button icon size={"mini"} basic color={"grey"}
                                                                                                                     disabled={state.currentId && el.id !== state.currentId } onClick={() => {deleteOccupation(el.id)}}>
                                                        <Icon name='trash alternate outline' color={"red"} />
                                                    </Button>} />
                                                </Table.Cell>
                                            </Table.Row>)
                                        )
                                    }
                                </Table.Body>

                                <Table.Footer>
                                    {/*<Table.Row>*/}
                                    {/*    <Table.HeaderCell colSpan='14' textAlign='center'>*/}
                                    {/*        <Menu  pagination>*/}
                                    {/*            <Menu.Item as='a' icon>*/}
                                    {/*                <Icon name='chevron left' />*/}
                                    {/*            </Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>1</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>2</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>3</Menu.Item>*/}
                                    {/*            <Menu.Item as='a'>4</Menu.Item>*/}
                                    {/*            <Menu.Item as='a' icon>*/}
                                    {/*                <Icon name='chevron right' />*/}
                                    {/*            </Menu.Item>*/}
                                    {/*        </Menu>*/}
                                    {/*    </Table.HeaderCell>*/}
                                    {/*</Table.Row>*/}
                                </Table.Footer>
                            </Table>: <div>Nema unesenih zanimanja.</div>}
                    {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                </Segment>

            </Segment.Group>
            <ToastContainer  />

        </div>
    );
};

export default Occupations;
