import './App.css';
import React from "react";
import AppRoutes from "./containers/AppRoutes/AppRoutes";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import MobileTopHeader from "./components/Header/MobileTopHeader";
import SecondaryFooter from "./components/Footer/SecondaryFooter";

function App() {

  return (

      <div className={'App'}>

          <Grid className='grid'>

              <Grid.Row only={"mobile tablet"} className={'removeTopPadding'}>
                  <Grid.Column className={'removeLeftRightPadding '} verticalAlign={"middle"}>
                      <MobileTopHeader/>
                  </Grid.Column>
              </Grid.Row>

              <Grid.Row className={'removeTopPadding'} only={"computer"}>
                  <Grid.Column className={'removeLeftRightPadding '} verticalAlign={"middle"}>
                      <Header/>
                  </Grid.Column>
              </Grid.Row>

              <Grid.Row  only={"computer"}>
                  <Grid.Column >
                      <div className={"placeholder"} >

                              <AppRoutes />

                      </div>
                  </Grid.Column>
              </Grid.Row>

              <Grid.Row className={'removeBottomPadding'} only={"mobile tablet"} >
                  {/*<Grid.Column width={4} className={"removeRightPadding"}>*/}
                  {/*    <MobileHeader/>*/}
                  {/*</Grid.Column>*/}
                  <Grid.Column className={'removeLeftRightPadding'} >

                          <div className={"placeholder bottomPadding"} >
                              <AppRoutes />
                          </div>

                          <Footer/>
                          <SecondaryFooter/>

                  </Grid.Column>
              </Grid.Row>

              <Grid.Row className={'removeBottomPadding'}  only={"computer"}>
                  <Footer/>
                  <SecondaryFooter/>
              </Grid.Row>

          </Grid>

      </div>
  );
}

export default App;
