import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from "../../components/Home/Home";
import Organizers from "../../components/Organizers/Organizers";
import OrganizerDetails from "../../components/Organizers/OrganizerDetails";
import OrganizerOccupations from "../../components/Organizers/OrganizerOccupations";
import Schedulers from "../../components/Schedulers/Schedulers";
import Students from "../../components/Students/Students";
import StudentDetails from "../../components/Students/StudentDetails";
import EditRequest from "../../components/Students/EditRequest";
import StudyPrograms from "../../components/StudyPrograms/StudyPrograms";
import Teachers from "../../components/Teachers/Teachers";
import TeacherDetails from "../../components/Teachers/TeacherDetails";
import Contests from "../../components/Contest/Contests";
import Contracts from "../../components/Contract/Contracts";
import Employers from "../../components/Employer/Employers";
import PersonSelector from "../PersonSelector";
import AddStudent from "../../components/Students/AddStudent";
import Courses from "../../components/StudyPrograms/Courses";
import AndragogyDocumentation from "../../components/AndragogyDocumentation/AndragogyDocumentation";
import DocumentTypes from "../../components/AndragogyDocumentation/DocumentTypes";
import DocumentSupplies from "../../components/AndragogyDocumentation/DocumentSupplies";
import AllCourses from "../../components/Courses/AllCourses";
import RecognizedCourseDocument from "../../components/Students/Recogniton/RecognizedCourseDocument";
import Committee from "../../components/Teachers/Committee/Committee";
import Zapisnik from "../../components/Students/Zapisnik";
import InformalEducation from "../../components/Students/InformalEducation/InformalEducation";
import FormalEducation from "../../components/Students/FormalEducation/FormalEducation";
import Occupations from "../../components/LKP/Occupations";
import Comment from "../../components/Comment/Comment";
import ExamRegistration from "../../components/Students/FormalEducation/ExamRegistration";
import EducationalGroupHead from "../../components/Teachers/EducationalGroupHead/EducationalGroupHead";
import StudentsRegister from "../../components/Students/StudentsRegister";
import RegistryBooks from "../../components/Students/RegistryBooks/RegistryBooks";
import MarksOverview from "../../components/Students/FormalEducation/MarksOverview";
import Users from "../../components/Users/Users";
import QrScan from "../../components/public/QRScan";
import LkpTemplate from "../../components/LKP/LKPTemplate";
import {lookup} from "../../components/LKP/lookup";
import LookupSimpler from "../../components/LKP/LookupSimpler";
import {Statistic} from "semantic-ui-react";
import Statistics from "../../components/Statistics/Statistics";


function AppRoutes() {
    return (

        <Routes>

                <Route index path="/" element={<Home />} />
                <Route exact index path="/verifikacija-dokumenta/:documentTypeId/:sspId" element={<QrScan />} />
                <Route path="/u/:id" element={<PersonSelector/>}  />
                <Route exact path="/organizatori" element={<Organizers/>} />
                <Route exact path="/organizatori/detalji" element={<OrganizerDetails/>} />
                <Route exact path="/profil" element={<OrganizerDetails/>} />
                <Route exact path="/organizatori/zanimanja" element={<OrganizerOccupations/>} />
                <Route exact path="/zanimanja" element={<OrganizerOccupations/>} />
                <Route exact path="/sifrarnici/zanimanja" element={<Occupations/>} />
                <Route exact path="/organizatori/raspored" element={<Schedulers/>} />
                <Route exact path="/organizatori/konkursi" element={<Contests/>} />
                <Route exact path="/organizatori/ugovori" element={<Contracts/>} />
                <Route exact path="/organizatori/poslodavci" element={<Employers/>} />

                <Route exact path="/polaznici" element={<Students/>} />
                <Route exact path="/polaznici/detalji" element={<StudentDetails/>} />
                <Route exact path="/polaznici/maticne-knjige" element={<RegistryBooks/>} />
                <Route exact path="/polaznici/registar-studenata" element={<StudentsRegister/>} />
                <Route exact path="/polaznici/zahtjevi-za-promjenu-podataka" element={<EditRequest/>} />
                <Route exact path="/polaznici/dokumenti" element={<Schedulers/>} />
                <Route exact path="/polaznici/rjesenje-o-razlici-predmeta" element={<RecognizedCourseDocument/>} />
                <Route exact path="/polaznici/kreiranje" element={<AddStudent/>} />
                <Route exact path="/polaznici/zapisnik" element={<Zapisnik/>} />
                <Route exact path="/polaznici/neformalno-obrazovanje" element={<InformalEducation/>} />
                <Route exact path="/polaznici/formalno-obrazovanje" element={<FormalEducation/>} />
                <Route exact path="/polaznici/formalno-obrazovanje/prijava" element={<ExamRegistration/>} />
                <Route exact path="/polaznici/formalno-obrazovanje/pregled-ocjena" element={<MarksOverview/>} />

                <Route exact path="/npp" element={<StudyPrograms/>} />
                <Route exact path="/npp/predmeti" element={<Courses/>} />

                <Route exact path="/nastava/predmeti" element={<AllCourses/>} />

                <Route exact path="/nastavnici" element={<Teachers/>} />
                <Route exact path="/nastavnici/detalji" element={<TeacherDetails/>} />
                <Route exact path="/komisije" element={<Committee/>} />
                <Route exact path="/voditelj-obrazovne-grupe" element={<EducationalGroupHead/>} />

                <Route exact path="/andragoska-dokumentacija" element={<AndragogyDocumentation/>} />
                <Route exact path="/andragoska-dokumentacija/obrasci" element={<DocumentTypes/>} />
                <Route exact path="/andragoska-dokumentacija/zalihe" element={<DocumentSupplies/>} />

                <Route exact path="/korisnici" element={<Users/>} />
                <Route exact path="/statistika" element={<Statistics/>} />


                <Route exact path="/sifrarnici" element={<LkpTemplate/>} />

                {lookup.map(
                    (configObj, index) =>
                        <Route exact key={index} path={'/sifrarnici' + configObj.path}
                               element={<LookupSimpler config={configObj}/>}
                        />
                )}

                <Route exact path="/komentari-i-prijedlozi" element={<Comment/>} />

        </Routes>

    );
}

export default AppRoutes;
