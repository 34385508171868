import React from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import moment from "moment";

const PreviousEducation = ({previousEducation}) => {
    return (
        <Table celled size={"small"}>
            <Table.Body>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Naziv završene institucije </Table.HeaderCell></Table.Cell>
                    <Table.Cell width={10}>{previousEducation ? previousEducation.institutionName : ''}</Table.Cell>
                </Table.Row>
                <Table.Row >
                    <Table.Cell><Table.HeaderCell>Broj dokumenta </Table.HeaderCell></Table.Cell>
                    <Table.Cell>{previousEducation ? previousEducation.documentNumber : ''}</Table.Cell>
                </Table.Row>
                <Table.Row >
                    <Table.Cell><Table.HeaderCell>Tip dokumenta </Table.HeaderCell></Table.Cell>
                    <Table.Cell>{previousEducation ? previousEducation.documentType.name : ''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Datum završetka</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{previousEducation ? moment(previousEducation.documentDate).format('D. M. YYYY.') : ''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Stepen obrazovanja</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{previousEducation ? previousEducation.educationLevel.name : ''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell> <Table.HeaderCell>Titula</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{previousEducation ? previousEducation.title : ''}</Table.Cell>
                </Table.Row>
                {/*<Table.Row>*/}
                {/*    <Table.Cell> <Table.HeaderCell>Mjesto institucije</Table.HeaderCell></Table.Cell>*/}
                {/*    <Table.Cell>{previousEducation ? previousEducation.institutionPlace.name : ''}</Table.Cell>*/}
                {/*</Table.Row>*/}
            </Table.Body>
        </Table>
    );
};

export default PreviousEducation;
