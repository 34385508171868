import React from 'react';
import {Header, Segment, SegmentGroup} from "semantic-ui-react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Select from "semantic-ui-react/dist/commonjs/addons/Select";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";


const Schedulers = () => {
    return (
        <div>
            <SegmentGroup >
                <Segment color={"teal"}>
                    <Header as={'h3'}>  Plan izvođenja nastave </Header>
                </Segment>
                <Segment>
                    <Form>
                        <Form.Group widths='equal'>

                                <Form.Field
                                    control={Select}
                                    label={'Period'}
                                    options={[]}
                                    placeholder='Period'
                                    search
                                    searchInput={{ id: 'form-select-control-gender' }}
                                />
                                <Form.Field
                                    control={Select}
                                    label={'Period'}
                                    options={[]}
                                    placeholder='Period'
                                    search
                                    searchInput={{ id: 'form-select-control-gender' }}
                                />
                            <Form.Field/>
                            <Form.Field/>
                            <Form.Field/>

                        </Form.Group>
                    </Form>


                    <Table basic='very' celled >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Nastavni program</Table.HeaderCell>
                                <Table.HeaderCell>Datum i vrijeme</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as='h4' image>

                                        <Header.Content>
                                            Lena
                                            <Header.Subheader>Human Resources</Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>22. 10. 2022.</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as='h4' image>

                                        <Header.Content>
                                            Matthew
                                            <Header.Subheader>Fabric Design</Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>22. 10. 2022.</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as='h4' image>

                                        <Header.Content>
                                            Lindsay
                                            <Header.Subheader>Entertainment</Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>22. 10. 2022.</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as='h4' image>

                                        <Header.Content>
                                            Mark
                                            <Header.Subheader>Executive</Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>22. 10. 2022.</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Segment>

            </SegmentGroup>
        </div>
    );
};

export default Schedulers;
