import React, {useEffect, useReducer, useState} from 'react';
import {Header, Icon, Menu, Popup, Segment, Table} from "semantic-ui-react";
import {lookup} from "./lookup";
import axios from "axios";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import DefaultForm from "./LKPForms/DefaultForm";
import {toast, ToastContainer} from "react-toastify";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'loader':
            return {...state, loader: action.payload};
        case 'data':
            return {...state, data: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        default:
            return state;

    }
}
const LookupSimpler = ({config}) => {
    const [showAddForm, setShowAddForm] = useState(false);
    const [state, dispatch] = useReducer(reducer,{
        loader: false,
        data: [],
        currentId: null,
    });

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }


    function deleteRow(id){
        setCurrentId(id);
        dispatch({type: 'loader', payload: true});
        axios.delete(config.urls.delete + '/' + id).then(
            (res) => {
                getLKPData();
                toast('Uspješno ste izbrisali podatak.');
                setCurrentId(null);
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            toast('Došlo je do greške, podatak nije obrisan.');
            console.log('err', err)
        });
    }

    useEffect(() => {
        getLKPData();
    },[]);
    function getLKPData(){
        dispatch({type: 'loader', payload: true});
        axios.get(config.urls.get,).then(
            (res) => {
                let data = res.data;
                dispatch({type: 'data', payload: data});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function addNewEntry(obj){

        axios.post(config.urls.add,{...obj}).then(
            (res) => {
                toast('Uspješno ste dodali podatak.');
                getLKPData();
            }).catch((err) => {
            toast('Došlo je do greške, podatak nije dodan.');
            console.log('err', err)
        });
    }

    return (
        <div>
            <Segment.Group>
                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}>
                                {localStorage.getItem('headerName')}
                            </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            <Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={()=>setShowAddForm(true)}>
                                    <Icon name='add' color={"teal"}/>
                                    Dodaj
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                            <span>
                                <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-2)}>
                                    <Icon name='arrow left'/>
                                </Button>
                                <Icon/>
                            </span>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>

                </Segment>
            {showAddForm && <Segment><DefaultForm addNewEntry={addNewEntry} add additionalInformation={config.additionalInformation} toggleEditForm={toggleAddForm}/></Segment>}
                <Segment>
            <Table celled size={'small'}>
                {/*<Table.Header>*/}
                {/*    <Table.Row>*/}
                {/*        <Table.HeaderCell colSpan={2}><Button onClick={()=>setShowAddForm(true)}>Dodaj</Button> </Table.HeaderCell>*/}

                {/*    </Table.Row>*/}
                {/*</Table.Header>*/}
                    <Table.Header>
                    <Table.Row>
                    {
                        config.columns.map((el, index)=>
                            ( <Table.HeaderCell >{el.simpleName}</Table.HeaderCell>
                       ))
                    }
                        <Table.HeaderCell width={1} verticalAlign="middle" textAlign="center"></Table.HeaderCell>

                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        state.data.map((el, index)=>
                            (<Table.Row key={index}
                                        onClick={() => {
                                            //   setPath('/sifrarnici' + el.path);
                                            //  setRedirect(true);
                                        }}
                                >
                                    {
                                        config.columns.map((e, index)=>
                                            (   <Table.Cell>{el[e.name]}</Table.Cell>
                                            ))
                                    }
                                <Table.Cell width={1} verticalAlign="middle" textAlign="center">
                                    <Popup size={"small"} content='Obriši' trigger={
                                    <Button icon size={"mini"} basic color={"grey"}
                                            disabled={state.currentId && el.id !== state.currentId } onClick={() => {deleteRow(el.id)}}
                                    >
                                        <Icon name='trash alternate outline' color={"red"} />
                                    </Button>} /></Table.Cell>

                                </Table.Row>))
                    }
                </Table.Body>
            </Table>
                </Segment>
                <ToastContainer/>
            </Segment.Group>
        </div>
    );
};

export default LookupSimpler;
